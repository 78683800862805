import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IRelation } from "../relationModel";

export const getAllRelation = createAsyncThunk(
  "Relation/RelationFindAll",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Relation/RelationFindAll`,
        {
          RelationName: "",
        }
      );
      //   return response.data?.Data;
      if (response?.data?.Status === 200) {
        // SuccessToast(response.data?.Details || "Success");
        return response.data?.Data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      console.error("Error Fetching Relation:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createRelation = createAsyncThunk(
  "Relation/RelationInsert",
  async (Relation: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Relation/RelationInsert`,
        Relation
      );
      if (response.data?.Status === 201) {
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Inserting Relation:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateRelation = createAsyncThunk(
  "Relation/RelationUpdate",
  async (Relation: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Relation/RelationUpdate`,
        Relation
      );
      if (response.data?.Status === 200) {
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Updating Relation:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getRelationById = createAsyncThunk(
  "Relation/RelationFindByID",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Relation/RelationFindByID`,
        {
          RelationGUID: ID,
        }
      );
      const result = response.data?.Data as any;
      return {
        ID: result.ID,
        RelationGUID: result.RelationGUID,
        RelationName: result.RelationName,
        IsActive: result.IsActive === true ? 1 : 2,
      } as IRelation;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Relation:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteRelation = createAsyncThunk(
  "Relation/RelationDelete",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Relation/RelationDelete`,
        {
          RelationGUID: ID,
        }
      );
      if (response.data?.Status === 200) {
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Deleting Relation:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const findAllActiveRelation = createAsyncThunk(
  "Relation/RelationFindAllActive",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Relation/RelationFindAllActive`
      );
      // SuccessToast(response.data?.Details || "Success")
      return response.data.Data;
    } catch (error: any) {
      console.error("Error Deleting Relation:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const RelationActiveInactive = createAsyncThunk(
  "Relation/RelationActiveInActive",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Relation/RelationActiveInActive`,
        formData
      );
      if (response.data?.Status === 200) {
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive Relation:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
