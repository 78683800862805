import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IClient,
  IClientFormInitialState,
  IInitial,
  IMnageUser,
} from "./clientformModel";
import {
  ClientActiveInactive,
  createClient,
  deleteClient,
  deleteLeadFamilyDetails,
  downloadClientPDF,
  findAllActiveClient,
  findAllActiveInitial,
  getAllClient,
  getAllUser,
  getClientById,
  updateClient,
  uploadClientImages,
} from "./services/services.clientform";

const initialState: IClientFormInitialState = {
  loading: false,
  clientLoading: false,
  formLoading: false,
  Lead_Stage: 0,
  error: "",
  ClientList: [],
  InitialList: [],
  UserList: [],
  ClientID: null,
  ClientDetail: {
    LeadStage: 1,
    InitialID: null,
    FirstName: "",
    LastName: "",
    MobileNo: "",
    EmailID: "",
    LeadOwnerID: null,
    CompanyName: "",
    Title: "",
    PhoneNo: "",
    Website: "",
    LeadSourceID: null,
    RefName: "",
    RefMobileNo: "",
    Industry: "",
    NoofEmp: "",
    LeadImage: "",
    AnnualRevenue: "",
    Address: "",
    CityID: null,
    StateID: null,
    CountryID: null,
    CategoryID: null,
    CategoryName: "",
    OccupationID: null,
    OccupationName: "",
    BirthDate: "",
    MarriageDate: "",
    IsFamilyDetails: true,
    PinCode: "",
    Notes: "",
    GroupCode: "",
    RelationID: null,
    ReferenceID: null,
    Details: "",
    Message: "",
    FamilysDetails: [],
    IsActive: 1,
  },
  DeleteIndex: -1,
  LeadSearchFilter: [],
  LeadSearch: "",
  LeadSearchField: "",
};

export const clientformSlice = createSlice({
  name: "client",
  initialState,
  reducers: {
    clearClientDetails: (state) => {
      state.ClientDetail = initialState.ClientDetail;
    },
    setClientID: (state, action) => {
      state.ClientID = action.payload;
    },
    clearClientID: (state) => {
      state.ClientID = null;
    },
    setFamilyDeleteIndex: (state, action) => {
      state.DeleteIndex = action.payload;
    },
    setLeadStage: (state, action) => {
      state.Lead_Stage = action.payload;
    },
    setLeadSearch: (state, action) => {
      state.LeadSearch = action.payload;
    },
    setLeadSearchField: (state, action) => {
      state.LeadSearchField = action.payload;
    },
    setLeadSearchFilter: (state, action) => {
      state.LeadSearchFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllClient.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllClient.fulfilled,
      (state, action: PayloadAction<IClient[]>) => {
        state.loading = false;
        state.ClientList = action.payload;
      }
    );
    builder.addCase(getAllClient.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.ClientList = [];
    });

    builder.addCase(findAllActiveClient.pending, (state) => {
      state.clientLoading = true;
      state.error = "";
    });
    builder.addCase(
      findAllActiveClient.fulfilled,
      (state, action: PayloadAction<IClient[]>) => {
        state.clientLoading = false;
        state.ClientList = action.payload || [];
      }
    );
    builder.addCase(findAllActiveClient.rejected, (state, action) => {
      state.clientLoading = false;
      state.error = action.error.message || "Something went wrong";
      state.ClientList = [];
    });

    builder.addCase(createClient.pending, (state) => {
      state.formLoading = false;
      state.error = "";
    });
    builder.addCase(createClient.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createClient.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(updateClient.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateClient.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateClient.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(deleteClient.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteClient.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteClient.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(ClientActiveInactive.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(ClientActiveInactive.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(ClientActiveInactive.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getClientById.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getClientById.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.ClientDetail = action.payload;
    });
    builder.addCase(getClientById.rejected, (state, action) => {
      state.formLoading = false;
      state.ClientDetail = initialState.ClientDetail;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteLeadFamilyDetails.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteLeadFamilyDetails.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteLeadFamilyDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(findAllActiveInitial.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      findAllActiveInitial.fulfilled,
      (state, action: PayloadAction<IInitial[]>) => {
        state.loading = false;
        state.InitialList = action.payload || [];
      }
    );
    builder.addCase(findAllActiveInitial.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.InitialList = [];
    });
    builder.addCase(getAllUser.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllUser.fulfilled,
      (state, action: PayloadAction<IMnageUser[]>) => {
        state.loading = false;
        state.UserList = action.payload;
      }
    );
    builder.addCase(getAllUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.UserList = [];
    });

    builder.addCase(uploadClientImages.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(uploadClientImages.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(uploadClientImages.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(downloadClientPDF.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(downloadClientPDF.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(downloadClientPDF.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearClientDetails,
  setClientID,
  clearClientID,
  setFamilyDeleteIndex,
  setLeadStage,
  setLeadSearch,
  setLeadSearchField,
  setLeadSearchFilter,
} = clientformSlice.actions;
export default clientformSlice.reducer;
