import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  deleteInquiryArrayDetails,
  nbInquiryFindByLeadStatus,
} from "./services/nbinquiry.services";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  GridLayout,
  GridLayoutItem,
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import { CompositeFilterDescriptor } from "@progress/kendo-data-query";
import {
  GridCellProps,
  GridCustomCellProps,
  GridCustomHeaderCellProps,
  Grid as KendoGrid,
  GridColumn as Column,
  GridFilterChangeEvent,
  GridPageChangeEvent,
} from "@progress/kendo-react-grid";
import { LoadingPanel } from "../../components/layout/Loading";
import AlertBox from "../../components/common/AlertBox";
import {
  HeaderThElement,
  PagerTargetEvent,
} from "@progress/kendo-react-data-tools";
import { filterBy } from "@progress/kendo-data-query";
import { FiEdit } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { TiLockClosedOutline, TiLockOpenOutline } from "react-icons/ti";
import { Button } from "@progress/kendo-react-buttons";
import IconButton from "../../components/common/IconButton";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import { clearNBInquiryTypeID, setNBInquiryTypeID } from "./nbinquirySlice";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { SuccessToast } from "../../components/toast/Toasts";
import TabStripTitle from "../../components/common/TabStripTitle";
import {
  AiOutlineAudio,
  AiOutlineDeploymentUnit,
  AiOutlineFile,
  AiOutlineFileDone,
  AiOutlinePaperClip,
  AiOutlinePhone,
  AiOutlineTeam,
} from "react-icons/ai";
import {
  DELETE_ACCESS,
  EDIT_ACCESS,
  INSERT_ACCESS,
} from "../../_contstants/common";
import { checkAcessRights } from "../../_helper/helper";
import { useResponsiveJSX } from "../../_helper/helper";

const CreateBNInquiryTypeStatus: React.FC = () => {
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const isAddAccess = checkAcessRights("/inquirylist", INSERT_ACCESS);

  const [selected, setSelected] = React.useState<any>(
    location.state.InquiryStatus.status
  );
  // const InquiryStatusDetailList = useAppSelector(
  //   (state) => state?.nbinquiry?.InquiryStatusDetailList
  // );
  const loading = useAppSelector((state) => state.nbinquiry.loading);
  const LeadID = location.state?.InquiryStatus?.LeadID;
  const ClientFullName = location.state?.InquiryStatus?.FullName;
  const ClietnLeadStage = location.state?.InquiryStatus?.LeadStage;
  const OpenCloseCount = useAppSelector(
    (state) => state.nbinquiry.OpenCloseCount
  );
  // console.log("OpenCloseCount", OpenCloseCount);
  const OpenCount = OpenCloseCount?.OpenCount || 0;
  const CloseCount = OpenCloseCount?.CloseCount || 0;

  // const OpenCount =
  //   InquiryStatusDetailList?.length > 0
  //     ? InquiryStatusDetailList[0]?.OpenCount
  //     : location.state?.InquiryStatus?.OpenCount ?? 0;
  // const CloseCount =
  //   InquiryStatusDetailList?.length > 0
  //     ? InquiryStatusDetailList[0]?.CLoseCount
  //     : location.state?.InquiryStatus?.CloseCount ?? 0;

  // console.log("InquiryStatusDetailList", InquiryStatusDetailList);

  // useEffect(() => {
  //   const payload = {
  //     LeadGUID: location.state?.LeadGUID,
  //     InquiryStatus: location.state.InquiryStatus?.InquiryStatus,
  //   };
  //   dispatch(nbInquiryFindByLeadStatus(payload));
  // }, []);

  useEffect(() => {
    const payload = {
      LeadGUID: location.state?.LeadGUID,
      InquiryStatus:
        selected === 0 ? "OPEN" : selected === 1 ? "CLOSED" : "ALL",
    };
    dispatch(nbInquiryFindByLeadStatus(payload));
  }, [selected]);

  const handleSelect = (e: TabStripSelectEventArguments) => {
    setSelected(e.selected);
  };
  const handleCreateInquiry = () => {
    navigate(`${location.pathname}/create`, {
      state: {
        inquiryType: {
          IsInquiryType: true,
          LeadID: LeadID,
          FullName: ClientFullName,
          LeadStage: ClietnLeadStage,
        },
      },
    });
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      <DeleteNBinquiryTypeDialog />
      <GridLayoutItem
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "4px 20px 20px 0",
        }}
      >
        <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
          Inquiry List
        </Typography.h4>
        {isAddAccess && (
          <Button
            onClick={handleCreateInquiry}
            fillMode={"solid"}
            themeColor={"primary"}
          >
            Create Inquiry
          </Button>
        )}
      </GridLayoutItem>
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem>
          <TabStrip
            className="nb-lead-type-status"
            selected={selected}
            onSelect={handleSelect}
          >
            <TabStripTab
              title={
                <TabStripTitle
                  title={`Open (${OpenCount}) `}
                  icon={TiLockOpenOutline}
                />
              }
            >
              <StatusTabel />
            </TabStripTab>
            <TabStripTab
              title={
                <TabStripTitle
                  title={`Close (${CloseCount})`}
                  icon={TiLockClosedOutline}
                />
              }
            >
              <StatusTabel />
            </TabStripTab>
            <TabStripTab
              title={
                <TabStripTitle
                  // title={`All (${OpenCount + CloseCount})`}
                  title={`All  (${OpenCount + CloseCount})`}
                  icon={AiOutlineDeploymentUnit}
                />
              }
            >
              <StatusTabel />
            </TabStripTab>
          </TabStrip>
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

interface PageState {
  skip: number;
  take: number;
}
const initialDataState: PageState = { skip: 0, take: 10 };
const StatusTabel: React.FC = () => {
  //   const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const LeadID = location.state.InquiryStatus.LeadID;
  const formloading = useAppSelector((state) => state.nbinquiry.formLoading);
  const breakPoint = useResponsiveJSX([1024, 2170, 2514]);
  const dynamicWidth = [150, 200, 250, 300][breakPoint] ?? 300;

  const isEditAccess = checkAcessRights("/inquirylist", EDIT_ACCESS);
  const isDeleteAccess = checkAcessRights("/inquirylist", DELETE_ACCESS);
  const InquiryStatusDetailList = useAppSelector(
    (state) => state?.nbinquiry?.InquiryStatusDetailList
  );
  const gridRef = useRef<any>(null);

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);
  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
    setPage({ skip: 0, take: page.take });
  };
  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();

  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All"
        ? InquiryStatusDetailList.length
        : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };
  const InquiryActivityLogsCommandCell = (props: GridCellProps) => {
    const handleActivityLogs = ({
      LeadID,
      NBInquiryTypeID,
      NBLeadTypeID,
      ActivityLogType,
    }: any) => {
      if (location.pathname.startsWith("/nbinquiry")) {
        navigate("/nbinquiry/nbinquiryactivitylogs", {
          state: {
            ActivityLog: {
              LeadID,
              NBInquiryTypeID,
              NBLeadTypeID,
              ActivityLogType,
            },
          },
        });
      }
      if (location.pathname.startsWith("/clientform")) {
        navigate("/clientform/clientactivitylogs", {
          state: {
            ActivityLog: {
              LeadID,
              NBInquiryTypeID,
              NBLeadTypeID,
              ActivityLogType,
            },
          },
        });
      }
    };

    return (
      <td
        className={`action-td ${props.className}`}
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "5px",
          flexWrap: "wrap",
          ...props.style,
        }}
      >
        <Button
          themeColor={"warning"}
          type="button"
          fillMode="outline"
          size="small"
          title="Notes"
          onClick={() =>
            handleActivityLogs({
              LeadID: LeadID,
              NBInquiryTypeID: props.dataItem.ID,
              NBLeadTypeID: null,
              ActivityLogType: 0,
            })
          }
        >
          <AiOutlineFile />
        </Button>
        <Button
          themeColor={"inverse"}
          type="button"
          fillMode="outline"
          size="small"
          title="Tasks"
          onClick={() =>
            handleActivityLogs({
              LeadID: LeadID,
              NBInquiryTypeID: props.dataItem.ID,
              NBLeadTypeID: null,
              ActivityLogType: 1,
            })
          }
        >
          <AiOutlineFileDone />
        </Button>
        <Button
          themeColor={"secondary"}
          type="button"
          fillMode="outline"
          size="small"
          title="Calls"
          onClick={() =>
            handleActivityLogs({
              LeadID: LeadID,
              NBInquiryTypeID: props.dataItem.ID,
              NBLeadTypeID: null,
              ActivityLogType: 2,
            })
          }
        >
          <AiOutlinePhone />
        </Button>
        <Button
          themeColor={"success"}
          type="button"
          fillMode="outline"
          size="small"
          title="Meetings"
          onClick={() =>
            handleActivityLogs({
              LeadID: LeadID,
              NBInquiryTypeID: props.dataItem.ID,
              NBLeadTypeID: null,
              ActivityLogType: 3,
            })
          }
        >
          <AiOutlineTeam />
        </Button>
        <Button
          themeColor={"tertiary"}
          type="button"
          fillMode="outline"
          size="small"
          title="Attachments"
          onClick={() =>
            handleActivityLogs({
              LeadID: LeadID,
              NBInquiryTypeID: props.dataItem.ID,
              NBLeadTypeID: null,
              ActivityLogType: 4,
            })
          }
        >
          <AiOutlinePaperClip />
        </Button>
        <Button
          themeColor={"error"}
          type="button"
          fillMode="outline"
          size="small"
          title="Recording"
          onClick={() =>
            handleActivityLogs({
              LeadID: LeadID,
              NBInquiryTypeID: props.dataItem.ID,
              NBLeadTypeID: null,
              ActivityLogType: 5,
            })
          }
        >
          <AiOutlineAudio />
        </Button>
      </td>
    );
  };

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleEditInquiry = (inquiryType: any) => {
      navigate(`${location.pathname}/edit`, {
        state: { inquiryType: inquiryType },
      });
    };
    const handleOpenDeleteDialog = (ID: number) => {
      dispatch(openDialog("deleteNBinquiryType"));
      dispatch(setNBInquiryTypeID(ID));
    };
    return (
      <td className={`action-td ${props.className}`} style={{ ...props.style }}>
        {isEditAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Edit"
            onClick={() =>
              handleEditInquiry({
                NBInquiryTypeGUID: props.dataItem.NBInquiryTypeGUID,
                LeadID: LeadID,
                NBInquiryBy: props.dataItem.NBInquiryBy,
                IsInquiryType: true,
              })
            }
          >
            <FiEdit
              className="absolute-position"
              style={{ fontSize: "20px" }}
            />
          </IconButton>
        )}
        {isDeleteAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Delete"
            onClick={() => handleOpenDeleteDialog(props.dataItem.ID)}
          >
            <MdDelete
              className="absolute-position"
              style={{ fontSize: "24px" }}
            />
          </IconButton>
        )}
      </td>
    );
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      key={props.thProps?.key}
      columnId={props.thProps?.columnId || ""}
      style={props.thProps?.style}
      {...props.thProps}
      className={`table-header ${
        props.index === 9 ||
        props.index === 10 ||
        props.index === 0 ||
        props.index === 1
          ? "locked-header"
          : ""
      }`}
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td
        style={{ ...props.style }}
        {...props.tdProps}
        colSpan={1}
        className={`table-cell ${props.className}`}
      >
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  if (formloading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <>
      {InquiryStatusDetailList && InquiryStatusDetailList?.length ? (
        <KendoGrid
          style={{ maxHeight: "calc(100vh - 152px)" }}
          filterable={true}
          filter={filter}
          data={
            filter
              ? filterBy(InquiryStatusDetailList, filter).slice(
                  page.skip,
                  page.take + page.skip
                )
              : InquiryStatusDetailList.slice(page.skip, page.take + page.skip)
          }
          onFilterChange={handleFilterChange}
          skip={page.skip}
          take={page.take}
          total={InquiryStatusDetailList.length}
          pageable={{
            buttonCount: 5,
            pageSizes: [5, 10, 15, "All"],
            pageSizeValue: pageSizeValue,
            type: "input",
          }}
          onPageChange={pageChange}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
        >
          <Column
            field="InquiryNo"
            locked
            title="Inquiry No"
            width={dynamicWidth}
          />
          <Column
            field="InquiryDate"
            locked
            title="Inquiry Date"
            width={dynamicWidth}
          />
          <Column
            field="NBInquiryByName"
            title="Inquiry By Person"
            width={250}
          />
          <Column field="LeadType" title="Lead Type" width={dynamicWidth} />
          <Column
            field="InquiryType"
            title="Inquiry Type"
            width={dynamicWidth}
          />
          <Column
            field="InquirySubType"
            title="Inquiry Sub Type"
            width={dynamicWidth}
          />
          <Column
            field="InquiryAllotmentName"
            title="Alloted To Person"
            width={250}
          />
          <Column field="LeadStatus" title="Lead Status" width={dynamicWidth} />
          <Column field="Frequency" title="Frequency" width={dynamicWidth} />
          <Column
            field="ID"
            title="Activity Logs"
            cell={InquiryActivityLogsCommandCell}
            width={150}
            filterable={false}
            locked
          />
          {(isDeleteAccess || isEditAccess) && (
            <Column
              locked
              field="NBInquiryTypeGUID"
              title="Actions"
              cell={MyEditCommandCell}
              width={110}
              filterable={false}
            />
          )}
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

const DeleteNBinquiryTypeDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const NBinquiryTypeID = useAppSelector(
    (state) => state.nbinquiry.NBinquiryTypeID
  );
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const location = useLocation();
  const status = location.state.InquiryStatus.status;
  const handleDeleteAction = async (ID: number | null) => {
    if (ID) {
      const response = await dispatch(deleteInquiryArrayDetails(ID));
      const payload = {
        LeadGUID: location.state.LeadGUID,
        InquiryStatus: status === 0 ? "OPEN" : status === 1 ? "CLOSED" : "ALL",
      };

      if (response.payload?.Status === 200) {
        SuccessToast(response.payload?.Details || "Success");
        dispatch(nbInquiryFindByLeadStatus(payload));
        dispatch(closeDialog());
        dispatch(clearNBInquiryTypeID());
      } else {
        SuccessToast(response.payload?.Details || "Something went wrong");
        dispatch(nbInquiryFindByLeadStatus(payload));
        dispatch(closeDialog());
        dispatch(clearNBInquiryTypeID());
      }
    }
  };

  return (
    <>
      {dialogName === "deleteNBinquiryType" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete NBinquiry ?"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this Inquiry?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => {
                    handleDeleteAction(NBinquiryTypeID);
                  }}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

export default CreateBNInquiryTypeStatus;
