import React, { useEffect, useRef } from "react";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { Typography } from "@progress/kendo-react-common";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { closeDialog } from "../../../components/dialog/dialogSlice";
import AppDialog from "../../../components/dialog/Dialog";
import FormTextField from "../../../components/formFields/FormTextField";
import { requiredValidator } from "../../../components/formFields/CommonValidator";
import FormTextArea from "../../../components/formFields/FormTextArea";
import FormFileUpload from "../../../components/formFields/FormFileUpload";
import FormSelectionField from "../../../components/formFields/FormSelectionField";
import FormDatePicker from "../../../components/formFields/FormDateField";
import FormCheckbox from "../../../components/formFields/FormCheckbox";
import FormTimePicker from "../../../components/formFields/FormTimeField";
import moment from "moment";
import {
  createTask,
  getAllTasks,
  getTaskById,
  updateTask,
} from "./services/services.tasks";
import { clearTasksDetail } from "./taskSlice";
import { LoadingPanel } from "../../../components/layout/Loading";
import { IMnageUser } from "../../clientForm/clientformModel";
import { useLocation } from "react-router-dom";

import { InserAttachment } from "../notes/services/services.notes";
import SlickSlider from "../../../components/common/SlickSlider";
import {
  Notifyvia,
  Repeat,
  task_status,
  TaskPriority,
} from "../../../_contstants/common";

const CloseLogDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const closeLogDialog = () => {
    dispatch(closeDialog());
  };
  return (
    <Button
      themeColor={"primary"}
      fillMode={"outline"}
      onClick={closeLogDialog}
    >
      Close
    </Button>
  );
};

const CreateTaskDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const formLoading = useAppSelector((state) => state.tasks.formLoading);
  const gridRef = useRef<any>(null);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const TasksDetail = useAppSelector((state) => state.tasks.TasksDetail);
  const UserList = useAppSelector((state) => state?.client?.UserList);
  const SelectedTab = useAppSelector((state) => state?.tasks?.SelectedTab);
  const LeadID = location?.state?.ActivityLog?.LeadID;
  const NBInquiryTypeID = location?.state?.ActivityLog?.NBInquiryTypeID;
  const NBLeadTypeID = location.state?.ActivityLog?.NBLeadTypeID;
  const TaskGUID = useAppSelector((state) => state.tasks.TaskGUID);
  useEffect(() => {
    if (TaskGUID) {
      dispatch(getTaskById(TaskGUID));
    }
  }, [TaskGUID]);

  const handleSubmit = async (values: any) => {
    const payload = {
      LeadID: LeadID ?? null,
      NBInquiryTypeID: NBInquiryTypeID ?? null,
      NBLeadTypeID: NBLeadTypeID ?? null,
    };
    const formData = {
      ...payload,
      TaskOwnerID: values.TaskOwnerID,
      Subject: values.Subject,
      StartDate: values.StartDate
        ? moment(values.StartDate).format("YYYY-MM-DD")
        : null,
      DueDate: values.DueDate
        ? moment(values.DueDate).format("YYYY-MM-DD")
        : null,
      TaskStatus: values.TaskStatus,
      Priority: values.Priority,
      Description: values.Description,
      IsReminder: values.IsReminder,
      ...(values.IsReminder && {
        ReminderDate: values.ReminderDate
          ? moment(values.ReminderDate).format("YYYY-MM-DD")
          : null,
        ReminderTime: values.ReminderTime
          ? moment(values.ReminderTime).format("HH:mm")
          : null,
        Repeat: values.Repeat,
        NotifyVia: values.NotifyVia,
      }),
      IsActive: values.IsActive === 1,
    };
    try {
      if (TaskGUID) {
        const editPayload = {
          TaskGUID: TaskGUID,
          ...formData,
        };
        dispatch(updateTask(editPayload));
        dispatch(
          getAllTasks({
            ...payload,
            TaskStatus: SelectedTab === 0 ? "" : "Completed",
          })
        );
        dispatch(closeDialog());
        dispatch(clearTasksDetail());
      } else {
        const response = await dispatch(createTask(formData));
        if (response?.payload?.Status === 201) {
          if (values.attachments) {
            const files = new FormData();
            files.append("AttachmentType", "Task");
            let AttachmentName: string[] = [];
            values?.attachments?.forEach((doc: any) => {
              const binaryDoc = doc.getRawFile();
              AttachmentName.push(binaryDoc.name);
              files.append("AttachmentURL", binaryDoc);
            });
            files.append("AttachmentName", AttachmentName.join(","));
            files.append("LeadID", LeadID || "");
            files.append(
              "ReferenceGUID",
              response?.payload?.Data?.ReferenceGUID || ""
            );
            files.append(
              "NBInquiryTypeID",
              NBInquiryTypeID ? NBInquiryTypeID : ""
            );
            files.append("NBLeadTypeID", NBLeadTypeID ?? "");
            dispatch(InserAttachment(files));
          }
          dispatch(
            getAllTasks({
              ...payload,
              TaskStatus: SelectedTab === 0 ? "" : "Completed",
            })
          );
          dispatch(closeDialog());
          dispatch(clearTasksDetail());
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  if (formLoading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      {dialogName === "TASK" && (
        <AppDialog className="dialog-width">
          <Form
            onSubmit={handleSubmit}
            initialValues={TasksDetail}
            render={(formRenderProps: FormRenderProps) => (
              <FormElement>
                <Typography.h5 style={{ margin: 0, textAlign: "center" }}>
                  {TaskGUID ? "Update Task" : "Create Task"}
                </Typography.h5>
                <GridLayout
                  gap={{ rows: 0, cols: 5 }}
                  cols={[
                    { width: "33.33%" },
                    { width: "33.33%" },
                    { width: "33.33%" },
                  ]}
                  style={{ marginRight: 10 }}
                >
                  <GridLayoutItem>
                    <Field
                      name="TaskOwnerID"
                      label="Task Owner"
                      placeholder="Select Task Owner"
                      component={FormSelectionField}
                      options={UserList?.map((user: IMnageUser) => {
                        return {
                          value: user?.ID,
                          label: `${user?.FirstName} ${user?.LastName}`,
                        };
                      })}
                      validator={requiredValidator}
                      astrike={true}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="Subject"
                      label="Subject"
                      placeholder="Subject"
                      component={FormTextField}
                      validator={requiredValidator}
                      astrike={true}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="StartDate"
                      label="Task Date"
                      maxDate={moment().toDate()}
                      component={FormDatePicker}
                      validator={requiredValidator}
                      astrike={true}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="DueDate"
                      label="Due Date"
                      component={FormDatePicker}
                      validator={requiredValidator}
                      astrike={true}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="TaskStatus"
                      label="Status"
                      placeholder="Select Task Status"
                      component={FormSelectionField}
                      options={task_status?.map((taskstatus: any) => {
                        return {
                          value: taskstatus?.value,
                          label: taskstatus?.label,
                        };
                      })}
                      validator={requiredValidator}
                      astrike={true}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="Priority"
                      label="Priority"
                      placeholder="Select Priority"
                      component={FormSelectionField}
                      options={TaskPriority?.map((priority: any) => {
                        return {
                          value: priority?.value,
                          label: priority?.label,
                        };
                      })}
                      validator={requiredValidator}
                      astrike={true}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem colSpan={3}>
                    <Field
                      name="Description"
                      label="Description"
                      rows={3}
                      component={FormTextArea}
                      validator={requiredValidator}
                      astrike={true}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem rowSpan={2}>
                    <Field
                      name="IsReminder"
                      maxLength="100"
                      label="Reminder"
                      component={FormCheckbox}
                    />
                  </GridLayoutItem>
                  {formRenderProps.valueGetter("IsReminder") === true && (
                    <>
                      <GridLayoutItem>
                        <Field
                          name="ReminderDate"
                          label="Reminder Date"
                          component={FormDatePicker}
                          validator={requiredValidator}
                          astrike={true}
                        />
                      </GridLayoutItem>
                      <GridLayoutItem>
                        <Field
                          name="ReminderTime"
                          label="Reminder Time"
                          component={FormTimePicker}
                          validator={requiredValidator}
                          astrike={true}
                        />
                      </GridLayoutItem>
                      <GridLayoutItem>
                        <Field
                          name="Repeat"
                          label="Repeat"
                          component={FormSelectionField}
                          options={Repeat?.map((repeat: any) => {
                            return {
                              value: repeat?.value,
                              label: repeat?.label,
                            };
                          })}
                        />
                      </GridLayoutItem>
                      <GridLayoutItem>
                        <Field
                          name="NotifyVia"
                          label="Notify Via"
                          component={FormSelectionField}
                          options={Notifyvia?.map((notify: any) => {
                            return {
                              value: notify?.value,
                              label: notify?.label,
                            };
                          })}
                        />
                      </GridLayoutItem>
                    </>
                  )}
                  {!TaskGUID && (
                    <GridLayoutItem colSpan={2}>
                      <Field
                        name="attachments"
                        type="file"
                        filetype="file"
                        label="Attachments"
                        multiple
                        // accept=".xlsx,.pdf ,.text"
                        component={FormFileUpload}
                      />
                    </GridLayoutItem>
                  )}
                  {TaskGUID && TasksDetail.TasksAttachmentList.length > 0 && (
                    <GridLayoutItem
                      colSpan={2}
                      style={{ marginTop: "20px", padding: "0 25px" }}
                    >
                      <Typography.p>Attachments</Typography.p>
                      <SlickSlider
                        list={TasksDetail.TasksAttachmentList}
                        src="AttachmentURL"
                        title="AttachmentName"
                      />
                    </GridLayoutItem>
                  )}
                </GridLayout>
                <GridLayout
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "10px",
                  }}
                >
                  <Button
                    type="submit"
                    themeColor={"primary"}
                    fillMode={"solid"}
                    style={{ marginRight: "10px" }}
                    disabled={!formRenderProps.allowSubmit}
                  >
                    {TaskGUID ? "Update" : "Save"}
                  </Button>
                  <CloseLogDialog />
                </GridLayout>
              </FormElement>
            )}
          />
        </AppDialog>
      )}
    </>
  );
};

export default CreateTaskDialog;
