import React, { useRef } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import AppDialog, {
  DialogCloseButton,
} from "../../../components/dialog/Dialog";
import { Typography } from "@progress/kendo-react-common";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { openDialog } from "../../../components/dialog/dialogSlice";
import {
  CompositeFilterDescriptor,
  filterBy,
} from "@progress/kendo-data-query";
import {
  GridCellProps,
  GridCustomCellProps,
  GridCustomHeaderCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  Grid as KendoGrid,
  GridColumn as Column,
} from "@progress/kendo-react-grid";
import {
  HeaderThElement,
  PagerTargetEvent,
} from "@progress/kendo-react-data-tools";
import { LoadingPanel } from "../../../components/layout/Loading";
import IconButton from "../../../components/common/IconButton";
import { MdDelete } from "react-icons/md";
import AlertBox from "../../../components/common/AlertBox";
import { FaEdit } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { deleteMeetings, getAllMeetings } from "./services/services.meetings";
import CreateMeetingsDialog from "./CreateMeetingsDialog";
import {
  clearMeetingDetails,
  clearMeetingGUID,
  clearMeetingsID,
  setMeetingGUID,
} from "./meetingsSlice";
import moment from "moment";
import { useResponsiveJSX } from "../../../_helper/helper";

const MeetingsDialog = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const CloseLogDialog: React.FC = () => {
    return (
      <div style={{ display: "flex", justifyContent: "end" }}>
        <Button
          style={{ width: "60px", padding: 4, marginTop: 10 }}
          themeColor={"primary"}
          fillMode={"outline"}
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
      </div>
    );
  };
  return (
    <>
      {dialogName === "MEETING" && <CreateMeetingsDialog />}

      <DeleteMeetingsDialog />
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            Meetings List
          </Typography.h4>

          <Button
            style={{ width: "auto", marginLeft: 5, padding: 6 }}
            themeColor={"primary"}
            type="button"
            fillMode="solid"
            size="small"
            title="Create Meeting"
            onClick={() => {
              dispatch(openDialog("MEETING"));
              dispatch(clearMeetingDetails());
              dispatch(clearMeetingGUID());
            }}
          >
            Create Meetings
          </Button>
        </GridLayoutItem>
        <GridLayoutItem>
          <MeetingsGridComponents />
        </GridLayoutItem>
        <CloseLogDialog />
      </GridLayout>
    </>
  );
};

interface PageState {
  skip: number;
  take: number;
}
const initialDataState: PageState = { skip: 0, take: 10 };
const MeetingsGridComponents: React.FC = () => {
  const gridRef = useRef<any>(null);
  const breakPoint = useResponsiveJSX([1024, 2170, 2514]);
  const dynamicWidth = [150, 200, 250, 300][breakPoint] ?? 300;
  const dispatch = useAppDispatch();
  const MeetingsList = useAppSelector((state) => state.meetings.MeetingsList);
  const loading = useAppSelector((state) => state.meetings.loading);

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);
  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();

  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All" ? MeetingsList.length : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;

  const MyEditCommandCell = (props: GridCellProps) => {
    const ID = props.dataItem.MeetingGUID;
    const handleOpenDeleteDialog = () => {
      dispatch(openDialog("deleteMeetings"));
      dispatch(setMeetingGUID(ID));
    };
    const handleOpenEditDialog = () => {
      dispatch(setMeetingGUID(ID));
      dispatch(openDialog("MEETING"));
    };
    return (
      <td className={`action-td ${props.className}`} style={{ ...props.style }}>
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Edit"
          onClick={handleOpenEditDialog}
        >
          <FaEdit className="absolute-position" style={{ fontSize: "20px" }} />
        </IconButton>
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Delete"
          onClick={handleOpenDeleteDialog}
        >
          <MdDelete
            className="absolute-position"
            style={{ fontSize: "24px" }}
          />
        </IconButton>
      </td>
    );
  };

  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
    setPage({ skip: 0, take: page.take });
  };
  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      style={props.thProps?.style}
      className={`table-header ${
        props.index === 13 || props.index === 0 ? "locked-header" : ""
      }`}
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td
        style={{ ...props.style }}
        {...props.tdProps}
        colSpan={1}
        className={`table-cell ${props.className}`}
      >
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );
  return (
    <>
      {MeetingsList && MeetingsList.length ? (
        <KendoGrid
          style={{ maxHeight: "calc(100vh - 152px)" }}
          filterable={true}
          filter={filter}
          data={
            filter
              ? filterBy(MeetingsList, filter).slice(
                  page.skip,
                  page.take + page.skip
                )
              : MeetingsList.slice(page.skip, page.take + page.skip)
          }
          onFilterChange={handleFilterChange}
          skip={page.skip}
          take={page.take}
          total={MeetingsList.length}
          pageable={{
            buttonCount: 5,
            pageSizes: [5, 10, 15, "All"],
            pageSizeValue: pageSizeValue,
            type: "input",
          }}
          onPageChange={pageChange}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
        >
          <Column
            field="MeetingDate"
            title="Date"
            width={dynamicWidth}
            locked
          />
          <Column
            field="MeetingType"
            title="Meeting Type"
            width={dynamicWidth}
          />

          <Column
            field="StartTime"
            width={dynamicWidth}
            cell={(props: GridCellProps) => {
              return (
                <td>
                  {moment(props.dataItem.StartTime, "HH:mm").format("hh:mm A")}
                </td>
              );
            }}
            title="Start Time"
          />
          <Column
            field="EndTime"
            title="End Time"
            width={dynamicWidth}
            cell={(props: GridCellProps) => {
              return (
                <td
                  className={` ${props.className}`}
                  style={{ ...props.style }}
                >
                  {moment(props.dataItem.EndTime, "HH:mm").format("hh:mm A")}
                </td>
              );
            }}
          />
          <Column field="Location" title="Location" width={dynamicWidth} />
          <Column field="Purpose" title="Purpose" width={dynamicWidth} />
          <Column
            field="Description"
            title="Description"
            width={dynamicWidth}
          />
          <Column field="MeetingStatus" title="Status" width={dynamicWidth} />
          <Column field="MeetingOutcome" title="Outcome" width={dynamicWidth} />
          <Column field="Attendee" title="Attendee" width={dynamicWidth} />
          <Column
            field="FollowupDate"
            title="Followup Date"
            width={dynamicWidth}
          />
          <Column
            field="FollowupTime"
            title="Followup Time"
            width={dynamicWidth}
          />
          <Column
            field="FollowupNotes"
            title="Followup Notes"
            width={dynamicWidth}
          />
          <Column
            field="MeetingGUID"
            title="Actions"
            locked
            cell={MyEditCommandCell}
            width={"110px"}
            filterable={false}
          />
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

const DeleteMeetingsDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const MeetingGUID = useAppSelector((state) => state.meetings?.MeetingGUID);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const LeadID = location?.state?.ActivityLog?.LeadID;
  const NBInquiryTypeID = location?.state?.ActivityLog?.NBInquiryTypeID;
  const NBLeadTypeID = location.state?.ActivityLog?.NBLeadTypeID;
  const payload = {
    LeadID: LeadID ?? null,
    NBInquiryTypeID: NBInquiryTypeID ?? null,
    NBLeadTypeID: NBLeadTypeID ?? null,
  };
  const handleDeleteAction = async (ID: string | null) => {
    if (ID) {
      const response = await dispatch(deleteMeetings(ID));
      if (response.payload?.Data.Status === 200) {
        dispatch(getAllMeetings(payload));
        // dispatch(closeDialog());
        dispatch(clearMeetingsID());
      } else {
        dispatch(getAllMeetings(payload));
        // dispatch(closeDialog());
        dispatch(clearMeetingsID());
      }
    }
  };

  return (
    <>
      {dialogName === "deleteMeetings" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Meetings?"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this Meetings?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => {
                    handleDeleteAction(MeetingGUID),
                      dispatch(openDialog("Meetings"));
                  }}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

export default MeetingsDialog;
