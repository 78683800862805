import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
} from "@progress/kendo-react-grid";
import {
  Avatar,
  GridLayout,
  GridLayoutItem,
} from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import { Button } from "@progress/kendo-react-buttons";
import {
  HeaderThElement,
  PagerTargetEvent,
} from "@progress/kendo-react-data-tools";
import { LoadingPanel } from "../../components/layout/Loading";
import {
  filterBy,
  CompositeFilterDescriptor,
} from "@progress/kendo-data-query";
import {
  activeInActiveUsers,
  deleteUser,
  getAllUsers,
  resetUserPassword,
} from "./services/user.services";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import IconButton from "../../components/common/IconButton";
import { FiEdit } from "react-icons/fi";
import { MdDelete, MdLockReset } from "react-icons/md";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { clearUserID, setUserID } from "./userSlice";
import { Switch } from "@progress/kendo-react-inputs";
import AlertBox from "../../components/common/AlertBox";
import { Tooltip } from "@progress/kendo-react-tooltip";
import {
  DELETE_ACCESS,
  EDIT_ACCESS,
  INSERT_ACCESS,
} from "../../_contstants/common";
import { checkAcessRights, getLocalStorageItem } from "../../_helper/helper";

const User: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const isAddAccess = checkAcessRights(location.pathname, INSERT_ACCESS);

  useEffect(() => {
    dispatch(getAllUsers());
    dispatch(closeDialog());
  }, []);

  const handleUserCreate = () => {
    navigate("/user/create");
  };

  return (
    <>
      <UserResetPasswordDialog />
      <DeleteUserDialog />
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4px 20px 20px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            User List
          </Typography.h4>
          {isAddAccess && (
            <Button
              onClick={handleUserCreate}
              fillMode={"solid"}
              themeColor={"primary"}
            >
              Create User
            </Button>
          )}
        </GridLayoutItem>
        <GridLayoutItem>
          <UserGridComponent />
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = { skip: 0, take: 10 };

const UserGridComponent: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const UserList = useAppSelector((state) => state.user.UserList);
  const loading = useAppSelector((state) => state.user.loading);
  const location = useLocation();
  const isEditAccess = checkAcessRights(location.pathname, EDIT_ACCESS);
  const isDeleteAccess = checkAcessRights(location.pathname, DELETE_ACCESS);
  const UserTypeID = getLocalStorageItem("UserTypeID");

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);
  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();
  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All" ? UserList.length : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleEditUser = (ID: number, UserGUID: string) => {
      navigate("/user/edit", { state: { UserID: ID, UserGUID: UserGUID } });
    };
    const handleOpenDeleteDialog = (ID: number) => {
      dispatch(openDialog("deleteUser"));
      dispatch(setUserID(ID));
    };
    const handleResetPassword = (ID: number) => {
      dispatch(openDialog("resetUserPassword"));
      dispatch(setUserID(ID));
    };
    return (
      <td className="action-td">
        {(UserTypeID === "1" || UserTypeID === "2") && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Reset Password"
            onClick={() => handleResetPassword(props.dataItem.ID)}
          >
            <MdLockReset
              className="absolute-position"
              style={{ fontSize: "24px" }}
            />
          </IconButton>
        )}
        {isEditAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Edit"
            onClick={() =>
              handleEditUser(props.dataItem.ID, props.dataItem.UserGUID)
            }
          >
            <FiEdit
              className="absolute-position"
              style={{ fontSize: "20px" }}
            />
          </IconButton>
        )}
        {isDeleteAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Delete"
            onClick={() => handleOpenDeleteDialog(props.dataItem.ID)}
          >
            <MdDelete
              className="absolute-position"
              style={{ fontSize: "24px" }}
            />
          </IconButton>
        )}
      </td>
    );
  };
  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
    setPage({ skip: 0, take: page.take });
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className={`table-header`}
    >
      {props.children}
    </HeaderThElement>
  );
  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };
  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );
  const MyActiveInActtiveCommandCell = (props: GridCellProps) => {
    const [IsActive, setIsActive] = useState<boolean>(props.dataItem.IsActive);

    const handleActiveInActive = () => {
      setIsActive(!IsActive);
      const data = {
        ID: props.dataItem.ID,
        IsActive: !IsActive,
      };
      dispatch(activeInActiveUsers(data)).then((response: any) => {
        if (response.payload?.data?.Status === 200) {
          dispatch(getAllUsers());
        } else {
          dispatch(getAllUsers());
        }
      });
    };

    return (
      <td style={{ textAlign: "center" }}>
        <Tooltip anchorElement="target" position="top" parentTitle={true}>
          <div title={`Set ${IsActive ? "Inactive" : "Active"}.`}>
            <Switch
              name="IsActive"
              checked={IsActive}
              onChange={handleActiveInActive}
              size="small"
              disabled={isEditAccess ? false : true}
            />
          </div>
        </Tooltip>
      </td>
    );
  };
  const gridRef = useRef<any>(null);
  if (loading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <>
      {UserList && UserList.length ? (
        <KendoGrid
          style={{ maxHeight: "calc(100vh - 152px)" }}
          filterable={true}
          filter={filter}
          data={
            filter
              ? filterBy(UserList, filter).slice(
                  page.skip,
                  page.take + page.skip
                )
              : UserList.slice(page.skip, page.take + page.skip)
          }
          onFilterChange={handleFilterChange}
          skip={page.skip}
          take={page.take}
          total={UserList.length}
          pageable={{
            buttonCount: 5,
            pageSizes: [5, 10, 15, "All"],
            pageSizeValue: pageSizeValue,
            type: "input",
          }}
          onPageChange={pageChange}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
        >
          <Column
            field="UserImage"
            title="Image"
            width="80px"
            filterable={false}
            cell={(props: any) => {
              const firstName = props.dataItem.FirstName
                ? props.dataItem.FirstName?.charAt(0)?.toUpperCase()
                : "";
              const lastName = props.dataItem.LastName
                ? props.dataItem.LastName?.charAt(0)?.toUpperCase()
                : "";
              return (
                <td>
                  <Avatar
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                      backgroundColor: props.dataItem.UserImage
                        ? "transparent"
                        : "#303F9F",
                    }}
                  >
                    {props.dataItem.UserImage ? (
                      <img
                        src={props.dataItem.UserImage}
                        alt={`${firstName + lastName}`}
                        style={{
                          width: "50px",
                          height: "50px",
                          // borderRadius: "50%",
                          objectFit: "cover",
                        }}
                      />
                    ) : (
                      `${firstName + lastName}`
                    )}
                  </Avatar>
                </td>
              );
            }}
          />
          <Column
            field="FirstName"
            title="Name"
            cell={(props: any) => (
              <td>
                {isEditAccess ? (
                  <div
                    className="hoverableName"
                    onClick={() =>
                      navigate("/user/edit", {
                        state: {
                          UserID: props.dataItem?.ID,
                          UserGUID: props.dataItem?.UserGUID,
                        },
                      })
                    }
                  >
                    {props.dataItem.FirstName} {props.dataItem.LastName}
                  </div>
                ) : (
                  props.dataItem.FirstName + " " + props.dataItem.LastName
                )}
              </td>
            )}
          />
          <Column field="EmailID" title="EmailID" />
          <Column field="MobileNo" title="MobileNo" />

          <Column
            field="ID"
            title="Status"
            cell={MyActiveInActtiveCommandCell}
            width={"90px"}
            filterable={false}
            locked={true}
          />

          {(isDeleteAccess || isEditAccess) && (
            <Column
              field="ID"
              title="Actions"
              cell={MyEditCommandCell}
              width={
                UserTypeID === "1" || UserTypeID === "2" ? "140px" : "110px"
              }
              filterable={false}
              locked={true}
            />
          )}
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

const DeleteUserDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const UserID = useAppSelector((state) => state.user?.UserID);
  const handleDeleteAction = async (UserID: number | null) => {
    if (UserID) {
      const response = await dispatch(deleteUser(UserID));
      if (response.payload.Data.Status === 200) {
        dispatch(getAllUsers());
        dispatch(closeDialog());
        dispatch(clearUserID());
      } else {
        dispatch(getAllUsers());
        dispatch(closeDialog());
        dispatch(clearUserID());
      }
    }
  };
  return (
    <>
      {dialogName === "deleteUser" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete User?"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this User?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(UserID)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};
// USER RESET PASSWORD
const UserResetPasswordDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const UserID = useAppSelector((state) => state.user.UserID);
  const handlePasswordResetAction = async (ID: number | null) => {
    if (ID) {
      const response = await dispatch(resetUserPassword(ID));
      if (response.payload.Data.Status === 200) {
        dispatch(getAllUsers());
        dispatch(closeDialog());
        dispatch(clearUserID());
      } else {
        dispatch(getAllUsers());
        dispatch(closeDialog());
        dispatch(clearUserID());
      }
    }
  };
  return (
    <>
      {dialogName === "resetUserPassword" && (
        <AppDialog>
          <>
            <Typography.h5>{"Reset User Password?"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to reset password this User?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handlePasswordResetAction(UserID)}
                  type="button"
                >
                  Reset
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

export default User;
