import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICompanyInitialState, ICompanyType } from "./companyModel";
import {
  CompanyActiveInactive,
  createCompany,
  deleteCompany,
  findAllActiveCompany,
  getAllCompany,
  getCompanyById,
  updateCompany,
} from "./services/services.company";

const initialState: ICompanyInitialState = {
  loading: false,
  formLoading: false,
  error: "",
  CompanyTypeList: [],
  CompanyTypeID: null,
  CompanyTypeDetail: {
    CompanyName: "",
    Plan: [],
    IsActive: 1,
  },
  PlanDeleteIndex: -1,
};

export const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    clearCompanyTypeDetails: (state) => {
      state.CompanyTypeDetail = initialState.CompanyTypeDetail;
    },
    setCompanyTypeID: (state, action) => {
      state.CompanyTypeID = action.payload;
    },
    clearCompanyTypeID: (state) => {
      state.CompanyTypeID = null;
    },
    setPlanDeleteIndex: (state, action) => {
      state.PlanDeleteIndex = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllCompany.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllCompany.fulfilled,
      (state, action: PayloadAction<ICompanyType[]>) => {
        state.loading = false;
        state.CompanyTypeList = action.payload;
      }
    );
    builder.addCase(getAllCompany.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.CompanyTypeList = [];
    });

    builder.addCase(findAllActiveCompany.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      findAllActiveCompany.fulfilled,
      (state, action: PayloadAction<ICompanyType[]>) => {
        state.loading = false;
        state.CompanyTypeList = action.payload || [];
      }
    );
    builder.addCase(findAllActiveCompany.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.CompanyTypeList = [];
    });

    builder.addCase(createCompany.pending, (state) => {
      state.formLoading = false;
      state.error = "";
    });
    builder.addCase(createCompany.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createCompany.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(updateCompany.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateCompany.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateCompany.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(deleteCompany.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteCompany.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteCompany.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(CompanyActiveInactive.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(CompanyActiveInactive.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(CompanyActiveInactive.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getCompanyById.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getCompanyById.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.CompanyTypeDetail = action.payload;
    });
    builder.addCase(getCompanyById.rejected, (state, action) => {
      state.formLoading = false;
      state.CompanyTypeDetail = initialState.CompanyTypeDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { clearCompanyTypeDetails, setCompanyTypeID, clearCompanyTypeID, setPlanDeleteIndex } =
  companySlice.actions;
export default companySlice.reducer;
