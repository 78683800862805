import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast } from "../../../components/toast/Toasts";

export const getAllInquiryLeadNoActivity = createAsyncThunk(
  "ActivityLog/FindAllInquiryLeadNotActivity",
  async (payload: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ActivityLog/FindAllInquiryLeadNotActivity`,
        payload
      );
      //   return response.data?.Data;
      if (response?.data?.Status === 200) {
        // SuccessToast(response.data?.Details || "Success");
        return response.data?.Data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      console.error("Error Fetching Lead Type:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
