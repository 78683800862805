import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../../app/config";
import { ILogin } from "./loginModel";
import { ErrorToast, SuccessToast } from "../../components/toast/Toasts";

const initialState = {
  loading: false,
  error: "",
  data: null,
};

export const employeeLogin = createAsyncThunk(
  "Master/ManageUserLogin",
  async (data: ILogin) => {
    try {
      const response = await axios.post(
        `${API_URL}/Master/ManageUserLogin`,
        data
      );
      if (response?.data?.Status === 200) {
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error("Error login:", response?.data?.Details);
      }
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error login:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(employeeLogin.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      employeeLogin.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.data = action.payload;
        state.loading = false;
        state.error = "";
        const result = action.payload?.Data;
        if (result?.DeviceToken) {
          localStorage.setItem("Token", result?.DeviceToken);
          localStorage.setItem("RefreshToken", result?.RefreshToken);
          localStorage.setItem("UserID", result?.ID);
          localStorage.setItem("UserGUID", result?.UserGUID);
          localStorage.setItem("UserImage", result?.UserImage);
          localStorage.setItem("Name", result?.Name);
          localStorage.setItem("UserName", result?.UserName);
          localStorage.setItem("EmailID", result?.EmailID);
          localStorage.setItem("IsUser", result?.IsUser);
          localStorage.setItem("UserTypeID", result?.UserTypeID);
          localStorage.setItem(
            "UserRightsAssign",
            JSON.stringify(result?.UserRightsAssign)
          );
        }
      }
    );
    builder.addCase(employeeLogin.rejected, (state, action) => {
      state.loading = false;
      state.data = null;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export default loginSlice.reducer;
