import { Button } from "@progress/kendo-react-buttons";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
// import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeDialog } from "../../components/dialog/dialogSlice";
import { TiLockClosedOutline } from "react-icons/ti";
import {
  CompositeFilterDescriptor,
  filterBy,
} from "@progress/kendo-data-query";
import {
  GridCellProps,
  GridCustomCellProps,
  GridCustomHeaderCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  Grid as KendoGrid,
  GridColumn as Column,
} from "@progress/kendo-react-grid";
import { HeaderThElement } from "@progress/kendo-react-data-tools";
import IconButton from "../../components/common/IconButton";
import { FiEdit } from "react-icons/fi";
// import { MdDelete } from "react-icons/md";
import { LoadingPanel } from "../../components/layout/Loading";
import AlertBox from "../../components/common/AlertBox";
import { IoOpen } from "react-icons/io5";
import { setCurrentPage, setPageLimit } from "../pagination/paginationSlice";
import {
  clearNBinquiryDetails,
  // clearNBinquiryID,
  // setNBinquiryID,
} from "./nbinquirySlice";
import {
  // deleteNBinquiry,
  findAllActiveNBinquiry,
} from "./services/nbinquiry.services";
import {
  // DELETE_ACCESS,
  EDIT_ACCESS,
  INSERT_ACCESS,
} from "../../_contstants/common";
import { checkAcessRights } from "../../_helper/helper";

const NBinquiry: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isAddAccess = checkAcessRights(location.pathname, INSERT_ACCESS);

  useEffect(() => {
    return () => {
      dispatch(closeDialog());
      dispatch(clearNBinquiryDetails());
      dispatch(setCurrentPage(0));
    };
  }, []);

  const handleNBinquiryCreate = () => {
    navigate("/nbinquiry/create");
  };
  return (
    <>
      {/* <DeleteNBinquiryDialog /> */}
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4px 20px 20px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            NB Inquiry List
          </Typography.h4>
          {isAddAccess && (
            <Button
              onClick={handleNBinquiryCreate}
              fillMode={"solid"}
              themeColor={"primary"}
            >
              Create NB Inquiry
            </Button>
          )}
        </GridLayoutItem>
        <GridLayoutItem>
          <NBInquiryGridComponent />
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

const NBInquiryGridComponent: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const NBinquiryList = useAppSelector(
    (state) => state.nbinquiry.NBinquiryList
  );
  const loading = useAppSelector((state) => state.nbinquiry.loading);
  const isEditAccess = checkAcessRights(location.pathname, EDIT_ACCESS);
  // const isDeleteAccess = checkAcessRights(location.pathname, DELETE_ACCESS);
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);

  useEffect(() => {
    const payload = {
      LeadSearch: "",
      limit: +pageLimit,
      skip: +currentPage * +pageLimit,
    };
    dispatch(findAllActiveNBinquiry(payload));
  }, [currentPage, pageLimit]);

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);

  const pageChange = (event: GridPageChangeEvent) => {
    const newPage = event.page.skip / event.page.take;
    dispatch(setPageLimit(event.page.take));
    dispatch(setCurrentPage(newPage));
  };

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleEditItem = (
      NBInquiryGUID: any,
      LeadID: any,
      LeadName: string
    ) => {
      navigate("/nbinquiry/edit", {
        state: { NBInquiryGUID, LeadID, LeadName },
      });
    };
    // const handleOpenDeleteDialog = (NBInquiryGUID: any) => {
    //   dispatch(openDialog("deleteNBinquiry"));
    //   dispatch(setNBinquiryID(NBInquiryGUID));
    // };

    return (
      <td className="action-td">
        {isEditAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Edit"
            onClick={() =>
              handleEditItem(
                props.dataItem?.NBInquiryGUID,
                props.dataItem?.LeadID,
                props.dataItem?.LeadName
              )
            }
          >
            <FiEdit
              className="absolute-position"
              style={{ fontSize: "20px" }}
            />
          </IconButton>
        )}

        {/* <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Delete"
          onClick={() => handleOpenDeleteDialog(props.dataItem.NBInquiryGUID)}
        >
          <MdDelete
            className="absolute-position"
            style={{ fontSize: "24px" }}
          />
        </IconButton> */}
      </td>
    );
  };

  const MyNbInquiryStatusCheck = (props: GridCellProps) => {
    const handleLeadTypeStatus = (LeadGUID: any, InquiryStatus: any) => {
      navigate("/nbinquiry/Inquirylist", {
        state: { LeadGUID, InquiryStatus },
      });
    };

    return (
      <td className="action-td">
        <Button
          style={{ marginRight: 5 }}
          themeColor={"primary"}
          type="button"
          fillMode="outline"
          size="small"
          title="Open"
          onClick={() =>
            handleLeadTypeStatus(props.dataItem.LeadGUID, {
              InquiryStatus: "OPEN",
              status: 0,
              LeadID: props.dataItem.LeadID,
              GroupCode: props.dataItem.GroupCode,
              OpenCount: props.dataItem.OpenCount,
              CloseCount: props.dataItem.CloseCount,
            })
          }
        >
          <IoOpen />
        </Button>
        <Button
          themeColor={"tertiary"}
          type="button"
          fillMode="outline"
          size="small"
          title="Close"
          onClick={() =>
            handleLeadTypeStatus(props.dataItem.LeadGUID, {
              InquiryStatus: "CLOSED",
              status: 1,
              LeadID: props.dataItem.LeadID,
              GroupCode: props.dataItem.GroupCode,
              OpenCount: props.dataItem.OpenCount,
              CloseCount: props.dataItem.CloseCount,
            })
          }
        >
          <TiLockClosedOutline />
        </Button>
      </td>
    );
  };

  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className={`table-header ${props.index === 5 ? "locked-header" : ""}`}
    >
      {props.children}
    </HeaderThElement>
  );
  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };
  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  const gridRef = useRef<any>(null);

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      {NBinquiryList && NBinquiryList.length ? (
        <KendoGrid
          style={{ maxHeight: "calc(100vh - 152px)" }}
          filterable={true}
          className="disableFilterOprator"
          filter={filter}
          data={filter ? filterBy(NBinquiryList, filter) : NBinquiryList}
          onFilterChange={handleFilterChange}
          skip={currentPage * pageLimit}
          take={pageLimit}
          pageable={{
            buttonCount: 5,
            pageSizes: [10, 50, 100, 300, 500],
            pageSizeValue: pageLimit,
            type: "input",
          }}
          onPageChange={pageChange}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
        >
          <Column
            field="LeadName"
            title="Client Name"
            width={200}
            cell={(props: any) => (
              <td>
                <div
                  className="hoverableName"
                  onClick={() =>
                    navigate("/nbinquiry/edit", {
                      state: {
                        NBInquiryGUID: props.dataItem?.NBInquiryGUID,
                        LeadID: props.dataItem?.LeadID,
                        LeadName: props.dataItem?.LeadName,
                      },
                    })
                  }
                >
                  {props.dataItem?.LeadName}
                </div>
              </td>
            )}
          />
          <Column field="GroupCode" title="Group Code" />
          <Column
            field="LeadStageID"
            title="Client Type"
            cell={(props) => (
              <td>
                {props.dataItem?.LeadStageID === 1
                  ? "Existing"
                  : props.dataItem?.LeadStageID === 2
                  ? "Prospect"
                  : ""}
              </td>
            )}
          />

          <Column field="MobileNo" title="Mobile No" />
          <Column field="EmailID" title="Email" />
          <Column field="AllotmentName" title="Allotment To" />
          <Column field="OccupationName" title="Occupation" />
          {/* <Column
            field="Open"
            title="Types"
            cell={MyNbInquiryTypesCheck}
            width={"150px"}
            filterable={false}
            locked={true}
          /> */}
          <Column
            field="Open"
            title="Status"
            cell={MyNbInquiryStatusCheck}
            width={"110px"}
            filterable={false}
            locked={true}
          />
          {isEditAccess && (
            <Column
              field="NBInquiryGUID"
              title="Actions"
              cell={MyEditCommandCell}
              width={"95px"}
              filterable={false}
              locked={true}
            />
          )}
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

// const DeleteNBinquiryDialog: React.FC = () => {
//   const dispatch = useAppDispatch();
//   const NBinquiryID = useAppSelector((state) => state.nbinquiry.NBinquiryID);
//   const dialogName = useAppSelector((state) => state.dialog.dialogName);
//   const currentPage = useAppSelector((state) => state.pagination.currentPage);
//   const pageLimit = useAppSelector((state) => state.pagination.pageLimit);

//   const handleDeleteAction = async (ID: number | null) => {
//     if (ID) {
//       const response = await dispatch(deleteNBinquiry(ID));
//       dispatch(setCurrentPage(0));
//       const payload = {
//         LeadSearch: "",
//         limit: +pageLimit,
//         skip: +currentPage * +pageLimit,
//       };
//       if (response.payload?.Data.Status === 200) {
//         dispatch(findAllActiveNBinquiry(payload));
//         dispatch(closeDialog());
//         dispatch(clearNBinquiryID());
//       } else {
//         dispatch(findAllActiveNBinquiry(payload));
//         dispatch(closeDialog());
//         dispatch(clearNBinquiryID());
//       }
//     }
//   };

//   return (
//     <>
//       {dialogName === "deleteNBinquiry" && (
//         <AppDialog>
//           <>
//             <Typography.h5>{"Delete NBinquiry ?"}</Typography.h5>
//             <GridLayout>
//               <GridLayoutItem>
//                 <Typography.p>
//                   Are you sure you want to delete this NBinquiry?
//                 </Typography.p>
//               </GridLayoutItem>
//               <GridLayoutItem
//                 style={{
//                   display: "flex",
//                   justifyContent: "end",
//                 }}
//               >
//                 <DialogCloseButton themeColor="error" />
//                 <Button
//                   fillMode={"solid"}
//                   themeColor={"error"}
//                   onClick={() => {
//                     handleDeleteAction(NBinquiryID);
//                   }}
//                   type="button"
//                 >
//                   Delete
//                 </Button>
//               </GridLayoutItem>
//             </GridLayout>
//           </>
//         </AppDialog>
//       )}
//     </>
//   );
// };

export default NBinquiry;
