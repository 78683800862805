import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createLeadType,
  deleteLeadType,
  findAllActiveLeadType,
  getAllLeadType,
  getLeadTypeById,
  LeadTypeActiveInactive,
  updateLeadType,
} from "./services/leadType.services";
import { ILeadType, ILeadTypeInitialState } from "./leadTypeModel";

const initialState: ILeadTypeInitialState = {
  loading: false,
  formLoading: false,
  error: "",
  LeadTypeID: null,
  LeadTypeList: [],
  LeadTypeDetail: {
    LeadType: "",
    IsActive: 1,
  },
};

export const leadTypeSlice = createSlice({
  name: "leadType",
  initialState,
  reducers: {
    clearLeadTypeDetails: (state) => {
      state.LeadTypeDetail = initialState.LeadTypeDetail;
    },
    setLeadTypeID: (state, action) => {
      state.LeadTypeID = action.payload;
    },
    clearLeadTypeID: (state) => {
      state.LeadTypeID = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllLeadType.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllLeadType.fulfilled,
      (state, action: PayloadAction<ILeadType[]>) => {
        state.loading = false;
        state.LeadTypeList = action.payload;
      }
    );
    builder.addCase(getAllLeadType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.LeadTypeList = [];
    });
    builder.addCase(findAllActiveLeadType.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      findAllActiveLeadType.fulfilled,
      (state, action: PayloadAction<ILeadType[]>) => {
        state.loading = false;
        state.LeadTypeList = action.payload || [];
      }
    );
    builder.addCase(findAllActiveLeadType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.LeadTypeList = [];
    });
    builder.addCase(createLeadType.pending, (state) => {
      state.formLoading = false;
      state.error = "";
    });
    builder.addCase(createLeadType.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createLeadType.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateLeadType.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateLeadType.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateLeadType.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteLeadType.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteLeadType.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteLeadType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getLeadTypeById.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getLeadTypeById.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.LeadTypeDetail = action.payload;
    });
    builder.addCase(getLeadTypeById.rejected, (state, action) => {
      state.formLoading = false;
      state.LeadTypeDetail = initialState.LeadTypeDetail;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(LeadTypeActiveInactive.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(LeadTypeActiveInactive.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(LeadTypeActiveInactive.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { clearLeadTypeDetails, setLeadTypeID, clearLeadTypeID } =
  leadTypeSlice.actions;
export default leadTypeSlice.reducer;
