import React from "react";
import Drawer from "@mui/material/Drawer";
import { closed } from "./drawerSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Box } from "@mui/system";
import Toolbar from "@mui/material/Toolbar";

export interface Element extends React.ReactElement<any, any> {}

interface IDrawerProps {
  children?: Element | Element[];
}

const AppDrawer: React.FC<IDrawerProps> = (props) => {
  const drawerState = useAppSelector((state) => state.drawer);
  const dispatch = useAppDispatch();
  const toggleDrawer = () => {
    dispatch(closed());
  };
  return (
    <Box
      sx={{
        width: { sm: drawerState?.drawerWidth },
        flexShrink: { sm: 0 },
      }}
    >
      <Drawer
        variant="temporary"
        ModalProps={{
          keepMounted: true,
        }}
        anchor={drawerState?.anchor}
        open={drawerState.open}
        onClose={toggleDrawer}
        sx={{
          display: { xs: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerState?.drawerWidth,
            padding: "0 15px",
            zIndex: 1,
          },
        }}
      >
        <Toolbar />
        {props.children}
      </Drawer>
    </Box>
  );
};

export default AppDrawer;
