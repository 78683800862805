import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IInquirySubtype, IInquirySubtypeInitial } from "./inquirysubtypeModel";
import {
  createInquirySubtype,
  deleteInquirySubtype,
  findAllActiveInquirySubtype,
  getAllInquirySubtype,
  getInquirySubtypeById,
  InquirySubtypeActiveInactive,
  updateInquirySubtype,
} from "./services/inquireysubtype.services";

const initialState: IInquirySubtypeInitial = {
  loading: false,
  formLoading: false,
  error: "",
  InquirySubtypeList: [],
  InquirySubtypeID: null,
  filterInquirySubtypeList: [],
  InquirySubtypeDetail: {
    InquirySubType: "",
    InquiryTypeID: null,
    IsActive: 1,
  },
};

export const InquirysubtypeSlice = createSlice({
  name: "inquirysubtype",
  initialState,
  reducers: {
    clearInquirySubtypeDetails: (state) => {
      state.InquirySubtypeDetail = initialState.InquirySubtypeDetail;
    },
    setInquirySubTypeID: (state, action) => {
      state.InquirySubtypeID = action.payload;
    },
    setFilterInquirySubTypeID: (state, action) => {
      state.filterInquirySubtypeList = action.payload;
    },
    clearFilterInquirySubTypeID: (state) => {
      state.filterInquirySubtypeList = initialState.filterInquirySubtypeList;
    },
    clearInquirySubTypeID: (state) => {
      state.InquirySubtypeID = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllInquirySubtype.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllInquirySubtype.fulfilled,
      (state, action: PayloadAction<IInquirySubtype[]>) => {
        state.loading = false;
        state.InquirySubtypeList = action.payload;
      }
    );
    builder.addCase(getAllInquirySubtype.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.InquirySubtypeList = [];
    });

    builder.addCase(findAllActiveInquirySubtype.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      findAllActiveInquirySubtype.fulfilled,
      (state, action: PayloadAction<IInquirySubtype[]>) => {
        state.loading = false;
        state.InquirySubtypeList = action.payload || [];
      }
    );
    builder.addCase(findAllActiveInquirySubtype.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.InquirySubtypeList = [];
    });

    builder.addCase(createInquirySubtype.pending, (state) => {
      state.formLoading = false;
      state.error = "";
    });
    builder.addCase(createInquirySubtype.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createInquirySubtype.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(updateInquirySubtype.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateInquirySubtype.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateInquirySubtype.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(deleteInquirySubtype.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteInquirySubtype.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteInquirySubtype.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(InquirySubtypeActiveInactive.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(InquirySubtypeActiveInactive.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(InquirySubtypeActiveInactive.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getInquirySubtypeById.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getInquirySubtypeById.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.InquirySubtypeDetail = action.payload;
    });
    builder.addCase(getInquirySubtypeById.rejected, (state, action) => {
      state.formLoading = false;
      state.InquirySubtypeDetail = initialState.InquirySubtypeDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearInquirySubtypeDetails,
  setInquirySubTypeID,
  clearInquirySubTypeID,
  clearFilterInquirySubTypeID,
  setFilterInquirySubTypeID,
} = InquirysubtypeSlice.actions;
export default InquirysubtypeSlice.reducer;
