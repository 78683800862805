import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICalls, ICallsInitialState } from "./callsModel";
import {
  CallActiveInactive,
  createCall,
  deleteCall,
  findAllActiveCalls,
  getAllCalls,
  getCallById,
  getAllCallPurpose,
  getAllCallResults,
  getAllCallTypes,
  updateCall,
} from "./services/calls.services";

const initialState: ICallsInitialState = {
  loading: false,
  formLoading: false,
  error: "",
  CallsList: [],
  CallGUID: "",
  SelectedTab: 0,
  CallsID: null,
  CallsDetail: {
    LeadID: null,
    NBLeadTypeID: null,
    NBInquiryTypeID: null,
    CallTypeID: null,
    CallStatus: "Completed",
    CallDate: null,
    CallPurposeID: null,
    CallResultID: null,
    Subject: "",
    Agenda: "",
    Description: "",
    IsFollowup: false,
    FollowupDate: "",
    FollowupTime: "",
    FollowupNotes: "",
    IsActive: 0,
  },
  ManageCallTypeList: [],
  ManageCallPurposeList: [],
  ManageCallResultList: [],
};

export const callSlice = createSlice({
  name: "calls",
  initialState,
  reducers: {
    clearCallsDetails: (state) => {
      state.CallsDetail = initialState.CallsDetail;
    },
    setCallsID: (state, action) => {
      state.CallsID = action.payload;
    },
    clearCallsID: (state) => {
      state.CallsID = null;
    },
    setCallGUID: (state, action) => {
      state.CallGUID = action.payload;
    },
    clearCallsGUID: (state) => {
      state.CallGUID = initialState.CallGUID;
    },
    setSelectedTab: (state, action) => {
      state.SelectedTab = action.payload;
    },
    clearSelectedTab: (state) => {
      state.SelectedTab = initialState.SelectedTab;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllCalls.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllCalls.fulfilled,
      (state, action: PayloadAction<ICalls[]>) => {
        state.loading = false;
        state.CallsList = action.payload;
      }
    );
    builder.addCase(getAllCalls.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.CallsList = [];
    });

    builder.addCase(findAllActiveCalls.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      findAllActiveCalls.fulfilled,
      (state, action: PayloadAction<ICalls[]>) => {
        state.loading = false;
        state.CallsList = action.payload || [];
      }
    );
    builder.addCase(findAllActiveCalls.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.CallsList = [];
    });

    builder.addCase(createCall.pending, (state) => {
      state.formLoading = false;
      state.error = "";
    });
    builder.addCase(createCall.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createCall.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(updateCall.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateCall.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateCall.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(deleteCall.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteCall.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteCall.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(CallActiveInactive.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(CallActiveInactive.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(CallActiveInactive.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getCallById.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getCallById.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.CallsDetail = action.payload;
    });
    builder.addCase(getCallById.rejected, (state, action) => {
      state.formLoading = false;
      state.CallsDetail = initialState.CallsDetail;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getAllCallTypes.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getAllCallTypes.fulfilled, (state, action: any) => {
      state.loading = false;
      state.ManageCallTypeList = action.payload;
    });
    builder.addCase(getAllCallTypes.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.ManageCallTypeList = [];
    });

    builder.addCase(getAllCallPurpose.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getAllCallPurpose.fulfilled, (state, action) => {
      state.loading = false;
      state.ManageCallPurposeList = action.payload;
    });
    builder.addCase(getAllCallPurpose.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.ManageCallPurposeList = [];
    });

    builder.addCase(getAllCallResults.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getAllCallResults.fulfilled, (state, action: any) => {
      state.loading = false;
      state.ManageCallResultList = action.payload;
    });
    builder.addCase(getAllCallResults.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.ManageCallResultList = [];
    });
  },
});

export const {
  clearCallsDetails,
  setCallsID,
  clearCallsID,
  clearCallsGUID,
  setCallGUID,
  clearSelectedTab,
  setSelectedTab,
} = callSlice.actions;
export default callSlice.reducer;
