import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";

export const changePassword = createAsyncThunk(
  "Master/ManageUserChangePassword",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Master/ManageUserChangePassword`,
        formData
      );
      return response.data;
    } catch (error: any) {
      console.error("Error Change Password:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
