import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ITasks, ITasksInitialState } from "./taskModel";
import {
  createTask,
  deleteTask,
  getAllTasks,
  getTaskById,
  updateTask,
} from "./services/services.tasks";

const initialState: ITasksInitialState = {
  loading: false,
  formLoading: false,
  error: "",
  SelectedTab: 0,
  TasksList: [],
  TasksID: null,
  TaskGUID: "",
  TasksDetail: {
    LeadID: null,
    NBLeadTypeID: null,
    NBInquiryTypeID: null,
    TaskOwnerID: null,
    Subject: "",
    StartDate:"",
    DueDate: "",
    TaskStatus: "",
    Priority: "",
    Description: "",
    IsReminder: false,
    ReminderDate: "",
    ReminderTime: "",
    Repeat: 1,
    NotifyVia: 1,
    TasksAttachmentList: [],
    IsActive: 1,
  },
};

export const taskSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    clearTasksDetail: (state) => {
      state.TasksDetail = initialState.TasksDetail;
    },
    setTasksID: (state, action) => {
      state.TasksID = action.payload;
    },
    setTaskGUID: (state, action) => {
      state.TaskGUID = action.payload;
    },
    clearTaskGUID: (state) => {
      state.TaskGUID = initialState.TaskGUID;
    },
    clearTasksID: (state) => {
      state.TasksID = null;
    },
    setSelectedTab: (state, action) => {
      state.SelectedTab = action.payload;
    },
    clearSelectedTab: (state) => {
      state.SelectedTab = initialState.SelectedTab;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllTasks.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllTasks.fulfilled,
      (state, action: PayloadAction<ITasks[]>) => {
        state.loading = false;
        state.TasksList = action.payload;
      }
    );
    builder.addCase(getAllTasks.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.TasksList = initialState.TasksList;
    });

    builder.addCase(createTask.pending, (state) => {
      state.formLoading = false;
      state.error = "";
    });
    builder.addCase(createTask.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createTask.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(updateTask.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateTask.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateTask.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(deleteTask.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteTask.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteTask.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getTaskById.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getTaskById.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.TasksDetail = action.payload;
    });
    builder.addCase(getTaskById.rejected, (state, action) => {
      state.formLoading = false;
      state.TasksDetail = initialState.TasksDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearTasksDetail,
  setTasksID,
  clearTasksID,
  setTaskGUID,
  clearTaskGUID,
  clearSelectedTab,
  setSelectedTab,
} = taskSlice.actions;
export default taskSlice.reducer;
