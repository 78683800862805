import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICountry, ICountryState } from "./countryModel";
import {
  countryActiveInactive,
  createCountry,
  deleteCountry,
  getAllActiveCountries,
  getAllCountries,
  getCountryByID,
  updateCountry,
} from "./services/country.services";

const initialState: ICountryState = {
  loading: false,
  formLoading: false,
  error: "",
  CountryList: [],
  CountryID: null,
  CountryDetail: {
    CountryName: "",
    // CountryCode: "",
    IsActive: 1,
  },
};

const countrySlice = createSlice({
  name: "country",
  initialState,
  reducers: {
    clearCountryDetails: (state) => {
      state.CountryDetail = initialState.CountryDetail;
    },
    setCountryID: (state, action) => {
      state.CountryID = action.payload;
    },
    clearCountryID: (state) => {
      state.CountryID = initialState.CountryID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllCountries.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllCountries.fulfilled,
      (state, action: PayloadAction<ICountry[]>) => {
        state.loading = false;
        state.CountryList = action.payload || [];
      }
    );
    builder.addCase(getAllCountries.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.CountryList = [];
    });
    builder.addCase(getAllActiveCountries.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveCountries.fulfilled,
      (state, action: PayloadAction<ICountry[]>) => {
        state.loading = false;
        state.CountryList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveCountries.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.CountryList = [];
    });
    builder.addCase(createCountry.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(createCountry.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createCountry.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateCountry.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateCountry.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateCountry.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteCountry.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteCountry.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteCountry.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(countryActiveInactive.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(countryActiveInactive.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(countryActiveInactive.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getCountryByID.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getCountryByID.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.CountryDetail = action.payload;
    });
    builder.addCase(getCountryByID.rejected, (state, action) => {
      state.formLoading = false;
      state.CountryDetail = initialState.CountryDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { clearCountryDetails, setCountryID, clearCountryID } =
  countrySlice.actions;
export default countrySlice.reducer;
