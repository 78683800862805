import React, { useEffect, useRef } from "react";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { Typography } from "@progress/kendo-react-common";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  closeDialog,
  openDialog,
} from "../../../components/dialog/dialogSlice";
import { RootState } from "../../../app/store";
import AppDialog from "../../../components/dialog/Dialog";
import FormTextField from "../../../components/formFields/FormTextField";
import { requiredValidator } from "../../../components/formFields/CommonValidator";
import FormTextArea from "../../../components/formFields/FormTextArea";
import FormFileUpload from "../../../components/formFields/FormFileUpload";
import FormSelectionField from "../../../components/formFields/FormSelectionField";
import { useLocation } from "react-router-dom";
import {
  createMeetings,
  getAllMeetings,
  getMeetingsById,
  updateMeetings,
} from "./services/services.meetings";
import { clearMeetingDetails } from "./meetingsSlice";
import FormDatePicker from "../../../components/formFields/FormDateField";
import FormTimePicker from "../../../components/formFields/FormTimeField";
import FormCheckbox from "../../../components/formFields/FormCheckbox";
import moment from "moment";
import { InserAttachment } from "../notes/services/services.notes";
import { LoadingPanel } from "../../../components/layout/Loading";
import SlickSlider from "../../../components/common/SlickSlider";

const CloseLogDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const closeLogDialog = () => {
    dispatch(closeDialog());
    dispatch(clearMeetingDetails());
    dispatch(openDialog("Meetings"));
  };
  return (
    <Button
      themeColor={"primary"}
      fillMode={"outline"}
      onClick={closeLogDialog}
    >
      Close
    </Button>
  );
};

const CreateMeetingsDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const MeetingGUID = useAppSelector((state) => state.meetings.MeetingGUID);
  const formLoading = useAppSelector((state) => state.meetings.formLoading);
  const gridRef = useRef<any>(null);
  const MeetingsStatusList = useAppSelector(
    (state) => state.meetings.MeetingsStatusList
  );
  const MeetingsTypeList = useAppSelector(
    (state) => state.meetings.MeetingsTypeList
  );
  const MeetingsOutComeList = useAppSelector(
    (state) => state.meetings.MeetingsOutComeList
  );
  const MeetingsDetail = useAppSelector(
    (state) => state.meetings.MeetingsDetail
  );
  const LeadID = location?.state?.ActivityLog?.LeadID;
  const NBInquiryTypeID = location?.state?.ActivityLog?.NBInquiryTypeID;
  const NBLeadTypeID = location.state?.ActivityLog?.NBLeadTypeID;
  const SelectedTab = useAppSelector((state) => state.meetings.SelectedTab);
  const dialogName = useAppSelector(
    (state: RootState) => state.dialog.dialogName
  );

  useEffect(() => {
    if (MeetingGUID) {
      dispatch(getMeetingsById(MeetingGUID));
    }
  }, [MeetingGUID]);

  const handleSubmit = async (values: any) => {
    const payload = {
      LeadID: LeadID ? LeadID : null,
      NBInquiryTypeID: NBInquiryTypeID ? NBInquiryTypeID : null,
      NBLeadTypeID: NBLeadTypeID ? NBLeadTypeID : null,
    };

    const formData = {
      ...payload,
      MeetingTypeID: values.MeetingTypeID ? +values.MeetingTypeID : null,
      MeetingDate: values.MeetingDate
        ? moment(values.MeetingDate).format("YYYY-MM-DD")
        : null,
      StartTime: values.StartTime
        ? moment(values.StartTime).format("HH:mm")
        : "",
      EndTime: values.EndTime ? moment(values.EndTime).format("HH:mm") : "",
      Location: values.Location,
      Purpose: values.Purpose,
      Description: values.Description,
      MeetingStatusID: values.MeetingStatusID ? +values.MeetingStatusID : null,
      MeetingOutcomeID: values.MeetingOutcomeID
        ? +values.MeetingOutcomeID
        : null,
      Attendee: values.Attendee,
      ...(values.IsFollowup && {
        FollowupDate: values.FollowupDate
          ? moment(values.FollowupDate).format("YYYY-MM-DD")
          : null,
        FollowupTime: values.FollowupTime
          ? moment(values.FollowupTime).format("HH:mm")
          : "",
        FollowupNotes: values.FollowupNotes || "",
      }),
      IsFollowup: values.IsFollowup,
    };
    try {
      if (!MeetingGUID) {
        const response = await dispatch(createMeetings(formData));
        if (response?.payload?.Status === 201) {
          if (values.attachments) {
            const files = new FormData();
            files.append("AttachmentType", "Meetings");
            let AttachmentName: string[] = [];
            values?.attachments?.forEach((doc: any) => {
              const binaryDoc = doc.getRawFile();
              AttachmentName.push(binaryDoc.name);
              files.append("AttachmentURL", binaryDoc);
            });
            files.append("AttachmentName", AttachmentName.join(","));
            files.append("LeadID", LeadID || "");
            files.append(
              "ReferenceGUID",
              response?.payload?.Data?.ReferenceGUID || ""
            );
            files.append(
              "NBInquiryTypeID",
              NBInquiryTypeID ? NBInquiryTypeID : ""
            );
            files.append("NBLeadTypeID", NBLeadTypeID ?? "");
            dispatch(InserAttachment(files));
          }
          dispatch(closeDialog());
          dispatch(clearMeetingDetails());
          dispatch(
            getAllMeetings({
              ...payload,
              MeetingStatusID: SelectedTab === 0 ? 0 : 4,
            })
          );
        }
      } else {
        const editPayload = {
          MeetingGUID: MeetingGUID,
          ...formData,
        };
        dispatch(updateMeetings(editPayload));
        dispatch(closeDialog());
        dispatch(clearMeetingDetails());
        dispatch(
          getAllMeetings({
            ...payload,
            MeetingStatusID: SelectedTab === 0 ? 0 : 4,
          })
        );
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  if (formLoading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      {dialogName === "MEETING" && (
        <AppDialog className="dialog-width overflow_dialog">
          <Form
            onSubmit={handleSubmit}
            initialValues={MeetingsDetail}
            render={(formRenderProps: FormRenderProps) => (
              <FormElement>
                <Typography.h5 style={{ margin: 0, textAlign: "center" }}>
                  {MeetingGUID ? "Update Meeting" : "Create Meeting"}
                </Typography.h5>
                <GridLayout
                  gap={{ rows: 0, cols: 5 }}
                  cols={[
                    { width: "33.33%" },
                    { width: "33.33%" },
                    { width: "33.33%" },
                  ]}
                  style={{ marginRight: 10 }}
                >
                  <GridLayoutItem style={{ width: "100%" }}>
                    <Field
                      name="MeetingTypeID"
                      label="Type"
                      placeholder="Select Type"
                      component={FormSelectionField}
                      validator={requiredValidator} astrike={true}
                      options={MeetingsTypeList?.map((meetingtype: any) => {
                        return {
                          value: meetingtype?.ID,
                          label: meetingtype?.MeetingType,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="MeetingDate"
                      label="Meeting Date"
                      component={FormDatePicker}
                      validator={requiredValidator} astrike={true}
                      maxDate={moment().toDate()}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="StartTime"
                      label="Start Time"
                      component={FormTimePicker}
                      validator={requiredValidator} astrike={true}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="EndTime"
                      label="End Time"
                      component={FormTimePicker}
                      validator={requiredValidator} astrike={true}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="Location"
                      label="Location"
                      placeholder="Location"
                      component={FormTextField}
                      validator={requiredValidator} astrike={true}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="Purpose"
                      label="Purpose"
                      placeholder="Purpose"
                      component={FormTextField}
                      validator={requiredValidator} astrike={true}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem colSpan={2} rowSpan={2}>
                    <Field
                      name="Description"
                      label="Description"
                      multiline
                      component={FormTextArea}
                      validator={requiredValidator} astrike={true}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="MeetingStatusID"
                      label="Status"
                      placeholder="Select Status"
                      component={FormSelectionField}
                      validator={requiredValidator} astrike={true}
                      options={MeetingsStatusList?.map((meetingstatus: any) => {
                        return {
                          value: meetingstatus?.ID,
                          label: meetingstatus?.MeetingStatus,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem style={{ width: "100%" }}>
                    <Field
                      name="MeetingOutcomeID"
                      label="Outcome"
                      placeholder="Select Outcome"
                      component={FormSelectionField}
                      validator={requiredValidator} astrike={true}
                      options={MeetingsOutComeList?.map(
                        (meetingfollowup: any) => {
                          return {
                            value: meetingfollowup?.ID,
                            label: meetingfollowup?.MeetingOutcome,
                          };
                        }
                      )}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="Attendee"
                      label="Attendee"
                      placeholder="Attendee"
                      component={FormTextField}
                      // validator={requiredValidator}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem rowSpan={2} colSpan={3}>
                    <Field
                      name="IsFollowup"
                      maxLength="100"
                      label="Is Follow-up"
                      component={FormCheckbox}
                    />
                  </GridLayoutItem>
                  {formRenderProps.valueGetter("IsFollowup") === true && (
                    <>
                      <GridLayoutItem>
                        <Field
                          name="FollowupDate"
                          label="Follow-up Date"
                          component={FormDatePicker}
                          validator={requiredValidator} astrike={true}
                        />
                        <Field
                          name="FollowupTime"
                          label="Follow-up Time"
                          component={FormTimePicker}
                          validator={requiredValidator} astrike={true}
                        />
                      </GridLayoutItem>
                      <GridLayoutItem colSpan={2} rowSpan={2}>
                        <Field
                          name="FollowupNotes"
                          label="Follow-up Notes"
                          multiline
                          component={FormTextArea}
                          // validator={requiredValidator}
                        />
                      </GridLayoutItem>
                    </>
                  )}
                  {!MeetingGUID && (
                    <GridLayoutItem colSpan={2}>
                      <Field
                        name="attachments"
                        type="file"
                        filetype="file"
                        label="Attachments"
                        multiple
                        // accept=".xlsx,.pdf ,.text"
                        component={FormFileUpload}
                      />
                    </GridLayoutItem>
                  )}
                  {MeetingGUID &&
                    MeetingsDetail.MeetingsAttachmentList.length > 0 && (
                      <GridLayoutItem
                        colSpan={2}
                        style={{ marginTop: "20px", padding: "0 25px" }}
                      >
                        <Typography.p>Attachments</Typography.p>
                        <SlickSlider
                          list={MeetingsDetail.MeetingsAttachmentList}
                          src="AttachmentURL"
                          title="AttachmentName"
                        />
                      </GridLayoutItem>
                    )}
                </GridLayout>
                <GridLayout
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "10px",
                  }}
                >
                  <Button
                    type="submit"
                    themeColor={"primary"}
                    fillMode={"solid"}
                    style={{ marginRight: "10px" }}
                    disabled={!formRenderProps.allowSubmit}
                  >
                    {MeetingGUID ? "Update" : "Save"}
                  </Button>
                  <CloseLogDialog />
                </GridLayout>
              </FormElement>
            )}
          />
        </AppDialog>
      )}
    </>
  );
};

export default CreateMeetingsDialog;
