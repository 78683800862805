import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IClient } from "../clientformModel";
import moment from "moment";

export const getAllClient = createAsyncThunk(
  "Leads/LeadsAll",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Leads/LeadsAll`,
        formData
      );
      return response.data?.Data;
    } catch (error: any) {
      console.error("Error Fetching Client:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createClient = createAsyncThunk(
  "Leads/LeadsInsert",
  async (Client: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Leads/LeadsInsert`,
        Client
      );
      if (response.data?.Status === 201) {
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Client:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateClient = createAsyncThunk(
  "Leads/LeadsUpdate",
  async (Client: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Leads/LeadsUpdate`,
        Client
      );
      if (response.data?.Status === 200) {
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Client:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getClientById = createAsyncThunk(
  "Leads/LeadsFindByID",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Leads/LeadsFindByID`,
        {
          LeadGUID: ID,
        }
      );
      const result = response.data?.Data as any;
      return {
        ID: result.ID,
        LeadGUID: result.LeadGUID,
        LeadStage: result.LeadStage,
        InitialID: result.InitialID,
        InitialName: result.InitialName,
        FirstName: result.FirstName,
        LastName: result.LastName,
        MobileNo: result.MobileNo ?? "",
        EmailID: result.EmailID,
        LeadOwnerID: result.LeadOwnerID,
        LeadOwnerName: result.LeadOwnerName,
        CompanyName: result.CompanyName,
        Title: result.Title,
        PhoneNo: result.PhoneNo ?? "",
        Website: result.Website,
        LeadSourceID: result.LeadSourceID,
        RefName: result.RefName,
        RefMobileNo: result.RefMobileNo ?? "",
        Industry: result.Industry,
        NoofEmp: result.NoofEmp,
        LeadImage: result.LeadImage,
        AnnualRevenue: result.AnnualRevenue,
        Address: result.Address,
        CityID: result.CityID,
        StateID: result.StateID,
        CountryID: result.CountryID,
        CategoryID: result.CategoryID,
        OccupationID: result.OccupationID,
        OccupationName: result,
        BirthDate: result.BirthDate
          ? moment(result.BirthDate, "DD/MM/YYYY").toDate()
          : "",
        MarriageDate: result.MarriageDate
          ? moment(result.MarriageDate, "DD/MM/YYYY").toDate()
          : "",
        IsFamilyDetails: result.IsFamilyDetails,
        PinCode: result.PinCode,
        Notes: result.Notes,
        GroupCode: result.GroupCode,
        RelationID: result.RelationID,
        ReferenceID: result.ReferenceID,
        CreatedOn: result.CreatedOn ? moment(result.CreatedOn).toDate() : "",
        CreatedBy: result.CreatedBy,
        UpdatedBy: result.UpdatedBy,
        UpdatedOn: result.UpdatedOn ? moment(result.UpdatedOn).toDate() : "",
        TotalAttachment: result.TotalAttachment,
        TotalTask: result.TotalTask,
        TotalNote: result.TotalNote,
        TotalMeeting: result.TotalMeeting,
        TotalCall: result.TotalCall,
        ExistingCount: result.ExistingCount,
        ProspectCount: result.ProspectCount,
        SearchCount: result.SearchCount,
        CreatedByName: result.CreatedByName,
        Status: result.Status,
        MainImage: result?.MainImage,
        OtherImage: result?.OtherImage,
        IsActive: result.IsActive === true ? 1 : 2,
        FamilysDetails: result.FamilysDetails
          ? result.FamilysDetails.map((results: any) => {
              return {
                ID: results.ID,
                InitialID: results.InitialID,
                RelationID: results.RelationID,
                FirstName: results.FirstName,
                LastName: results.LastName,
                MobileNo: results.MobileNo ?? "",
                BirthDate: results.BirthDate
                  ? moment(results.BirthDate, "DD/MM/YYYY").toDate()
                  : "",
              };
            })
          : [],
      } as IClient;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Client:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteClient = createAsyncThunk(
  "Leads/LeadsDelete",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Leads/LeadsDelete`,
        {
          LeadGUID: ID,
        }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Deleting Client:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const findAllActiveClient = createAsyncThunk(
  "Leads/LeadsFindAllActive",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Leads/LeadsFindAllActive`,
        formData
      );
      // SuccessToast(response.data?.Details || "Success")
      return response.data.Data;
    } catch (error: any) {
      console.error("Error Deleting Client:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const ClientActiveInactive = createAsyncThunk(
  "Leads/LeadsActiveInActive",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Leads/LeadsActiveInActive`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive Client:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteLeadFamilyDetails = createAsyncThunk(
  "Leads/LeadFamilyDetailsDelete",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Leads/LeadFamilyDetailsDelete`,
        { ID }
      );
      // SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error LeadFamily Details:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const findAllActiveInitial = createAsyncThunk(
  "findAllActiveInitial/ManageInitial",
  async (Initial: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Master/ManageInitial`,
        Initial
      );
      if (response.data?.Status === 200) {
        // SuccessToast(response.data?.Details || "Success");
        return response.data.Data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      console.error("Error Fetching LeadSource:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllUser = createAsyncThunk(
  "getAllUser/ManageUsers",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Master/ManageUsers`,
        {
          InquirySubtype: "",
          OperationType: 5,
        }
      );
      if (response.data.Status === 200) {
        return response.data?.Data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      console.error("Error Fetching InquirySubtype:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
export const uploadClientImages = createAsyncThunk(
  "Leads/LeadImageUpload",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Leads/LeadImageUpload`,
        formData
      );
      return response.data;
    } catch (error: any) {
      console.error("Error Upload Client Images:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const downloadClientPDF = createAsyncThunk(
  "Leads/LeadReportPDF",
  async (LeadGUID: string) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Leads/LeadReportPDF`,
        { LeadGUID }
      );
      return response.data;
    } catch (error: any) {
      console.error("Error Download Client PDF:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

// export const getClientListById = createAsyncThunk(
//   "Leads/ClientListById",
//   async (ID: any) => {
//     try {
//       const response = await axiosApiInstance.post(
//         `${API_URL}/Leads/ClientListById`,
//         { ID }
//       );
//       // SuccessToast(response.data?.Details || "Success");
//       const result = response.data?.Data as any;
//       return {
//         ID: result.ID,
//         InitialName: result.InitialName,
//         FirstName: result.FirstName,
//         LastName: result.LastName,
//         GroupCode: result.GroupCode,
//         IsActive: null,
//       } as unknown as IClient;
//     } catch (error: any) {
//       ErrorToast(error?.response?.data?.Details || "Something went wrong");
//       console.error("Error Fetching Order:", error);
//       return Promise.reject({
//         message: error?.response?.data?.Details || "Something went wrong",
//       });
//     }
//   }
// );
