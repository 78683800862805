import React, { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  filterBy,
  CompositeFilterDescriptor,
} from "@progress/kendo-data-query";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
} from "@progress/kendo-react-grid";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { PagerTargetEvent } from "@progress/kendo-react-data-tools";
import { HeaderThElement } from "@progress/kendo-react-data-tools";
import { Typography } from "@progress/kendo-react-common";
import AlertBox from "../../../components/common/AlertBox";
import { LoadingPanel } from "../../../components/layout/Loading";
import { getInquiryClosingAmount } from "../services/dashboard.services";
import { useLocation } from "react-router-dom";
import { useResponsiveJSX } from "../../../_helper/helper";

const DashboardInquiryClosingAmount: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const UserID = location?.state?.UserID;

  useEffect(() => {
    dispatch(getInquiryClosingAmount(UserID));
  }, [UserID]);

  return (
    <>
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4px 20px 20px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            Inquiry Closing Amount
          </Typography.h4>
        </GridLayoutItem>
        <GridLayoutItem>
          <InquiryClosingAmountGrid />
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = { skip: 0, take: 10 };

const InquiryClosingAmountGrid: React.FC = () => {
  const gridRef = useRef<any>(null);
  // const dispatch = useAppDispatch();
  const breakPoint = useResponsiveJSX([1024, 2170, 2514]);
  const dynamicWidth = [150, 200, 250, 300][breakPoint] ?? 300;

  const InquiryClosingAmountList = useAppSelector(
    (state) => state.dashboard?.InquiryClosingAmountList
  );

  const loading = useAppSelector((state) => state.dashboard?.loading);
  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);
  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();

  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All"
        ? InquiryClosingAmountList.length
        : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
    setPage({ skip: 0, take: page.take });
  };
  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      style={props.thProps?.style}
      {...props.thProps}
      className={`table-header`}
      //   className={`table-header ${
      //     props.index === 0 || props.index === 1 ? "locked-header" : ""
      //   }`}
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td
        style={{ ...props.style }}
        {...props.tdProps}
        colSpan={1}
        className={`table-cell ${props.className}`}
      >
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      {InquiryClosingAmountList && InquiryClosingAmountList.length ? (
        <KendoGrid
          style={{ maxHeight: "calc(100vh - 152px)" }}
          filterable={true}
          filter={filter}
          data={
            filter
              ? filterBy(InquiryClosingAmountList, filter).slice(
                  page.skip,
                  page.take + page.skip
                )
              : InquiryClosingAmountList.slice(page.skip, page.take + page.skip)
          }
          onFilterChange={handleFilterChange}
          skip={page.skip}
          take={page.take}
          total={InquiryClosingAmountList.length}
          pageable={{
            buttonCount: 5,
            pageSizes: [5, 10, 15, "All"],
            pageSizeValue: pageSizeValue,
            type: "input",
          }}
          onPageChange={pageChange}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
        >
          <Column field="InquiryNo" title="Inquiry No" width={100} />
          <Column
            field="InquiryDate"
            title="Inquiry Date"
            width={dynamicWidth}
          />
          <Column field="LeadName" title="Client Name" width={dynamicWidth} />
          <Column field="GroupCode" title="Group Code" width={dynamicWidth} />
          <Column
            field="InquiryAllotmentName"
            title="Inquiry Allotment Name"
            width={dynamicWidth}
          />
          <Column field="LeadType" title="Lead Type" width={dynamicWidth} />
          <Column field="LeadStatus" title="Lead Status" width={dynamicWidth} />
          <Column
            field="InquiryType"
            title="Inquiry Type"
            width={dynamicWidth}
          />
          <Column
            field="ProposedAmount"
            title="Proposed Amount"
            width={dynamicWidth}
          />
          <Column
            field="ClosingAmount"
            title="Closing Amount"
            width={dynamicWidth}
          />
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

export default DashboardInquiryClosingAmount;
