import { Button } from "@progress/kendo-react-buttons";
import { Card, GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import React from "react";
import { Field, FieldArrayRenderProps } from "@progress/kendo-react-form";
import { Typography } from "@progress/kendo-react-common";
import { setPlanDeleteIndex } from "./companySlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import FormTextField from "../../components/formFields/FormTextField";
import { requiredValidator } from "../../components/formFields/CommonValidator";

const PlanArray = (fieldArrayRenderProps: FieldArrayRenderProps) => {
  const dispatch = useAppDispatch();
  const PlanDeleteIndex = useAppSelector(
    (state) => state.company.PlanDeleteIndex
  );
  const dialogName = useAppSelector((state) => state.dialog.dialogName);

  const handleDeleteAction = React.useCallback(
    (index: number) => {
      fieldArrayRenderProps.onRemove({ index: index });
      dispatch(closeDialog());
      dispatch(setPlanDeleteIndex(-1));
    },
    [fieldArrayRenderProps]
  );
  const pushElementInarray = React.useCallback(() => {
    fieldArrayRenderProps?.onPush({
      value: {
        PlanName: "",
      },
    });
  }, [fieldArrayRenderProps]);

  const handleOpenDeleteDialog = (index: any) => {
    dispatch(openDialog("planDelteDialog"));
    dispatch(setPlanDeleteIndex(index));
  };

  return (
    <GridLayout cols={[{ width: "100%" }]}>
      {fieldArrayRenderProps?.value?.length <= 0 ? pushElementInarray() : null}
      {dialogName === "planDelteDialog" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Plan Data"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(PlanDeleteIndex)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
      {fieldArrayRenderProps.value &&
        fieldArrayRenderProps.value.length > 0 &&
        fieldArrayRenderProps.value.map((process_data: any, index: number) => (
          <GridLayoutItem key={index} style={{ margin: "5px 0" }}>
            <Card className="fieldarray-card">
              <GridLayout
                style={{ marginRight: 10, padding: 10 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[{ width: "90%" }, { width: "10%" }]}
              >
                <GridLayoutItem>
                  <Field
                    wrapperClassName="m-0"
                    name={`Plan.${index}.PlanName`}
                    label="Title"
                    placeholder="Title"
                    component={FormTextField}
                    validator={requiredValidator} astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem style={{ marginTop: 22, textAlign: "end" }}>
                  <Button
                    style={{ padding: 4, marginLeft: 0 }}
                    onClick={() => handleOpenDeleteDialog(index)}
                    disabled={fieldArrayRenderProps.value.length === 1}
                    fillMode={"solid"}
                    themeColor={"error"}
                    type="button"
                    size={"small"}
                  >
                    Delete
                  </Button>
                </GridLayoutItem>
              </GridLayout>
            </Card>
          </GridLayoutItem>
        ))}
      <GridLayoutItem
        style={{ marginBottom: 10, textAlign: "left", marginTop: 10 }}
      >
        <Button
          fillMode={"solid"}
          themeColor={"primary"}
          onClick={pushElementInarray}
          type="button"
        >
          Add Plan
        </Button>
      </GridLayoutItem>
    </GridLayout>
  );
};

export default PlanArray;
