import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IInquiryType } from "../inquirytypeModel";
import {
  DELETE_TYPE,
  GETALL_TYPE,
  GETBYGUID_TYPE,
} from "../../../_contstants/common";

export const getAllInquiryType = createAsyncThunk(
  "getAllInquiryType/ManageInquiryType",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Master/ManageInquiryType`,
        {
          InquiryType: "",
          OperationType: GETALL_TYPE,
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      console.error("Error Fetching InquiryType:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createInquiryType = createAsyncThunk(
  "createInquiryType/ManageInquiryType",
  async (InquiryTypes: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Master/ManageInquiryType`,
        InquiryTypes
      );
      if (response.data.Status === 201) {
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching InquiryType:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateInquiryType = createAsyncThunk(
  "updateInquiryType/ManageInquiryType",
  async (InquiryType: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Master/ManageInquiryType`,
        InquiryType
      );
      if (response.data?.Status === 200) {
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching InquiryType:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getInquiryTypeById = createAsyncThunk(
  "getInquiryTypeById/ManageInquiryType",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Master/ManageInquiryType`,
        {
          InquiryTypeGUID: ID,
          OperationType: GETBYGUID_TYPE,
        }
      );
      const result = response.data?.Data[0] as any;
      return {
        ID: result?.ID,
        InquiryTypeGUID: result?.InquiryTypeGUID,
        InquiryType: result?.InquiryType,
        IsActive: result?.IsActive === true ? 1 : 2,
      } as IInquiryType;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching InquiryType:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteInquiryType = createAsyncThunk(
  "deleteInquiryType/ManageInquiryType",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Master/ManageInquiryType`,
        {
          InquiryTypeGUID: ID,
          OperationType: DELETE_TYPE,
        }
      );
      if (response.data?.Status === 200) {
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Deleting InquiryType:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const findAllActiveInquiryType = createAsyncThunk(
  "findAllActiveInquiryType/ManageInquiryType",
  async (InquiryType: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Master/ManageInquiryType`,
        InquiryType
      );
      return response.data.Data;
    } catch (error: any) {
      console.error("Error Fetching InquiryType:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const InquiryTypeActiveInactive = createAsyncThunk(
  "InquiryTypeActiveInactive/ManageInquiryType",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Master/ManageInquiryType`,
        formData
        // OperationType: ACTIVEINACTIVE_TYPE,
      );
      if (response.data?.Status === 200) {
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive InquiryType:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
