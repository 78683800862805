import { Button } from "@progress/kendo-react-buttons";
import { Card, GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import React, { useEffect } from "react";
import { Field, FieldArrayRenderProps } from "@progress/kendo-react-form";
import {
  mobileNoValidator,
  requiredValidator,
} from "../../components/formFields/CommonValidator";
import FormTextField from "../../components/formFields/FormTextField";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { Typography } from "@progress/kendo-react-common";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setFamilyDeleteIndex } from "./clientformSlice";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import FormDatePicker from "../../components/formFields/FormDateField";
import { deleteLeadFamilyDetails } from "./services/services.clientform";
import { ErrorToast, SuccessToast } from "../../components/toast/Toasts";
import { findAllActiveRelation } from "../relation/services/relation.services";
import { IRelation } from "../relation/relationModel";
import { IInitial } from "./clientformModel";
import { MdDelete } from "react-icons/md";
import moment from "moment";
// import { GETALLACTIVEWITHFILTER_TYPE } from "../../_contstants/common";

const FamilyMemberArray = (fieldArrayRenderProps: FieldArrayRenderProps) => {
  const dispatch = useAppDispatch();
  const FamilyDeleteIndex = useAppSelector((state) => state.client.DeleteIndex);
  const RelationList = useAppSelector((state) => state.relation.RelationList);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const InitialList = useAppSelector((state) => state?.client?.InitialList);

  //   const handleDeleteAction = React.useCallback(
  //     (index: number) => {
  //       fieldArrayRenderProps.onRemove({ index: index });
  //       dispatch(closeDialog());
  //       dispatch(setFamilyDeleteIndex(-1));
  //     },
  //     [fieldArrayRenderProps]
  //   );
  useEffect(() => {
    dispatch(findAllActiveRelation());
  }, []);

  const handleDeleteAction = React.useCallback(
    async (index: number) => {
      if (fieldArrayRenderProps?.value[index].ID) {
        try {
          const response = await dispatch(
            deleteLeadFamilyDetails(fieldArrayRenderProps?.value[index].ID)
          );
          SuccessToast(response?.payload?.Details || "Success");

          // if (response?.payload?.Status
          // ) {

          // }
        } catch (error: any) {
          ErrorToast(error?.response?.data?.Details || "Something went wrong");
          console.error("Error deleting Family Detail:", error);
          return Promise.reject({
            message: error?.response?.data?.Details || "Something went wrong",
          });
        }
        fieldArrayRenderProps.onRemove({ index: index });
        dispatch(closeDialog());
        dispatch(setFamilyDeleteIndex(-1));
      } else {
        fieldArrayRenderProps.onRemove({ index: index });
        dispatch(closeDialog());
        dispatch(setFamilyDeleteIndex(-1));
      }
    },
    [fieldArrayRenderProps]
  );

  const pushElementInarray = React.useCallback(() => {
    fieldArrayRenderProps?.onPush({
      value: {
        RelationID: null,
        InitialID: null,
        FirstName: "",
        LastName: "",
        MobileNo: "",
        BirthDate: "",
      },
    });
  }, [fieldArrayRenderProps]);

  const handleOpenDeleteDialog = (index: any) => {
    dispatch(openDialog("familyMemberDeleteDialog"));
    dispatch(setFamilyDeleteIndex(index));
  };

  return (
    <GridLayout cols={[{ width: "100%" }]}>
      {/* {fieldArrayRenderProps?.value?.length <= 0 ? pushElementInarray() : null} */}
      {dialogName === "familyMemberDeleteDialog" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Family Member Details"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(FamilyDeleteIndex)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
      {fieldArrayRenderProps.value &&
        fieldArrayRenderProps.value.length > 0 &&
        fieldArrayRenderProps.value.map((process_data: any, index: number) => (
          <GridLayoutItem key={index}>
            <Card className="fieldarray-card" style={{ marginBottom: 10 }}>
              <GridLayout
                style={{ marginRight: 60, marginBottom: 20, marginLeft: 15 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "15.5%" },
                  { width: "15.5%" },
                  { width: "15.5%" },
                  { width: "15.5%" },
                  { width: "15.5%" },
                  { width: "15.5%" },
                  { width: "5%" },
                ]}
              >
                <GridLayoutItem>
                  <Field
                    name={`FamilysDetails.${index}.RelationID`}
                    label="Relation"
                    placeholder="Relation"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    astrike={true}
                    options={RelationList?.map((relation: IRelation) => {
                      return {
                        value: relation?.ID,
                        label: relation?.RelationName,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`FamilysDetails.${index}.InitialID`}
                    label="Initial"
                    placeholder="Initial"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    astrike={true}
                    options={InitialList?.map((initial: IInitial) => {
                      return {
                        value: initial?.ID,
                        label: initial?.Initial,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`FamilysDetails.${index}.FirstName`}
                    label="First Name"
                    placeholder="First Name"
                    component={FormTextField}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`FamilysDetails.${index}.LastName`}
                    label="Last Name"
                    placeholder="Last Name"
                    component={FormTextField}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`FamilysDetails.${index}.MobileNo`}
                    label="Mobile No"
                    placeholder="Mobile No"
                    type="number"
                    max={10}
                    component={FormTextField}
                    validator={mobileNoValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`FamilysDetails.${index}.BirthDate`}
                    label="Birth Date"
                    placeholder="Birth Date"
                    maxDate={moment().toDate()}
                    component={FormDatePicker}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  style={{
                    position: "relative",
                  }}
                >
                  <Button
                    style={{
                      position: "absolute",
                      top: 34,
                      right: 0,
                      padding: 4,
                      height: 30,
                      width: 30,
                    }}
                    onClick={() => handleOpenDeleteDialog(index)}
                    // disabled={fieldArrayRenderProps.value.length === 1}
                    fillMode={"solid"}
                    themeColor={"error"}
                    type="button"
                    size={"small"}
                  >
                    <MdDelete
                      className="absolute-position"
                      style={{ fontSize: "16px" }}
                    />
                  </Button>
                </GridLayoutItem>
              </GridLayout>
            </Card>
          </GridLayoutItem>
        ))}
      <GridLayoutItem
        style={{ marginBottom: 10, textAlign: "left", marginTop: 15 }}
      >
        <Button
          fillMode={"solid"}
          themeColor={"primary"}
          onClick={pushElementInarray}
          type="button"
        >
          Add Member
        </Button>
      </GridLayoutItem>
    </GridLayout>
  );
};

export default FamilyMemberArray;
