import {
  Field,
  FieldArray,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import React, { useEffect, useRef, useState } from "react";
import ShadowCard from "../../components/common/ShadowCard";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import FormTextField from "../../components/formFields/FormTextField";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Button } from "@progress/kendo-react-buttons";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { closed } from "../../components/drawer/drawerSlice";
import {
  GETALLACTIVEWITHFILTER_TYPE,
  STATUSARRAY,
} from "../../_contstants/common";
import { LoadingPanel } from "../../components/layout/Loading";
import { useLocation, useNavigate } from "react-router-dom";
import {
  emailValidator,
  mobileNoValidator,
  requiredValidator,
} from "../../components/formFields/CommonValidator";
import {
  createClient,
  findAllActiveInitial,
  getAllUser,
  getClientById,
  updateClient,
  uploadClientImages,
} from "./services/services.clientform";
import { clearCategoryDetails } from "../category/categorySlice";
import { clearClientDetails } from "./clientformSlice";
import FamilyMemberArray from "./FamilyMemberArray";
import moment from "moment";
import { findAllActiveOccupationType } from "../occupationType/services/occupation.services";
import { IOccupationType } from "../occupationType/occupationtypeModel";
import { findAllActiveCategory } from "../category/services/category.services";
import { ILeadSorce } from "../leadsource/leadsourceModel";
import { findAllActiveLeadSource } from "../leadsource/services/leadsource.services";
import { getAllActiveCountries } from "../country/services/country.services";
import { ICountry } from "../country/countryModel";
import { getAllActiveStates } from "../state/services/state.services";
import { getAllActiveCities } from "../city/services/city.services";
import { IState } from "../state/stateModel";
import { ICity } from "../city/cityModel";
import FormDatePicker from "../../components/formFields/FormDateField";
import { IInitial, IMnageUser } from "./clientformModel";
import FormRating from "../../components/formFields/FormRating";
import FormRadioGroup from "../../components/formFields/FormRadioGroup";
import { getLocalStorageItem } from "../../_helper/helper";
import { findIndex } from "lodash";
import FormImageUpload from "../../components/formFields/FormImageUpload";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}

const UserChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const location = useLocation();
  const UserID = getLocalStorageItem("UserID");
  const LeadGUID = location.state?.LeadGUID;

  useEffect(() => {
    if (!LeadGUID) {
      formRenderProps.onChange("LeadOwnerID", {
        value: UserID ? +UserID : null,
      });
    }
  }, [UserID]);

  return null;
};

interface CityChangeWatcherProps {
  formRenderProps: FormRenderProps;
  CityList: ICity[];
}

const CityChangeWatcher: React.FC<CityChangeWatcherProps> = ({
  CityList,
  formRenderProps,
}) => {
  const cityID = formRenderProps.valueGetter("CityID");

  useEffect(() => {
    const CityID = cityID ? cityID : undefined;
    const cityIndex = findIndex(CityList, { ID: CityID });
    if (cityIndex > -1) {
      const city = CityList[cityIndex];
      formRenderProps.onChange("StateID", { value: city.StateID });
      formRenderProps.onChange("CountryID", { value: city.CountryID });
    } else {
      formRenderProps.onChange("CityID", { value: "" });
      formRenderProps.onChange("StateID", { value: "" });
      formRenderProps.onChange("CountryID", { value: "" });
    }
  }, [cityID]);
  return null;
};

const CreateClientForm: React.FC = () => {
  const gridRef = useRef<any>(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const LeadGUID = location.state?.LeadGUID;
  const ClientDetail = useAppSelector((state) => state.client.ClientDetail);
  const OccupationTypeList = useAppSelector(
    (state) => state.occupationtype.OccupationTypeList
  );
  const LeadSorceList = useAppSelector(
    (state) => state?.leadsource?.LeadSorceList
  );
  const CountryList = useAppSelector((state) => state?.country?.CountryList);
  const StateList = useAppSelector((state) => state?.state?.StateList);
  const CityList = useAppSelector((state) => state?.city?.CityList);
  const InitialList = useAppSelector((state) => state?.client?.InitialList);
  const UserList = useAppSelector((state) => state?.client?.UserList);
  const formLoading = useAppSelector((state) => state.client.formLoading);
  const [formKey, setFormKey] = useState(1);

  useEffect(() => {
    if (LeadGUID) {
      // const payload = { LeadGUID };
      dispatch(getClientById(LeadGUID));
    }
  }, [LeadGUID]);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [ClientDetail]);

  useEffect(() => {
    dispatch(
      findAllActiveLeadSource({ OperationType: GETALLACTIVEWITHFILTER_TYPE })
    );
    dispatch(findAllActiveOccupationType());
    dispatch(findAllActiveCategory());
    dispatch(getAllActiveCountries());
    dispatch(getAllActiveStates());
    dispatch(getAllActiveCities());
    dispatch(getAllUser());

    dispatch(
      findAllActiveInitial({ OperationType: GETALLACTIVEWITHFILTER_TYPE })
    );
    return () => {
      dispatch(clearCategoryDetails());
      dispatch(closed());
    };
  }, []);

  const LEAD_STAGE = [
    { value: 1, label: "Existing" },
    { value: 2, label: "Prospect" },
  ];

  const handleSubmit = async (values: any) => {
    const formData = {
      InitialID: values.InitialID ? +values.InitialID : null,
      LeadStage: values.LeadStage ? +values.LeadStage : null,
      FirstName: values.FirstName,
      LastName: values.LastName,
      MobileNo: values.MobileNo,
      EmailID: values.EmailID,
      GroupCode: values.GroupCode,
      OccupationID: values.OccupationID ? +values.OccupationID : null,
      CategoryID: values.CategoryID ? +values.CategoryID : null,
      LeadOwnerID: values.LeadOwnerID ? +values.LeadOwnerID : null,
      CompanyName: values.CompanyName,
      Title: values.Title,
      PhoneNo: values.PhoneNo,
      Website: values.Website,
      LeadSourceID: values.LeadSourceID,
      RefName: values.RefName,
      RefMobileNo: values.RefMobileNo,
      Industry: values.Industry,
      NoofEmp: values.NoofEmp,
      AnnualRevenue: values.AnnualRevenue,
      Address: values.Address,
      CityID: values.CityID ? +values.CityID : null,
      StateID: values.StateID ? +values.StateID : null,
      CountryID: values.CountryID ? +values.CountryID : null,
      PinCode: values.PinCode,
      Notes: values.Notes,
      BirthDate: values.BirthDate
        ? moment(values.BirthDate).format("YYYY-MM-DD")
        : null,
      MarriageDate: values.MarriageDate
        ? moment(values.MarriageDate).format("YYYY-MM-DD")
        : null,
      FamilysDetails: values.FamilysDetails
        ? values.FamilysDetails.map((family_detail: any) => {
            return {
              ID: family_detail.ID ?? 0,
              InitialID: family_detail.InitialID
                ? +family_detail.InitialID
                : null,
              RelationID: family_detail.RelationID
                ? +family_detail.RelationID
                : null,
              FirstName: family_detail.FirstName,
              LastName: family_detail.LastName,
              MobileNo: family_detail.MobileNo,
              BirthDate: family_detail.BirthDate
                ? moment(family_detail.BirthDate).format("YYYY-MM-DD")
                : null,
            };
          })
        : [],
      IsActive: values.IsActive === 1,
    };
    if (LeadGUID) {
      try {
        const editPayload = {
          LeadGUID: LeadGUID,
          ...formData,
        };
        const response = await dispatch(updateClient(editPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          if (
            (typeof values?.MainImage !== "string" &&
              values?.MainImage?.length > 0) ||
            (typeof values?.OtherImage !== "string" &&
              values?.OtherImage?.length > 0)
          ) {
            const files = new FormData();
            files.append("ID", values?.ID);
            if (typeof values?.MainImage !== "string") {
              const mainImage = values?.MainImage[0]?.getRawFile();
              files.append("MainImage", mainImage);
            }
            if (typeof values?.OtherImage !== "string") {
              const otherImage = values?.OtherImage[0]?.getRawFile();
              files.append("OtherImage", otherImage);
            }
            dispatch(uploadClientImages(files));
          }
          navigate(-1);
          dispatch(closed());
          dispatch(clearClientDetails());
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
      }
    } else {
      try {
        const response = await dispatch(createClient(formData));
        if (response?.meta?.requestStatus === "fulfilled") {
          if (
            (typeof values?.MainImage !== "string" &&
              values?.MainImage?.length > 0) ||
            (typeof values?.OtherImage !== "string" &&
              values?.OtherImage?.length > 0)
          ) {
          const files = new FormData();
          files.append("ID", response.payload?.Data?.ID);
          if (values?.MainImage) {
            const mainImage = values?.MainImage[0].getRawFile();
            files.append("MainImage", mainImage);
          }
          if (values?.OtherImage) {
            const otherImage = values?.OtherImage[0].getRawFile();
            files.append("OtherImage", otherImage);
          }
          await dispatch(uploadClientImages(files));
        }
        navigate(-1);
        dispatch(closed());
        dispatch(clearClientDetails()); 
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    }
  };

  if (formLoading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      <Form
        onSubmit={handleSubmit}
        initialValues={ClientDetail}
        key={formKey}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 20 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "33.33%" },
                  { width: "33.33%" },
                  { width: "33.33%" },
                ]}
              >
                <GridLayoutItem colSpan={3}>
                  <Typography.h4>
                    {LeadGUID ? "Update Client" : "Create Client"}
                  </Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem colSpan={3}>
                  <Field
                    id={"LeadStage"}
                    name={"LeadStage"}
                    component={FormRadioGroup}
                    layout="horizontal"
                    label={"Lead Stage"}
                    options={LEAD_STAGE}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`InitialID`}
                    label="Initial"
                    placeholder="Initial"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    astrike={true}
                    options={InitialList?.map((initial: IInitial) => {
                      return {
                        value: initial?.ID,
                        label: initial?.Initial,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="FirstName"
                    label="First Name"
                    placeholder="First Name"
                    component={FormTextField}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="LastName"
                    label="Last Name"
                    placeholder="Last Name"
                    component={FormTextField}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="MobileNo"
                    label="Mobile No"
                    placeholder="Mobile No"
                    type="number"
                    max={10}
                    component={FormTextField}
                    validator={mobileNoValidator}
                    
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="EmailID"
                    label="Email ID"
                    placeholder="Email ID"
                    component={FormTextField}
                    validator={emailValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="GroupCode"
                    label="Group Code"
                    placeholder="Group Code"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="OccupationID"
                    label="Occupation"
                    placeholder="Occupation"
                    component={FormSelectionField}
                    options={OccupationTypeList?.map(
                      (occupation: IOccupationType) => {
                        return {
                          value: occupation?.ID,
                          label: occupation?.OccupationName,
                        };
                      }
                    )}
                  />
                </GridLayoutItem>
                <GridLayoutItem style={{ marginTop: 13 }}>
                  <label style={{ fontWeight: 500, color: "black" }}>
                    Category
                  </label>
                  <Field
                    style={{ marginTop: 0 }}
                    name="CategoryID"
                    placeholder="Category"
                    component={FormRating}
                    max={5}
                  />
                </GridLayoutItem>
                <GridLayoutItem colSpan={3} style={{ marginTop: 20 }}>
                  <Typography.h5 style={{ textDecoration: "underline" }}>
                    Lead Info
                  </Typography.h5>
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="LeadOwnerID"
                    label="Lead Owner"
                    placeholder="Lead Owner"
                    component={FormSelectionField}
                    options={UserList?.map((user: IMnageUser) => {
                      return {
                        value: user?.ID,
                        label: `${user?.FirstName} ${user?.LastName}`,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="CompanyName"
                    label="Company"
                    placeholder="Company"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="Title"
                    label="Title"
                    placeholder="Title"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="PhoneNo"
                    label="Phone No"
                    placeholder="Phone No"
                    type="number"
                    component={FormTextField}
                    max={10}
                    validator={mobileNoValidator}
                 
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="Website"
                    label="Website"
                    placeholder="Website"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="LeadSourceID"
                    label="Source"
                    placeholder="Source"
                    component={FormSelectionField}
                    options={LeadSorceList?.map((leadsource: ILeadSorce) => {
                      return {
                        value: leadsource?.ID,
                        label: leadsource?.LeadSource,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="RefName"
                    label="Reference Name"
                    placeholder="Reference Name"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="RefMobileNo"
                    label="Reference Mobile No"
                    type="number"
                    placeholder="Reference Mobile No"
                    max={10}
                    component={FormTextField}
                    validator={mobileNoValidator}
                   
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="Industry"
                    label="Industry"
                    placeholder="Industry"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="NoofEmp"
                    label="No Of Emp"
                    placeholder="No Of Emp"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="AnnualRevenue"
                    label="Annual Revenue"
                    type="number"
                    placeholder="Annual Revenue"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem colSpan={3} style={{ marginTop: 20 }}>
                  <Typography.h5 style={{ textDecoration: "underline" }}>
                    Address Info
                  </Typography.h5>
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="Address"
                    label="Address"
                    placeholder="Address"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="CityID"
                    label="City"
                    placeholder="City"
                    component={FormSelectionField}
                    // validator={requiredValidator}
                    options={CityList?.map((city: ICity) => {
                      return {
                        value: city?.ID,
                        label: city?.CityName,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="StateID"
                    label="State"
                    placeholder="State"
                    component={FormSelectionField}
                    options={StateList?.map((state: IState) => {
                      return {
                        value: state?.ID,
                        label: state?.StateName,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="CountryID"
                    label="Country"
                    placeholder="Country"
                    component={FormSelectionField}
                    options={CountryList?.map((country: ICountry) => {
                      return {
                        value: country?.ID,
                        label: country?.CountryName,
                      };
                    })}
                  />
                </GridLayoutItem>

                <GridLayoutItem>
                  <Field
                    name="PinCode"
                    label="Pincode"
                    placeholder="Pincode"
                    max={6}
                    type="number"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="Notes"
                    label="Notes"
                    placeholder="Notes"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem colSpan={3} style={{ marginTop: 20 }}>
                  <Typography.h5 style={{ textDecoration: "underline" }}>
                    Personal Info
                  </Typography.h5>
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="BirthDate"
                    label="Birth Date"
                    placeholder="Birth Date"
                    maxDate={moment().toDate()}
                    component={FormDatePicker}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="MarriageDate"
                    label="Marriage Date"
                    placeholder="Marriage Date"
                    maxDate={moment().toDate()}
                    component={FormDatePicker}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    id={"IsActive"}
                    name={"IsActive"}
                    component={FormSelectionField}
                    label={"Status"}
                    placeholder="Status"
                    validator={requiredValidator}
                    astrike={true}
                    options={STATUSARRAY?.map((status: any) => {
                      return {
                        value: status.value,
                        label: status.label,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="MainImage"
                    component={FormImageUpload}
                    type="file"
                    accept="image/*"
                    label={"Main Image"}
                    //     validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem colSpan={2}></GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="OtherImage"
                    component={FormImageUpload}
                    type="file"
                    accept="image/*"
                    label={"Other Image"}
                    //     validator={requiredValidator}
                  />
                </GridLayoutItem>
              </GridLayout>
              <UserChangeWatcher formRenderProps={formRenderProps} />
              <CityChangeWatcher
                formRenderProps={formRenderProps}
                CityList={CityList}
              />
            </ShadowCard>

            <ShadowCard style={{ marginTop: 10 }}>
              <GridLayoutItem colSpan={3}>
                <Typography.h4>{"Family Member"}</Typography.h4>
              </GridLayoutItem>
              <FieldArray
                formRenderProps={formRenderProps}
                component={FamilyMemberArray}
                name="FamilysDetails"
              />
              <GridLayoutItem
                colSpan={3}
                style={{
                  textAlign: "end",
                  marginTop: "20px",
                  width: "100%",
                }}
              >
                <Button
                  type="button"
                  fillMode={"outline"}
                  themeColor={"primary"}
                  style={{ marginRight: 4 }}
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>
                <ButtonWithLoading
                  label={!LeadGUID ? "Save" : "Update"}
                  type="submit"
                  disabled={!formRenderProps.allowSubmit || formLoading}
                  loading={formLoading}
                />
              </GridLayoutItem>
            </ShadowCard>
          </FormElement>
        )}
      />
    </>
  );
};

export default CreateClientForm;
