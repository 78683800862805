import { Button } from "@progress/kendo-react-buttons";
import {
  GridLayout,
  GridLayoutItem,
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import {
  CompositeFilterDescriptor,
  filterBy,
} from "@progress/kendo-data-query";
import {
  GridCellProps,
  GridCustomCellProps,
  GridCustomHeaderCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  Grid as KendoGrid,
  GridColumn as Column,
} from "@progress/kendo-react-grid";
import { HeaderThElement } from "@progress/kendo-react-data-tools";
import IconButton from "../../components/common/IconButton";
import { FiEdit } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { FiList } from "react-icons/fi";
import { RxActivityLog } from "react-icons/rx";
import { MdLeaderboard } from "react-icons/md";
import { LoadingPanel } from "../../components/layout/Loading";
import {
  clearClientDetails,
  clearClientID,
  setClientID,
  setLeadSearch,
  setLeadSearchField,
  setLeadSearchFilter,
  setLeadStage,
} from "./clientformSlice";
import { setCurrentPage, setPageLimit } from "../pagination/paginationSlice";
import {
  ClientActiveInactive,
  deleteClient,
  downloadClientPDF,
  getAllClient,
} from "./services/services.clientform";
import { setSelectedILTab } from "../clientActivityTab/clientActivityTabSlice";
import { Switch, TextBox } from "@progress/kendo-react-inputs";
import { TbUserSearch } from "react-icons/tb";
import { TbUserCheck } from "react-icons/tb";
import TabStripTitle from "../../components/common/TabStripTitle";
import {
  DELETE_ACCESS,
  EDIT_ACCESS,
  INSERT_ACCESS,
} from "../../_contstants/common";
import { LuMenuSquare } from "react-icons/lu";
import { checkAcessRights } from "../../_helper/helper";
import { FaFileDownload } from "react-icons/fa";
import { filterClearIcon } from "@progress/kendo-svg-icons";
import _debounce from "lodash/debounce";

const ClientForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const ClientList = useAppSelector((state) => state.client.ClientList);
  const Lead_Stage = useAppSelector((state) => state.client.Lead_Stage);
  const isAddAccess = checkAcessRights(location.pathname, INSERT_ACCESS);
  const handleSelect = (e: TabStripSelectEventArguments) => {
    dispatch(setLeadStage(e.selected));
    dispatch(setLeadSearch(""));
  };
  useEffect(() => {
    return () => {
      dispatch(closeDialog());
      dispatch(clearClientDetails());
      dispatch(setCurrentPage(0));
      dispatch(setLeadSearch(""));
    };
  }, []);

  const handleClientCreate = () => {
    navigate("/clientform/create");
  };

  return (
    <>
      <DeleteClientDialog />
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4px 20px 20px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            Client List
          </Typography.h4>
          {isAddAccess && (
            <Button
              onClick={handleClientCreate}
              fillMode={"solid"}
              themeColor={"primary"}
            >
              Create Client
            </Button>
          )}
        </GridLayoutItem>
        <GridLayoutItem>
          <TabStrip
            selected={Lead_Stage}
            onSelect={handleSelect}
            className="client-grid"
          >
            <TabStripTab
              title={
                <TabStripTitle
                  title={`Existing (${
                    ClientList?.length > 0 ? ClientList[0]?.ExistingCount : 0
                  })`}
                  icon={TbUserCheck}
                />
              }
            >
              <ClientGridComponent />
            </TabStripTab>
            <TabStripTab
              title={
                <TabStripTitle
                  title={`Prospect (${
                    ClientList?.length > 0 ? ClientList[0]?.ProspectCount : 0
                  })`}
                  icon={TbUserSearch}
                />
              }
            >
              <ClientGridComponent />
            </TabStripTab>
          </TabStrip>
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

const ClientGridComponent: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.client.loading);
  const ClientList = useAppSelector((state) => state.client?.ClientList);
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);
  const LeadStage = useAppSelector((state) => state.client.Lead_Stage);
  const LeadSearch = useAppSelector((state) => state.client.LeadSearch);
  const isEditAccess = checkAcessRights(location.pathname, EDIT_ACCESS);
  const isDeleteAccess = checkAcessRights(location.pathname, DELETE_ACCESS);

  useEffect(() => {
    const fetchItemList = async () => {
      const payload = {
        LeadSearch: LeadSearch ?? "",
        LeadStage: LeadStage === 0 ? 1 : 2,
        limit: +pageLimit,
        skip: +currentPage * +pageLimit,
      };
      await dispatch(getAllClient(payload));
    };
    fetchItemList();
  }, [currentPage, pageLimit]);

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);

  const pageChange = (event: GridPageChangeEvent) => {
    const newPage = event.page.skip / event.page.take;
    dispatch(setPageLimit(event.page.take));
    dispatch(setCurrentPage(newPage));
  };

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleEditItem = (LeadGUID: any) => {
      navigate("/clientform/edit", {
        state: { LeadGUID },
      });
    };

    const handleDownloadPDF = async (LeadGUID: string) => {
      const response = await dispatch(downloadClientPDF(LeadGUID));
      if (response?.payload?.Status === 200) {
        const url = response?.payload?.Data;
        if (url && typeof url === "string") {
          window.open(url);
        }
      }
    };
    const handleOpenDeleteDialog = (LeadGUID: any) => {
      dispatch(openDialog("deleteClient"));
      dispatch(setClientID(LeadGUID));
    };

    return (
      <td
        className="action-td"
        style={{
          ...props.style,
        }}
      >
        {isEditAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Download"
            onClick={() => handleDownloadPDF(props.dataItem.LeadGUID)}
          >
            <FaFileDownload
              className="absolute-position"
              style={{ fontSize: "20px" }}
            />
          </IconButton>
        )}
        {isEditAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Edit"
            onClick={() => handleEditItem(props.dataItem.LeadGUID)}
          >
            <FiEdit
              className="absolute-position"
              style={{ fontSize: "20px" }}
            />
          </IconButton>
        )}
        {isDeleteAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Delete"
            onClick={() => handleOpenDeleteDialog(props.dataItem.LeadGUID)}
          >
            <MdDelete
              className="absolute-position"
              style={{ fontSize: "24px" }}
            />
          </IconButton>
        )}
      </td>
    );
  };
  const ActivityLogCommandCell = (props: GridCellProps) => {
    const handleInquiryList = (LeadGUID: any, InquiryStatus: any) => {
      navigate("/clientform/Inquirylist", {
        state: { LeadGUID, InquiryStatus },
      });
    };

    const handleLeadList = (LeadGUID: any, InquiryStatus: any) => {
      navigate("/clientform/leadlist", {
        state: { LeadGUID, InquiryStatus },
      });
    };

    const handleActivityLog = (ClientActivityLog: any) => {
      dispatch(setSelectedILTab(ClientActivityLog.logStatus));
      navigate("/clientform/clientactivitylog", {
        state: {
          Clientlogs: ClientActivityLog,
        },
      });
    };

    const handleOtherInvestors = (OtherInvestor: any) => {
      navigate("/otherinvestors", {
        state: {
          otherinverstor: OtherInvestor,
        },
      });
    };

    return (
      <td className="action-td">
        <IconButton
          type="button"
          fillMode="outline"
          size="small"
          themeColor="info"
          style={{ marginRight: 5 }}
          title="Inquiry List"
          onClick={() =>
            handleInquiryList(props.dataItem.LeadGUID, {
              InquiryStatus: "OPEN",
              status: 0,
              LeadID: props.dataItem.ID,
              FullName: `${props.dataItem.FirstName} ${props.dataItem.LastName}`,
              LeadStage: props.dataItem?.LeadStage,
              OpenCount: props.dataItem?.OpenCount,
              CloseCount: props.dataItem?.CloseCount,
            })
          }
        >
          <FiList />
        </IconButton>
        <IconButton
          type="button"
          fillMode="outline"
          size="small"
          themeColor="primary"
          style={{ marginRight: 5 }}
          title="Lead List"
          onClick={() =>
            handleLeadList(props.dataItem.LeadGUID, {
              InquiryStatus: "ALL",
              status: 1,
              flag: 2,
              ClientID: props.dataItem.ID,
            })
          }
        >
          <MdLeaderboard />
        </IconButton>
        <IconButton
          type="button"
          fillMode="outline"
          size="small"
          style={{ marginRight: 5 }}
          themeColor="tertiary"
          title="Activity Log"
          onClick={() =>
            handleActivityLog({
              LeadGUID: props.dataItem.LeadGUID,
              logStatus: 0,
              NBLeadsGUID: null,
            })
          }
        >
          <RxActivityLog />
        </IconButton>
        <IconButton
          type="button"
          fillMode="outline"
          size="small"
          themeColor="dark"
          title="Other Investors"
          onClick={() =>
            handleOtherInvestors({
              LeadID: props.dataItem.ID,
            })
          }
        >
          <LuMenuSquare />
        </IconButton>
      </td>
    );
  };
  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className={`table-header ${props.index === 5 ? "locked-header" : ""}`}
    >
      {props.children}
    </HeaderThElement>
  );
  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };
  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );
  const MyActiveInActtiveCommandCell = (props: GridCellProps) => {
    const [IsActive, setIsActive] = useState<boolean>(props.dataItem.IsActive);

    const handleActiveInActive = () => {
      setIsActive(!IsActive);
      const data = {
        LeadGUID: props.dataItem.LeadGUID,
        IsActive: !IsActive,
      };
      const payload = {
        LeadSearch: LeadSearch ?? "",
        LeadStage: LeadStage === 0 ? 1 : 2,
        limit: +pageLimit,
        skip: +currentPage * +pageLimit,
      };
      dispatch(ClientActiveInactive(data)).then((response: any) => {
        if (response.payload?.data?.Status === 200) {
          dispatch(getAllClient(payload));
        } else {
          dispatch(getAllClient(payload));
        }
      });
    };

    return (
      <td style={{ textAlign: "center" }}>
        <Switch
          name="IsActive"
          checked={IsActive}
          disabled={isEditAccess ? false : true}
          onChange={handleActiveInActive}
          size="small"
        />
      </td>
    );
  };
  const gridRef = useRef<any>(null);
  // if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      {loading && <LoadingPanel gridRef={gridRef} />}
      <KendoGrid
        style={{ maxHeight: "calc(100vh - 225px)" }}
        filterable={true}
        className="disableFilterOprator"
        filter={filter}
        data={filter ? filterBy(ClientList || [], filter) : ClientList || []}
        onFilterChange={handleFilterChange}
        total={
          (ClientList && ClientList[0]?.SearchCount) || ClientList?.length || 0
        }
        skip={currentPage * pageLimit}
        take={pageLimit}
        pageable={{
          buttonCount: 5,
          pageSizes: [10, 50, 100, 500, 1000, 5000],
          pageSizeValue: pageLimit,
          type: "input",
        }}
        onPageChange={pageChange}
        cells={{
          headerCell: HeaderCustomCell,
          data: MyDataCustomCell,
        }}
      >
        <Column
          field={"FirstName"}
          title="First Name"
          filterable
          filterCell={MyCustomFilterCell}
          cell={(props: any) => (
            <td>
              {isEditAccess ? (
                <div
                  className="hoverableName"
                  onClick={() =>
                    navigate("/clientform/edit", {
                      state: {
                        LeadGUID: props.dataItem?.LeadGUID,
                      },
                    })
                  }
                >
                  {`${props.dataItem?.FirstName} ${props.dataItem?.LastName}`}
                </div>
              ) : (
                props.dataItem?.FirstName
              )}
            </td>
          )}
        />
        <Column
          field="GroupCode"
          filterable
          filterCell={MyCustomFilterCell}
          title="Group Code"
        />
        <Column
          field="MobileNo"
          title="Mobile No"
          filterable
          filterCell={MyCustomFilterCell}
        />
        <Column
          field="EmailID"
          title="Email"
          filterable
          filterCell={MyCustomFilterCell}
        />
        <Column field="OccupationName" title="Occupation" />
        <Column
          field="ID"
          title="Status"
          cell={MyActiveInActtiveCommandCell}
          width={"90px"}
          filterable={false}
          locked={true}
        />
        <Column
          field="Open"
          title="Others"
          cell={ActivityLogCommandCell}
          width={"200px"}
          filterable={false}
          locked={true}
        />
        {(isDeleteAccess || isEditAccess) && (
          <Column
            field="LeadGUID"
            title="Actions"
            cell={MyEditCommandCell}
            width={"140px"}
            filterable={false}
            locked={true}
          />
        )}
      </KendoGrid>
    </>
  );
};

const DeleteClientDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const ClientID = useAppSelector((state) => state.client.ClientID);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);
  const LeadSearch = useAppSelector((state) => state.client.LeadSearch);
  const LeadStage = useAppSelector((state) => state.client.Lead_Stage);
  const handleDeleteAction = async (ID: number | null) => {
    if (ID) {
      const response = await dispatch(deleteClient(ID));
      dispatch(setCurrentPage(0));
      const payload = {
        LeadStage: LeadStage,
        LeadSearch: LeadSearch ?? "",
        limit: +pageLimit,
        skip: +currentPage * +pageLimit,
      };
      if (response.payload?.Data.Status === 200) {
        dispatch(getAllClient(payload));
        dispatch(closeDialog());
        dispatch(clearClientID());
      } else {
        dispatch(getAllClient(payload));
        dispatch(closeDialog());
        dispatch(clearClientID());
      }
    }
  };

  return (
    <>
      {dialogName === "deleteClient" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete ClientForm ?"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this Client?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => {
                    handleDeleteAction(ClientID);
                  }}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

const MyCustomFilterCell = (props: any) => {
  const dispatch = useAppDispatch();
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);
  const LeadStage = useAppSelector((state) => state.client.Lead_Stage);
  
  // const LeadSearch = useAppSelector((state) => state.client.LeadSearch);
  // const LeadSearchField = useAppSelector(
  //   (state) => state.client.LeadSearchField
  // );
  let textBox: any;
  const [inputValue, setInputValue] = useState(props.value || "");
  const [inputField, setInputField] = useState(props.field || "");
  const valueRef = useRef<any>(false);

  const containsText = (value: any, filter: any) =>
    value && value.toLowerCase().includes(filter.toLowerCase());

  const onChange = (event: any) => {
    setInputValue(event.target.value);
    setInputField(event.target.name);
    props.onChange({
      value: textBox.value,
      operator: containsText,
      syntheticEvent: event.syntheticEvent,
    });
  };

  const delayedApiCall = React.useCallback(
    _debounce((field: string, value: string) => {
      dispatch(setCurrentPage(0));
      dispatch(setLeadSearchFilter({ Field: field, Value: value }));
      dispatch(setLeadSearch(value));
      dispatch(setLeadSearchField(field));
      const payload = {
        LeadSearch: value,
        LeadStage: LeadStage === 0 ? 1 : 2,
        limit: +pageLimit,
        skip: 0 * +pageLimit,
      };

      dispatch(getAllClient(payload));
    }, 300),
    [dispatch, pageLimit]
  );
  useEffect(() => {
    if (valueRef.current) {
      delayedApiCall(inputField, inputValue);
    } else {
      valueRef.current = true;
    }
    return () => {
      delayedApiCall.cancel();
    };
  }, [inputValue, delayedApiCall]);

  const onClearButtonClick = (event: any) => {
    event.preventDefault();
    props.onChange({
      value: null,
      operator: "",
      syntheticEvent: event,
    });
    dispatch(setLeadSearch(""));
    dispatch(setLeadSearchField(""));
    const payload = {
      LeadSearch: "",
      LeadStage: LeadStage === 0 ? 1 : 2,
      limit: +pageLimit,
      skip: +currentPage * +pageLimit,
    };
    dispatch(getAllClient(payload));
  };

  return (
    <div className="d-flex">
      <TextBox
        value={props.value}
        ref={(input) => {
          textBox = input;
        }}
        onChange={onChange}
      />
      <Button
        svgIcon={filterClearIcon}
        title="Clear"
        disabled={!props.value}
        onClick={onClearButtonClick}
        type="button"
        style={{
          marginLeft: "8px",
        }}
      ></Button>
    </div>
  );
};

export default ClientForm;
