import React, { useEffect, useRef } from "react";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormTextField from "../../components/formFields/FormTextField";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import { clearStateDetails } from "./stateSlice";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import {
  createState,
  updateState,
  getAllStates,
  getStateByID,
} from "./services/state.services";
import { getAllActiveCountries } from "../country/services/country.services";
import { ICountry } from "../country/countryModel";
import { Typography } from "@progress/kendo-react-common";
import { RxCross1 } from "react-icons/rx";
import { STATUSARRAY } from "../../_contstants/common";
import { closed } from "../../components/drawer/drawerSlice";

const CreateState: React.FC = () => {
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);

  const CountryList = useAppSelector((state) => state.country.CountryList);
  const StateDetail = useAppSelector((state) => state.state.StateDetail);
  const StateGUID = useAppSelector((state) => state.drawer.data);
  const formLoading = useAppSelector((state) => state.state.formLoading);

  useEffect(() => {
    dispatch(getAllActiveCountries());

    return () => {
      dispatch(clearStateDetails());
      dispatch(closed());
    };
  }, []);

  useEffect(() => {
    if (StateGUID) {
      dispatch(getStateByID(StateGUID));
    }
  }, [StateGUID]);

  const handleSubmit = async (values: any) => {
    try {
      if (!StateGUID) {
        const insertPayload = {
          StateName: values.StateName,
          CountryID: values.CountryID ? +values.CountryID : null,
          IsActive: values.IsActive === 1,
        };
        const response = await dispatch(createState(insertPayload));
        if (response?.payload?.Status === 201) {
          dispatch(closed());
          dispatch(clearStateDetails());
          dispatch(getAllStates());
        }
      } else {
        const editPayload = {
          StateGUID: StateGUID,
          StateName: values.StateName,
          CountryID: values.CountryID ? +values.CountryID : null,
          IsActive: values.IsActive === 1,
        };
        const response = await dispatch(updateState(editPayload));
        if (response?.payload?.Status === 200) {
          dispatch(closed());
          dispatch(clearStateDetails());
          dispatch(getAllStates());
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  const handleFormClose = () => {
    dispatch(closed());
  };

  if (formLoading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={StateDetail}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement style={{ width: "100%" }}>
          <GridLayout
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
            gap={{ rows: 10 }}
            cols={[{ width: "100%" }]}
          >
            <GridLayoutItem className="drawer-header">
              <Typography.h4 className="m-0">
                {StateGUID ? "Update State" : "Create State"}
              </Typography.h4>
              <Button
                type="button"
                fillMode={"flat"}
                themeColor={"dark"}
                style={{ marginRight: 4 }}
                onClick={() => dispatch(closed())}
              >
                <RxCross1 />
              </Button>
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="StateName"
                label="State Name"
                placeholder="i.e.Gujarat"
                component={FormTextField}
                validator={requiredValidator} astrike={true}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                id={"CountryID"}
                name={`CountryID`}
                label={"Country Name"}
                placeholder="i.e. India"
                component={FormSelectionField}
                validator={requiredValidator} astrike={true}
                options={CountryList?.map((country: ICountry) => {
                  return {
                    value: country?.ID,
                    label: country?.CountryName,
                  };
                })}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                id={"IsActive"}
                name={"IsActive"}
                label={"Status"}
                placeholder="Status"
                component={FormSelectionField}
                validator={requiredValidator} astrike={true}
                options={STATUSARRAY?.map((status: any) => {
                  return {
                    value: status.value,
                    label: status.label,
                  };
                })}
              />
            </GridLayoutItem>
            <GridLayoutItem
              colSpan={3}
              style={{
                marginTop: 20,
                width: "100%",
              }}
            >
              <ButtonWithLoading
                label={StateGUID ? "Update" : "Create"}
                type="submit"
                disabled={!formRenderProps.allowSubmit || formLoading}
                loading={formLoading}
              />
              <Button
                type="button"
                fillMode={"outline"}
                themeColor={"primary"}
                style={{ marginLeft: 4 }}
                onClick={handleFormClose}
              >
                Cancel
              </Button>
            </GridLayoutItem>
          </GridLayout>
        </FormElement>
      )}
    />
  );
};

export default CreateState;
