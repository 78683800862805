import { Button } from "@progress/kendo-react-buttons";
import { Card, GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import React, { useEffect, useState } from "react";
import {
  Field,
  FieldArrayRenderProps,
  FormRenderProps,
} from "@progress/kendo-react-form";
import {
  requiredArrayValidator,
  requiredValidator,
} from "../../components/formFields/CommonValidator";
import FormTextField from "../../components/formFields/FormTextField";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { Typography } from "@progress/kendo-react-common";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import FormDatePicker from "../../components/formFields/FormDateField";
import { ErrorToast, SuccessToast } from "../../components/toast/Toasts";
// import { findAllActiveRelation } from "../relation/services/relation.services";
import { setInquiryDeleteIndex } from "./nbinquirySlice";
import {
  deleteInquiryArrayDetails,
  // findClientsByID,
} from "./services/nbinquiry.services";
import { ILeadType } from "../leadtype/leadTypeModel";
import { ILeadStatus } from "../leadstatus/leadStatusModel";
import { IInquiryType } from "../inquiryType/inquirytypeModel";
import { IInquirySubtype } from "../inquirySubType/inquirysubtypeModel";
import { FREQUANCY_LIST } from "../../_contstants/common";
import { useLocation } from "react-router-dom";
import FormMultiSelectionFiled from "../../components/formFields/FormMultiSelectionFiled";
import IconButton from "../../components/common/IconButton";
import { MdDelete } from "react-icons/md";
// import InquiryDetailsSubArray from "./InquiryDetailsSubArray";
interface InquiryTypeChangeWatcherProps {
  formRenderProps: FormRenderProps;
  index: number;
}
const InquiryTypeChangeWatcher: React.FC<InquiryTypeChangeWatcherProps> = ({
  formRenderProps,
  index,
}) => {
  const InquiryTypeID = formRenderProps.valueGetter(
    `NBInquiryList.${index}.InquiryTypeID`
  );
  const NBInquirySubtype = formRenderProps.valueGetter(
    `NBInquiryList.${index}.InquirySubTypeID`
  );
  const InquirySubtypeList = useAppSelector(
    (state) => state.inquirysubtype.InquirySubtypeList
  );

  const filterInquirySubType = (InquirySub: any) => {
    if (InquirySub && InquirySub.length > 0) {
      const result = InquirySubtypeList?.filter((inquitysubtype: any) => {
        return InquirySub?.includes(inquitysubtype.InquiryTypeID);
      });
      return result?.map((inquitysubtype: IInquirySubtype) => {
        return {
          value: inquitysubtype?.ID,
          label: inquitysubtype?.InquirySubType,
        };
      });
    }
  };

  const InquiryTypeList = useAppSelector(
    (state) => state.inquiry.InquiryTypeList
  );
  const [InquiryTypeSelected, setInquiryTypeSelected] = useState<any[] | null>(
    []
  );

  useEffect(() => {
    if (InquiryTypeID && InquiryTypeID.length > 0) {
      // Populate InquiryTypeSelected.current with selected Inquiry Types

      const filteredSubType = filterInquirySubType(InquiryTypeID);
      const selectedSubtype = filteredSubType
        ?.filter((item) => NBInquirySubtype?.includes(item.value))
        .map((item) => item.value)
        .filter((value): value is number => value !== null);
      formRenderProps.onChange(`NBInquiryList.${index}.InquirySubTypeID`, {
        value: selectedSubtype?.length ? selectedSubtype : null,
      });

      const selectedTypes = InquiryTypeID.map((id: number) =>
        InquiryTypeList.find((item: any) => item.ID === id)
      ).filter(Boolean); // Filter out undefined values if any ID doesn't match
      // Check for removed InquiryTypeIDs and clear associated data
      const removedTypes = InquiryTypeSelected?.filter(
        (item) => !InquiryTypeID.includes(item.ID)
      );
      removedTypes?.forEach((item) => {
        const NBInquiryDetailEntityArray = formRenderProps.valueGetter(
          `NBInquiryList.${index}.NBInquiryDetailEntity`
        );
        if (NBInquiryDetailEntityArray.length > 0) {
          delete NBInquiryDetailEntityArray[0][`P_${item.ID}`];
          delete NBInquiryDetailEntityArray[0][`C_${item.ID}`];
        }
        formRenderProps.onChange(
          `NBInquiryList.${index}.NBInquiryDetailEntity`,
          { value: NBInquiryDetailEntityArray }
        );
      });
      setInquiryTypeSelected(selectedTypes);
    } else {
      formRenderProps.onChange(`NBInquiryList.${index}.NBInquiryDetailEntity`, {
        value: [],
      });
      formRenderProps.onChange(`NBInquiryList.${index}.InquirySubTypeID`, {
        value: null,
      });
      setInquiryTypeSelected(null);
    }
  }, [InquiryTypeID, InquiryTypeList]);

  const LeadStatusID = formRenderProps.valueGetter(
    `NBInquiryList.${index}.LeadStatusID`
  );
  const LeadStatusList = useAppSelector(
    (state) => state.leadStatus.LeadStatusList
  );
  const LeadStatusIsWON = LeadStatusList.some(
    (item: any) => item.ID === LeadStatusID && item.LeadStatus === "WON"
  );

  useEffect(() => {
    InquiryTypeSelected?.forEach((item) => {
      const NBInquiryDetailEntityArray = formRenderProps.valueGetter(
        `NBInquiryList.${index}.NBInquiryDetailEntity`
      );
      if (NBInquiryDetailEntityArray.length > 0) {
        delete NBInquiryDetailEntityArray[0][`C_${item.ID}`];
      }
      formRenderProps.onChange(`NBInquiryList.${index}.NBInquiryDetailEntity`, {
        value: NBInquiryDetailEntityArray,
      });
    });
  }, [LeadStatusIsWON]);
  const renderFields = (prefix: string) =>
    InquiryTypeSelected?.map((item: any) => (
      <Field
        key={item.ID}
        wrapperStyle={{
          display: "flex",
          alignItems: "center",
        }}
        name={`NBInquiryList.${index}.NBInquiryDetailEntity.${0}.${prefix}_${
          item.ID
        }`}
        type="number"
        label={item.InquiryType}
        labelStyle={{ width: "50%" }}
        placeholder={item.InquiryType}
        component={FormTextField}
        validator={requiredValidator} astrike={true}
      />
    ));

  return (
    <>
      {InquiryTypeSelected && InquiryTypeSelected.length > 0 && (
        <GridLayoutItem colSpan={2}>
          <Card
            style={{
              marginTop: "10px",
              border: "1px dotted lightgray",
              padding: "10px",
            }}
          >
            <Typography.h6 style={{ textAlign: "center", margin: "0" }}>
              Proposed Amounts
            </Typography.h6>
            {renderFields("P")}
          </Card>
        </GridLayoutItem>
      )}

      {InquiryTypeSelected && InquiryTypeSelected.length > 0 && LeadStatusIsWON && (
        <GridLayoutItem colSpan={2}>
          <Card
            style={{
              marginTop: "10px",
              border: "1px dotted lightgray",
              padding: "10px",
            }}
          >
            <Typography.h6 style={{ textAlign: "center", margin: "0" }}>
              Closing Amounts
            </Typography.h6>
            {renderFields("C")}
          </Card>
        </GridLayoutItem>
      )}
    </>
  );
};

const InquiryArray = (fieldArrayRenderProps: FieldArrayRenderProps) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const InquiryDeleteIndex = useAppSelector(
    (state) => state.nbinquiry.DeleteIndex
  );
  const NBInquiryTypeGUID = location.state?.inquiryType?.NBInquiryTypeGUID;
  const IsInquiryType = location.state?.inquiryType?.IsInquiryType;
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const LeadTypeList = useAppSelector((state) => state.leadType.LeadTypeList);
  const LeadStatusList = useAppSelector(
    (state) => state.leadStatus.LeadStatusList
  );
  const InquiryTypeList = useAppSelector(
    (state) => state.inquiry.InquiryTypeList
  );
  const FamilyMemberList = useAppSelector(
    (state) => state.nbinquiry.FamilyMemberList
  );
  const InquirySubtypeList = useAppSelector(
    (state) => state.inquirysubtype.InquirySubtypeList
  );
  const UserList = useAppSelector((state) => state.client.UserList);
  //const ID = location?.state?.LeadID;

  // useEffect(() => {
  //   dispatch(findClientsByID(ID));
  //   dispatch(findAllActiveRelation());
  // }, []);

  const handleDeleteAction = React.useCallback(
    async (index: number) => {
      if (fieldArrayRenderProps?.value[index].ID) {
        try {
          const response = await dispatch(
            deleteInquiryArrayDetails(fieldArrayRenderProps?.value[index].ID)
          ); 
          SuccessToast(response?.payload?.Details || "Success");
        } catch (error: any) {
          ErrorToast(error?.response?.data?.Details || "Something went wrong");
          console.error("Error deleting Family Detail:", error);
          return Promise.reject({
            message: error?.response?.data?.Details || "Something went wrong",
          });
        }
        fieldArrayRenderProps.onRemove({ index: index });
        dispatch(closeDialog());
        dispatch(setInquiryDeleteIndex(-1));
      } else {
        fieldArrayRenderProps.onRemove({ index: index });
        dispatch(closeDialog());
        dispatch(setInquiryDeleteIndex(-1));
      }
    },
    [fieldArrayRenderProps]
  );

  const pushElementInarray = React.useCallback(() => {
    fieldArrayRenderProps?.onPush({
      value: {
        ID: null,
        NBInquiryBy: null,
        InquiryAllotmentID: null,
        InquirySubTypeID: null,
        LeadTypeID: null,
        LeadStatusID: null,
        CoPersonAllotmentID: null,
        Frequency: "",
        InquiryDate: null,
        NBInquiryDetailEntity: null,
      },
    });
  }, [fieldArrayRenderProps]);
  const filterInquirySubType = (InquirySub: any) => {
    if (InquirySub && InquirySub.length > 0) {
      const result = InquirySubtypeList?.filter((inquitysubtype: any) => {
        return InquirySub?.includes(inquitysubtype.InquiryTypeID);
      });
      return result?.map((inquitysubtype: IInquirySubtype) => {
        return {
          value: inquitysubtype?.ID,
          label: inquitysubtype?.InquirySubType,
        };
      });
    }
  };

  const handleOpenDeleteDialog = (index: any) => {
    dispatch(openDialog("inquiryDeleteDialog"));
    dispatch(setInquiryDeleteIndex(index));
  };
  return (
    <GridLayout cols={[{ width: "100%" }]}>
      {fieldArrayRenderProps?.value?.length <= 0 ? pushElementInarray() : null}
      {dialogName === "inquiryDeleteDialog" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Inquiry Details"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(InquiryDeleteIndex)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
      {fieldArrayRenderProps.value &&
        fieldArrayRenderProps.value.length > 0 &&
        fieldArrayRenderProps.value.map((process_data: any, index: number) => (
          <GridLayoutItem key={index}>
            <Card className="fieldarray-card" style={{ marginBottom: 10 }}>
              <GridLayout
                style={{ marginRight: 60, marginBottom: 20, marginLeft: 15 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "20%" },
                  { width: "20%" },
                  { width: "20%" },
                  { width: "20%" },
                  { width: "20%" },
                ]}
              >
                <GridLayoutItem colSpan={4} className="d-flex align-items-end">
                  <h6
                    className="m-0 fw-600"
                    style={{
                      color: "green",
                      background: "#ececec",
                      padding: "7px 10px",
                    }}
                  >
                    Inquiry
                  </h6>
                </GridLayoutItem>
                <GridLayoutItem
                  style={{
                    marginTop: 17,
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  {!IsInquiryType && (
                    <IconButton
                      onClick={() => handleOpenDeleteDialog(index)}
                      disabled={fieldArrayRenderProps.value.length === 1}
                      themeColor={"error"}
                      size={"small"}
                      fillMode={"solid"}
                      type="button"
                      style={{
                        height: 32,
                        width: 32,
                      }}
                    >
                      <MdDelete
                        className="absolute-position"
                        style={{ fontSize: "16px" }}
                      />
                    </IconButton>
                  )}
                  {/* <Button
                    style={{ padding: 4, marginLeft: 0 }}
                    onClick={() => handleOpenDeleteDialog(index)}
                    disabled={fieldArrayRenderProps.value.length === 1}
                    fillMode={"solid"}
                    themeColor={"error"}
                    type="button"
                    size={"small"}
                  >
                    Delete
                  </Button> */}
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`NBInquiryList.${index}.NBInquiryBy`}
                    label="Family Member"
                    placeholder="Family Member"
                    component={FormSelectionField}
                    validator={requiredValidator} astrike={true}
                    options={FamilyMemberList?.map((familymember: any) => {
                      return {
                        value: familymember?.ID,
                        label: `${familymember?.FirstName} ${familymember?.LastName}`,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`NBInquiryList.${index}.InquiryDate`}
                    label="Inquiry Date"
                    placeholder="Inquiry Date"
                    disabled={NBInquiryTypeGUID}
                    component={FormDatePicker}
                    validator={requiredValidator} astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`NBInquiryList.${index}.InquiryTypeID`}
                    label="Inquiry Type"
                    placeholder="Inquiry Type"
                    component={FormMultiSelectionFiled}
                    validator={requiredArrayValidator}
                    options={InquiryTypeList?.map(
                      (inquirytype: IInquiryType) => {
                        return {
                          value: inquirytype?.ID,
                          label: inquirytype?.InquiryType,
                        };
                      }
                    )}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`NBInquiryList.${index}.InquirySubTypeID`}
                    label="Inquiry Sub Type"
                    placeholder="Inquiry Sub Type"
                    component={FormMultiSelectionFiled}
                    validator={requiredValidator} astrike={true}
                    options={
                      filterInquirySubType(
                        fieldArrayRenderProps.formRenderProps.valueGetter(
                          `NBInquiryList.${index}.InquiryTypeID`
                        )
                      ) || []
                    }
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`NBInquiryList.${index}.LeadTypeID`}
                    label="Lead Type"
                    placeholder="Lead Type"
                    component={FormSelectionField}
                    validator={requiredValidator} astrike={true}
                    options={LeadTypeList?.map((leadtype: ILeadType) => {
                      return {
                        value: leadtype?.ID,
                        label: leadtype?.LeadType,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`NBInquiryList.${index}.LeadStatusID`}
                    label="Lead Status"
                    placeholder="Lead Status"
                    component={FormSelectionField}
                    validator={requiredValidator} astrike={true}
                    options={LeadStatusList?.map((leadstatus: ILeadStatus) => {
                      return {
                        value: leadstatus?.ID,
                        label: leadstatus?.LeadStatus,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`NBInquiryList.${index}.InquiryAllotmentID`}
                    label="Alloted To"
                    placeholder="Alloted To"
                    component={FormSelectionField}
                    validator={requiredValidator} astrike={true}
                    options={UserList?.map((user: any) => {
                      return {
                        value: user?.ID,
                        label: `${user?.FirstName} ${user?.LastName}`,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`NBInquiryList.${index}.CoPersonAllotmentID`}
                    label="Co-Person Alloted To"
                    placeholder="Co-Person Alloted"
                    component={FormSelectionField}
                    options={UserList?.map((user: any) => {
                      return {
                        value: user?.ID,
                        label: `${user?.FirstName} ${user?.LastName}`,
                      };
                    })}
                  />
                </GridLayoutItem>
                {/* <GridLayoutItem>
                  <Field
                    name={`NBInquiryList.${index}.ProposedAmount`}
                    label="Proposed Amount"
                    placeholder="Proposed Amount"
                    type="number"
                    component={FormTextField}
                    validator={requiredValidator} astrike={true}
                  />
                </GridLayoutItem> */}
                <GridLayoutItem>
                  <Field
                    name={`NBInquiryList.${index}.Frequency`}
                    label="Frequency"
                    placeholder="Frequency"
                    component={FormSelectionField}
                    validator={requiredValidator} astrike={true}
                    options={FREQUANCY_LIST?.map((frequancy: any) => {
                      return {
                        value: frequancy?.value,
                        label: frequancy?.label,
                      };
                    })}
                  />
                </GridLayoutItem>
                <InquiryTypeChangeWatcher
                  formRenderProps={fieldArrayRenderProps.formRenderProps}
                  index={index}
                />
              </GridLayout>
            </Card>
          </GridLayoutItem>
        ))}
      {!IsInquiryType && (
        <GridLayoutItem
          style={{ marginBottom: 10, textAlign: "left", marginTop: 15 }}
        >
          <Button
            fillMode={"solid"}
            themeColor={"primary"}
            onClick={pushElementInarray}
            type="button"
          >
            Add Inquiry
          </Button>
        </GridLayoutItem>
      )}
    </GridLayout>
  );
};

export default InquiryArray;
