import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IOccupationInitialState, IOccupationType } from "./occupationtypeModel";
import { createOccupationType, deleteOccupationType, findAllActiveOccupationType, getAllOccupationType, getOccupationTypeById, OccupationTypeActiveInactive, updateOccupationType } from "./services/occupation.services";


const initialState: IOccupationInitialState = {
  loading: false,
  formLoading: false,
  error: "",
  OccupationTypeList: [],
  OccupationTypeID: null,
  OccupationTypeDetail: {
      OccupationGUID: "",
      OccupationName: "",
      IsActive: 1
  },
};

export const occupationtypeSlice = createSlice({
  name: "occupationtype",
  initialState,
  reducers: {
    clearOccupationTypeDetails: (state) => {
      state.OccupationTypeDetail = initialState.OccupationTypeDetail;
    },
    setOccupationTypeID: (state, action) => {
      state.OccupationTypeID = action.payload;
    },
    clearOccupationTypeID: (state) => {
      state.OccupationTypeID = null;
    },
  },
  extraReducers: (builder) => {

    builder.addCase(getAllOccupationType.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllOccupationType.fulfilled,
      (state, action: PayloadAction<IOccupationType[]>) => {
        state.loading = false;
        state.OccupationTypeList = action.payload;
      }
    );
    builder.addCase(getAllOccupationType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.OccupationTypeList = [];
    });


    builder.addCase(findAllActiveOccupationType.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      findAllActiveOccupationType.fulfilled,
      (state, action: PayloadAction<IOccupationType[]>) => {
        state.loading = false;
        state.OccupationTypeList = action.payload || [];
      }
    );
    builder.addCase(findAllActiveOccupationType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.OccupationTypeList = [];
    });


    builder.addCase(createOccupationType.pending, (state) => {
      state.formLoading = false;
      state.error = "";
    });
    builder.addCase(createOccupationType.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createOccupationType.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });


    builder.addCase(updateOccupationType.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateOccupationType.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateOccupationType.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });


    builder.addCase(deleteOccupationType.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteOccupationType.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteOccupationType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });


    builder.addCase(OccupationTypeActiveInactive.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(OccupationTypeActiveInactive.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(OccupationTypeActiveInactive.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });


    builder.addCase(getOccupationTypeById.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getOccupationTypeById.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.OccupationTypeDetail = action.payload;
    });
    builder.addCase(getOccupationTypeById.rejected, (state, action) => {
      state.formLoading = false;
      state.OccupationTypeDetail = initialState.OccupationTypeDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearOccupationTypeDetails,
  setOccupationTypeID,
  clearOccupationTypeID,
} = occupationtypeSlice.actions;
export default occupationtypeSlice.reducer;
