import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../../app/config";
import { axiosApiInstance } from "../../../../app/axios";

export const getAllAttachments = createAsyncThunk(
  "MasterSub/AttachmentFilter",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/MasterSub/AttachmentFilter`,
        formData
      );
      return response.data?.Data;
    } catch (error: any) {
      console.error("Error Fetching attachments:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
export const createAttachment = createAsyncThunk(
  "MasterSub/InsertAttachment",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/MasterSub/InsertAttachment`,
        formData
      );
      return response.data;
    } catch (error: any) {
      console.error("Error create attachment:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
export const updateAttachment = createAsyncThunk(
  "MasterSub/UpdateAttachment",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/MasterSub/UpdateAttachment`,
        formData
      );
      return response.data;
    } catch (error: any) {
      console.error("Error Update attachment:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
export const deleteAttachment = createAsyncThunk(
  "MasterSub/AttachmentDelete",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/MasterSub/AttachmentDelete`,
        { ID: ID }
      );
      return response.data;
    } catch (error: any) {
      console.error("Error Update attachment:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
