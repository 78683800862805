import React, { useEffect } from "react";
import {
  GridLayout,
  GridLayoutItem,
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from "@progress/kendo-react-layout";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setSelectedILTab } from "../clientActivityTab/clientActivityTabSlice";
import ClientActivityLogs from "./ClientActivityLogs";
import { useLocation } from "react-router-dom";
import { getAllClientActivityLogs } from "../clientActivityTab/services/clientActivityTab.services";
import { Typography } from "@progress/kendo-react-common";
import { getClientById } from "./services/services.clientform";
import { clearClientDetails } from "./clientformSlice";
import { getAllActiveUsers } from "../user/services/user.services";
import TabStripTitle from "../../components/common/TabStripTitle";
import { FiList } from "react-icons/fi";
import { MdLeaderboard } from "react-icons/md";
import { AiOutlineDeploymentUnit } from "react-icons/ai";

const ClientActivityLogsTab: React.FC = () => {
  const location = useLocation();
  const SelectedILTab = useAppSelector(
    (state) => state.activityLog.SelectedILTab
  );

  const LeadGUID = location?.state?.Clientlogs?.LeadGUID;
  const NBLeadsGUID = location?.state?.Clientlogs?.NBLeadsGUID;
  const alignment = useAppSelector((state) => state.activityLog.alignment);
  const dispatch = useAppDispatch();
  const handleSelect = (e: TabStripSelectEventArguments) => {
    dispatch(setSelectedILTab(e.selected));
  };
  useEffect(() => {
    const payload = {
      PastActivityFilters: alignment === "PAST" ? 1 : 0,
      NBGUID: LeadGUID ?? null,
      NBLeadsGUID: NBLeadsGUID ?? null,
      NBActivityType:
        SelectedILTab === 1 ? "Lead" : SelectedILTab === 2 ? "All" : "Inquiry",
    };
    dispatch(getAllClientActivityLogs(payload));
  }, [SelectedILTab, alignment]);

  useEffect(() => {
    dispatch(getAllActiveUsers());
    return () => {
      dispatch(clearClientDetails());
    };
  }, []);

  useEffect(() => {
    if (LeadGUID) {
      dispatch(getClientById(LeadGUID));
    }
  }, [LeadGUID]);

  return (
    <>
      <GridLayout gap={{ rows: 10, cols: 20 }} cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            Client Activity Log
          </Typography.h4>
        </GridLayoutItem>
        <GridLayoutItem style={{ marginTop: 10 }}>
          <TabStrip
            className="nb-inquiry-types-tab"
            selected={SelectedILTab}
            onSelect={handleSelect}
          >
            <TabStripTab
              title={<TabStripTitle title={"Inquiry"} icon={FiList} />}
            >
              <ClientActivityLogs />
            </TabStripTab>
            <TabStripTab
              title={<TabStripTitle title={"Lead"} icon={MdLeaderboard} />}
            >
              <ClientActivityLogs />
            </TabStripTab>
            <TabStripTab
              title={
                <TabStripTitle title={"All"} icon={AiOutlineDeploymentUnit} />
              }
            >
              <ClientActivityLogs />
            </TabStripTab>
          </TabStrip>
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

export default ClientActivityLogsTab;
