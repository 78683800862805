import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { INBinquiry, INBinquirytialState } from "./nbinquiryModel";
import {
  createNBinquiry,
  deleteInquiryArrayDetails,
  findAllActiveNBinquiry,
  findClientsByID,
  findNBinquiryByID,
  getAllNBinquiry,
  getNBinquiryByGUID,
  getNBinquiryTypeByGUID,
  nbInquiryFindByLeadStatus,
  updateNBinquiry,
} from "./services/nbinquiry.services";

const initialState: INBinquirytialState = {
  loading: false,
  formLoading: false,
  error: "",
  NBInquiryTypeGUID: "",
  NBinquiryTypeID: null,
  NBinquiryList: [],
  InquiryStatusDetailList: [],
  NBinquiryID: null,
  NBInquiryTypeDetail: {
    LeadID: null,
    LeadStageID: "",
    NBInquiryList: [
      {
        ID: null,
        SrNo: 0,
        InquirySubTypeID: [],
        InquiryTypeID: [],
        NBInquiryBy: null,
        InquiryDate: "",
        Frequency: "",
        CoPersonAllotmentID: null,
        InquiryAllotmentID: null,
        LeadStatusID: null,
        LeadTypeID: null,
        NBInquiryDetailEntity: [],
      },
    ],
  },
  NBinquiryDetail: {
    ID: null,
    LeadID: null,
    AllotmentID: null,
    FamilyID: null,
    LeadStageID: "",
    NBInquiryList: [
      {
        ID: null,
        SrNo: 0,
        InquirySubTypeID: [],
        InquiryTypeID: [],
        NBInquiryBy: null,
        InquiryDate: "",
        Frequency: "",
        CoPersonAllotmentID: null,
        InquiryAllotmentID: null,
        LeadStatusID: null,
        LeadTypeID: null,
        NBInquiryDetailEntity: [],
      },
    ],
  },
  DeleteIndex: -1,
  FamilyMemberList: [],
  OpenCloseCount: {
    OpenCount: 0,
    CloseCount: 0,
  },
};

export const nbinquirySlice = createSlice({
  name: "nbinquiry",
  initialState,
  reducers: {
    clearNBinquiryDetails: (state) => {
      state.NBinquiryDetail = initialState.NBinquiryDetail;
      state.NBInquiryTypeDetail = initialState.NBInquiryTypeDetail;
    },
    setNBinquiryID: (state, action) => {
      state.NBinquiryID = action.payload;
    },
    clearNBinquiryID: (state) => {
      state.NBinquiryID = null;
    },
    clearNBInquiryTypeID: (state) => {
      state.NBinquiryTypeID = null;
    },
    setInquiryDeleteIndex: (state, action) => {
      state.DeleteIndex = action.payload;
    },
    setNBInquiryTypeGUID: (state, action) => {
      state.NBInquiryTypeGUID = action.payload;
    },
    setNBInquiryTypeID: (state, action) => {
      state.NBinquiryTypeID = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllNBinquiry.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllNBinquiry.fulfilled,
      (state, action: PayloadAction<INBinquiry[]>) => {
        state.loading = false;
        state.NBinquiryList = action.payload;
      }
    );
    builder.addCase(getAllNBinquiry.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.NBinquiryList = [];
    });

    builder.addCase(findAllActiveNBinquiry.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      findAllActiveNBinquiry.fulfilled,
      (state, action: PayloadAction<INBinquiry[]>) => {
        state.loading = false;
        state.NBinquiryList = action.payload || [];
      }
    );
    builder.addCase(findAllActiveNBinquiry.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.NBinquiryList = [];
    });

    builder.addCase(createNBinquiry.pending, (state) => {
      state.formLoading = false;
      state.error = "";
    });
    builder.addCase(createNBinquiry.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createNBinquiry.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(updateNBinquiry.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateNBinquiry.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateNBinquiry.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getNBinquiryByGUID.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getNBinquiryByGUID.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.NBinquiryDetail = action.payload;
    });
    builder.addCase(getNBinquiryByGUID.rejected, (state, action) => {
      state.formLoading = false;
      state.NBinquiryDetail = initialState.NBinquiryDetail;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getNBinquiryTypeByGUID.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getNBinquiryTypeByGUID.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.NBInquiryTypeDetail = action.payload;
    });
    builder.addCase(getNBinquiryTypeByGUID.rejected, (state, action) => {
      state.formLoading = false;
      state.NBInquiryTypeDetail = initialState.NBInquiryTypeDetail;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(findNBinquiryByID.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(findNBinquiryByID.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.NBinquiryList = action.payload;
    });
    builder.addCase(findNBinquiryByID.rejected, (state, action) => {
      state.formLoading = false;
      state.NBinquiryList = initialState.NBinquiryList;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(deleteInquiryArrayDetails.pending, (state) => {
      state.loading = false;
      state.error = "";
    });
    builder.addCase(deleteInquiryArrayDetails.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteInquiryArrayDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(findClientsByID.pending, (state) => {
      // state.formLoading = true;
      state.error = "";
    });
    builder.addCase(findClientsByID.fulfilled, (state, action) => {
      // state.formLoading = false;
      state.FamilyMemberList = action.payload || [];
    });
    builder.addCase(findClientsByID.rejected, (state, action) => {
      // state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
      state.FamilyMemberList = [];
    });

    builder.addCase(nbInquiryFindByLeadStatus.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(nbInquiryFindByLeadStatus.fulfilled, (state, action) => {
      state.formLoading = false; 
      state.InquiryStatusDetailList = action.payload?.NBInquiryLeadStatus;
      state.OpenCloseCount.OpenCount = action.payload?.OpenCount;
      state.OpenCloseCount.CloseCount = action.payload?.CloseCount;
    });
    builder.addCase(nbInquiryFindByLeadStatus.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.InquiryStatusDetailList = [];
    });

    // builder.addCase(createNbLead.pending, (state) => {
    //   state.formLoading = false;
    //   state.error = "";
    // });
    // builder.addCase(createNbLead.fulfilled, (state, action) => {
    //   state.formLoading = false;
    //   state.LeadList = action.payload

    // });
    // builder.addCase(createNbLead.rejected, (state, action) => {
    //   state.formLoading = false;
    //   state.error = action.error.message || "Something went wrong";
    //   state.LeadList = [];
    // });
    // builder.addCase(findNBLeadByLeadGUID.pending, (state) => {
    //   state.formLoading = false;
    //   state.error = "";
    // });
    // builder.addCase(findNBLeadByLeadGUID.fulfilled, (state, action) => {
    //   state.formLoading = false;
    //   state.LeadList = action.payload

    // });
    // builder.addCase(findNBLeadByLeadGUID.rejected, (state, action) => {
    //   state.formLoading = false;
    //   state.error = action.error.message || "Something went wrong";
    //   state.LeadList = [];
    // });
  },
});

export const {
  clearNBinquiryDetails,
  setNBinquiryID,
  clearNBinquiryID,
  setInquiryDeleteIndex,
  setNBInquiryTypeGUID,
  clearNBInquiryTypeID,
  setNBInquiryTypeID,
} = nbinquirySlice.actions;
export default nbinquirySlice.reducer;
