import {
  // Avatar,
  MenuProps,
  Dropdown,
} from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
// import UserLogo from "../../assets/Images/useravatar.jpg";
import {  FaRegUser } from "react-icons/fa";
import { RiLockPasswordLine } from "react-icons/ri";
import { useAppDispatch } from "../../app/hooks";
import { openDialog } from "../dialog/dialogSlice";
import { FiEdit } from "react-icons/fi";


const UserProfile = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const UserName = localStorage.getItem("UserName");
  const UserImage = localStorage.getItem("UserImage");
  // const UserImage = localStorage.getItem("UserImage");
  const items: MenuProps["items"] = [
    {
      label: "Edit Profile",
      key: "editprofile",
      icon: <FiEdit />,
    },
    {
      label: "Change Password",
      key: "changepassword",
      icon: <RiLockPasswordLine />,
    },
    {
      label: "Logout",
      key: "logout",
      icon: <LogoutOutlined />,
    },
  ];

  const handleMenuClick: MenuProps["onClick"] = (e) => {
    switch (e.key) {
      case "logout":
        localStorage.clear();
        navigate("/login");
        break;

      case "changepassword":
        dispatch(openDialog("changePassword"));
        break;

      case "editprofile":
        navigate("/user/edit", {
          state: {
            UserID: localStorage.getItem("UserID"),
            UserGUID: localStorage.getItem("UserGUID"),
            IsEditProfile: true,
          },
        });
        break;
      default:
        console.warn(`Unknown key: ${e.key}`);
    }
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <Dropdown menu={menuProps} trigger={["click"]}>
      {/* <div className="userprofile">
        <Avatar
          className="userprofile-avatar"
          size={40}
          gap={0}
          icon={
            <img
              src={UserLogo}
              alt={`user image`}
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "50%",
              }}
            />
          }
        />
        <div style={{ display: "flex", alignItems: "center" }}>
          <strong>{UserName || ""}</strong>
        </div>
      </div> */}

      <div className=" userprofilenew">
        <div className="me-2" style={{ lineHeight: "20px" }}>
          <h6 style={{ margin: 0, textAlign: "end" }}>{UserName} </h6>
          <p style={{ margin: 0, textAlign: "end" }}>Active</p>
        </div>
        <div className="user_avatar">
          {UserImage ? (
            <img
              src={UserImage}
              height={35}
              width={35}
              style={{
                borderRadius: "50%",
                objectFit: "cover",
              }}
              alt="user"
            />
          ) : (
            <FaRegUser size={18} />
          )}
        </div>
      </div>
    </Dropdown>
  );
};

export default UserProfile;
