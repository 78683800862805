import React, { useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  GridCellProps,
  GridCustomCellProps,
  GridCustomHeaderCellProps,
  Grid as KendoGrid,
  GridColumn as Column,
  GridPageChangeEvent,
  GridFilterChangeEvent,
} from "@progress/kendo-react-grid";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { CompositeFilterDescriptor } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import { FaMicrophoneAlt, FaRegStickyNote, FaTasks } from "react-icons/fa";
import { FiEdit, FiPhoneCall } from "react-icons/fi";
import { FaPeopleGroup } from "react-icons/fa6";
import { TiAttachment } from "react-icons/ti";
import { openDialog } from "../../components/dialog/dialogSlice";
import { setNBInquiryTypeID } from "../NB Inquiry/nbinquirySlice";
import IconButton from "../../components/common/IconButton";
import { MdDelete } from "react-icons/md";
import {
  HeaderThElement,
  PagerTargetEvent,
} from "@progress/kendo-react-data-tools";
import { filterBy } from "@progress/kendo-data-query";
import { LoadingPanel } from "../../components/layout/Loading";
import AlertBox from "../../components/common/AlertBox";
import { DELETE_ACCESS, EDIT_ACCESS } from "../../_contstants/common";
import { checkAcessRights } from "../../_helper/helper";

interface PageState {
  skip: number;
  take: number;
}
const initialDataState: PageState = { skip: 0, take: 10 };
const NoActivityGrid: React.FC = () => {
  //   const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const LeadID = location?.state?.InquiryStatus?.LeadID;
  const loading = useAppSelector((state) => state.noActivityIL.loading);
  const isEditAccess = checkAcessRights(location.pathname, EDIT_ACCESS);
  const isDeleteAccess = checkAcessRights(location.pathname, DELETE_ACCESS);

  const SelectedLeadInquiryTab = useAppSelector(
    (state) => state.noActivityIL.SelectedLeadInquiryTab
  );
  const NoActivityLeadInquiryList = useAppSelector(
    (state) => state?.noActivityIL?.NoActivityLeadInquiryList
  );
  const gridRef = useRef<any>(null);

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);
  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();

  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All"
        ? NoActivityLeadInquiryList.length
        : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };
  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
    setPage({ skip: 0, take: page.take });
  };

  const InquiryActivityLogsCommandCell = (props: GridCellProps) => {
    const handleActivityLogs = ({
      LeadID,
      NBInquiryTypeID,
      NBLeadTypeID,
      ActivityLogType,
    }: any) => {

      if (SelectedLeadInquiryTab === 0) {
        navigate("/nbinquiry/nbinquiryactivitylogs", {
          state: {
            ActivityLog: {
              LeadID,
              NBInquiryTypeID,
              NBLeadTypeID,
              ActivityLogType,
            },
          },
        });
      }
      if (SelectedLeadInquiryTab === 1) {
        navigate("/clientform/clientactivitylogs", {
          state: {
            ActivityLog: {
              LeadID,
              NBInquiryTypeID,
              NBLeadTypeID,
              ActivityLogType,
            },
          },
        });
      }
    };

    return (
      <td
        className={`action-td ${props.className}`}
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "5px",
          flexWrap: "wrap",
          ...props.style,
        }}
      >
        <Button
          themeColor={"warning"}
          type="button"
          fillMode="outline"
          size="small"
          title="Notes"
          onClick={() =>
            handleActivityLogs({
              LeadID: LeadID ?? props.dataItem.LeadID,
              NBInquiryTypeID: props.dataItem.ID,
              NBLeadTypeID: null,
              ActivityLogType: 0,
            })
          }
        >
          <FaRegStickyNote />
        </Button>
        <Button
          themeColor={"inverse"}
          type="button"
          fillMode="outline"
          size="small"
          title="Tasks"
          onClick={() =>
            handleActivityLogs({
              LeadID: LeadID ?? props.dataItem.LeadID,
              NBInquiryTypeID: props.dataItem.ID,
              NBLeadTypeID: null,
              ActivityLogType: 1,
            })
          }
        >
          <FaTasks />
        </Button>
        <Button
          themeColor={"secondary"}
          type="button"
          fillMode="outline"
          size="small"
          title="Calls"
          onClick={() =>
            handleActivityLogs({
              LeadID: LeadID ?? props.dataItem.LeadID,
              NBInquiryTypeID: props.dataItem.ID,
              NBLeadTypeID: null,
              ActivityLogType: 2,
            })
          }
        >
          <FiPhoneCall />
        </Button>
        <Button
          themeColor={"success"}
          type="button"
          fillMode="outline"
          size="small"
          title="Meetings"
          onClick={() =>
            handleActivityLogs({
              LeadID: LeadID ?? props.dataItem.LeadID,
              NBInquiryTypeID: props.dataItem.ID,
              NBLeadTypeID: null,
              ActivityLogType: 3,
            })
          }
        >
          <FaPeopleGroup />
        </Button>
        <Button
          themeColor={"tertiary"}
          type="button"
          fillMode="outline"
          size="small"
          title="Attachments"
          onClick={() =>
            handleActivityLogs({
              LeadID: LeadID ?? props.dataItem.LeadID,
              NBInquiryTypeID: props.dataItem.ID,
              NBLeadTypeID: null,
              ActivityLogType: 4,
            })
          }
        >
          <TiAttachment />
        </Button>
        <Button
          themeColor={"error"}
          type="button"
          fillMode="outline"
          size="small"
          title="Recording"
          onClick={() =>
            handleActivityLogs({
              LeadID: LeadID ?? props.dataItem.LeadID,
              NBInquiryTypeID: props.dataItem.ID,
              NBLeadTypeID: null,
              ActivityLogType: 5,
            })
          }
        >
          <FaMicrophoneAlt />
        </Button>
      </td>
    );
  };

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleInquiryEdit = (inquiryType: any) => {
      navigate("/noactivity/inquiry/edit", {
        state: { inquiryType: inquiryType },
      });
    };

    const handleLeadEdit = (NBLeadGUID: any, NBLeadBy: any, ClientID: any) => {
      navigate("/noactivity/lead/edit", {
        state: { NBLeadGUID: { NBLeadGUID, NBLeadBy }, ClientID },
      });
    };

    const handleOpenDeleteDialog = (ID: number) => {
      dispatch(openDialog("deleteNBinquiryType"));
      dispatch(setNBInquiryTypeID(ID));
    };

    const handleOnClick = () => {
      if (SelectedLeadInquiryTab === 0) {
        handleInquiryEdit({
          NBInquiryTypeGUID: props.dataItem.NBInquiryTypeGUID,
          LeadID: LeadID ?? props.dataItem.LeadID,
          NBInquiryBy: props.dataItem.NBInquiryBy,
          IsInquiryType: true,
        });
      } else {
        handleLeadEdit(
          props.dataItem.NBLeadsGUID,
          props.dataItem.NBLeadBy,
          props?.dataItem?.LeadID
        );
      }
    };
    return (
      <td className={`action-td ${props.className}`} style={{ ...props.style }}>
        {isEditAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Edit"
            onClick={handleOnClick}
          >
            <FiEdit
              className="absolute-position"
              style={{ fontSize: "20px" }}
            />
          </IconButton>
        )}

        {isDeleteAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Delete"
            onClick={() => handleOpenDeleteDialog(props.dataItem.ID)}
          >
            <MdDelete
              className="absolute-position"
              style={{ fontSize: "24px" }}
            />
          </IconButton>
        )}
      </td>
    );
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      key={props.thProps?.columnId}
      style={props.thProps?.style}
      {...props.thProps}
      className={`table-header ${
        [0, 1, 8].includes(props?.index || 0) ||
        ((isDeleteAccess || isEditAccess) && props.index === 9)
          ? "locked-header"
          : ""
      }`}
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td
        style={{ ...props.style }}
        {...props.tdProps}
        colSpan={1}
        className={`table-cell ${props.className}`}
      >
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      {NoActivityLeadInquiryList && NoActivityLeadInquiryList?.length ? (
        <KendoGrid
          style={{ maxHeight: "calc(100vh - 152px)" }}
          filterable={true}
          filter={filter}
          data={
            filter
              ? filterBy(NoActivityLeadInquiryList, filter).slice(
                  page.skip,
                  page.take + page.skip
                )
              : NoActivityLeadInquiryList.slice(
                  page.skip,
                  page.take + page.skip
                )
          }
          onFilterChange={handleFilterChange}
          skip={page.skip}
          take={page.take}
          total={NoActivityLeadInquiryList.length}
          pageable={{
            buttonCount: 5,
            pageSizes: [5, 10, 15, "All"],
            pageSizeValue: pageSizeValue,
            type: "input",
          }}
          onPageChange={pageChange}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
        >
          <Column field="InquiryNo" locked title="Inquiry No" width={110} />
          <Column field="InquiryDate" locked title="Inquiry Date" width={120} />
          <Column field="InquiryPerson" title="Inquiry By Person" width={250} />
          <Column field="LeadType" title="Lead Type" width={150} />
          <Column
            field="InquiryType"
            title="Inquiry Type"
            width={(isDeleteAccess || isEditAccess) ? 150 : 300}
          />
          <Column field="InquirySubType" title="Inquiry Sub Type" width={150} />
          <Column field="AllotmentName" title="Alloted To Person" width={250} />
          <Column field="LeadStatus" title="Lead Status" width={150} />
          {/* <Column field="Frequency" title="Frequency" width={150} /> */}
          <Column
            field="ID"
            title="Activity Logs"
            cell={InquiryActivityLogsCommandCell}
            width={150}
            filterable={false}
            locked
          />
          {(isDeleteAccess || isEditAccess) && (
            <Column
              locked
              field="ID"
              title="Actions"
              cell={MyEditCommandCell}
              width={110}
              filterable={false}
            />
          )}
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

export default NoActivityGrid;
