import React, { useEffect, useRef } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import AppDialog, {
  DialogCloseButton,
} from "../../../components/dialog/Dialog";
import { Typography } from "@progress/kendo-react-common";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import {
  closeDialog,
  openDialog,
} from "../../../components/dialog/dialogSlice";
import {
  CompositeFilterDescriptor,
  filterBy,
} from "@progress/kendo-data-query";
import {
  GridCellProps,
  GridCustomCellProps,
  GridCustomHeaderCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  Grid as KendoGrid,
  GridColumn as Column,
} from "@progress/kendo-react-grid";
import {
  HeaderThElement,
  PagerTargetEvent,
} from "@progress/kendo-react-data-tools";
import { LoadingPanel } from "../../../components/layout/Loading";
import IconButton from "../../../components/common/IconButton";
import { MdDelete } from "react-icons/md";
import AlertBox from "../../../components/common/AlertBox";
import { FaEdit } from "react-icons/fa";
import {
  deleteAttachment,
  getAllAttachments,
} from "./services/attachment.services";
import {
  clearAttachmentDetail,
  clearAttachmentID,
  setAttachmentID,
} from "./attachmentSlice";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CreateAttachmentDialog from "./CreateAttachmentDialog";

const AttachmentDialog = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const gridRef = useRef<any>(null);
  const loading = useAppSelector((state) => state.attachment.loading);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const NBLeadTypeID = location.state?.ActivityLog?.NBLeadTypeID;
  const LeadID = location?.state?.ActivityLog?.LeadID;
  const NBInquiryTypeID = location?.state?.ActivityLog?.NBInquiryTypeID;

  useEffect(() => {
    const payload = {
      LeadID: LeadID ?? null,
      NBLeadTypeID: NBLeadTypeID ?? null,
      NBInquiryTypeID: NBInquiryTypeID ?? null,
      AttachmentType: "",
    };
    dispatch(getAllAttachments(payload));
  }, []);

  const CloseAttachmentDialog: React.FC = () => {
    const CloseAttachment = () => {
      navigate(-1);
    };
    return (
      <div style={{ display: "flex", justifyContent: "end" }}>
        <Button
          style={{ width: "60px", padding: 4, marginTop: 10 }}
          themeColor={"primary"}
          fillMode={"outline"}
          onClick={CloseAttachment}
        >
          Back
        </Button>
      </div>
    );
  };
  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      {dialogName === "CreateAttachment" && <CreateAttachmentDialog />}
      <DeleteAttachmentDialog />
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            Attachments List
          </Typography.h4>

          <Button
            style={{ width: "auto", marginLeft: 5, padding: 6 }}
            themeColor={"primary"}
            type="button"
            fillMode="solid"
            size="small"
            title="Create Attachment"
            onClick={() => {
              dispatch(openDialog("CreateAttachment"));
              dispatch(clearAttachmentDetail());
              dispatch(clearAttachmentID());
            }}
          >
            Create Attachment
          </Button>
        </GridLayoutItem>
        <GridLayoutItem>
          <AttachmentGridComponent />
        </GridLayoutItem>
        <CloseAttachmentDialog />
      </GridLayout>
    </>
  );
};

interface PageState {
  skip: number;
  take: number;
}
const initialDataState: PageState = { skip: 0, take: 10 };
const AttachmentGridComponent: React.FC = () => {
  const dispatch = useAppDispatch();

  const AttachmentList = useAppSelector(
    (state) => state.attachment.AttachmentList
  );
  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);
  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();

  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All" ? AttachmentList.length : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const MyEditCommandCell = (props: GridCellProps) => {
    const ID = props.dataItem.ID;
    const handleOpenDeleteDialog = () => {
      dispatch(openDialog("deleteAttachment"));
      dispatch(setAttachmentID(ID));
    };
    const handleOpenEditDialog = () => {
      dispatch(setAttachmentID(ID));
      // dispatch(getAttachmentById(ID));
      dispatch(openDialog("CreateAttachment"));
    };
    return (
      <td
        className="action-td"
        style={{
          ...props.style,
        }}
      >
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Edit"
          onClick={handleOpenEditDialog}
        >
          <FaEdit style={{ fontSize: "20px" }} />
        </IconButton>
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Delete"
          // themeColor="error"
          onClick={handleOpenDeleteDialog}
        >
          <MdDelete style={{ fontSize: "24px" }} />
        </IconButton>
      </td>
    );
  };

  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
    setPage({ skip: 0, take: page.take });
  };
  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className="table-header"
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );
  return (
    <>
      {AttachmentList && AttachmentList.length ? (
        <KendoGrid
          style={{ maxHeight: "calc(100vh - 152px)" }}
          filterable={true}
          filter={filter}
          data={
            filter
              ? filterBy(AttachmentList, filter).slice(
                  page.skip,
                  page.take + page.skip
                )
              : AttachmentList.slice(page.skip, page.take + page.skip)
          }
          onFilterChange={handleFilterChange}
          skip={page.skip}
          take={page.take}
          total={AttachmentList.length}
          pageable={{
            buttonCount: 5,
            pageSizes: [5, 10, 15, "All"],
            pageSizeValue: pageSizeValue,
            type: "input",
          }}
          onPageChange={pageChange}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
        >
          <Column field="AttachmentName" title="Attachment Name" />
          <Column field="AttachmentType" title="Attachment Type" />
          <Column
            field="AttachmentURL"
            title="Attachment"
            width={120}
            filterable={false}
            cell={(props: GridCellProps) => {
              return (
                <td>
                  <Link to={props.dataItem.AttachmentURL} target="_blank">
                    <Button
                      type="button"
                      fillMode="solid"
                      size="small"
                      title="view"
                      themeColor="info"
                    >
                      View
                    </Button>
                  </Link>
                </td>
              );
            }}
          />

          <Column
            field="ID"
            title="Actions"
            cell={MyEditCommandCell}
            width={"110px"}
            filterable={false}
          />
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

const DeleteAttachmentDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const AttachmentID = useAppSelector(
    (state) => state.attachment?.AttachmentID
  );
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const LeadID = location?.state?.ActivityLog?.LeadID;
  const NBInquiryTypeID = location?.state?.ActivityLog?.NBInquiryTypeID;
  const NBLeadTypeID = location.state?.ActivityLog?.NBLeadTypeID;
  const payload = {
    LeadID: LeadID ?? null,
    NBInquiryTypeID: NBInquiryTypeID ?? null,
    NBLeadTypeID: NBLeadTypeID ?? null,
  };
  const handleDeleteAction = async (ID: number | null) => {
    if (ID) {
      const response = await dispatch(deleteAttachment(ID));
      if (response.payload?.Status === 200) {
        dispatch(getAllAttachments(payload));
        dispatch(closeDialog());
        dispatch(clearAttachmentID());
      } else {
        dispatch(getAllAttachments(payload));
        dispatch(closeDialog());
        dispatch(clearAttachmentID());
      }
    }
  };

  return (
    <>
      {dialogName === "deleteAttachment" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Attachment?"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this Attachment?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(AttachmentID)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

export default AttachmentDialog;
