import React, { useEffect, useRef } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import AppDialog, {
  DialogCloseButton,
} from "../../../components/dialog/Dialog";
import { Typography } from "@progress/kendo-react-common";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import {
  closeDialog,
  openDialog,
} from "../../../components/dialog/dialogSlice";
import CreateNotesDialog from "./CreateNotesDialog";
import {
  CompositeFilterDescriptor,
  filterBy,
} from "@progress/kendo-data-query";
import {
  GridCellProps,
  GridCustomCellProps,
  GridCustomHeaderCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  Grid as KendoGrid,
  GridColumn as Column,
} from "@progress/kendo-react-grid";
import {
  HeaderThElement,
  PagerTargetEvent,
} from "@progress/kendo-react-data-tools";
import { LoadingPanel } from "../../../components/layout/Loading";
import { clearNoteGUID, clearNotesDetail, setNoteGUID } from "./notesSlice";
import IconButton from "../../../components/common/IconButton";
import { MdDelete } from "react-icons/md";
import AlertBox from "../../../components/common/AlertBox";
import { FaEdit } from "react-icons/fa";
import { deleteNotes, getAllNotes } from "./services/services.notes";
import { useLocation, useNavigate } from "react-router-dom";

const NotesDialog = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const LeadID = location?.state?.ActivityLog?.LeadID;
  const NBInquiryTypeID = location?.state?.ActivityLog?.NBInquiryTypeID;
  const NBLeadTypeID = location?.state?.ActivityLog?.NBLeadTypeID;
  useEffect(() => {
    const payload = {
      LeadID: LeadID ?? null,
      NBInquiryTypeID: NBInquiryTypeID ?? null,
      NBLeadTypeID: NBLeadTypeID ?? null,
    };
    dispatch(getAllNotes(payload));
  }, []);

  const CloseLogDialog: React.FC = () => {
    const closeNote = () => {
      navigate(-1);
    };
    return (
      <div style={{ display: "flex", justifyContent: "end" }}>
        <Button
          style={{ width: "60px", padding: 4, marginTop: 10 }}
          themeColor={"primary"}
          fillMode={"outline"}
          onClick={closeNote}
        >
          Back
        </Button>
      </div>
    );
  };
  return (
    <>
      {dialogName === "CreateNotes" && <CreateNotesDialog />}
      <DeleteNoteDialog />
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            Notes List
          </Typography.h4>
          <Button
            style={{ width: "auto", marginLeft: 5, padding: 6 }}
            themeColor={"primary"}
            type="button"
            fillMode="solid"
            size="small"
            title="Create Note"
            onClick={() => {
              dispatch(openDialog("CreateNotes"));
              dispatch(clearNotesDetail());
              dispatch(clearNoteGUID());
            }}
          >
            Create Notes
          </Button>
        </GridLayoutItem>
        <GridLayoutItem>
          <NotesGridComponent />
        </GridLayoutItem>
        <CloseLogDialog />
      </GridLayout>
    </>
  );
};

interface PageState {
  skip: number;
  take: number;
}
const initialDataState: PageState = { skip: 0, take: 10 };
const NotesGridComponent: React.FC = () => {
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const NotesList = useAppSelector((state) => state.notes.NotesList);
  const loading = useAppSelector((state) => state.notes.loading);

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);
  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();

  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All" ? NotesList.length : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;

  const MyEditCommandCell = (props: GridCellProps) => {
    const ID = props.dataItem.NoteGUID;
    const handleOpenDeleteDialog = () => {
      dispatch(openDialog("deleteNote"));
      dispatch(setNoteGUID(ID));
    };
    const handleOpenEditDialog = () => {
      dispatch(setNoteGUID(ID));
      // dispatch(getCallById(ID));
      dispatch(openDialog("CreateNotes"));
    };
    return (
      <td
        className="action-td"
        style={{
          ...props.style,
        }}
      >
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Edit"
          onClick={handleOpenEditDialog}
        >
          <FaEdit className="absolute-position" style={{ fontSize: "20px" }} />
        </IconButton>
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Delete"
          onClick={handleOpenDeleteDialog}
        >
          <MdDelete
            className="absolute-position"
            style={{ fontSize: "24px" }}
          />
        </IconButton>
      </td>
    );
  };

  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
    setPage({ skip: 0, take: page.take });
  };
  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className="table-header"
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );
  return (
    <>
      {NotesList && NotesList.length ? (
        <KendoGrid
          style={{ maxHeight: "calc(100vh - 152px)" }}
          filterable={true}
          filter={filter}
          data={
            filter
              ? filterBy(NotesList, filter).slice(
                  page.skip,
                  page.take + page.skip
                )
              : NotesList.slice(page.skip, page.take + page.skip)
          }
          onFilterChange={handleFilterChange}
          skip={page.skip}
          take={page.take}
          total={NotesList.length}
          pageable={{
            buttonCount: 5,
            pageSizes: [5, 10, 15, "All"],
            pageSizeValue: pageSizeValue,
            type: "input",
          }}
          onPageChange={pageChange}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
        >
          <Column field="Title" title="Title" />
          <Column field="Description" title="Description" />
          <Column
            field="NoteGUID"
            title="Actions"
            cell={MyEditCommandCell}
            width={"110px"}
            filterable={false}
          />
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

const DeleteNoteDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const NoteGUID = useAppSelector((state) => state.notes?.NoteGUID);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const LeadID = location?.state?.ActivityLog?.LeadID;
  const NBInquiryTypeID = location?.state?.ActivityLog?.NBInquiryTypeID;
  const NBLeadTypeID = location?.state?.ActivityLog?.NBLeadTypeID;

  const payload = {
    LeadID: LeadID ?? null,
    NBInquiryTypeID: NBInquiryTypeID ?? null,
    NBLeadTypeID: NBLeadTypeID ?? null,
  };
  const handleDeleteAction = async (ID: string | null) => {
    if (ID) {
      const response = await dispatch(deleteNotes(ID));
      if (response.payload?.Data.Status === 200) {
        dispatch(getAllNotes(payload));
        dispatch(closeDialog());
        dispatch(clearNoteGUID());
      } else {
        dispatch(getAllNotes(payload));
        dispatch(closeDialog());
        dispatch(clearNoteGUID());
      }
    }
  };

  return (
    <>
      {dialogName === "deleteNote" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Notes?"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this Notes?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => {
                    handleDeleteAction(NoteGUID);
                  }}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

export default NotesDialog;
