import React, { useRef } from "react";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { Typography } from "@progress/kendo-react-common";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { closeDialog } from "../../../components/dialog/dialogSlice";
import { RootState } from "../../../app/store";
import AppDialog from "../../../components/dialog/Dialog";
import FormFileUpload from "../../../components/formFields/FormFileUpload";
import { useLocation } from "react-router-dom";
import {
  createAttachment,
  getAllAttachments,
  updateAttachment,
} from "./services/attachment.services";

import { LoadingPanel } from "../../../components/layout/Loading";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
const CloseLogDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const closeLogDialog = () => {
    dispatch(closeDialog());
  };
  return (
    <Button
      themeColor={"primary"}
      fillMode={"outline"}
      onClick={closeLogDialog}
    >
      Close
    </Button>
  );
};

const CreateAttachmentDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const AttachmentDetail = useAppSelector(
    (state) => state.attachment.AttachmentDetail
  );
  const AttachmentID = useAppSelector((state) => state.attachment.AttachmentID);
  const formLoading = useAppSelector((state) => state.attachment.formLoading);
  const LeadID = location?.state?.ActivityLog?.LeadID;
  const NBInquiryTypeID = location?.state?.ActivityLog?.NBInquiryTypeID;
  const NBLeadTypeID = location.state?.ActivityLog?.NBLeadTypeID;
  const gridRef = useRef<any>(null);
  const dialogName = useAppSelector(
    (state: RootState) => state.dialog.dialogName
  );

  const handleSubmit = async (values: any) => {
    const payload = {
      LeadID: LeadID ?? null,
      NBLeadTypeID: NBLeadTypeID ?? null,
      NBInquiryTypeID: NBInquiryTypeID ?? null,
      AttachmentType: "",
    };
    try {
      if (AttachmentID) {
        if (values.attachments) {
          const files = new FormData();
          files.append("ID", `${AttachmentID}`);
          const binaryDoc = values?.attachments[0].getRawFile();
          files.append("AttachmentName", binaryDoc.name);
          files.append("AttachmentURL", binaryDoc);
          files.append("LeadID", LeadID || "");
          files.append("NBInquiryTypeID", NBInquiryTypeID ?? "");
          files.append("NBLeadTypeID", NBLeadTypeID ?? "");
          const response = await dispatch(updateAttachment(files));
          response.payload.Status === 200
            ? SuccessToast(response.payload.Detail || "Success!")
            : ErrorToast(response.payload.Detail || "Error Update Attachment!");
          dispatch(closeDialog());
          dispatch(getAllAttachments(payload));
        }
      } else {
        if (values.attachments) {
          const files = new FormData();
          files.append("AttachmentType", "Attachments");
          let AttachmentName: string[] = [];
          values?.attachments?.forEach((doc: any) => {
            const binaryDoc = doc.getRawFile();
            AttachmentName.push(binaryDoc.name);
            files.append("AttachmentURL", binaryDoc);
          });
          files.append("AttachmentName", AttachmentName.join(","));
          files.append("LeadID", LeadID || "");
          files.append("ReferenceGUID", "");
          files.append("NBInquiryTypeID", NBInquiryTypeID ?? "");
          files.append("NBLeadTypeID", NBLeadTypeID ?? "");
          const response = await dispatch(createAttachment(files));
          response.payload.Status === 200
            ? SuccessToast(response.payload.Detail || "Success!")
            : ErrorToast(response.payload.Detail || "Error Inset Attachment!");
          dispatch(closeDialog());
          dispatch(getAllAttachments(payload));
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  if (formLoading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      {dialogName === "CreateAttachment" && (
        <AppDialog className="dialog-width">
          <Form
            onSubmit={handleSubmit}
            initialValues={AttachmentDetail}
            render={(formRenderProps: FormRenderProps) => (
              <FormElement>
                <Typography.h5 style={{ margin: 0, textAlign: "center" }}>
                  {AttachmentID ? "Update Attachment" : "Create Attachment"}
                </Typography.h5>
                <GridLayout
                  gap={{ rows: 0, cols: 5 }}
                  cols={[
                    { width: "33.33%" },
                    { width: "33.33%" },
                    { width: "33.33%" },
                  ]}
                  style={{ marginRight: 10 }}
                >
                  {AttachmentID && (
                    <GridLayoutItem colSpan={2}>
                      <Field
                        name="attachments"
                        type="file"
                        filetype="file"
                        label="Attachments"
                        component={FormFileUpload}
                      />
                    </GridLayoutItem>
                  )}
                  {!AttachmentID && (
                    <GridLayoutItem colSpan={2}>
                      <Field
                        name="attachments"
                        type="file"
                        filetype="file"
                        multiple
                        label="Attachments"
                        component={FormFileUpload}
                      />
                    </GridLayoutItem>
                  )}
                </GridLayout>
                <GridLayout
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "10px",
                  }}
                >
                  <Button
                    type="submit"
                    themeColor={"primary"}
                    fillMode={"solid"}
                    style={{ marginRight: "10px" }}
                    disabled={!formRenderProps.allowSubmit}
                  >
                      {AttachmentID ? "Update" : "Save"}
                  </Button>
                  <CloseLogDialog />
                </GridLayout>
              </FormElement>
            )}
          />
        </AppDialog>
      )}
    </>
  );
};

export default CreateAttachmentDialog;
