import React, { useRef } from "react";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import {
  GridLayout,
  GridLayoutItem,
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import { LoadingPanel } from "../../components/layout/Loading";

import NotesDialog from "../Activity Logs/notes/NotesDialog";
import AttachmentDialog from "../Activity Logs/attechment/AttachmentDialog";
import RecodingDialog from "../Activity Logs/recodings/RecodingsDialog";
import CallActivityTab from "../Activity Logs/calls/CallsTabDialogs";
import MeetingsActivityTab from "../Activity Logs/meetings/MeetingsTabDialog";
import TaskActivityTab from "../Activity Logs/tasks/TaskTabDialog";
import TabStripTitle from "../../components/common/TabStripTitle";
import {
  AiOutlineAudio,
  AiOutlineFile,
  AiOutlineFileDone,
  AiOutlinePaperClip,
  AiOutlinePhone,
  AiOutlineTeam,
} from "react-icons/ai";

const CreateNBinquiryActivityLogs: React.FC = () => {
  const gridRef = useRef<any>(null);
  const location = useLocation();
  // const params = new URLSearchParams(location.search);
  const [selected, setSelected] = React.useState<any>(
    location?.state?.ActivityLog?.ActivityLogType
  );

  const loading = useAppSelector((state) => state.nbinquiry.loading);
  const handleSelect = (e: TabStripSelectEventArguments) => {
    setSelected(e.selected);
  };
  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      <GridLayoutItem
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "4px 20px 20px 0",
        }}
      >
        <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
          {location.pathname.startsWith("/clientform")
            ? "Client  Activity Log"
            : "Inquiry  Activity Log"}
        </Typography.h4>
      </GridLayoutItem>
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem>
          <TabStrip
            className="nb-inquiry-types-tab"
            selected={selected}
            onSelect={handleSelect}
          >
            <TabStripTab
              title={<TabStripTitle title="Notes" icon={AiOutlineFile} />}
            >
              <NotesDialog />
            </TabStripTab>
            <TabStripTab
              title={<TabStripTitle title="Tasks" icon={AiOutlineFileDone} />}
            >
              <TaskActivityTab />
            </TabStripTab>
            <TabStripTab
              title={<TabStripTitle title="Calls" icon={AiOutlinePhone} />}
            >
              <CallActivityTab />
            </TabStripTab>
            <TabStripTab
              title={<TabStripTitle title="Meetings" icon={AiOutlineTeam} />}
            >
              <MeetingsActivityTab />
            </TabStripTab>
            <TabStripTab
              title={
                <TabStripTitle title="Attachments" icon={AiOutlinePaperClip} />
              }
            >
              <AttachmentDialog />
            </TabStripTab>
            <TabStripTab
              title={<TabStripTitle title="Recordings" icon={AiOutlineAudio} />}
            >
              <RecodingDialog />
            </TabStripTab>
          </TabStrip>
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

export default CreateNBinquiryActivityLogs;
