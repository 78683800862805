import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";

import {
  DELETE_TYPE,
  GETALL_TYPE,
  GETBYGUID_TYPE,
} from "../../../_contstants/common";
import { ILeadSorce } from "../leadsourceModel";

export const getAllLeadSource = createAsyncThunk(
  "getAllLeadSource/ManageLeadSource",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Master/ManageLeadSource`,
        {
          LeadSource: "",
          OperationType: GETALL_TYPE,
        }
      );
      if (response.data?.Status === 200) {
        return response.data?.Data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      console.error("Error Fetching LeadSource:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createLeadSource = createAsyncThunk(
  "createLeadSource/ManageLeadSource",
  async (InquiryTypes: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Master/ManageLeadSource`,
        InquiryTypes
      );
      if (response.data.Status === 201) {
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching LeadSource:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateLeadSource = createAsyncThunk(
  "updateLeadSource/ManageLeadSource",
  async (LeadSource: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Master/ManageLeadSource`,
        LeadSource
      );
      if (response.data?.Status === 200) {
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching LeadSource:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getLeadSourceById = createAsyncThunk(
  "getLeadSourceById/ManageLeadSource",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Master/ManageLeadSource`,
        {
          LeadSourceGUID: ID,
          OperationType: GETBYGUID_TYPE,
        }
      );
      const result = response.data?.Data[0] as any;
      return {
        ID: result?.ID,
        LeadSource: result.LeadSource,
        LeadSourceGUID: result.LeadSourceGUID,
        IsActive: result?.IsActive === true ? 1 : 2,
      } as ILeadSorce;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching LeadSource:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteLeadSource = createAsyncThunk(
  "deleteLeadSource/ManageLeadSource",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Master/ManageLeadSource`,
        {
          LeadSourceGUID: ID,
          OperationType: DELETE_TYPE,
        }
      );
      if (response.data?.Status === 200) {
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Deleting LeadSource:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const findAllActiveLeadSource = createAsyncThunk(
  "findAllActiveLeadSource/ManageLeadSource",
  async (LeadSources: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Master/ManageLeadSource`,
        LeadSources
      );
      if (response.data?.Status === 200) {
        // SuccessToast(response.data?.Details || "Success");
        return response.data.Data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      console.error("Error Fetching LeadSource:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const LeadSourceActiveInactive = createAsyncThunk(
  "LeadSourceActiveInactive/ManageLeadSource",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Master/ManageLeadSource`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive LeadSource:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
