import React, { useEffect } from "react";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import DashBoardCard from "./DashBoardCard";
import ShadowCard from "../../components/common/ShadowCard";
import { Link, useNavigate } from "react-router-dom";
import { Typography } from "@progress/kendo-react-common";
import LeadStatusCard from "./LeadStatusCard";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getDashboard } from "./services/dashboard.services";
import { LoadingPanel } from "../../components/layout/Loading";
import { BiLockOpenAlt } from "react-icons/bi";
import {
  FaLockOpen,
  FaLock,
  FaTrophy,
  FaTimesCircle,
  FaCheckCircle,
  FaChartLine,
} from "react-icons/fa";
import { IoReload } from "react-icons/io5";
import { FaRegUser } from "react-icons/fa";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { DateRangeFilter } from "../../_contstants/common";
import moment from "moment";
import { setDateFilter } from "./dashboardSlice";
import FormDatePicker from "../../components/formFields/FormDateField";
import { getFilterFromToDate } from "../../_helper/helper";
import DashboardActivityCard from "./DashboardActivityCard";
import { AiOutlineFile, AiOutlinePhone, AiOutlineTeam } from "react-icons/ai";
interface DateFilterChangeWatcherProps {
  formRenderProps: FormRenderProps;
}
const DateFilterChangeWatcher: React.FC<DateFilterChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const SelectedDate = formRenderProps.valueGetter("SelectedDate");
  const FromDate = formRenderProps.valueGetter("FromDate");
  const ToDate = formRenderProps.valueGetter("ToDate");
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (SelectedDate !== 6) {
      formRenderProps.onChange("FromDate", { value: null });
      formRenderProps.onChange("ToDate", { value: null });
      const filter = getFilterFromToDate(SelectedDate);
      dispatch(getDashboard(filter));
      dispatch(setDateFilter({ SelectedDate, ...filter }));
    }
  }, [SelectedDate]);

  useEffect(() => {
    const filter = {
      FromDate: FromDate ? moment(FromDate).format("YYYY-MM-DD") : "",
      ToDate: ToDate ? moment(ToDate).format("YYYY-MM-DD") : "",
    };
    if (FromDate && ToDate) {
      dispatch(getDashboard(filter));
      dispatch(setDateFilter({ SelectedDate, FromDate, ToDate }));
    }
  }, [FromDate, ToDate]);
  return null;
};
const Dashboard: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loading = useAppSelector((state) => state?.dashboard?.loading);
  const gridRef = React.useRef<any>(null);
  const dateFilter = useAppSelector((state) => state?.dashboard?.dateFilter);
  const DashboardDetails = useAppSelector(
    (state) => state?.dashboard?.DashboardDetails
  );
  useEffect(() => {
    if (!dateFilter?.SelectedDate) {
      const filter = {
        FromDate: "",
        ToDate: "",
      };
      dispatch(getDashboard(filter));
    }
  }, []);

  const AmountDifferentiation = (value: number) => {
    const val = Math.abs(value || 0);
    const formatValue = (num: number) => {
      return num % 1 === 0 ? num?.toFixed(0) : num?.toFixed(2);
    };
    if (val >= 10000000) return `${formatValue(value / 10000000)} Cr`;
    if (val >= 100000) return `${formatValue(value / 100000)} Lac`;
    return formatValue(value);
  };

  // if (loading) return <LoadingPanel gridRef={gridRef} />;

  const handleInquiryWisedata = (InquiryTypeID: number) => {
    navigate("/dashboard/inquirytypewiselist", {
      state: { InquiryTypeID, OperationType: 4 },
    });
  };
  const handleEmployeeWiseLeads = (UserID: number) => {
    navigate("/dashboard/employeewiselist", {
      state: { UserID, OperationType: 6 },
    });
  };
  const handleEmployeeWiseProspectLeads = (UserID: number) => {
    navigate("/dashboard/employeewiseprospectleadlist", {
      state: { UserID },
    });
  };

  // const handleEmployeeWiseInquiryAndLead = (UserID: number) => {
  //   navigate("/dashboard/employeewiseinquiryandlead", {
  //     state: { UserID },
  //   });
  // };

  const handleInquiryClosingAmount = (UserID: number) => {
    navigate("/dashboard/inquiryclosingamount", {
      state: { UserID },
    });
  };
  const handleLeadClosingAmount = (UserID: number) => {
    navigate("/dashboard/leadclosingamount", {
      state: { UserID },
    });
  };
  return (
    <>
      {/* <Typography.h4 style={{ margin: "50px", textAlign: "center" }}>
        Welcome to the Insurance Vala
      </Typography.h4> */}
      {loading && <LoadingPanel gridRef={gridRef} />}
      <GridLayout
        style={{ marginRight: 60, marginBottom: 50 }}
        gap={{ rows: 20, cols: 20 }}
        cols={[{ width: "70%" }, { width: "30%" }]}
      >
        {/* LEFT SIDE */}
        <GridLayoutItem style={{ width: "100%" }}>
          <ShadowCard style={{ padding: 12 }}>
            <GridLayout
              style={{ marginRight: 40 }}
              gap={{ rows: 20, cols: 20 }}
              cols={[
                { width: "33.33%" },
                { width: "33.33%" },
                { width: "33.33%" },
              ]}
            >
              <GridLayoutItem>
                <Typography.h6 style={{ margin: 0 }}>
                  My Dashboard
                </Typography.h6>
              </GridLayoutItem>
              <GridLayoutItem>
                <Typography.h6 style={{ margin: 0, textAlign: "start" }}>
                  Prospect Client Count :{" "}
                  <span style={{ fontWeight: "bolder", color: "forestgreen" }}>
                    {DashboardDetails?.ProspectCount}
                  </span>
                </Typography.h6>
              </GridLayoutItem>
              <GridLayoutItem>
                <Typography.h6 style={{ margin: 0, textAlign: "start" }}>
                  Group Code :{" "}
                  <span style={{ fontWeight: "bolder", color: "forestgreen" }}>
                    {DashboardDetails?.GroupCode}
                  </span>
                </Typography.h6>
              </GridLayoutItem>
              <GridLayoutItem>
                <Link
                  to={"/dashboard/totalleadlist"}
                  state={{ OperationType: 2 }}
                  style={{ textDecoration: "none" }}
                >
                  <DashBoardCard
                    icon={FaChartLine}
                    amount={DashboardDetails?.TotalInquiry || 0}
                    cardColor="#00CFE8"
                    title="Total Lead"
                  />
                </Link>
              </GridLayoutItem>
              <GridLayoutItem>
                <Link
                  to={"/dashboard/totalopenleadlist"}
                  state={{
                    OperationType: 5,
                    LeadStatusID: 2,
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <DashBoardCard
                    icon={BiLockOpenAlt}
                    amount={DashboardDetails?.OpenNBInquiry || 0}
                    cardColor="#EA5455"
                    title="Open Lead"
                  />
                </Link>
              </GridLayoutItem>
              <GridLayoutItem>
                <Link
                  to={"/dashboard/ownleadlist"}
                  state={{ OperationType: 3 }}
                  style={{ textDecoration: "none" }}
                >
                  <DashBoardCard
                    icon={FaRegUser}
                    amount={DashboardDetails?.OwnInquiry || 0}
                    cardColor="#28C76F"
                    title="My Lead"
                  />
                </Link>
              </GridLayoutItem>
            </GridLayout>
          </ShadowCard>
          {/* ACTIVITY  */}
          <ShadowCard style={{ padding: 12, marginTop: 20 }}>
            <GridLayout
              style={{ marginRight: 40 }}
              gap={{ rows: 10, cols: 20 }}
              cols={[
                { width: "33.33%" },
                { width: "33.33%" },
                { width: "33.33%" },
              ]}
            >
              <GridLayoutItem colSpan={3}>
                <Typography.h6 style={{ margin: 0 }}>
                  Follow-up And Remider
                </Typography.h6>
              </GridLayoutItem>
              <GridLayoutItem>
                <Link
                  to={"/dashboard/upcommingtasks"}
                  style={{ textDecoration: "none" }}
                >
                  <DashboardActivityCard
                    icon={AiOutlineFile}
                    amount={DashboardDetails?.UpComingTasks || 0}
                    cardColor="#222e50"
                    title="Tasks"
                  />
                </Link>
              </GridLayoutItem>
              <GridLayoutItem>
                <Link
                  to={"/dashboard/upcommingcalls"}
                  style={{ textDecoration: "none" }}
                >
                  <DashboardActivityCard
                    icon={AiOutlinePhone}
                    amount={DashboardDetails?.UpComingCalls || 0}
                    cardColor="#007991"
                    title="Calls"
                  />
                </Link>
              </GridLayoutItem>
              <GridLayoutItem>
                <Link
                  to={"/dashboard/upcommingmeetings"}
                  style={{ textDecoration: "none" }}
                >
                  <DashboardActivityCard
                    icon={AiOutlineTeam}
                    amount={DashboardDetails?.UpComingMeetings || 0}
                    cardColor="#439a86"
                    title="Meetings"
                  />
                </Link>
              </GridLayoutItem>
            </GridLayout>
          </ShadowCard>
          {/* LEAD STATUS */}
          <ShadowCard style={{ padding: 12, marginTop: 20 }}>
            <Form
              onSubmit={() => {}}
              initialValues={dateFilter}
              render={(formRenderProps: FormRenderProps) => (
                <FormElement style={{ width: "100%" }}>
                  <GridLayout
                    style={{ marginRight: 40 }}
                    gap={{ rows: 20, cols: 20 }}
                    cols={[
                      { width: "33.33%" },
                      { width: "33.33%" },
                      { width: "33.33%" },
                    ]}
                  >
                    <GridLayoutItem>
                      <Typography.h6 style={{ margin: 0 }}>
                        Lead Status
                      </Typography.h6>
                    </GridLayoutItem>
                    {/* <GridLayoutItem className="mydatepickerdash"></GridLayoutItem> */}
                    <GridLayoutItem
                      className="d-flex justify-content-end align-items-center "
                      colSpan={2}
                    >
                      {formRenderProps.valueGetter("SelectedDate") === 6 && (
                        <>
                          <Field
                            wrapperStyle={{
                              margin: "0 0 0 10px",
                              width: 150,
                              flex: "none",
                            }}
                            name="FromDate"
                            component={FormDatePicker}
                            size={"small"}
                            placeholder="From Date"
                          />
                          <Field
                            wrapperStyle={{
                              margin: "0 0 0 10px",
                              width: 150,
                              flex: "none",
                            }}
                            name="ToDate"
                            component={FormDatePicker}
                            size={"small"}
                            minDate={formRenderProps.valueGetter("FromDate")}
                            placeholder="To Date"
                          />
                        </>
                      )}
                      <Field
                        wrapperStyle={{
                          margin: "0 0 0 10px",
                          width: 150,
                          flex: "none",
                        }}
                        name="SelectedDate"
                        component={FormSelectionField}
                        size={"small"}
                        placeholder="Filter"
                        options={DateRangeFilter?.map((date: any) => {
                          return {
                            value: date.value,
                            label: date.label,
                          };
                        })}
                      />
                    </GridLayoutItem>
                    <DateFilterChangeWatcher
                      formRenderProps={formRenderProps}
                      // setDateRange={setDateRange}
                    />
                    <GridLayoutItem>
                      <Link
                        style={{ textDecoration: "none" }}
                        to={"/dashboard/leadstatuswiselist"}
                        state={{
                          OperationType: 5,
                          LeadStatusID:
                            DashboardDetails?.LeadStatusWiseCount[0]
                              ?.LeadStatusID,
                        }}
                      >
                        <LeadStatusCard
                          icon={FaCheckCircle}
                          amount={
                            DashboardDetails?.LeadStatusWiseCount[0]
                              ?.LeadStatusCount
                          }
                          cardColor="#7FE7F3"
                          title="Alloted"
                        />
                      </Link>
                    </GridLayoutItem>
                    <GridLayoutItem>
                      <Link
                        to={"/dashboard/leadstatuswiselist"}
                        state={{
                          OperationType: 5,
                          LeadStatusID:
                            DashboardDetails?.LeadStatusWiseCount[1]
                              ?.LeadStatusID,
                          ...dateFilter?.FromDate,
                          ...dateFilter?.ToDate,
                        }}
                        style={{ textDecoration: "none" }}
                      >
                        <LeadStatusCard
                          icon={FaLockOpen}
                          amount={
                            DashboardDetails?.LeadStatusWiseCount[1]
                              ?.LeadStatusCount
                          }
                          cardColor="#F4A9AA"
                          title="Open"
                        />
                      </Link>
                    </GridLayoutItem>
                    <GridLayoutItem>
                      <Link
                        to={"/dashboard/leadstatuswiselist"}
                        state={{
                          OperationType: 5,
                          LeadStatusID:
                            DashboardDetails?.LeadStatusWiseCount[2]
                              ?.LeadStatusID,
                          ...dateFilter?.FromDate,
                          ...dateFilter?.ToDate,
                        }}
                        style={{ textDecoration: "none" }}
                      >
                        <LeadStatusCard
                          icon={IoReload}
                          amount={
                            DashboardDetails?.LeadStatusWiseCount[2]
                              ?.LeadStatusCount
                          }
                          cardColor="#B9B3F7"
                          title="Process"
                        />
                      </Link>
                    </GridLayoutItem>
                    <GridLayoutItem>
                      <Link
                        to={"/dashboard/leadstatuswiselist"}
                        state={{
                          OperationType: 5,
                          LeadStatusID:
                            DashboardDetails?.LeadStatusWiseCount[3]
                              ?.LeadStatusID,
                          ...dateFilter?.FromDate,
                          ...dateFilter?.ToDate,
                        }}
                        style={{ textDecoration: "none" }}
                      >
                        <LeadStatusCard
                          icon={FaTimesCircle}
                          amount={
                            DashboardDetails?.LeadStatusWiseCount[3]
                              ?.LeadStatusCount
                          }
                          cardColor="#93E3B7"
                          title="Not-Alloted"
                        />
                      </Link>
                    </GridLayoutItem>
                    <GridLayoutItem>
                      <Link
                        to={"/dashboard/leadstatuswiselist"}
                        state={{
                          OperationType: 5,
                          LeadStatusID:
                            DashboardDetails?.LeadStatusWiseCount[4]
                              ?.LeadStatusID,
                          ...dateFilter?.FromDate,
                          ...dateFilter?.ToDate,
                        }}
                        style={{ textDecoration: "none" }}
                      >
                        <LeadStatusCard
                          icon={FaLock}
                          amount={
                            DashboardDetails?.LeadStatusWiseCount[4]
                              ?.LeadStatusCount
                          }
                          cardColor="#8BB9F4"
                          title="Close"
                        />
                      </Link>
                    </GridLayoutItem>
                    <GridLayoutItem>
                      <Link
                        to={"/dashboard/leadstatuswiselist"}
                        state={{
                          OperationType: 5,
                          LeadStatusID:
                            DashboardDetails?.LeadStatusWiseCount[5]
                              ?.LeadStatusID,
                          ...dateFilter?.FromDate,
                          ...dateFilter?.ToDate,
                        }}
                        style={{ textDecoration: "none" }}
                      >
                        <LeadStatusCard
                          icon={FaTrophy}
                          amount={
                            DashboardDetails?.LeadStatusWiseCount[5]
                              ?.LeadStatusCount
                          }
                          cardColor="#FFCFA1"
                          title="WON"
                        />
                      </Link>
                    </GridLayoutItem>
                  </GridLayout>
                </FormElement>
              )}
            />
          </ShadowCard>
          {/* TABLES */}
          <GridLayout
            style={{ marginRight: 20, marginTop: 20 }}
            gap={{ rows: 20, cols: 20 }}
            cols={[{ width: "50%" }, { width: "50%" }]}
          >
            {/* Employee Wise Closed Inquiry And Lead */}
            {DashboardDetails?.EmployeeWiseInquiryCloseAmount.length > 0 && (
              <GridLayoutItem>
                <ShadowCard>
                  <h6 style={{ color: "forestgreen" }}>
                    Employee Wise Inquiry Closing Amount
                  </h6>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th style={{ background: "gainsboro" }}>Sr.No</th>
                        <th style={{ background: "gainsboro" }}>Name</th>
                        <th style={{ background: "gainsboro" }}>Inquiry</th>
                        <th style={{ background: "gainsboro" }}>
                          Closing Amount
                        </th>
                        {/* <th style={{ background: "gainsboro" }}>Total</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {DashboardDetails?.EmployeeWiseInquiryCloseAmount?.map(
                        (item: any, ind: number) => (
                          <tr
                            key={ind}
                            onClick={() =>
                              handleInquiryClosingAmount(item?.UserID)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <td>{ind + 1}</td>
                            <td
                              className="hoverableName"
                              style={{ fontWeight: "bold" }}
                            >
                              {item?.UserName}
                            </td>
                            <td>{item?.InquiryCount}</td>
                            <td>
                              ₹
                              {AmountDifferentiation(
                                item?.InquiryClosingAmount
                              )}
                            </td>
                            {/* <td>₹{AmountDifferentiation(item?.Total)}</td> */}
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </ShadowCard>
              </GridLayoutItem>
            )}
            {DashboardDetails?.EmployeeWiseLeadCloseAmount.length > 0 && (
              <GridLayoutItem>
                <ShadowCard>
                  <h6 style={{ color: "forestgreen" }}>
                    Employee Wise Lead Closing Amount
                  </h6>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th style={{ background: "gainsboro" }}>Sr.No</th>
                        <th style={{ background: "gainsboro" }}> Name</th>
                        <th style={{ background: "gainsboro" }}>Lead</th>
                        <th style={{ background: "gainsboro" }}>
                          Closing Amount
                        </th>
                        {/* <th style={{ background: "gainsboro" }}>Total</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {DashboardDetails?.EmployeeWiseLeadCloseAmount?.map(
                        (item: any, ind: number) => (
                          <tr
                            key={ind}
                            onClick={() =>
                              handleLeadClosingAmount(item?.UserID)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <td>{ind + 1}</td>
                            <td
                              className="hoverableName"
                              style={{ fontWeight: "bold" }}
                            >
                              {item?.UserName}
                            </td>
                            <td>{item?.LeadCount}</td>
                            <td>
                              ₹{AmountDifferentiation(item?.LeadClosingAmount)}
                            </td>
                            {/* <td>₹{AmountDifferentiation(item?.Total)}</td> */}
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </ShadowCard>
              </GridLayoutItem>
            )}
            {DashboardDetails?.EmployeeWiseProspectsCounts.length > 0 && (
              <GridLayoutItem>
                <ShadowCard>
                  <h6 style={{ color: "forestgreen" }}>
                    Employee Wise Prospect Leads
                  </h6>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th style={{ background: "gainsboro" }}>Sr.No</th>
                        <th style={{ background: "gainsboro" }}>Name</th>
                        <th style={{ background: "gainsboro" }}>Count</th>
                      </tr>
                    </thead>
                    <tbody>
                      {DashboardDetails?.EmployeeWiseProspectsCounts?.map(
                        (item: any, ind: number) => (
                          <tr
                            key={ind}
                            onClick={() =>
                              handleEmployeeWiseProspectLeads(item?.UserID)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <td>{ind + 1}</td>
                            <td
                              className="hoverableName"
                              style={{ fontWeight: "bold" }}
                            >
                              {item?.UserName}
                            </td>
                            <td>{item?.ProsepectTotalCount}</td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </ShadowCard>
              </GridLayoutItem>
            )}
          </GridLayout>
          {/* <GridLayout
            style={{ marginRight: 20, marginTop: 20 }}
            gap={{ rows: 20, cols: 20 }}
            cols={[{ width: "50%" }, { width: "50%" }]}
          ></GridLayout> */}
        </GridLayoutItem>
        {/* RIGHT SIDE */}
        <GridLayoutItem style={{ width: "100%" }}>
          {DashboardDetails?.InquiryTypeWiseCounts.length > 0 && (
            <ShadowCard style={{ padding: 12 }}>
              <h6 style={{ color: "forestgreen" }}>Inquiry Type List</h6>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th style={{ background: "gainsboro" }}>Inquiry Type</th>
                    <th style={{ background: "gainsboro" }}>Count</th>
                    <th style={{ background: "gainsboro" }}>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {DashboardDetails?.InquiryTypeWiseCounts?.map(
                    (item: any, idx: any) => (
                      <tr
                        style={{ cursor: "pointer" }}
                        key={idx}
                        onClick={() =>
                          handleInquiryWisedata(item?.InquiryTypeID)
                        }
                      >
                        <td
                          className="hoverableName"
                          style={{ fontWeight: "bold" }}
                        >
                          {item?.InquiryType}
                        </td>
                        <td> {item?.InquiryTypeCount}</td>
                        <td width={100}>
                          ₹{AmountDifferentiation(item?.ProposedAmount)}
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </ShadowCard>
          )}
          {DashboardDetails?.EmployeeWiseCounts?.length > 0 && (
            <ShadowCard style={{ padding: 12, marginTop: 20 }}>
              <h6 style={{ color: "forestgreen" }}>Employee Wise Leads</h6>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th style={{ background: "gainsboro" }}>Sr.No</th>
                    <th style={{ background: "gainsboro" }}>Employee Name</th>
                    <th style={{ background: "gainsboro" }}>Count</th>
                  </tr>
                </thead>
                <tbody>
                  {DashboardDetails?.EmployeeWiseCounts?.map(
                    (item: any, ind: number) => (
                      <tr
                        key={ind}
                        onClick={() => handleEmployeeWiseLeads(item?.UserID)}
                        style={{ cursor: "pointer" }}
                      >
                        <td>{ind + 1}</td>
                        <td
                          className="hoverableName"
                          style={{ fontWeight: "bold" }}
                        >
                          {item.UserName}
                        </td>
                        <td>{item.InquiryTotalCount}</td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </ShadowCard>
          )}
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

export default Dashboard;
