import { Button } from "@progress/kendo-react-buttons";
import { Card, GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import React from "react";
import { Field, FieldArrayRenderProps } from "@progress/kendo-react-form";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import AppDialog from "../../components/dialog/Dialog";
import { Typography } from "@progress/kendo-react-common";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setBrocherAttachmentArrayIndex } from "./planBrochureSlice";
import FormImageUpload from "../../components/formFields/FormImageUpload";
import {
  createBrochureAttachment,
  //   createBrochureAttachment,
  deleteBrochureAttachment,
  updateBrochureAttachment,
  //   updateBrochureAttachment,
} from "../PlanBrochureAttachment/services/planBrochureAttachment.services";
import { ErrorToast } from "../../components/toast/Toasts";
import { Ripple } from "@progress/kendo-react-ripple";
import { FaRegEye } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { useLocation } from "react-router-dom";
import { getPlanBrochureByID } from "./services/planBrochure.services";

const BrochureAttachmentArray = (
  fieldArrayRenderProps: FieldArrayRenderProps
) => {
  const dispatch = useAppDispatch();
  const BrocherAttachmentArrayIndex = useAppSelector(
    (state) => state.planBrochure.BrocherAttachmentArrayIndex
  );
  const location = useLocation();
  const PlanBrochureID = location?.state?.ID;
  const dialogName = useAppSelector((state) => state.dialog.dialogName);

  const pushElementInarray = React.useCallback(() => {
    fieldArrayRenderProps.onPush({
      value: {
        AttachmentURL: "",
        ID: null,
      },
    });
  }, [fieldArrayRenderProps]);
  const handleImageSave = async (index: number) => {
    const attachmentID = fieldArrayRenderProps?.value[index]?.ID;
    const attachment = fieldArrayRenderProps?.value[index]?.AttachmentURL;

    try {
      if (attachmentID) {
        const files = new FormData();
        if (
          attachment &&
          typeof attachment !== "string" &&
          attachment.length > 0
        ) {
          const binaryDoc = attachment[0]?.getRawFile();
          files.append("ID", attachmentID);
          files.append("AttachmentName", binaryDoc?.name);
          files.append("AttachmentURL", binaryDoc);
          const res = await dispatch(updateBrochureAttachment(files));
          if (res.payload.Status === 200) {
            dispatch(getPlanBrochureByID(PlanBrochureID));
          }
        }
      } else {
        const files = new FormData();
        if (attachment && attachment.length > 0) {
          const binaryDoc = attachment[0]?.getRawFile();
          files.append("PlanBrochureID", PlanBrochureID);
          files.append("AttachmentName", binaryDoc?.name);
          files.append("AttachmentURL", binaryDoc);
          const res = await dispatch(createBrochureAttachment(files));
          if (res.payload.Status === 200) {
            dispatch(getPlanBrochureByID(PlanBrochureID));
          }
        }
      }
    } catch (error: any) {
      // Error handling
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error saving Image:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  };

  const handleOpenDeleteDialog = (index: number) => {
    dispatch(openDialog("ImageDeleteDialog"));
    dispatch(setBrocherAttachmentArrayIndex(index));
  };

  const handleOpenImage = (url: string) => {
    if (url && typeof url === "string") {
      window.open(url);
    }
  };
  const handleDeleteAction = React.useCallback(
    async (index: number) => {
      if (fieldArrayRenderProps?.value[index]?.ID) {
        try {
          const response = await dispatch(
            deleteBrochureAttachment(fieldArrayRenderProps?.value[index]?.ID)
          );
          if (response?.meta?.requestStatus === "fulfilled") {
            fieldArrayRenderProps?.onRemove({ index: index });
            dispatch(closeDialog());
            dispatch(setBrocherAttachmentArrayIndex(-1));
            // SuccessToast(response.payload.message || "Success");
          }
        } catch (error: any) {
          ErrorToast(error?.response?.data?.Details || "Something went wrong");
          console.error("Error deleting Image:", error);
          return Promise.reject({
            message: error?.response?.data?.Details || "Something went wrong",
          });
        }
      } else {
        fieldArrayRenderProps.onRemove({ index: index });
        dispatch(closeDialog());
        dispatch(setBrocherAttachmentArrayIndex(-1));
      }
    },
    [fieldArrayRenderProps]
  );

  return (
    <GridLayout gap={{ rows: 10, cols: 10 }}>
      {fieldArrayRenderProps?.value?.length < 1 ? pushElementInarray() : null}
      {dialogName === "ImageDeleteDialog" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Image"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Ripple>
                  <Button
                    style={{ marginRight: 4 }}
                    fillMode={"outline"}
                    themeColor={"error"}
                    onClick={() => {
                      dispatch(closeDialog());
                      dispatch(setBrocherAttachmentArrayIndex(-1));
                    }}
                  >
                    Cancel
                  </Button>
                </Ripple>
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() =>
                    handleDeleteAction(BrocherAttachmentArrayIndex)
                  }
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
      {fieldArrayRenderProps.value &&
        fieldArrayRenderProps.value.length > 0 &&
        fieldArrayRenderProps.value.map((Images: any, index: number) => (
          <GridLayoutItem key={index}>
            <Card className="fieldarray-card-imageArray">
              <GridLayout
                style={{
                  marginRight: 60,
                  alignItems: "center",
                  marginLeft: 20,
                  padding: 10,
                }}
                gap={{ rows: 10, cols: 10 }}
                cols={[{ width: "10%" }, { width: "80%" }, { width: "10%" }]}
              >
                <GridLayoutItem style={{ width: "100%" }}>
                  <Typography.h5 style={{ fontWeight: "bold" }}>
                    {Images.AttachmentName}
                  </Typography.h5>
                </GridLayoutItem>
                <GridLayoutItem style={{ display: "flex" }}>
                  <Field
                    imageWidth={60}
                    imageHeight={60}
                    name={`PlanBrochureAttachmentList.${index}.AttachmentURL`}
                    label="Attachments"
                    type="file"
                    // accept="image/*"
                    component={FormImageUpload}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <div
                    style={{
                      width: 230,
                      display: "flex",
                      alignItems: "end",
                      justifyContent: "center",
                      marginBottom: 8,
                    }}
                  >
                    <Button
                      style={{ padding: 5 }}
                      type="button"
                      fillMode={"solid"}
                      themeColor={"primary"}
                      onClick={() => handleImageSave(index)}
                    >
                      {!fieldArrayRenderProps.value[index]?.ID
                        ? "Save"
                        : "Update"}
                    </Button>
                    <Button
                      style={{
                        marginLeft: 5,
                        position: "relative",
                        width: 30,
                        height: 32,
                      }}
                      onClick={() => handleOpenDeleteDialog(index)}
                      disabled={fieldArrayRenderProps.value.length === 1}
                      fillMode={"solid"}
                      themeColor={"error"}
                      type="button"
                      size={"small"}
                    >
                      <MdDelete className="absolute-position" />
                    </Button>
                    <Button
                      style={{
                        marginLeft: 5,
                        position: "relative",
                        width: 30,
                        height: 32,
                      }}
                      onClick={() =>
                        handleOpenImage(
                          fieldArrayRenderProps.value[index]?.AttachmentURL
                        )
                      }
                      disabled={!fieldArrayRenderProps.value[index]?.ID}
                      fillMode={"solid"}
                      themeColor={"primary"}
                      type="button"
                      size={"small"}
                    >
                      <FaRegEye className="absolute-position" />
                    </Button>
                  </div>
                </GridLayoutItem>
              </GridLayout>
            </Card>
          </GridLayoutItem>
        ))}
      <GridLayoutItem style={{ marginBottom: 10, textAlign: "left" }}>
        <Button
          fillMode={"solid"}
          themeColor={"primary"}
          onClick={pushElementInarray}
          type="button"
          disabled={
            fieldArrayRenderProps.value?.filter(
              (e: any) => typeof e.attachments === "object"
            )?.length > 0
          }
        >
          Add Attachment
        </Button>
      </GridLayoutItem>
    </GridLayout>
  );
};

export default BrochureAttachmentArray;
