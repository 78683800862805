import React from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Anchor, IDrawerState, opened } from "./drawerSlice";
import { useAppDispatch } from "../../app/hooks";

interface CreateButtonType {
  drawerName: string;
  drawerWidth?: number;
  label: string;
  anchor?: Anchor;
  style?: any;
  size?: "large" | "small" | "medium";
}

const ButtonCreate: React.FC<CreateButtonType> = (props) => {
  const dispatch = useAppDispatch();

  const handleCreate = () => {
    const drawerOptions: IDrawerState = {
      open: true,
      anchor: props?.anchor || ("right" as Anchor),
      name: props?.drawerName,
      drawerWidth: props?.drawerWidth || 400,
    };
    dispatch(opened(drawerOptions));
  };

  return (
    <Button
      onClick={handleCreate}
      fillMode={"solid"}
      themeColor={"primary"}
      style={props?.style}
      size={props?.size || "medium"}
    >
      {props?.label}
    </Button>
  );
};

export default ButtonCreate;
