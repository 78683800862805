import { createSlice } from "@reduxjs/toolkit";
import { IDialogState } from "./dialogModel";

const initialState: IDialogState = {
  open: false,
  dialogName: "",
};

const dialogSlice = createSlice({
  name: "dialog",
  initialState,
  reducers: {
    openDialog: (state, action) => {
      state.open = true;
      state.dialogName = action.payload;
    },
    closeDialog: (state) => {
      state.open = false;
      state.dialogName = initialState.dialogName;
    },
  },
});

export default dialogSlice.reducer;
export const { openDialog, closeDialog } = dialogSlice.actions;
