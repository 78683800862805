import React, { useRef } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import AppDialog, {
  DialogCloseButton,
} from "../../../components/dialog/Dialog";
import { Typography } from "@progress/kendo-react-common";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import {
  closeDialog,
  openDialog,
} from "../../../components/dialog/dialogSlice";
import CreateTaskDialog from "./CreateTaskDialog";
import {
  CompositeFilterDescriptor,
  filterBy,
} from "@progress/kendo-data-query";
import {
  GridCellProps,
  GridCustomCellProps,
  GridCustomHeaderCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  Grid as KendoGrid,
  GridColumn as Column,
} from "@progress/kendo-react-grid";
import {
  HeaderThElement,
  PagerTargetEvent,
} from "@progress/kendo-react-data-tools";
import { LoadingPanel } from "../../../components/layout/Loading";
import IconButton from "../../../components/common/IconButton";
import { MdDelete } from "react-icons/md";
import AlertBox from "../../../components/common/AlertBox";
import { FaEdit } from "react-icons/fa";
import { deleteTask, getAllTasks } from "./services/services.tasks";
import {
  clearTaskGUID,
  clearTasksDetail,
  clearTasksID,
  setTaskGUID,
} from "./taskSlice";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { Repeat } from "../../../_contstants/common";
import { useResponsiveJSX } from "../../../_helper/helper";

const TaskDialog = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const gridRef = useRef<any>(null);
  const loading = useAppSelector((state) => state.tasks.loading);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const CloseTaskDialog: React.FC = () => {
    const CloseTask = () => {
      navigate(-1);
    };
    return (
      <div style={{ display: "flex", justifyContent: "end" }}>
        <Button
          style={{ padding: 4, marginTop: 10 }}
          themeColor={"primary"}
          fillMode={"outline"}
          onClick={CloseTask}
        >
          Back
        </Button>
      </div>
    );
  };
  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      {dialogName === "TASK" && <CreateTaskDialog />}
      <DeleteTaskDialog />
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            Tasks List
          </Typography.h4>

          <Button
            style={{ width: "auto", marginLeft: 5, padding: 6 }}
            themeColor={"primary"}
            type="button"
            fillMode="solid"
            size="small"
            title="Create Tasks"
            onClick={() => {
              dispatch(openDialog("TASK"));
              dispatch(clearTasksDetail());
              dispatch(clearTaskGUID());
            }}
          >
            Create Task
          </Button>
        </GridLayoutItem>
        <GridLayoutItem>
          <TasksGridComponent />
        </GridLayoutItem>
        <CloseTaskDialog />
      </GridLayout>
    </>
  );
};

interface PageState {
  skip: number;
  take: number;
}
const initialDataState: PageState = { skip: 0, take: 10 };
const TasksGridComponent: React.FC = () => {
  const dispatch = useAppDispatch();

  const TasksList = useAppSelector((state) => state.tasks.TasksList);
  const breakPoint = useResponsiveJSX([1024, 2170, 2514]);
  const dynamicWidth = [150, 200, 250, 300][breakPoint] ?? 300;
  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);
  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();

  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All" ? TasksList.length : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const MyEditCommandCell = (props: GridCellProps) => {
    const ID = props.dataItem.TaskGUID;
    const handleOpenDeleteDialog = () => {
      dispatch(openDialog("deleteTask"));
      dispatch(setTaskGUID(ID));
    };
    const handleOpenEditDialog = () => {
      dispatch(setTaskGUID(ID));
      // dispatch(getTaskById(ID));
      dispatch(openDialog("TASK"));
    };
    return (
      <td className={`action-td ${props.className}`} style={{ ...props.style }}>
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Edit"
          onClick={handleOpenEditDialog}
        >
          <FaEdit style={{ fontSize: "20px" }} />
        </IconButton>
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Delete"
          onClick={handleOpenDeleteDialog}
        >
          <MdDelete style={{ fontSize: "24px" }} />
        </IconButton>
      </td>
    );
  };

  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
    setPage({ skip: 0, take: page.take });
  };
  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      style={props.thProps?.style}
      className={`table-header ${
        props.index === 9 || props.index === 0 ? "locked-header" : ""
      }`}
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td
        style={{ ...props.style }}
        {...props.tdProps}
        colSpan={1}
        className={`table-cell ${props.className}`}
      >
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );
  return (
    <>
      {TasksList && TasksList.length ? (
        <KendoGrid
          style={{ maxHeight: "calc(100vh - 152px)" }}
          filterable={true}
          filter={filter}
          data={
            filter
              ? filterBy(TasksList, filter).slice(
                  page.skip,
                  page.take + page.skip
                )
              : TasksList.slice(page.skip, page.take + page.skip)
          }
          onFilterChange={handleFilterChange}
          skip={page.skip}
          take={page.take}
          total={TasksList.length}
          pageable={{
            buttonCount: 5,
            pageSizes: [5, 10, 15, "All"],
            pageSizeValue: pageSizeValue,
            type: "input",
          }}
          onPageChange={pageChange}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
        >
          <Column
            field="StartDate"
            title="Start Date"
            width={dynamicWidth}
            locked
            cell={(props: GridCellProps) => (
              <td className={`${props.className}`} style={{ ...props.style }}>
                {props.dataItem.StartDate
                  ? moment(props.dataItem.StartDate, "DD/MM/YYYY").format(
                      "DD/MM/YYYY"
                    )
                  : ""}
              </td>
            )}
          />
          <Column
            field="DueDate"
            title="Due Date"
            width={dynamicWidth}
            cell={(props: GridCellProps) => (
              <td className={`${props.className}`} style={{ ...props.style }}>
                {props.dataItem.DueDate
                  ? moment(props.dataItem.DueDate, "DD/MM/YYYY").format(
                      "DD/MM/YYYY"
                    )
                  : ""}
              </td>
            )}
          />
          <Column field="Subject" title="Subject" width={dynamicWidth} />
          <Column field="TaskStatus" title="Status" width={dynamicWidth} />
          <Column field="Priority" title="Priority" width={dynamicWidth} />
          <Column
            field="ReminderDate"
            title="Remider Date"
            width={dynamicWidth}
          />
          <Column
            field="ReminderTime"
            title="Remider Time"
            width={dynamicWidth}
          />
          <Column
            field="Repeat"
            width={dynamicWidth}
            title="Task Repeat"
            cell={(props: GridCellProps) => (
              <td className={` ${props.className}`} style={{ ...props.style }}>
                {
                  Repeat.find(
                    (item) => item.value === (props.dataItem.Repeat ?? 1)
                  )?.label
                }
              </td>
            )}
          />
          <Column
            field="Description"
            title="Description"
            width={dynamicWidth}
          />
          <Column
            field="TaskGUID"
            title="Actions"
            locked
            cell={MyEditCommandCell}
            width={"110px"}
            filterable={false}
          />
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

const DeleteTaskDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const TasksGUID = useAppSelector((state) => state.tasks?.TaskGUID);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const LeadID = location?.state?.ActivityLog?.LeadID;
  const NBLeadTypeID = location?.state?.ActivityLog?.NBLeadTypeID;
  const NBInquiryTypeID = location?.state?.ActivityLog?.NBInquiryTypeID;
  const payload = {
    LeadID: LeadID ?? null,
    NBInquiryTypeID: NBInquiryTypeID ?? null,
    NBLeadTypeID: NBLeadTypeID ?? null,
  };
  const handleDeleteAction = async (ID: string | null) => {
    if (ID) {
      const response = await dispatch(deleteTask(ID));
      if (response.payload?.Data.Status === 200) {
        dispatch(getAllTasks(payload));
        dispatch(closeDialog());
        dispatch(clearTasksID());
      } else {
        dispatch(getAllTasks(payload));
        dispatch(closeDialog());
        dispatch(clearTasksID());
      }
    }
  };

  return (
    <>
      {dialogName === "deleteTask" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Task?"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this Task?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(TasksGUID)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

export default TaskDialog;
