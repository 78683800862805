import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IRights } from "../rightsModel";

export const getAllRights = createAsyncThunk(
  "Rights/FindAllRights",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Rights/FindAllRights`,
        {
          Name: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Rights:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createRights = createAsyncThunk(
  "Rights/InsertRights",
  async (RightsData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Rights/InsertRights`,
        RightsData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating rights:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateRights = createAsyncThunk(
  "Rights/UpdateRights",
  async (RightsData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Rights/UpdateRights`,
        RightsData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating rights:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteRights = createAsyncThunk(
  "Rights/DeleteRights",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Rights/DeleteRights`,
        { ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting rights:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getRightsByID = createAsyncThunk(
  "Rights/FindByIDRights",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Rights/FindByIDRights`,
        { ID }
      );
      const result = response.data?.Data as any;
      return {
        ID: result.ID,
        Name: result.Name,
        IsActive: result.IsActive === true ? 1 : 2,
      } as IRights;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching rights:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveRights = createAsyncThunk(
  "Rights/FindAllActiveRights",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Rights/FindAllActiveRights`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Rights:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const ActiveInactiveRights = createAsyncThunk(
  "Rights/ActiveInActiveRights",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Rights/ActiveInActiveRights`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive rights:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
