import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../../app/config";
import { axiosApiInstance } from "../../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../../components/toast/Toasts";

export const getAllRecoding = createAsyncThunk(
  "RecodingFiles/RecodingFilesFindAll",
  async (paylaod: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/RecodingFiles/RecodingFilesFindAll`,
        paylaod
      );
      return response.data?.Data;
    } catch (error: any) {
      console.error("Error Fetching Recoding:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createRecoding = createAsyncThunk(
  "RecodingFiles/RecodingFilesInsert",
  async (Recoding: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/RecodingFiles/RecodingFilesInsert`,
        Recoding
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Recoding:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateRecoding = createAsyncThunk(
  "RecodingFiles/RecodingFilesImageUpdate",
  async (Recoding: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/RecodingFiles/RecodingFilesImageUpdate`,
        Recoding
      );
      if (response.data?.Status === 200) {
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Recoding:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteRecoding = createAsyncThunk(
  "RecodingFiles/RecodingFilesDelete",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/RecodingFiles/RecodingFilesDelete`,
        { ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Deleting Recoding:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
