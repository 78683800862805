import { Button } from "@progress/kendo-react-buttons";
import { Typography } from "@progress/kendo-react-common";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { Card, GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
// import { closed } from "../../components/drawer/drawerSlice";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { LoadingPanel } from "../../components/layout/Loading";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { IInquiryType } from "../inquiryType/inquirytypeModel";
import { IInquirySubtype } from "../inquirySubType/inquirysubtypeModel";
import { ILeadType } from "../leadtype/leadTypeModel";
import { ILeadStatus } from "../leadstatus/leadStatusModel";
import { getAllUser } from "../clientForm/services/services.clientform";
import { findAllActiveLeadType } from "../leadtype/services/leadType.services";
import { findAllActiveLeadStatus } from "../leadstatus/services/leadStatus.services";
import {
  FREQUANCY_LIST,
  GETALLACTIVEWITHFILTER_TYPE,
} from "../../_contstants/common";
import { findAllActiveInquiryType } from "../inquiryType/services/inquirytype.services";
import { findAllActiveInquirySubtype } from "../inquirySubType/services/inquireysubtype.services";
import { useLocation, useNavigate } from "react-router-dom";
import { findClientsByID } from "../NB Inquiry/services/nbinquiry.services";
import ShadowCard from "../../components/common/ShadowCard";
import FormDatePicker from "../../components/formFields/FormDateField";
import moment from "moment";
import {
  createBNLead,
  getBNLeadById,
  updateNBLead,
} from "../NBLead/services/nblead.services";
import { clearNBLeadDetails } from "../NBLead/nbleadSlice";
import FormMultiSelectionFiled from "../../components/formFields/FormMultiSelectionFiled";
import FormTextField from "../../components/formFields/FormTextField";

interface InquiryTypeChangeWatcherProps {
  formRenderProps: FormRenderProps;
}
const InquiryTypeChangeWatcher: React.FC<InquiryTypeChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  const InquiryTypeID = formRenderProps.valueGetter("InquiryTypeID");
  const NBInquirySubtype = formRenderProps.valueGetter(`InquirySubTypeID`);
  if (NBInquirySubtype && !NBInquirySubtype.length) {
    formRenderProps.onChange(`InquirySubTypeID`, {
      value: null,
    });
  }
  const InquiryTypeList = useAppSelector(
    (state) => state.inquiry.InquiryTypeList
  );
  const InquiryTypeSelected = useRef<any[] | null>([]);
  const InquirySubtypeList = useAppSelector(
    (state) => state.inquirysubtype.InquirySubtypeList
  );
  const filterInquirySubType = (InquirySub: any) => {
    if (InquirySub && InquirySub.length > 0) {
      const result = InquirySubtypeList?.filter((inquitysubtype: any) => {
        return InquirySub?.includes(inquitysubtype.InquiryTypeID);
      });
      return result?.map((inquitysubtype: IInquirySubtype) => {
        return {
          value: inquitysubtype?.ID,
          label: inquitysubtype?.InquirySubType,
        };
      });
    }
  };

  useEffect(() => {
    if (InquiryTypeID && InquiryTypeID.length > 0) {
      const filteredSubType = filterInquirySubType(InquiryTypeID);
      const selectedSubtype = filteredSubType
        ?.filter((item) => NBInquirySubtype?.includes(item.value))
        .map((item) => item.value)
        .filter((value): value is number => value !== null);
      formRenderProps.onChange(`InquirySubTypeID`, {
        value: selectedSubtype?.length ? selectedSubtype : null,
      });
      const removedTypes = InquiryTypeSelected.current?.filter(
        (item) => !InquiryTypeID.includes(item.ID)
      );
      removedTypes?.forEach((item) => {
        const LeadP_AND_C = formRenderProps.valueGetter(`P_AND_C`);
        delete LeadP_AND_C[`P_${item.ID}`];
        delete LeadP_AND_C[`C_${item.ID}`];
        formRenderProps.onChange(`P_AND_C`, {
          value: LeadP_AND_C,
        });
      });
      InquiryTypeSelected.current = InquiryTypeID.map((id: number) =>
        InquiryTypeList.find((item: any) => item.ID === id)
      ).filter(Boolean);
    } else {
      formRenderProps.onChange(`P_AND_C`, {
        value: {},
      });
      InquiryTypeSelected.current = null;
      formRenderProps.onChange(`InquirySubTypeID`, {
        value: null,
      });
    }
  }, [InquiryTypeID, InquiryTypeList, dispatch]);

  const LeadStatusID = formRenderProps.valueGetter("LeadStatusID");
  const LeadStatusList = useAppSelector(
    (state) => state.leadStatus.LeadStatusList
  );

  const LeadStatusIsWON = LeadStatusList.some(
    (item: any) => item.ID === LeadStatusID && item.LeadStatus === "WON"
  );
  useEffect(() => {
    if (!LeadStatusIsWON) {
      InquiryTypeSelected?.current?.forEach((item) => {
        const LeadP_AND_C = formRenderProps.valueGetter(`P_AND_C`);
        delete LeadP_AND_C[`C_${item.ID}`];
        formRenderProps.onChange(`P_AND_C`, {
          value: LeadP_AND_C,
        });
      });
    }
  }, [LeadStatusIsWON]);

  const renderFields = (prefix: string) =>
    InquiryTypeSelected?.current?.map((item: any) => (
      <Field
        key={item.ID}
        wrapperStyle={{
          display: "flex",
          alignItems: "center",
        }}
        name={`P_AND_C.${prefix}_${item.ID}`}
        label={item.InquiryType}
        labelStyle={{ width: "50%" }}
        type="number"
        placeholder={item.InquiryType}
        component={FormTextField}
        validator={requiredValidator} astrike={true}
      />
    ));
  return (
    <>
      {InquiryTypeSelected.current &&
        InquiryTypeSelected.current.length > 0 && (
          <GridLayoutItem>
            <Card
              style={{
                marginTop: "10px",
                border: "1px dotted lightgray",
                padding: "10px",
              }}
            >
              <Typography.h6 style={{ textAlign: "center", margin: "0" }}>
                Proposed Amounts
              </Typography.h6>
              {renderFields("P")}
            </Card>
          </GridLayoutItem>
        )}

      {InquiryTypeSelected.current &&
        InquiryTypeSelected.current.length > 0 &&
        LeadStatusIsWON && (
          <Card
            style={{
              marginTop: "10px",
              border: "1px dotted lightgray",
              padding: "10px",
            }}
          >
            <Typography.h6 style={{ textAlign: "center", margin: "0" }}>
              Closing Amounts
            </Typography.h6>
            {renderFields("C")}
          </Card>
        )}
    </>
  );
};

const CreateLead: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const LeadID = location.state.ClientID;

  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);
  const loading = useAppSelector((state) => state.nblead.formLoading);
  const LeadTypeList = useAppSelector((state) => state.leadType.LeadTypeList);
  const LeadStatusList = useAppSelector(
    (state) => state.leadStatus.LeadStatusList
  );
  const InquiryTypeList = useAppSelector(
    (state) => state.inquiry.InquiryTypeList
  );
  const FamilyMemberList = useAppSelector(
    (state) => state.nbinquiry.FamilyMemberList
  );
  const NBLeadDetail = useAppSelector((state) => state.nblead.NBLeadDetail);
  const InquirySubtypeList = useAppSelector(
    (state) => state.inquirysubtype.InquirySubtypeList
  );
  // This prcess for fill the ProposedAmount an ClosingAmount
  const result: Record<string, string> = {};
  const ids = NBLeadDetail.InquiryTypeID;
  const proposedAmounts = NBLeadDetail?.ProposedAmount.split(",").map(
    (amount) => amount.trim()
  );
  const closingAmounts = NBLeadDetail?.ClosingAmount.split(",").map((amount) =>
    amount.trim()
  );
  ids?.forEach((id, index) => {
    result[`P_${id}`] = proposedAmounts[index];
    result[`C_${id}`] = closingAmounts[index];
  });
  const newNBLeadDetails = { ...NBLeadDetail, P_AND_C: { ...result } };

  const UserList = useAppSelector((state) => state.client.UserList);
  const [formKey, setFormKey] = useState(1);


  const NBLeadBy = location.state?.NBLeadGUID?.NBLeadBy;
  const NBLeadGUID = location.state?.NBLeadGUID?.NBLeadGUID;

  useEffect(() => {
    if (NBLeadGUID) {
      dispatch(getBNLeadById(NBLeadGUID));
    }
  }, [NBLeadGUID]);

  useEffect(() => {
    dispatch(findClientsByID(LeadID ? LeadID : NBLeadBy));
    dispatch(getAllUser());
    dispatch(findAllActiveLeadType());
    dispatch(
      findAllActiveLeadStatus({ OperationType: GETALLACTIVEWITHFILTER_TYPE })
    );
    dispatch(
      findAllActiveInquiryType({ OperationType: GETALLACTIVEWITHFILTER_TYPE })
    );
    dispatch(
      findAllActiveInquirySubtype({
        OperationType: GETALLACTIVEWITHFILTER_TYPE,
      })
    );
    return () => {
      dispatch(clearNBLeadDetails());
    };
  }, []);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [NBLeadDetail]);

  const handleSubmit = async (values: any) => {
    const NBLeadDetailEntity = values?.InquiryTypeID?.map((id: number) => {
      return {
        InquiryTypeID: id,
        ProposedAmount: +values.P_AND_C[`P_${id}`],
        ClosingAmount: +values.P_AND_C[`C_${id}`] || null,
      };
    });
    const formData = {
      LeadID: LeadID,
      NBLeadBy: values.NBLeadBy,
      LeadDate: moment(values.LeadDate ?? "").format("YYYY-MM-DD"),
      LeadAllotmentID: values.LeadAllotmentID,
      InquirySubTypeID: values.InquirySubTypeID.join(","),
      LeadTypeID: values.LeadTypeID,
      LeadStatusID: values.LeadStatusID,
      Frequency: values.Frequency,
      CoPersonAllotmentID: values.CoPersonAllotmentID,
      NBLeadDetailEntity: NBLeadDetailEntity,
      IsActive: true,
    };

    try {
      if (!NBLeadGUID) {
        const response = await dispatch(createBNLead(formData));
        if (response?.payload?.Status === 201) {
          dispatch(clearNBLeadDetails());
          navigate(-1);
        }
      } else {
        const editPayload = {
          NBLeadsGUID: NBLeadGUID,
          ...formData,
        };
        const response = await dispatch(updateNBLead(editPayload));
        if (response?.payload?.Status === 200) {
          dispatch(clearNBLeadDetails());
          navigate(-1);
        }
      }
    } catch (error) {
      console.error("Error in Update Inquiry:", error);
      throw error;
    }
  };

  const handleFormClose = () => {
    navigate(-1);
  };
  const filterInquirySubType = (InquirySub: any) => {
    if (InquirySub && InquirySub.length > 0) {
      const result = InquirySubtypeList?.filter((inquitysubtype: any) => {
        return InquirySub?.includes(inquitysubtype.InquiryTypeID);
      });
      return result?.map((inquitysubtype: IInquirySubtype) => {
        return {
          value: inquitysubtype?.ID,
          label: inquitysubtype?.InquirySubType,
        };
      });
    }
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        key={formKey}
        initialValues={newNBLeadDetails}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 20 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "33.33%" },
                  { width: "33.33%" },
                  { width: "33.33%" },
                ]}
              >
                <GridLayoutItem colSpan={3}>
                  <Typography.h4 className="m-0">
                    {NBLeadGUID ? "Update Lead" : "Create Lead"}
                  </Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem style={{ width: "100%" }}>
                  <Field
                    name={"NBLeadBy"}
                    label="Family Member"
                    placeholder="Family Member"
                    component={FormSelectionField}
                    validator={requiredValidator} astrike={true}
                    options={FamilyMemberList?.map((familymember: any) => {
                      return {
                        value: familymember.ID,
                        label: `${familymember.FirstName} ${familymember.LastName}`,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={"LeadDate"}
                    label="Lead Date"
                    component={FormDatePicker}
                    validator={requiredValidator} astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={"InquiryTypeID"}
                    label="Inquiry Type"
                    placeholder="Inquiry Type"
                    component={FormMultiSelectionFiled}
                    validator={requiredValidator} astrike={true}
                    options={InquiryTypeList?.map(
                      (inquirytype: IInquiryType) => {
                        return {
                          value: inquirytype?.ID,
                          label: inquirytype?.InquiryType,
                        };
                      }
                    )}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={"InquirySubTypeID"}
                    label="Inquiry Sub Type"
                    placeholder="Inquiry Sub Type"
                    component={FormMultiSelectionFiled}
                    validator={requiredValidator} astrike={true}
                    options={
                      filterInquirySubType(
                        formRenderProps.valueGetter(`InquiryTypeID`)
                      ) || []
                    }
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={"LeadTypeID"}
                    label="Lead Type"
                    placeholder="Lead Type"
                    component={FormSelectionField}
                    validator={requiredValidator} astrike={true}
                    options={LeadTypeList?.map((leadtype: ILeadType) => {
                      return {
                        value: leadtype?.ID,
                        label: leadtype?.LeadType,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={"LeadStatusID"}
                    label="Lead Status"
                    placeholder="Lead Status"
                    component={FormSelectionField}
                    validator={requiredValidator} astrike={true}
                    options={LeadStatusList?.map((leadstatus: ILeadStatus) => {
                      return {
                        value: leadstatus?.ID,
                        label: leadstatus?.LeadStatus,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={"LeadAllotmentID"}
                    label="Allotment To"
                    placeholder="Allotment To"
                    component={FormSelectionField}
                    validator={requiredValidator} astrike={true}
                    options={UserList?.map((user: any) => {
                      return {
                        value: user?.ID,
                        label: `${user?.FirstName} ${user?.LastName}`,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={"CoPersonAllotmentID"}
                    label="Co-Person Allotted To"
                    placeholder="Co-Person Allotted To"
                    component={FormSelectionField}
                 //   validator={requiredValidator}
                    options={UserList?.map((user: any) => {
                      return {
                        value: user?.ID,
                        label: `${user?.FirstName} ${user?.LastName}`,
                      };
                    })}
                  />
                </GridLayoutItem>
                {/* <GridLayoutItem>
                  <Field
                    name={"ProposedAmount"}
                    label="Proposed Amount"
                    placeholder="Proposed Amount"
                    component={FormTextField}
                    validator={requiredValidator} astrike={true}
                  />
                </GridLayoutItem> */}
                <GridLayoutItem>
                  <Field
                    name={"Frequency"}
                    label="Frequency"
                    placeholder="Frequency"
                    component={FormSelectionField}
                    validator={requiredValidator} astrike={true}
                    options={FREQUANCY_LIST?.map((frequancy: any) => {
                      return {
                        value: frequancy?.value,
                        label: frequancy?.label,
                      };
                    })}
                  />
                </GridLayoutItem>
                <InquiryTypeChangeWatcher formRenderProps={formRenderProps} />
                <GridLayoutItem
                  colSpan={3}
                  style={{
                    textAlign: "end",
                    marginTop: "10px",
                    width: "100%",
                  }}
                >
                  <Button
                    type="button"
                    fillMode={"outline"}
                    themeColor={"primary"}
                    style={{ marginRight: 4 }}
                    onClick={handleFormClose}
                  >
                    Cancel
                  </Button>
                  <ButtonWithLoading
                    label={NBLeadGUID ? "Update" : "Create"}
                    type="submit"
                    disabled={!formRenderProps.allowSubmit || loading}
                    loading={loading}
                  />
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
          </FormElement>
        )}
      />
    </>
  );
};

export default CreateLead;
