import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../../app/config";
import { axiosApiInstance } from "../../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../../components/toast/Toasts";
import moment from "moment";

export const getAllCalls = createAsyncThunk(
  "Calls/CallsFindAll",
  async (payload: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Calls/CallsFindAll`,
        payload
      );
      return response.data?.Data?.map((e: any) => ({
        ...e,
        IsFollowup: e.IsFollowup ? "True" : "False",
      }));
    } catch (error: any) {
      console.error("Error Fetching Calls:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createCall = createAsyncThunk(
  "Calls/CallsInsert",
  async (Calls: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Calls/CallsInsert`,
        Calls
      );
      if (response.data?.Status === 201) {
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Calls:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateCall = createAsyncThunk(
  "Calls/CallsUpdate",
  async (Calls: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Calls/CallsUpdate`,
        Calls
      );
      if (response.data?.Status === 200) {
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Calls:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getCallById = createAsyncThunk(
  "Calls/CallsFindByID",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Calls/CallsFindByID`,
        {
          CallGUID: ID,
        }
      );
      const result = response.data?.Data;
      return {
        ID: result.ID,
        CallGUID: result.CallGUID,
        LeadID: result.LeadID,
        NBLeadTypeID: result.NBLeadTypeID,
        NBInquiryTypeID: result.NBInquiryTypeID,
        CallTypeID: result.CallTypeID,
        CallType: result.CallType,
        CallStatus: result.CallStatus,
        CallDate: result.CallDate
          ? moment(result.CallDate, "DD/MM/YYYY").toDate()
          : "",
        CallPurposeID: result.CallPurposeID,
        CallPurpose: result.CallPurpose,
        CallResultID: result.CallResultID,
        CallResult: result.CallResult,
        Subject: result.Subject,
        Agenda: result.Agenda,
        Description: result.Description,
        IsFollowup: result.IsFollowup,
        FollowupDate: result.FollowupDate
          ? moment(result.FollowupDate, "DD/MM/YYYY").toDate()
          : "",
        FollowupTime: result.FollowupTime
          ? moment(result.FollowupTime, "HH:mm:ss").toDate()
          : "",
        FollowupNotes: result.FollowupNotes,
      };
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Calls:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteCall = createAsyncThunk(
  "Calls/CallsDelete",
  async (ID: string) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Calls/CallsDelete`,
        {
          CallGUID: ID,
        }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Deleting Calls:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const findAllActiveCalls = createAsyncThunk(
  "Calls/CallsFindAllActive",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Calls/CallsFindAllActive`
      );
      // SuccessToast(response.data?.Details || "Success")
      return response.data.Data;
    } catch (error: any) {
      console.error("Error Deleting OccupationType:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const CallActiveInactive = createAsyncThunk(
  "Calls/CallsActiveInActive",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Calls/CallsActiveInActive`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive OccupationType:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllCallTypes = createAsyncThunk(
  "Master/ManageCallType",
  async (ManagecallType: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Master/ManageCallType`,
        ManagecallType
      );
      return response.data.Data;
    } catch (error: any) {
      console.error("Error Deleting Manage ManagecallType:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllCallPurpose = createAsyncThunk(
  "Master/ManageCallPurpose",
  async (ManagecallPurpose: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Master/ManageCallPurpose`,
        ManagecallPurpose
      );
      return response.data.Data;
    } catch (error: any) {
      console.error("Error Deleting Manage ManagecallPurpose:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllCallResults = createAsyncThunk(
  "Master/ManageCallResult",
  async (managecallresult: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Master/ManageCallResult`,
        managecallresult
      );
      return response.data.Data;
    } catch (error: any) {
      console.error("Error Deleting Manage managecallresult:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
