import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import React, { useEffect, useRef } from "react";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { Button } from "@progress/kendo-react-buttons";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { LoadingPanel } from "../../components/layout/Loading";
import { useAppDispatch, useAppSelector } from "../../app/hooks";

import { closed } from "../../components/drawer/drawerSlice";
import {
  EDIT_TYPE,
  GETALLACTIVEWITHFILTER_TYPE,
  INSERT_TYPE,
  STATUSARRAY,
} from "../../_contstants/common";
import { Typography } from "@progress/kendo-react-common";
import { RxCross1 } from "react-icons/rx";
import {
  createInquirySubtype,
  getAllInquirySubtype,
  getInquirySubtypeById,
  updateInquirySubtype,
} from "./services/inquireysubtype.services";
import { clearInquirySubtypeDetails } from "./inquirysubtypeSlice";
import { findAllActiveInquiryType } from "../inquiryType/services/inquirytype.services";
import FormTextField from "../../components/formFields/FormTextField";

const CreateInquirySubType: React.FC = () => {
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);
  const InquirySubtypeDetail = useAppSelector(
    (state) => state.inquirysubtype.InquirySubtypeDetail
  );
  const InquirySubTypeGUID = useAppSelector((state) => state.drawer.data);
  const formLoading = useAppSelector(
    (state) => state.inquirysubtype.formLoading
  );
  const InquiryTypeList = useAppSelector(
    (state) => state.inquiry.InquiryTypeList
  );

  useEffect(() => {
    dispatch(
      findAllActiveInquiryType({ OperationType: GETALLACTIVEWITHFILTER_TYPE })
    );
    return () => {
      dispatch(clearInquirySubtypeDetails());
      dispatch(closed());
    };
  }, []);

  useEffect(() => {
    if (InquirySubTypeGUID) {
      dispatch(getInquirySubtypeById(InquirySubTypeGUID));
    }
  }, [InquirySubTypeGUID]);

  const handleSubmit = async (values: any) => {
    try {
      if (!InquirySubTypeGUID) {
        const insertPayload = {
          InquirySubType: values.InquirySubType,
          InquiryTypeID: values.InquiryTypeID ? `${values.InquiryTypeID}` : "",
          IsActive: values.IsActive === 1,
          OperationType: INSERT_TYPE,
        };
        const response = await dispatch(createInquirySubtype(insertPayload));
        if (response?.payload?.Status === 201) {
          dispatch(closed());
          dispatch(clearInquirySubtypeDetails());
          dispatch(getAllInquirySubtype());
        }
      } else {
        const editPayload = {
          ID: values.ID,
          InquirySubTypeGUID: InquirySubTypeGUID,
          InquirySubType: values.InquirySubType,
          InquiryTypeID: values.InquiryTypeID ? `${values.InquiryTypeID}` : "",
          IsActive: values.IsActive === 1,
          OperationType: EDIT_TYPE,
        };
        const response = await dispatch(updateInquirySubtype(editPayload));
        if (response?.payload?.Status === 200) {
          dispatch(closed());
          dispatch(clearInquirySubtypeDetails());
          dispatch(getAllInquirySubtype());
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  const handleFormClose = () => {
    dispatch(closed());
  };

  if (formLoading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={InquirySubtypeDetail}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement style={{ width: "100%" }}>
          <GridLayout
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
            gap={{ rows: 10 }}
            cols={[{ width: "100%" }]}
          >
            <GridLayoutItem className="drawer-header">
              <Typography.h4 className="m-0">
                {InquirySubTypeGUID
                  ? "Update Inquiry Sub Type"
                  : "Create Inquiry Sub Type"}
              </Typography.h4>
              <Button
                type="button"
                fillMode={"flat"}
                themeColor={"dark"}
                style={{ marginRight: 4 }}
                onClick={() => dispatch(closed())}
              >
                <RxCross1 />
              </Button>
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="InquiryTypeID"
                label="Inquiry Type"
                placeholder="Inquiry Type"
                component={FormSelectionField}
                validator={requiredValidator} astrike={true}
                options={InquiryTypeList?.map((inquiry: any) => {
                  return {
                    value: inquiry.ID,
                    label: inquiry.InquiryType,
                  };
                })}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="InquirySubType"
                label="Inquiry Sub Type"
                placeholder="Inquiry Sub Type"
                component={FormTextField}
                validator={requiredValidator} astrike={true}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                id={"IsActive"}
                name={"IsActive"}
                component={FormSelectionField}
                label={"Status"}
                placeholder="Status"
                validator={requiredValidator} astrike={true}
                options={STATUSARRAY?.map((status: any) => {
                  return {
                    value: status.value,
                    label: status.label,
                  };
                })}
              />
            </GridLayoutItem>
            <GridLayoutItem
              colSpan={3}
              style={{
                marginTop: 20,
                width: "100%",
              }}
            >
              <ButtonWithLoading
                label={InquirySubTypeGUID ? "Update" : "Create"}
                type="submit"
                disabled={!formRenderProps.allowSubmit || formLoading}
                loading={formLoading}
              />
              <Button
                type="button"
                fillMode={"outline"}
                themeColor={"primary"}
                style={{ marginLeft: 4 }}
                onClick={handleFormClose}
              >
                Cancel
              </Button>
            </GridLayoutItem>
          </GridLayout>
        </FormElement>
      )}
    />
  );
};

export default CreateInquirySubType;
