import React from "react";
import { IconType } from "react-icons";

interface TabStripTitleProps {
  title: string;
  icon?: IconType;
}
const TabStripTitle = ({ title, icon: Icon }: TabStripTitleProps) => {
  return (
    <>
      {Icon && <Icon style={{ fontSize: "18" }} />}
      <span style={{ fontWeight: "bold", marginLeft: Icon ? "5px" : "0px" }}>
        {title}
      </span>
    </>
  );
};

export default TabStripTitle;
