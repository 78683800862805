import React from "react";
import { FieldRenderProps, FieldWrapper } from "@progress/kendo-react-form";
import { Error, Hint, Label } from "@progress/kendo-react-labels";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import {
  MultiViewCalendar,
  MultiViewCalendarProps,
} from "@progress/kendo-react-dateinputs";
import { CgDanger } from "react-icons/cg";

export const CustomCalendar: React.FC = (props: MultiViewCalendarProps) => {
  return <MultiViewCalendar {...props} views={1} />;
};

const FormDatePicker = (fieldRenderProps: FieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    format = "dd/MM/yyyy",
    wrapperStyle,
    hintDirection,
    astrike = false,
    ...others
  } = fieldRenderProps;

  const showValidationMessage: string | false | null =
    touched && validationMessage;
  const showHint: boolean = !showValidationMessage && hint;
  const hintId: string = showHint ? `${id}_hint` : "";
  const errorId: string = showValidationMessage ? `${id}_error` : "";
  const labelId: string = label ? `${id}_label` : "";


  return (
    <FieldWrapper style={wrapperStyle}>
      <Label
        id={labelId}
        editorId={id}
        editorValid={valid}
        editorDisabled={disabled}
        className="k-form-label"
      >
        {label} {astrike && <span style={{ color: "red" }}>*</span>}
      </Label>
      <div className={"k-form-field-wrap"}>
        <div>
          <DatePicker
            calendar={CustomCalendar}
            ariaLabelledBy={labelId}
            ariaDescribedBy={`${hintId} ${errorId}`}
            valid={valid}
            id={id}
            format={format}
            // popupSettings={{
            //     animate: false,
            //     popupClass:'year-only-popup'
            // }}
            // show={true}
            min={others.minDate || new Date(1900, 0, 1)}
            max={others.maxDate}
            defaultValue={others.defaultDate}
            disabled={disabled}
            {...others}
          />
        </div>
        {showHint && (
          <Hint id={hintId} direction={hintDirection}>
            {hint}
          </Hint>
        )}
        {showValidationMessage && (
          <Error className="d-flex justify-content-end" id={errorId}>
            <span
              className="d-flex align-items-center"
              style={{
                background: "#DF4957",
                color: "white",
                padding: "1px 8px 1px 5px",
                borderRadius: 10,
                gap: 5,
              }}
            >
              <CgDanger /> {validationMessage}
            </span>
          </Error>
        )}
      </div>
    </FieldWrapper>
  );
};

export default FormDatePicker;
