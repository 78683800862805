import { FieldRenderProps } from "@progress/kendo-react-form";
import { Rating } from "@progress/kendo-react-inputs";
import { Error } from "@progress/kendo-react-labels";

const FormRating = (fieldRenderProps: FieldRenderProps) => {
  const { validationMessage, visited, ...others } = fieldRenderProps;
  return (
    <div>
      <Rating style={{ color: "red", background: "red" }} {...others} />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};

export default FormRating;
