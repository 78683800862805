import React, { useRef } from "react";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { Typography } from "@progress/kendo-react-common";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeDialog } from "../../components/dialog/dialogSlice";
import { RootState } from "../../app/store";
import AppDialog from "../../components/dialog/Dialog";
import FormFileUpload from "../../components/formFields/FormFileUpload";
import { createExcel } from "./services/excelUpload.services";

import { LoadingPanel } from "../../components/layout/Loading";
import { ErrorToast, SuccessToast } from "../../components/toast/Toasts";
const CloseLogDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const closeLogDialog = () => {
    dispatch(closeDialog());
  };
  return (
    <Button
      themeColor={"primary"}
      fillMode={"outline"}
      onClick={closeLogDialog}
    >
      Close
    </Button>
  );
};

const CreateAttachmentDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const AttachmentDetail = useAppSelector(
    (state) => state.attachment.AttachmentDetail
  );
  const formLoading = useAppSelector((state) => state.excelUpload.formLoading);
  const gridRef = useRef<any>(null);
  const dialogName = useAppSelector(
    (state: RootState) => state.dialog.dialogName
  );
  const handleSubmit = async (values: any) => {
    try {
      if (values.attachments.length > 0) {
        const file = new FormData();
        const binaryDoc = values?.attachments[0]?.getRawFile();
        file.append("File", binaryDoc);
        const response = await dispatch(createExcel(file));
        response?.payload?.Status === 200
          ? SuccessToast(response?.payload?.Detail || "Success!")
          : ErrorToast(response?.payload?.Detail || "Error Excel Upload!");
        dispatch(closeDialog());
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  if (formLoading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      {dialogName === "CreateExcel" && (
        <AppDialog className="dialog-width ">
          <Form
            onSubmit={handleSubmit}
            initialValues={AttachmentDetail}
            render={(formRenderProps: FormRenderProps) => (
              <FormElement>
                <Typography.h5 style={{ margin: 0, textAlign: "center" }}>
                  Upload Excel
                </Typography.h5>
                <GridLayout
                  gap={{ rows: 0, cols: 5 }}
                  style={{ marginRight: 10 }}
                >
                  <GridLayoutItem>
                    <Field
                      name="attachments"
                      type="file"
                      filetype="file"
                      label="Attachments"
                      accept={".xls ,.xlsx"}
                      component={FormFileUpload}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem
                    //colSpan={2}
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: "10px",
                    }}
                  >
                    <Button
                      type="submit"
                      themeColor={"primary"}
                      fillMode={"solid"}
                      style={{ marginRight: "10px" }}
                      disabled={!formRenderProps.allowSubmit}
                    >
                      Upload
                    </Button>
                    <CloseLogDialog />
                  </GridLayoutItem>
                </GridLayout>

                
              </FormElement>
            )}
          />
        </AppDialog>
      )}
    </>
  );
};
export default CreateAttachmentDialog;
