import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IPlanBrochure, IPlanBrochureInitialState } from "./planBrochureModel";
import {
  createPlanBrochure,
  deletePlanBrochure,
  FindAllCompanyIdByPlan,
  getAllActivePlanBrochure,
  getAllPlanBrochure,
  getPlanBrochureByID,
  planBrochureActiveInactive,
  updatePlanBrochure,
} from "./services/planBrochure.services";

const initialState: IPlanBrochureInitialState = {
  loading: false,
  formLoading: false,
  error: "",
  PlanBrochureList: [],
  PlanList: [],
  PlanBrochureID: null,
  BrocherAttachmentArrayIndex: -1,
  PlanBrochureDetail: {
    CompanyID: null,
    PlanID: null,
    IsActive: 1,
  },
};

export const planBrochureSlice = createSlice({
  name: "planBrochure",
  initialState,
  reducers: {
    clearPlanBrochureDetail: (state) => {
      state.PlanBrochureDetail = initialState.PlanBrochureDetail;
    },
    setPlanBrochureID: (state, action) => {
      state.PlanBrochureID = action.payload;
    },
    clearPlanList: (state) => {
      state.PlanList = initialState.PlanList;
    },
    clearPlanBrochureID: (state) => {
      state.PlanBrochureID = null;
    },
    setBrocherAttachmentArrayIndex: (state, action) => {
      state.BrocherAttachmentArrayIndex = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllPlanBrochure.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllPlanBrochure.fulfilled,
      (state, action: PayloadAction<IPlanBrochure[]>) => {
        state.loading = false;
        state.PlanBrochureList = action.payload;
      }
    );
    builder.addCase(getAllPlanBrochure.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.PlanBrochureList = [];
    });
    // FIND PLAN BY COMAPNY
    builder.addCase(FindAllCompanyIdByPlan.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      FindAllCompanyIdByPlan.fulfilled,
      (state, action: PayloadAction<[]>) => {
        state.loading = false;
        state.PlanList = action.payload;
      }
    );
    builder.addCase(FindAllCompanyIdByPlan.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.PlanList = [];
    });

    builder.addCase(getAllActivePlanBrochure.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActivePlanBrochure.fulfilled,
      (state, action: PayloadAction<IPlanBrochure[]>) => {
        state.loading = false;
        state.PlanBrochureList = action.payload || [];
      }
    );
    builder.addCase(getAllActivePlanBrochure.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.PlanBrochureList = [];
    });

    builder.addCase(createPlanBrochure.pending, (state) => {
      state.formLoading = false;
      state.error = "";
    });
    builder.addCase(createPlanBrochure.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createPlanBrochure.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(updatePlanBrochure.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updatePlanBrochure.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updatePlanBrochure.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(deletePlanBrochure.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deletePlanBrochure.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deletePlanBrochure.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getPlanBrochureByID.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getPlanBrochureByID.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.PlanBrochureDetail = action.payload;
    });
    builder.addCase(getPlanBrochureByID.rejected, (state, action) => {
      state.formLoading = false;
      state.PlanBrochureDetail = initialState.PlanBrochureDetail;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(planBrochureActiveInactive.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(planBrochureActiveInactive.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(planBrochureActiveInactive.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearPlanBrochureDetail,
  clearPlanBrochureID,
  setPlanBrochureID,
  clearPlanList,
  setBrocherAttachmentArrayIndex,
} = planBrochureSlice.actions;
export default planBrochureSlice.reducer;
