import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import React, { useEffect, useRef } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Typography } from "@progress/kendo-react-common";

import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closed } from "../../components/drawer/drawerSlice";
import { LoadingPanel } from "../../components/layout/Loading";
import ShadowCard from "../../components/common/ShadowCard";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import {
  clearInvestmentGUID,
  clearOtherInvestorDetails,
} from "./otherInvestorSlice";
import {
  createOtherInvestor,
  findOtherInvestorByID,
  getAllOtherInvestor,
  updateOtherInvestor,
} from "./services/otherInvestor.services";
import moment from "moment";
import FormDatePicker from "../../components/formFields/FormDateField";
import FormTextArea from "../../components/formFields/FormTextArea";
import { getAllActiveUsers } from "../user/services/user.services";
import { IUser } from "../user/userModel";
import { findAllActiveInquiryType } from "../inquiryType/services/inquirytype.services";
import { IInquiryType } from "../inquiryType/inquirytypeModel";

const CreateOtherInvestor: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const gridRef = useRef<any>(null);
  const OtherInvestorDetail = useAppSelector(
    (state) => state.otherinvestor.OtherInvestorDetail
  );
  const InquiryTypeList = useAppSelector(
    (state) => state.inquiry.InquiryTypeList
  );
  const UserList = useAppSelector((state) => state.user.UserList);
  const InvestmentGUID = location?.state?.InvestmentGUID;
  const LeadID = location?.state?.LeadID;

  console.log("LEADID", LeadID);

  const formLoading = useAppSelector(
    (state) => state.otherinvestor.formLoading
  );

  useEffect(() => {
    dispatch(
      findAllActiveInquiryType({
        OperationType: 5,
      })
    );
    dispatch(getAllActiveUsers());
    return () => {
      dispatch(clearOtherInvestorDetails());
      dispatch(clearInvestmentGUID());
      dispatch(closed());
    };
  }, []);

  useEffect(() => {
    if (InvestmentGUID) {
      dispatch(findOtherInvestorByID(InvestmentGUID));
    }
  }, [InvestmentGUID]);

  const handleSubmit = async (values: any) => {
    const formdata = {
      CategoryID: values?.CategoryID,
      InvestmentDetails: values?.InvestmentDetails,
      AllottedDate: values?.AllottedDate
        ? moment(values?.AllottedDate).format("YYYY-MM-DD")
        : null,
      FurtherAction: values?.FurtherAction,
      UserID: values?.UserID,
      LeadID: LeadID,
    };
    try {
      if (!InvestmentGUID) {
        const response = await dispatch(createOtherInvestor(formdata));
        if (response?.payload?.Status === 201) {
          dispatch(closed());
          dispatch(clearOtherInvestorDetails());
          dispatch(getAllOtherInvestor(LeadID));
          navigate(-1);
        }
      } else {
        const editPayload = {
          InvestmentGUID: InvestmentGUID,
          ...formdata,
          LeadID: values?.LeadID,
        };
        const response = await dispatch(updateOtherInvestor(editPayload));
        if (response?.payload?.Status === 200) {
          dispatch(closed());
          dispatch(clearOtherInvestorDetails());
          dispatch(getAllOtherInvestor(LeadID));
          navigate(-1);
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  const handleFormClose = () => {
    dispatch(closed());
    navigate(-1);
  };

  if (formLoading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={OtherInvestorDetail}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement>
          <ShadowCard style={{ padding: 12 }}>
            <GridLayout
              style={{ marginRight: 20 }}
              gap={{ rows: 0, cols: 10 }}
              cols={[
                { width: "33.33%" },
                { width: "33.33%" },
                { width: "33.33%" },
              ]}
            >
              <GridLayoutItem colSpan={3}>
                <Typography.h4 className="m-0">
                  {InvestmentGUID
                    ? "Update Other Investment"
                    : "Create Other Investment"}
                </Typography.h4>
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name={`CategoryID`}
                  label="Category"
                  placeholder="Selecte Category"
                  component={FormSelectionField}
                  validator={requiredValidator}
                  astrike={true}
                  options={InquiryTypeList?.map((cate: IInquiryType) => {
                    return {
                      value: cate?.ID,
                      label: cate?.InquiryType,
                    };
                  })}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="AllottedDate"
                  label="Alloted Date"
                  component={FormDatePicker}
                  validator={requiredValidator}
                  astrike={true}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name={`UserID`}
                  label="User"
                  placeholder="Selecte User"
                  component={FormSelectionField}
                  validator={requiredValidator}
                  astrike={true}
                  options={UserList?.map((user: IUser) => {
                    return {
                      value: user?.ID,
                      label: `${user?.FirstName} ${user?.LastName}`,
                    };
                  })}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="InvestmentDetails"
                  label="Investment Details"
                  placeholder="Write Investment Details..."
                  component={FormTextArea}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="FurtherAction"
                  label="Further Action"
                  placeholder="Write Further Action..."
                  component={FormTextArea}
                />
              </GridLayoutItem>
              <GridLayoutItem
                colSpan={3}
                style={{
                  textAlign: "end",
                  marginTop: "10px",
                  width: "100%",
                }}
              >
                <Button
                  type="button"
                  fillMode={"outline"}
                  themeColor={"primary"}
                  style={{ marginRight: 5 }}
                  onClick={handleFormClose}
                >
                  Cancel
                </Button>
                <ButtonWithLoading
                  label={InvestmentGUID ? "Update" : "Create"}
                  type="submit"
                  disabled={!formRenderProps.allowSubmit || formLoading}
                  loading={formLoading}
                />
              </GridLayoutItem>
            </GridLayout>
          </ShadowCard>
        </FormElement>
      )}
    />
  );
};

export default CreateOtherInvestor;
