//  eslint-disable react-hooks/exhaustive-deps
import React, { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  filterBy,
  CompositeFilterDescriptor,
} from "@progress/kendo-data-query";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
} from "@progress/kendo-react-grid";
import {
  GridLayout,
  GridLayoutItem,
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from "@progress/kendo-react-layout";
import { PagerTargetEvent } from "@progress/kendo-react-data-tools";
import { HeaderThElement } from "@progress/kendo-react-data-tools";
import { Typography } from "@progress/kendo-react-common";
import AlertBox from "../../components/common/AlertBox";
import { LoadingPanel } from "../../components/layout/Loading";
import {
  getClientsProspectLeadList,
  getInquiryClosingAmount,
  getLeadClosingAmount,
} from "./services/dashboard.services";
import { useLocation } from "react-router-dom";
import { setClosingAmountTab } from "./dashboardSlice";
import TabStripTitle from "../../components/common/TabStripTitle";
import { FiList } from "react-icons/fi";
import { MdLeaderboard } from "react-icons/md";

const DashboardClientWiseInquiryAndLead: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const UserID = location?.state?.UserID;

  const ClosingAmountTab = useAppSelector(
    (state) => state.dashboard.ClosingAmountTab
  );
  const handleSelect = (e: TabStripSelectEventArguments) => {
    dispatch(setClosingAmountTab(e.selected));
  };
  useEffect(() => {
    ClosingAmountTab === 0
      ? dispatch(getInquiryClosingAmount(UserID))
      : dispatch(getLeadClosingAmount(UserID));
  }, [ClosingAmountTab]);

  useEffect(() => {
    const payload = {
      UserID: UserID ?? null,
    };
    dispatch(getClientsProspectLeadList(payload));
  }, []);

  return (
    <>
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4px 20px 20px 0",
          }}
        >
          {/* <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            Employee Wise Inquiry & Lead Closing Amount
          </Typography.h4> */}
        </GridLayoutItem>
        <GridLayoutItem>
          <TabStrip
            className="nb-inquiry-types-tab"
            selected={ClosingAmountTab}
            onSelect={handleSelect}
          >
            <TabStripTab
              title={<TabStripTitle title={"Inquiry"} icon={FiList} />}
            >
              <InquiryClosingAmountGrid />
            </TabStripTab>
            <TabStripTab
              title={<TabStripTitle title={"Lead"} icon={MdLeaderboard} />}
            >
              <LeadClosingAmountGrid />
            </TabStripTab>
          </TabStrip>
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = { skip: 0, take: 10 };

// LEAD CLOSING AMOUNT GRID
const InquiryClosingAmountGrid: React.FC = () => {
  const gridRef = useRef<any>(null);
  // const dispatch = useAppDispatch();
  const InquiryClosingAmountList = useAppSelector(
    (state) => state.dashboard?.InquiryClosingAmountList
  );

  const loading = useAppSelector((state) => state.dashboard?.loading);
  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);
  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();

  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All"
        ? InquiryClosingAmountList.length
        : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
    setPage({ skip: 0, take: page.take });
  };
  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      style={props.thProps?.style}
      className={`table-header ${
        props.index === 0 || props.index === 1 ? "locked-header" : ""
      }`}
      {...props.thProps}
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td
        style={{ ...props.style }}
        {...props.tdProps}
        colSpan={1}
        className={`table-cell ${props.className}`}
      >
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
        Inquiry Closing Amount
      </Typography.h4>
      {InquiryClosingAmountList && InquiryClosingAmountList.length ? (
        <KendoGrid
          style={{ maxHeight: "calc(100vh - 152px)" }}
          filterable={true}
          filter={filter}
          data={
            filter
              ? filterBy(InquiryClosingAmountList, filter).slice(
                  page.skip,
                  page.take + page.skip
                )
              : InquiryClosingAmountList.slice(page.skip, page.take + page.skip)
          }
          onFilterChange={handleFilterChange}
          skip={page.skip}
          take={page.take}
          total={InquiryClosingAmountList.length}
          pageable={{
            buttonCount: 5,
            pageSizes: [5, 10, 15, "All"],
            pageSizeValue: pageSizeValue,
            type: "input",
          }}
          onPageChange={pageChange}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
        >
          <Column field="InquiryNo" title="Inquiry No" />
          <Column field="InquiryDate" title="Inquiry Date" />
          <Column field="LeadName" title="Client Name" />
          <Column field="GroupCode" title="Group Code" />
          <Column field="InquiryAllotmentName" title="Inquiry Allotment Name" />
          <Column field="LeadType" title="Lead Type" />
          <Column field="LeadStatus" title="Lead Status" />
          <Column field="InquiryType" title="Inquiry Type" />
          <Column field="ProposedAmount" title="Proposed Amount" />
          <Column field="ClosingAmount" title="Closing Amount" />
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

// LEAD CLOSING AMOUNT GRID
const LeadClosingAmountGrid: React.FC = () => {
  const gridRef = useRef<any>(null);
  // const dispatch = useAppDispatch();
  const LeadClosingAmountList = useAppSelector(
    (state) => state.dashboard?.LeadClosingAmountList
  );

  const loading = useAppSelector((state) => state.dashboard?.loading);
  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);
  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();

  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All"
        ? LeadClosingAmountList.length
        : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;

  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
    setPage({ skip: 0, take: page.take });
  };
  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      style={props.thProps?.style}
      {...props.thProps}
      className={`table-header ${
        props.index === 0 || props.index === 1 ? "locked-header" : ""
      }`}
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td
        style={{ ...props.style }}
        {...props.tdProps}
        colSpan={1}
        className={`table-cell ${props.className}`}
      >
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  return (
    <>
      <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
        Lead Closing Amount
      </Typography.h4>
      {LeadClosingAmountList && LeadClosingAmountList.length ? (
        <KendoGrid
          style={{ maxHeight: "calc(100vh - 152px)" }}
          filterable={true}
          filter={filter}
          data={
            filter
              ? filterBy(LeadClosingAmountList, filter).slice(
                  page.skip,
                  page.take + page.skip
                )
              : LeadClosingAmountList.slice(page.skip, page.take + page.skip)
          }
          onFilterChange={handleFilterChange}
          skip={page.skip}
          take={page.take}
          total={LeadClosingAmountList.length}
          pageable={{
            buttonCount: 5,
            pageSizes: [5, 10, 15, "All"],
            pageSizeValue: pageSizeValue,
            type: "input",
          }}
          onPageChange={pageChange}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
        >
          <Column field="FirstName" title="First Name" />
          <Column field="LastName" title="Last Name" />
          <Column field="GroupCode" title="Group Code" />
          <Column field="EmailID" title="Email ID" />
          <Column field="MobileNo" title="Mobile No" />
          <Column field="CreatedByName" title="Employee Name" />
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

export default DashboardClientWiseInquiryAndLead;
