import React, { useEffect, useRef } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Typography } from "@progress/kendo-react-common";
import {
  GridLayout,
  GridLayoutItem,
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from "@progress/kendo-react-layout";
import {
  CompositeFilterDescriptor,
  filterBy,
} from "@progress/kendo-data-query";
import {
  GridCellProps,
  GridCustomCellProps,
  GridCustomHeaderCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  Grid as KendoGrid,
  GridColumn as Column,
} from "@progress/kendo-react-grid";
import {
  HeaderThElement,
  PagerTargetEvent,
} from "@progress/kendo-react-data-tools";
import { LoadingPanel } from "../../../components/layout/Loading";
import AlertBox from "../../../components/common/AlertBox";
import { useNavigate } from "react-router-dom";
import { Repeat } from "../../../_contstants/common";
import { getUpcomingTasksList } from "../services/dashboard.services";
import { useResponsiveJSX } from "../../../_helper/helper";
import TabStripTitle from "../../../components/common/TabStripTitle";
import { MdLeaderboard } from "react-icons/md";
import { FiList } from "react-icons/fi";
import { setUpComingTasksTab } from "../dashboardSlice";
import {
  AiOutlineAudio,
  AiOutlineFile,
  AiOutlineFileDone,
  AiOutlinePaperClip,
  AiOutlinePhone,
  AiOutlineTeam,
} from "react-icons/ai";

const TasksUpcoming = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const UpComingTasksTab = useAppSelector(
    (state) => state.dashboard.UpComingTasksTab
  );
  const handleSelect = (e: TabStripSelectEventArguments) => {
    dispatch(setUpComingTasksTab(e.selected));
  };
  useEffect(() => {
    const upcommingName = UpComingTasksTab === 0 ? "inquiry" : "lead";
    dispatch(getUpcomingTasksList(upcommingName));
  }, [UpComingTasksTab]);

  const CloseTaskDialog: React.FC = () => {
    const CloseTask = () => {
      navigate(-1);
    };
    return (
      <div style={{ display: "flex", justifyContent: "end" }}>
        <Button
          style={{ padding: 4, marginTop: 10 }}
          themeColor={"primary"}
          fillMode={"outline"}
          onClick={CloseTask}
        >
          Back
        </Button>
      </div>
    );
  };

  return (
    <>
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            Tasks List
          </Typography.h4>
        </GridLayoutItem>
        <GridLayoutItem>
          <TabStrip
            className="nb-inquiry-types-tab"
            selected={UpComingTasksTab}
            onSelect={handleSelect}
          >
            <TabStripTab
              title={<TabStripTitle title={"Inquiry"} icon={FiList} />}
            >
              <TasksGridComponent />
            </TabStripTab>
            <TabStripTab
              title={<TabStripTitle title={"Lead"} icon={MdLeaderboard} />}
            >
              <TasksGridComponent />
            </TabStripTab>
          </TabStrip>
        </GridLayoutItem>
        <CloseTaskDialog />
      </GridLayout>
    </>
  );
};

interface PageState {
  skip: number;
  take: number;
}
const initialDataState: PageState = { skip: 0, take: 10 };
const TasksGridComponent: React.FC = () => {
  const navigate = useNavigate();
  const UpcomingTasksList = useAppSelector(
    (state) => state.dashboard.UpcomingTasksList
  );
  const loading = useAppSelector((state) => state.dashboard.loading);
  const gridRef = useRef<any>(null);
  const breakPoint = useResponsiveJSX([1024, 2170, 2514]);
  const dynamicWidth = [150, 200, 250, 300][breakPoint] ?? 300;
  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);
  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();
  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All" ? UpcomingTasksList.length : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
    setPage({ skip: 0, take: page.take });
  };
  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      style={props.thProps?.style}
      // className={`table-header`}
      className={`table-header ${
        props.index === 10 || props.index === 0 ? "locked-header" : ""
      }`}
    >
      {props.children}
    </HeaderThElement>
  );

  const LeadActivityLogsCommandCell = (props: GridCellProps) => {
    const handleActivityLogs = ({
      LeadID,
      NBInquiryTypeID,
      NBLeadTypeID,
      ActivityLogType,
    }: any) => {
      navigate("/clientform/leadactivitylogs", {
        state: {
          ActivityLog: {
            LeadID,
            NBInquiryTypeID,
            NBLeadTypeID,
            ActivityLogType,
          },
        },
      });
    };

    return (
      <td
        className={`action-td ${props.className}`}
        style={{
          ...props.style,
        }}
      >
        <Button
          themeColor={"warning"}
          type="button"
          style={{ margin: "2px" }}
          fillMode="outline"
          size="small"
          title="Notes"
          onClick={() =>
            handleActivityLogs({
              LeadID: props.dataItem?.LeadID,
              NBInquiryTypeID: props.dataItem?.NBInquiryTypeID,
              NBLeadTypeID: props.dataItem?.NBLeadTypeID,
              ActivityLogType: 0,
            })
          }
        >
          <AiOutlineFile />
        </Button>
        <Button
          themeColor={"inverse"}
          style={{ margin: "2px" }}
          type="button"
          fillMode="outline"
          size="small"
          title="Tasks"
          onClick={() =>
            handleActivityLogs({
              LeadID: props.dataItem?.LeadID,
              NBInquiryTypeID: props.dataItem?.NBInquiryTypeID,
              NBLeadTypeID: props.dataItem?.NBLeadTypeID,
              ActivityLogType: 1,
            })
          }
        >
          <AiOutlineFileDone />
        </Button>
        <Button
          themeColor={"secondary"}
          type="button"
          style={{ margin: "2px" }}
          fillMode="outline"
          size="small"
          title="Calls"
          onClick={() =>
            handleActivityLogs({
              LeadID: props.dataItem?.LeadID,
              NBInquiryTypeID: props.dataItem?.NBInquiryTypeID,
              NBLeadTypeID: props.dataItem?.NBLeadTypeID,
              ActivityLogType: 2,
            })
          }
        >
          <AiOutlinePhone />
        </Button>
        <Button
          themeColor={"success"}
          type="button"
          fillMode="outline"
          style={{ margin: "2px" }}
          size="small"
          title="Meetings"
          onClick={() =>
            handleActivityLogs({
              LeadID: props.dataItem?.LeadID,
              NBInquiryTypeID: props.dataItem?.NBInquiryTypeID,
              NBLeadTypeID: props.dataItem?.NBLeadTypeID,
              ActivityLogType: 3,
            })
          }
        >
          <AiOutlineTeam />
        </Button>
        <Button
          themeColor={"tertiary"}
          type="button"
          style={{ margin: "2px" }}
          fillMode="outline"
          size="small"
          title="Attachment"
          onClick={() =>
            handleActivityLogs({
              LeadID: props.dataItem?.LeadID,
              NBInquiryTypeID: props.dataItem?.NBInquiryTypeID,
              NBLeadTypeID: props.dataItem?.NBLeadTypeID,
              ActivityLogType: 4,
            })
          }
        >
          <AiOutlinePaperClip />
        </Button>
        <Button
          themeColor={"error"}
          type="button"
          style={{ margin: "2px" }}
          fillMode="outline"
          size="small"
          title="Recording"
          onClick={() =>
            handleActivityLogs({
              LeadID: props.dataItem?.LeadID,
              NBInquiryTypeID: props.dataItem?.NBInquiryTypeID,
              NBLeadTypeID: props.dataItem?.NBLeadTypeID,
              ActivityLogType: 5,
            })
          }
        >
          <AiOutlineAudio />
        </Button>
      </td>
    );
  };

  const CustomCell = (props: any) => {
    return (
      <td
        style={{ ...props.style }}
        {...props.tdProps}
        colSpan={1}
        className={`table-cell ${props.className}`}
      >
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );
  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      {UpcomingTasksList && UpcomingTasksList.length ? (
        <KendoGrid
          style={{ maxHeight: "calc(100vh - 152px)" }}
          filterable={true}
          filter={filter}
          data={
            filter
              ? filterBy(UpcomingTasksList, filter).slice(
                  page.skip,
                  page.take + page.skip
                )
              : UpcomingTasksList.slice(page.skip, page.take + page.skip)
          }
          onFilterChange={handleFilterChange}
          skip={page.skip}
          take={page.take}
          total={UpcomingTasksList.length}
          pageable={{
            buttonCount: 5,
            pageSizes: [5, 10, 15, "All"],
            pageSizeValue: pageSizeValue,
            type: "input",
          }}
          onPageChange={pageChange}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
        >
          <Column
            field="LeadName"
            locked
            title="Client Name"
            width={dynamicWidth}
          />
          <Column field="GroupCode" title="Group Code" width={dynamicWidth} />
          <Column field="Subject" title="Subject" width={dynamicWidth} />
          <Column
            field="ReminderDate"
            title="Remider Date"
            width={dynamicWidth}
          />
          <Column
            field="ReminderTime"
            title="Remider Time"
            width={dynamicWidth}
          />
          <Column field="DueDate" title="Due Date" width={dynamicWidth} />
          <Column field="TaskStatus" title="Task Status" width={dynamicWidth} />
          <Column field="Priority" title="Priority" width={dynamicWidth} />
          <Column
            field="Description"
            title="Description"
            width={dynamicWidth}
          />
          <Column
            field="Repeat"
            width={dynamicWidth}
            title="Task Repeat"
            cell={(props: GridCellProps) => (
              <td>
                {
                  Repeat.find(
                    (item) => item.value === (props.dataItem.Repeat ?? 1)
                  )?.label
                }
              </td>
            )}
          />
          <Column
            field="ID"
            title="Activity Logs"
            cell={LeadActivityLogsCommandCell}
            width={"150px"}
            locked={true}
            filterable={false}
          />
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

export default TasksUpcoming;
