export const GENDER = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
];

export type ISelectionType = {
  value: number;
  label: string;
};

export const DRAWER_WIDTH = 400;
export const INSERT_OPT_TYPE = 1;
export const EDIT_OPT_TYPE = 2;
export const DELETE_OPT_TYPE = 3;
export const GET_BY_GUID_OPT_TYPE = 4;
export const GET_ALL_ACTIVE_WITH_FILTER_OPT_TYPE = 5;
export const GET_ALL_OPT_TYPE = 6;
export const ACTIVE_INACTIVE_OPT_TYPE = 7;

export const INSERT_ACCESS = "1";
export const EDIT_ACCESS = "2";
export const DELETE_ACCESS = "3";
export const PDF_DOWNLOAD_ACCESS = "4";
export const EXCEL_EXPORT_ACCESS = "5";

export const INSERT_TYPE = 1;
export const EDIT_TYPE = 2;
export const DELETE_TYPE = 3;
export const GETBYGUID_TYPE = 4;
export const GETALLACTIVEWITHFILTER_TYPE = 5;
export const GETALL_TYPE = 6;
export const ACTIVEINACTIVE_TYPE = 7;

export const STATUSARRAY = [
  { label: "Active", value: 1 },
  { label: "Inactive", value: 2 },
];

export const FREQUANCY_LIST = [
  { value: "Single", label: "Single" },
  { value: "Yearly", label: "Yearly" },
  { value: "Half Yearly", label: "Half Yearly" },
  { value: "Quaterly", label: "Quaterly" },
  { value: "Monthly", label: "Monthly" },
];
export const task_status = [
  { value: "Not Started", label: "Not Started" },
  { value: "Deferred", label: "Deferred" },
  { value: "In Progress", label: "In Progress" },
  { value: "Completed", label: "Completed" },
  { value: "Waiting for input", label: "Waiting for input" },
];

export const TaskPriority = [
  { value: "High", label: "High" },
  { value: "Highest", label: "Highest" },
  { value: "Low", label: "Low" },
  { value: "Lowest", label: "Lowest" },
  { value: "Normal", label: "Normal" },
];

export const Repeat = [
  { value: 1, label: "None" },
  { value: 2, label: "Daily" },
  { value: 3, label: "Weekly" },
  { value: 4, label: "Monthly" },
  { value: 5, label: "Yearly" },
];

export const Notifyvia = [
  { value: 1, label: "Email" },
  { value: 2, label: "Pop Up" },
  { value: 3, label: "Both" },
];
export const DateRangeFilter = [
  { value: 1, label: "Today" },
  { value: 2, label: "Yesterday" },
  { value: 3, label: "This Week" },
  { value: 4, label: "Last 30 Days" },
  { value: 5, label: "This Month" },
  { value: 6, label: "Custom" },
];
