import React, { useRef } from "react";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { Typography } from "@progress/kendo-react-common";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeDialog } from "../../components/dialog/dialogSlice";
import { RootState } from "../../app/store";
import AppDialog from "../../components/dialog/Dialog";
import FormTextField from "../../components/formFields/FormTextField";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import { useNavigate } from "react-router-dom";
import { LoadingPanel } from "../../components/layout/Loading";
import { ErrorToast, SuccessToast } from "../../components/toast/Toasts";
import { changePassword } from "./services/changepassword.services";
import { clearChangePasswordDetails } from "./changePasswordSlice";

const CloseLogDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const closeLogDialog = () => {
    dispatch(closeDialog());
  };

  return (
    <Button
      themeColor={"primary"}
      fillMode={"outline"}
      onClick={closeLogDialog}
    >
      Close
    </Button>
  );
};

const ChangePasswordDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);
  const navigate = useNavigate();
  const loading = useAppSelector((state) => state.password.loading);
  const ChangePasswordDetails = useAppSelector(
    (state) => state.password.ChangePasswordDetails
  );

  const dialogName = useAppSelector(
    (state: RootState) => state.dialog.dialogName
  );

  const handleSubmit = async (values: any) => {
    try {
      if (values.NewPassword !== values.ConfirmPassword) {
        ErrorToast("New Password and Confirm Password does not Match!");
      } else {
        const credentials = {
          ID: Number(localStorage.getItem("UserID")),
          OldPassword: values.OldPassword,
          Password: values.NewPassword,
        };
        const response = await dispatch(changePassword(credentials));
        if (response?.payload?.Status === 200) {
          dispatch(closeDialog());
          dispatch(clearChangePasswordDetails());
          SuccessToast(response?.payload?.Details || "Success!");
          setTimeout(() => {
            SuccessToast("Please login with new Password!");
          }, 1000);
          setTimeout(() => {
            navigate("/login");
            localStorage.clear();
          }, 3000);
        } else {
          ErrorToast(response?.payload?.Details || "Somthing went Wrong!");
          dispatch(closeDialog());
          dispatch(clearChangePasswordDetails());
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      {dialogName === "changePassword" && (
        <AppDialog className="dialog-width">
          <Form
            onSubmit={handleSubmit}
            initialValues={ChangePasswordDetails}
            render={(formRenderProps: FormRenderProps) => (
              <FormElement>
                <Typography.h5 style={{ margin: 0, textAlign: "center" }}>
                  Change Password
                </Typography.h5>
                <GridLayout
                  gap={{ rows: 0, cols: 5 }}
                  style={{ marginRight: 10 }}
                >
                  <GridLayoutItem style={{ width: "100%" }}>
                    <Field
                      name="OldPassword"
                      label="Old Password"
                      placeholder="Old Password"
                      component={FormTextField}
                      validator={requiredValidator} astrike={true}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem style={{ width: "100%" }}>
                    <Field
                      name="NewPassword"
                      label="New Password"
                      placeholder="New Password"
                      component={FormTextField}
                      validator={requiredValidator} astrike={true}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem style={{ width: "100%" }}>
                    <Field
                      name="ConfirmPassword"
                      label="Confirm Password"
                      placeholder="Confirm Password"
                      component={FormTextField}
                      validator={requiredValidator} astrike={true}
                    />
                  </GridLayoutItem>
                </GridLayout>
                <GridLayout
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "10px",
                  }}
                >
                  <Button
                    type="submit"
                    themeColor={"primary"}
                    fillMode={"solid"}
                    style={{ marginRight: "10px" }}
                    disabled={!formRenderProps.allowSubmit}
                  >
                    Submit
                  </Button>
                  <CloseLogDialog />
                </GridLayout>
              </FormElement>
            )}
          />
        </AppDialog>
      )}
    </>
  );
};

export default ChangePasswordDialog;
