import {
  Field,
  FieldArray,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ShadowCard from "../../components/common/ShadowCard";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import FormTextField from "../../components/formFields/FormTextField";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Button } from "@progress/kendo-react-buttons";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { closed } from "../../components/drawer/drawerSlice";
import { LoadingPanel } from "../../components/layout/Loading";
import { useLocation, useNavigate } from "react-router-dom";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import moment from "moment";
import {
  createNBinquiry,
  findClientsByID,
  getNBinquiryByGUID,
  updateNBinquiry,
} from "./services/nbinquiry.services";
import { clearNBinquiryDetails } from "./nbinquirySlice";
import {
  findAllActiveClient,
  getAllUser,
} from "../clientForm/services/services.clientform";
import InquiryArray from "./InquiryArray";
import { findAllActiveLeadType } from "../leadtype/services/leadType.services";
import { findAllActiveLeadStatus } from "../leadstatus/services/leadStatus.services";
import { GETALLACTIVEWITHFILTER_TYPE } from "../../_contstants/common";
import { findAllActiveInquiryType } from "../inquiryType/services/inquirytype.services";
import { findAllActiveInquirySubtype } from "../inquirySubType/services/inquireysubtype.services";
import { getLocalStorageItem } from "../../_helper/helper";
import FormIncrementalSearch from "../../components/formFields/FormIncrementalSearch";
//This Function form Create
function NBInquiryDetailEntityArray(
  inputArray: { [key: string]: string }[],
  srno: number
): any[] {
  const result: {
    [key: number]: {
      SrNo: number;
      InquiryTypeID: number;
      ProposedAmount: number | null;
      ClosingAmount: number | null;
    };
  } = {};
  inputArray.forEach((item) => {
    Object.entries(item).forEach(([key, value]) => {
      const [type, id] = key.split("_");
      const inquiryTypeID = Number(id);
      const amount = Number(value);
      // Ensure the result object for this ID exists
      if (!result[inquiryTypeID]) {
        result[inquiryTypeID] = {
          SrNo: srno,
          InquiryTypeID: inquiryTypeID,
          ProposedAmount: null,
          ClosingAmount: null,
        };
      }
      if (type === "P") result[inquiryTypeID].ProposedAmount = amount;
      if (type === "C") result[inquiryTypeID].ClosingAmount = amount;
    });
  });
  return Object.values(result);
}
//This Function form Edit
function NBInquiryDetailEntityArrayReverce(input: {
  InquiryTypeID: number[];
  ProposedAmount: number[];
  ClosingAmount: number[];
}): { [key: string]: string }[] {
  const result: { [key: string]: string } = {};

  input?.InquiryTypeID?.forEach((id, index) => {
    if (index < input?.ProposedAmount?.length) {
      result[`P_${id}`] = input?.ProposedAmount[index].toString();
    }
    if (index < input.ClosingAmount.length) {
      result[`C_${id}`] = input.ClosingAmount[index].toString();
    }
  });
  return [result]; // Wrap the result object in an array
}
interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}
const ClientChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const ClientList = useAppSelector((state) => state.client.ClientList);
  const ClientID = formRenderProps.valueGetter("LeadID");
  const LeadID = location?.state?.inquiryType?.LeadID;
  const FullName = location.state?.inquiryType?.FullName;
  const LeadStage = location.state?.inquiryType?.LeadStage;
  useEffect(() => {
    if (LeadID) {
      formRenderProps.onChange("LeadID", { value: LeadID });
      formRenderProps.onChange("LeadName", { value: FullName });
      formRenderProps.onChange("LeadStageID", {
        value: LeadStage === 1 ? "Existing" : "Prospect",
      });
    }
  }, [LeadID]);

  const clientOption = formRenderProps.valueGetter("client_options");
  useEffect(() => {
    if (ClientID) {
      dispatch(findClientsByID(ClientID));
    }
    if (ClientList.length && clientOption) {
      const ClientData = ClientList.find(
        (client: any) => client.ID === ClientID
      );
      const LeadStageID = ClientData?.LeadStage === 1 ? "Existing" : "Prospect";
      formRenderProps.onChange("LeadStageID", { value: LeadStageID ?? "" });
    }
  }, [ClientID]);

  return null;
};

const UserChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const location = useLocation();
  const UserID = getLocalStorageItem("UserID");
  const NBInquiryGUID = location.state?.NBInquiryGUID;
  useEffect(() => {
    if (!NBInquiryGUID) {
      formRenderProps.onChange("AllotmentID", {
        value: UserID ? +UserID : null,
      });
    }
  }, [UserID]);
  return null;
};
const CreateNBinquiry: React.FC = () => {
  const gridRef = useRef<any>(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const NBInquiryGUID = location.state?.NBInquiryGUID;
  const IsInquiryType = location.state?.inquiryType?.IsInquiryType;
  const NBinquiryDetail = useAppSelector(
    (state) => state.nbinquiry.NBinquiryDetail
  );
  const formLoading = useAppSelector((state) => state.nbinquiry.formLoading);
  const UserList = useAppSelector((state) => state.client.UserList);
  const clientLoading = useAppSelector((state) => state.client.clientLoading);
  const [formKey, setFormKey] = useState(1);
  const LeadName = location.state?.LeadName;
  useEffect(() => {
    dispatch(getAllUser());
    dispatch(findAllActiveLeadType());
    dispatch(
      findAllActiveLeadStatus({ OperationType: GETALLACTIVEWITHFILTER_TYPE })
    );
    dispatch(
      findAllActiveInquiryType({ OperationType: GETALLACTIVEWITHFILTER_TYPE })
    );
    dispatch(
      findAllActiveInquirySubtype({
        OperationType: GETALLACTIVEWITHFILTER_TYPE,
      })
    );
    return () => {
      dispatch(clearNBinquiryDetails());
    };
  }, []);

  useEffect(() => {
    if (NBInquiryGUID) {
      dispatch(getNBinquiryByGUID(NBInquiryGUID));
    }
  }, [NBInquiryGUID]);

  const NewNBInquiryDetails = {
    LeadName: LeadName ?? "",
    ...NBinquiryDetail,
    NBInquiryList: NBinquiryDetail?.NBInquiryList?.map((item: any) => ({
      ...item,
      NBInquiryDetailEntity: NBInquiryDetailEntityArrayReverce(
        item.NBInquiryDetailEntity || []
      ),
    })),
  };

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [NBinquiryDetail]);

  const handleSubmit = async (values: any) => {
    const formData = {
      LeadID: values.LeadID ?? null,
      LeadStageID: values.LeadStageID === "Existing" ? 1 : 2,
      AllotmentID: values.AllotmentID,
      FamilyID: values.LeadID,
      NBInquiryList: values.NBInquiryList?.map((item: any, index: number) => ({
        ID: +item.ID || 0,
        SrNo: index + 1,
        NBInquiryBy: item.NBInquiryBy ? +item.NBInquiryBy : null,
        InquiryDate: item.InquiryDate
          ? moment(item.InquiryDate).format("YYYY-MM-DD")
          : "",
        InquirySubTypeID: item.InquirySubTypeID?.join(",") ?? "",
        LeadTypeID: item.LeadTypeID ? +item.LeadTypeID : null,
        LeadStatusID: item.LeadStatusID ? +item.LeadStatusID : null,
        InquiryAllotmentID: item.InquiryAllotmentID
          ? +item.InquiryAllotmentID
          : null,
        CoPersonAllotmentID: item.CoPersonAllotmentID
          ? +item.CoPersonAllotmentID
          : null,
        Frequency: item.Frequency ?? "",
        NBInquiryDetailEntity: item.NBInquiryDetailEntity
          ? NBInquiryDetailEntityArray(item.NBInquiryDetailEntity, index + 1)
          : [],
      })),
    };
    if (NBInquiryGUID) {
      try {
        const editPayload = {
          NBInquiryGUID: NBInquiryGUID,
          ...formData,
        };
        const response = await dispatch(updateNBinquiry(editPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate(-1);
          dispatch(closed());
          dispatch(clearNBinquiryDetails());
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
      }
    } else {
      try {
        const response = await dispatch(createNBinquiry(formData));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate(-1);
          dispatch(closed());
          dispatch(clearNBinquiryDetails());
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    }
  };

  const handleFormClose = () => {
    dispatch(closed());
    navigate(-1);
  };
  const handleClientSearchChange = useCallback(
    async (search: string, field: string, formRenderProps: FormRenderProps) => {
      const payload = {
        LeadSearch: search,
        limit: 10,
        skip: 0,
      };
      const result = await dispatch(findAllActiveClient(payload));
      formRenderProps.onChange(field, {
        value: result.payload,
      });
    },
    [dispatch]
  );
  if (formLoading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      <Form
        onSubmit={handleSubmit}
        initialValues={NewNBInquiryDetails}
        key={formKey}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 20 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "33.33%" },
                  { width: "33.33%" },
                  { width: "33.33%" },
                ]}
              >
                <GridLayoutItem colSpan={3}>
                  <Typography.h4>
                    {NBInquiryGUID ? "Update NB Inquiry" : "Create NB Inquiry"}
                  </Typography.h4>
                </GridLayoutItem>
                {(NBInquiryGUID || IsInquiryType) && (
                  <GridLayoutItem>
                    <Field
                      name="LeadName"
                      label="Client"
                      disabled={NBInquiryGUID || IsInquiryType}
                      component={FormTextField}
                      validator={requiredValidator}
                      astrike={true}
                    />
                  </GridLayoutItem>
                )}
                {!NBInquiryGUID && !IsInquiryType && (
                  <GridLayoutItem>
                    <Field
                      name="LeadID"
                      label="Client"
                      placeholder="i.e.Jhon , D050"
                      component={FormIncrementalSearch}
                      disabled={NBInquiryGUID}
                      validator={requiredValidator}
                      astrike={true}
                      loading={clientLoading}
                      fetchIncrementalData={(search: any) =>
                        handleClientSearchChange(
                          search,
                          `client_options`,
                          formRenderProps
                        )
                      }
                      options={
                        formRenderProps
                          .valueGetter("client_options")
                          ?.map((client: any) => {
                            return {
                              value: client.ID,
                              label: `${client?.FirstName} ${client?.LastName} - ${client?.GroupCode}`,
                            };
                          }) || []
                      }
                    />
                  </GridLayoutItem>
                )}
                <GridLayoutItem>
                  <Field
                    name="LeadStageID"
                    label="Client Type"
                    placeholder="Client Type"
                    disabled
                    component={FormTextField}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>

                <GridLayoutItem>
                  <Field
                    name={`AllotmentID`}
                    label="Permanent Alloted To"
                    placeholder="Permanent Alloted To"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    astrike={true}
                    disabled={NBInquiryGUID}
                    options={UserList?.map((user: any) => {
                      return {
                        value: user?.ID,
                        label: `${user?.FirstName} ${user?.LastName}`,
                      };
                    })}
                  />
                </GridLayoutItem>

                <ClientChangeWatcher formRenderProps={formRenderProps} />
                <UserChangeWatcher formRenderProps={formRenderProps} />
              </GridLayout>
            </ShadowCard>
            <ShadowCard style={{ marginTop: 10 }}>
              <GridLayoutItem colSpan={7}>
                <Typography.h4>{"Inquiry Details"}</Typography.h4>
              </GridLayoutItem>
              <FieldArray
                formRenderProps={formRenderProps}
                component={InquiryArray}
                name="NBInquiryList"
              />
              <GridLayoutItem
                colSpan={3}
                style={{
                  textAlign: "end",
                  marginTop: "20px",
                  width: "100%",
                }}
              >
                <Button
                  type="button"
                  fillMode={"outline"}
                  themeColor={"primary"}
                  style={{ marginRight: 4 }}
                  onClick={handleFormClose}
                >
                  Cancel
                </Button>
                <ButtonWithLoading
                  label={NBInquiryGUID ? "Update" : "Save"}
                  type="submit"
                  disabled={!formRenderProps.allowSubmit || formLoading}
                  loading={formLoading}
                />
              </GridLayoutItem>
            </ShadowCard>
          </FormElement>
        )}
      />
    </>
  );
};

export default CreateNBinquiry;
