import { configureStore } from "@reduxjs/toolkit";
import { getDefaultMiddleware } from "@reduxjs/toolkit";
import dialogSlice from "../components/dialog/dialogSlice";
import loginReducer from "../features/login/loginSlice";
import countrySlice from "../features/country/countrySlice";
import userSlice from "../features/user/userSlice";
import drawerSlice from "../components/drawer/drawerSlice";
import menuSlice from "../features/menu/menuSlice";
import rightsSlice from "../features/rights/rightsSlice";
import userTypeSlice from "../features/userType/userTypeSlice";
import categorySlice from "../features/category/categorySlice";
import inquirytypeSlice from "../features/inquiryType/inquirytypeSlice";
import inquirysubtypeSlice from "../features/inquirySubType/inquirysubtypeSlice";
import relationSlice from "../features/relation/relationSlice";
import leadStatusSlice from "../features/leadstatus/leadStatusSlice";
import leadTypeSlice from "../features/leadtype/leadTypeSlice";
import leadsourceSlice from "../features/leadsource/leadsourceSlice";
import occupationtypeSlice from "../features/occupationType/occupationtypeSlice";
import paginationSlice from "../features/pagination/paginationSlice";
import clientformSlice from "../features/clientForm/clientformSlice";
import stateSlice from "../features/state/stateSlice";
import citySlice from "../features/city/citySlice";
import companySlice from "../features/company/companySlice";
import nbinquirySlice from "../features/NB Inquiry/nbinquirySlice";
import nbleadSlice from "../features/NBLead/nbleadSlice";
import taskSlice from "../features/Activity Logs/tasks/taskSlice";
import notesSlice from "../features/Activity Logs/notes/notesSlice";
// import callsSlice from "../features/Activity Logs/calls/callsSlice";
import meetingsSlice from "../features/Activity Logs/meetings/meetingsSlice";
import callsSlice from "../features/Activity Logs/calls/callsSlice";
import attachmentSlice from "../features/Activity Logs/attechment/attachmentSlice";
import recodingSlice from "../features/Activity Logs/recodings/recodingSlice";
import activityLogSlice from "../features/clientActivityTab/clientActivityTabSlice";
import DashboardSlice from "../features/dashboard/dashboardSlice";
import changePasswordSlice from "../features/ChangePassword/changePasswordSlice";
import planBrochureSlice from "../features/planBrochure/planBrochureSlice";
import BrochureAttachSlice from "../features/PlanBrochureAttachment/planBrochureAttachmentSlice";
import excelUploadSlice from "../features/excelUpload/excelUploadSlice";
import noActivityILSlice from "../features/noActivity/noActivitySlice";
import otherInvestorSlice from "../features/OtherInvestor/otherInvestorSlice";

const customizedMiddleware = () =>
  getDefaultMiddleware({
    serializableCheck: false,
  });

const store = configureStore({
  reducer: {
    login: loginReducer,
    password: changePasswordSlice,
    dashboard: DashboardSlice,
    dialog: dialogSlice,
    drawer: drawerSlice,
    state: stateSlice,
    country: countrySlice,
    city: citySlice,
    user: userSlice,
    menu: menuSlice,
    rights: rightsSlice,
    userType: userTypeSlice,
    category: categorySlice,
    inquiry: inquirytypeSlice,
    inquirysubtype: inquirysubtypeSlice,
    relation: relationSlice,
    leadStatus: leadStatusSlice,
    leadType: leadTypeSlice,
    leadsource: leadsourceSlice,
    occupationtype: occupationtypeSlice,
    company: companySlice,
    pagination: paginationSlice,
    client: clientformSlice,
    nbinquiry: nbinquirySlice,
    nblead: nbleadSlice,
    tasks: taskSlice,
    notes: notesSlice,
    calls: callsSlice,
    meetings: meetingsSlice,
    attachment: attachmentSlice,
    recoding: recodingSlice,
    activityLog: activityLogSlice,
    planBrochure: planBrochureSlice,
    brochureAttachment: BrochureAttachSlice,
    excelUpload: excelUploadSlice,
    noActivityIL: noActivityILSlice,
    otherinvestor: otherInvestorSlice,
  },
  middleware: customizedMiddleware,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
