import React, { useEffect, useRef } from "react";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  FieldArray,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { Typography } from "@progress/kendo-react-common";
import { STATUSARRAY } from "../../_contstants/common";
import { closed } from "../../components/drawer/drawerSlice";
import { findAllActiveCompany } from "../company/services/services.company";
import { clearPlanBrochureDetail, clearPlanList } from "./planBrochureSlice";
import {
  createPlanBrochure,
  FindAllCompanyIdByPlan,
  getAllPlanBrochure,
  getPlanBrochureByID,
  updatePlanBrochure,
} from "./services/planBrochure.services";
import ShadowCard from "../../components/common/ShadowCard";
import { useLocation, useNavigate } from "react-router-dom";
import BrochureAttachmentArray from "./BrochureAttachmentArray";
interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}

const CompanyChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const CompanyID = formRenderProps.valueGetter("CompanyID");
  const fistTime = useRef(false);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (CompanyID) {
      dispatch(FindAllCompanyIdByPlan(CompanyID));
      if (fistTime.current) {
        formRenderProps.onChange("PlanID", { value: null });
      } else {
        fistTime.current = true;
      }
    } else {
      formRenderProps.onChange("PlanID", { value: null });
      dispatch(clearPlanList());
    }
  }, [CompanyID]);

  return null;
};
const CreatePlanBrochure: React.FC = () => {
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);
  const PlanBrochureDetail = useAppSelector(
    (state) => state.planBrochure.PlanBrochureDetail
  );
  const navigate = useNavigate();
  const location = useLocation();
  const CompanyList = useAppSelector(
    (state) => state?.company?.CompanyTypeList
  );
  const PlanBrochureID = location?.state?.ID;
  const formLoading = useAppSelector(
    (state) => state?.planBrochure?.formLoading
  );
  const PlanList = useAppSelector((state) => state?.planBrochure?.PlanList);

  useEffect(() => {
    dispatch(findAllActiveCompany());
    return () => {
      dispatch(clearPlanBrochureDetail());
      dispatch(closed());
    };
  }, []);

  useEffect(() => {
    if (PlanBrochureID) {
      dispatch(getPlanBrochureByID(PlanBrochureID));
    }
  }, [PlanBrochureID]);

  const handleSubmit = async (values: any) => {
    const payload = {
      CompanyID: 0,
      PlanID: 0,
    };
    const formData = {
      CompanyID: values.CompanyID,
      PlanID: values.PlanID,
      IsActive: values.IsActive === 1,
    };
    try {
      if (!PlanBrochureID) {
        const response = await dispatch(createPlanBrochure(formData));
        if (response?.payload?.Status === 201) {
          const newPlanBrochureID = response?.payload?.Data?.PlanBrochureID;
          navigate("/planbrochure/edit", {
            state: { ID: newPlanBrochureID },
          });
          dispatch(closed());
        }
      } else {
        const editPayload = {
          ID: PlanBrochureID,
          ...formData,
        };
        const response = await dispatch(updatePlanBrochure(editPayload));
        if (response?.payload?.Status === 200) {
          navigate("/planbrochure");
          dispatch(closed());
          dispatch(clearPlanBrochureDetail());
          dispatch(getAllPlanBrochure(payload));
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };
  const handleFormClose = () => {
    dispatch(closed());
    navigate("/planbrochure");
  };

  if (formLoading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={PlanBrochureDetail}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement style={{ width: "100%" }}>
          <ShadowCard style={{ padding: 12 }}>
            <GridLayout
              style={{ marginRight: 20 }}
              gap={{ rows: 10, cols: 10 }}
              cols={[
                { width: "33.33%" },
                { width: "33.33%" },
                { width: "33.33%" },
              ]}
            >
              <GridLayoutItem colSpan={3}>
                <Typography.h4 className="m-0">
                  {PlanBrochureID
                    ? "Update Plan Brochure"
                    : "Create Plan Brochure"}
                </Typography.h4>
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  id={"CompanyID"}
                  name={"CompanyID"}
                  label={"Company Name"}
                  placeholder="Select Company"
                  component={FormSelectionField}
                  validator={requiredValidator} astrike={true}
                  options={CompanyList?.map((company: any) => {
                    return {
                      value: company?.ID,
                      label: company?.CompanyName,
                    };
                  })}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  id={"PlanID"}
                  name={"PlanID"}
                  label={"Plan Name"}
                  placeholder="Select Plan"
                  component={FormSelectionField}
                  validator={requiredValidator} astrike={true}
                  // disabled={true}
                  options={PlanList?.map((plan: any) => {
                    return {
                      value: plan?.ID,
                      label: plan?.PlanName,
                    };
                  })}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  id={"IsActive"}
                  name={"IsActive"}
                  label={"Status"}
                  placeholder="Status"
                  component={FormSelectionField}
                  validator={requiredValidator} astrike={true}
                  options={STATUSARRAY?.map((status: any) => {
                    return {
                      value: status.value,
                      label: status.label,
                    };
                  })}
                />
              </GridLayoutItem>
              <CompanyChangeWatcher formRenderProps={formRenderProps} />
            </GridLayout>
          </ShadowCard>
          {PlanBrochureID && (
            <ShadowCard style={{ marginTop: 10 }}>
              <GridLayoutItem colSpan={3}>
                <Typography.h4>Attachment Upload</Typography.h4>
              </GridLayoutItem>

              <FieldArray
                formRenderProps={formRenderProps}
                component={BrochureAttachmentArray}
                name="PlanBrochureAttachmentList"
              />
            </ShadowCard>
          )}
          <ShadowCard style={{ marginTop: 10 }}>
            <GridLayoutItem
              colSpan={3}
              style={{
                textAlign: "end",
                marginTop: 20,
                width: "100%",
              }}
            >
              <ButtonWithLoading
                label={PlanBrochureID ? "Update" : "Create"}
                type="submit"
                disabled={!formRenderProps.allowSubmit || formLoading}
                loading={formLoading}
              />
              <Button
                type="button"
                fillMode={"outline"}
                themeColor={"primary"}
                style={{ marginLeft: 4 }}
                onClick={handleFormClose}
              >
                Cancel
              </Button>
            </GridLayoutItem>
          </ShadowCard>
        </FormElement>
      )}
    />
  );
};

export default CreatePlanBrochure;
