import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMeetings, IMeetingsInitialState } from "./meetingsModel";
import {
  createMeetings,
  deleteMeetings,
  findAllMeetingStatus,
  findAllMeetingType,
  getAllMeetings,
  getMeetingsById,
  MangeMeetingsOutComes,
  updateMeetings,
} from "./services/services.meetings";

const initialState: IMeetingsInitialState = {
  loading: false,
  formLoading: false,
  error: "",
  MeetingsList: [],
  MeetingsID: null,
  MeetingGUID: "",
  SelectedTab: 0,
  MeetingsDetail: {
    LeadID: null,
    NBLeadTypeID: null,
    NBInquiryTypeID: null,
    MeetingTypeID: null,
    MeetingDate: "",
    StartTime: "",
    EndTime: "",
    Location: "",
    Purpose: "",
    Description: "",
    MeetingStatusID: null,
    MeetingOutcomeID: null,
    Attendee: "",
    FollowupDate: "",
    FollowupTime: "",
    FollowupNotes: "",
    IsFollowup: false,
    MeetingsAttachmentList: [],
    IsActive: 1,
  },
  MeetingsTypeList: [],
  MeetingsOutComeList: [],
  MeetingsStatusList: [],
};

export const meetingsSlice = createSlice({
  name: "meetings",
  initialState,
  reducers: {
    clearMeetingDetails: (state) => {
      state.MeetingsDetail = initialState.MeetingsDetail;
    },
    setMeetingsID: (state, action) => {
      state.MeetingsID = action.payload;
    },
    setSelectedTab: (state, action) => {
      state.SelectedTab = action.payload;
    },
    clearSelectedTab: (state) => {
      state.SelectedTab = initialState.SelectedTab;
    },
    clearMeetingsID: (state) => {
      state.MeetingsID = initialState.MeetingsID;
    },
    setMeetingGUID: (state, action) => {
      state.MeetingGUID = action.payload;
    },
    clearMeetingGUID: (state) => {
      state.MeetingGUID = initialState.MeetingGUID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllMeetings.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllMeetings.fulfilled,
      (state, action: PayloadAction<IMeetings[]>) => {
        state.loading = false;
        state.MeetingsList = action.payload;
      }
    );
    builder.addCase(getAllMeetings.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.MeetingsList = [];
    });

    builder.addCase(createMeetings.pending, (state) => {
      state.formLoading = false;
      state.error = "";
    });
    builder.addCase(createMeetings.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createMeetings.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(updateMeetings.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateMeetings.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateMeetings.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(deleteMeetings.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteMeetings.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteMeetings.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getMeetingsById.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getMeetingsById.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.MeetingsDetail = action.payload;
    });
    builder.addCase(getMeetingsById.rejected, (state, action) => {
      state.formLoading = false;
      state.MeetingsDetail = initialState.MeetingsDetail;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(findAllMeetingType.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(findAllMeetingType.fulfilled, (state, action: any) => {
      state.loading = false;
      state.MeetingsTypeList = action.payload;
    });
    builder.addCase(findAllMeetingType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.MeetingsTypeList = [];
    });

    builder.addCase(findAllMeetingStatus.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(findAllMeetingStatus.fulfilled, (state, action: any) => {
      state.loading = false;
      state.MeetingsStatusList = action.payload;
    });
    builder.addCase(findAllMeetingStatus.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.MeetingsStatusList = [];
    });

    builder.addCase(MangeMeetingsOutComes.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(MangeMeetingsOutComes.fulfilled, (state, action: any) => {
      state.loading = false;
      state.MeetingsOutComeList = action.payload;
    });
    builder.addCase(MangeMeetingsOutComes.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.MeetingsOutComeList = [];
    });
  },
});

export const {
  clearMeetingDetails,
  setMeetingsID,
  clearMeetingsID,
  setMeetingGUID,
  clearMeetingGUID,
  setSelectedTab,
  clearSelectedTab,
} = meetingsSlice.actions;
export default meetingsSlice.reducer;
