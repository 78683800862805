import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createLeadStatus,
  deleteLeadStatus,
  findAllActiveLeadStatus,
  getAllLeadStatus,
  getLeadStatusById,
  LeadStatusActiveInactive,
  updateLeadStatus,
} from "./services/leadStatus.services";
import { ILeadStatus, ILeadStatusInitialState } from "./leadStatusModel";

const initialState: ILeadStatusInitialState = {
  loading: false,
  formLoading: false,
  error: "",
  LeadStatusID: null,
  LeadStatusList: [],
  LeadStatusDetail: {
    LeadStatus: "",
    IsActive: 1,
  },
};

export const leadStatusSlice = createSlice({
  name: "leadStatus",
  initialState,
  reducers: {
    clearLeadStatusDetails: (state) => {
      state.LeadStatusDetail = initialState.LeadStatusDetail;
    },
    setLeadStatusID: (state, action) => {
      state.LeadStatusID = action.payload;
    },
    clearLeadStatusID: (state) => {
      state.LeadStatusID = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllLeadStatus.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllLeadStatus.fulfilled,
      (state, action: PayloadAction<ILeadStatus[]>) => {
        state.loading = false;
        state.LeadStatusList = action.payload;
      }
    );
    builder.addCase(getAllLeadStatus.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.LeadStatusList = [];
    });
    builder.addCase(findAllActiveLeadStatus.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      findAllActiveLeadStatus.fulfilled,
      (state, action: PayloadAction<ILeadStatus[]>) => {
        state.loading = false;
        state.LeadStatusList = action.payload || [];
      }
    );
    builder.addCase(findAllActiveLeadStatus.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.LeadStatusList = [];
    });
    builder.addCase(createLeadStatus.pending, (state) => {
      state.formLoading = false;
      state.error = "";
    });
    builder.addCase(createLeadStatus.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createLeadStatus.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateLeadStatus.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateLeadStatus.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateLeadStatus.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteLeadStatus.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteLeadStatus.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteLeadStatus.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getLeadStatusById.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getLeadStatusById.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.LeadStatusDetail = action.payload;
    });
    builder.addCase(getLeadStatusById.rejected, (state, action) => {
      state.formLoading = false;
      state.LeadStatusDetail = initialState.LeadStatusDetail;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(LeadStatusActiveInactive.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(LeadStatusActiveInactive.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(LeadStatusActiveInactive.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { clearLeadStatusDetails, setLeadStatusID, clearLeadStatusID } =
  leadStatusSlice.actions;
export default leadStatusSlice.reducer;
