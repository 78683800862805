import React from "react";
import { FieldRenderProps, FieldWrapper } from "@progress/kendo-react-form";
import { Error, Hint, Label } from "@progress/kendo-react-labels";
import { Upload } from "@progress/kendo-react-upload";

const FormFileUpload = (fieldRenderProps: FieldRenderProps) => {
  const {
    value,
    id,
    optional,
    label,
    hint,
    validationMessage,
    touched,
    multiple = false,
    astrike = false,
    ...others
  } = fieldRenderProps;

  const showValidationMessage: string | false | null =
    touched && validationMessage;
  const showHint: boolean = !showValidationMessage && hint;
  const hintId: string = showHint ? `${id}_hint` : "";
  const errorId: string = showValidationMessage ? `${id}_error` : "";
  const labelId: string = label ? `${id}_label` : "";

  const onChangeHandler = (event: any) => {
    fieldRenderProps.onChange({ value: event.newState });
  };
  const onRemoveHandler = (event: any) => {
    fieldRenderProps.onChange({ value: event.newState });
  };

  return (
    <FieldWrapper>
      <Label
        id={labelId}
        editorId={id}
        optional={optional}
        className="k-form-label field-label"
      >
        {label} {astrike && <span style={{ color: "red" }}>*</span>}
      </Label>
      <div className={"k-form-field-wrap"}>
        <Upload
          id={id}
          autoUpload={false}
          showActionButtons={false}
          multiple={multiple}
          files={Array.isArray(value) ? value : []}
          onAdd={onChangeHandler}
          onRemove={onRemoveHandler}
          ariaDescribedBy={`${hintId} ${errorId}`}
          ariaLabelledBy={labelId}
          {...others}
        />
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && (
          <Error id={errorId}>{validationMessage}</Error>
        )}
      </div>
    </FieldWrapper>
  );
};

export default FormFileUpload;
