import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IOtherInvestor, IOtherInvestorInitial } from "./otherInvestorModel";
import {
  createOtherInvestor,
  deleteOtherInvestor,
  findOtherInvestorByID,
  getAllOtherInvestor,
  updateOtherInvestor,
} from "./services/otherInvestor.services";

const initialState: IOtherInvestorInitial = {
  loading: false,
  formLoading: false,
  error: "",
  OtherInvestorList: [],
  InvestmentGUID: "",
  OtherInvestorDetail: {
    InvestmentGUID: "",
    CategoryID: null,
    InvestmentDetails: "",
    AllottedDate: "",
    FurtherAction: "",
    UserID: null,
    LeadID: null,
  },
};

export const otherInvestorSlice = createSlice({
  name: "otherinvestor",
  initialState,
  reducers: {
    clearOtherInvestorDetails: (state) => {
      state.OtherInvestorDetail = initialState.OtherInvestorDetail;
    },
    setInvestmentGUID: (state, action) => {
      state.InvestmentGUID = action.payload;
    },
    clearInvestmentGUID: (state) => {
      state.InvestmentGUID = initialState.InvestmentGUID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllOtherInvestor.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllOtherInvestor.fulfilled,
      (state, action: PayloadAction<IOtherInvestor[]>) => {
        state.loading = false;
        state.OtherInvestorList = action.payload;
      }
    );
    builder.addCase(getAllOtherInvestor.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.OtherInvestorList = initialState.OtherInvestorList;
    });

    builder.addCase(createOtherInvestor.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(createOtherInvestor.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createOtherInvestor.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(updateOtherInvestor.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateOtherInvestor.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateOtherInvestor.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(deleteOtherInvestor.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteOtherInvestor.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteOtherInvestor.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(findOtherInvestorByID.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(findOtherInvestorByID.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.OtherInvestorDetail = action.payload;
    });
    builder.addCase(findOtherInvestorByID.rejected, (state, action) => {
      state.formLoading = false;
      state.OtherInvestorDetail = initialState.OtherInvestorDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearInvestmentGUID,
  clearOtherInvestorDetails,
  setInvestmentGUID,
} = otherInvestorSlice.actions;
export default otherInvestorSlice.reducer;
