import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import {
  GridLayout,
  GridLayoutItem,
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from "@progress/kendo-react-layout";

import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  getAllCallPurpose,
  getAllCallResults,
  getAllCalls,
  getAllCallTypes,
} from "./services/calls.services";
import CallsDialog from "./CallsDialogs";
import { setSelectedTab } from "./callsSlice";


const CallActivityTab: React.FC = () => {
  const location = useLocation();
  const SelectedTab = useAppSelector((state) => state.calls.SelectedTab);
  const dispatch = useAppDispatch();
  const handleSelect = (e: TabStripSelectEventArguments) => {
    dispatch(setSelectedTab(e.selected));
  };
  const LeadID = location?.state?.ActivityLog?.LeadID;
  const NBInquiryTypeID = location?.state?.ActivityLog?.NBInquiryTypeID;
  const NBLeadTypeID = location.state?.ActivityLog?.NBLeadTypeID;

  useEffect(() => {
    dispatch(getAllCallPurpose({ OperationType: 5 }));
    dispatch(getAllCallResults({ OperationType: 5 }));
    dispatch(getAllCallTypes({ OperationType: 5 }));
  }, []);
  useEffect(() => {
    const payload = {
      LeadID: LeadID ?? null,
      NBInquiryTypeID: NBInquiryTypeID ?? null,
      NBLeadTypeID: NBLeadTypeID ?? null,
      CallStatus: SelectedTab === 0 ? "" : "Completed",
    };
    dispatch(getAllCalls(payload));
  }, [SelectedTab]);

  return (
    <>
      <GridLayoutItem
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          //   padding: "4px 20px 20px 0",
        }}
      >
        {/* <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
          Lead Activity Logs
        </Typography.h4> */}
      </GridLayoutItem>
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem>
          <TabStrip
            className="nb-inquiry-types-tab"
            selected={SelectedTab}
            onSelect={handleSelect}
          >
            <TabStripTab title={"Calls"}>
              <CallsDialog />
            </TabStripTab>
            <TabStripTab title="Closed Calls">
              <CallsDialog />
            </TabStripTab>
          </TabStrip>
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

export default CallActivityTab;
