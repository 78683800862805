import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { INBLeadDetails } from "../nbLeadModel";
import moment from "moment";
// import { INBLead } from "../nbLeadModel";

export const getAllBNLead = createAsyncThunk(
  "NBLead/NBLeadFindAll",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/NBLead/NBLeadFindAll`,
        {
          BNLead: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      console.error("Error Fetching BNLead:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createBNLead = createAsyncThunk(
  "NBLead/NBLeadInsert",
  async (BNLead: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/NBLead/NBLeadInsert`,
        BNLead
      );
      if (response.data?.Status === 201) {
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching BNLead:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateNBLead = createAsyncThunk(
  "NBLead/NBLeadUpdate",
  async (BNLead: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/NBLead/NBLeadUpdate`,
        BNLead
      );
      if (response.data?.Status === 200) {
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching BNLead:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getBNLeadById = createAsyncThunk(
  "NBLead/NBLeadFindByID",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/NBLead/NBLeadFindByID`,
        { NBLeadsGUID: ID }
      );
      const result = response.data?.Data as any;
      return {
        ID: result.ID,
        NBLeadsGUID: result.NBLeadsGUID,
        LeadID: result.LeadID,
        LeadAllotmentID: result.LeadAllotmentID,
        InquiryTypeID: result.InquiryTypeID
          ? result.InquiryTypeID.split(",").map(Number)
          : [],
        InquirySubTypeID: result.InquirySubTypeID
          ? result.InquirySubTypeID.split(",").map(Number)
          : [],
        LeadTypeID: result.LeadTypeID,
        CoPersonAllotmentID: result.CoPersonAllotmentID,
        NBLeadBy: result.NBLeadBy,
        LeadStatusID: result.LeadStatusID,
        ProposedAmount: result.ProposedAmount,
        ClosingAmount: result.ClosingAmount,
        Frequency: result.Frequency,
        LeadDate: result.LeadDate
          ? moment(result.LeadDate, "DD/MM/YYYY").toDate()
          : "",
        NBLeadDetailEntity: result.NBLeadDetailEntity,
      } as INBLeadDetails;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching BNLead:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteBNLead = createAsyncThunk(
  "NBLead/NBLeadDelete",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/NBLead/NBLeadDelete`,
        {
          NBLeadsGUID: ID,
        }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Deleting BNLead:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const findAllActiveBNLead = createAsyncThunk(
  "NBLead/NBLeadFindAllActive",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/NBLead/NBLeadFindAllActive`
      );
      // SuccessToast(response.data?.Details || "Success")
      return response.data.Data;
    } catch (error: any) {
      console.error("Error Deleting BNLead:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const BNLeadActiveInactive = createAsyncThunk(
  "NBLead/NBLeadActiveInActive",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/NBLead/NBLeadActiveInActive`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive BNLead:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const findNBLeadByLeadGUID = createAsyncThunk(
  "NBLead/NBLeadByLeadGUID",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/NBLead/NBLeadByLeadGUID`,
        { LeadGUID: ID }
      );
      response.data?.Data as any;
      return response.data?.Data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching NBinquiry:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
