import React, { useCallback } from "react";
import { Typography } from "@progress/kendo-react-common";
import Slider from "react-slick";
import { FcDocument } from "react-icons/fc";
import { SiMicrosoftexcel } from "react-icons/si";
import { FaRegFileWord, FaFilePdf } from "react-icons/fa";
import { IoCloudDownloadOutline, IoShareSocialOutline } from "react-icons/io5";
import { RWebShare } from "react-web-share";

// Define types for SlickSliderProps
interface SlickSliderProps {
  src: string;
  title: string;
  list: any; // Type for list array items
  icon?: boolean;
  download?: boolean;
  share?: boolean;
}

// Define the file extensions that are images
const imageFileExtensions = [
  "jpg",
  "jpeg",
  "png",
  "gif",
  "bmp",
  "tiff",
  "svg",
  "webp",
  "heic",
  "ico",
  "psd",
  "ai",
  "eps",
  "raw",
];

const SlickSlider: React.FC<SlickSliderProps> = ({
  list,
  src,
  title,
  download,
  share,
}) => {
  // Helper to determine icon based on file extension
  const checkIcon = useCallback((filename: string) => {
    const ext = filename.split(".").pop()?.toLowerCase();
    switch (ext) {
      case "xlsx":
      case "xls":
      case "xlsm":
      case "xltx":
      case "xltm":
      case "csv":
        return (
          <SiMicrosoftexcel
            style={{ fontSize: "50px", color: "green", margin: "auto" }}
          />
        );
      case "docx":
      case "doc":
      case "dotx":
      case "dotm":
      case "rtf":
      case "odt":
        return <FaRegFileWord style={{ fontSize: "50px", color: "blue" }} />;
      case "pdf":
        return (
          <FaFilePdf
            style={{ fontSize: "50px", color: "red", margin: "auto" }}
          />
        );
      default:
        return <FcDocument style={{ fontSize: "50px", margin: "auto" }} />;
    }
  }, []);

  // Function to open images or files in a new tab
  const handleOpenFile = (url: string) => {
    if (url) window.open(url, "_blank");
  };

  // const handleDownload = async (filePath: string, fileName: string) => {
  //   try {
  //     // ON HOLD I HAVE TO CHANGE THIS URL
  //     const response = await fetch(
  //       `https://cors-anywhere.herokuapp.com/${filePath}`
  //       //    filePath
  //     );
  //     const blob = await response.blob();
  //     const url = window.URL.createObjectURL(blob);

  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.download = fileName;
  //     document.body.appendChild(link);
  //     link.click();

  //     document.body.removeChild(link);
  //     window.URL.revokeObjectURL(url);
  //   } catch (error) {
  //     console.error("Download failed:", error);
  //   }
  // };



  const settings = {
    dots: false,
    infinite: true,
    autoplay: false,
    centerMode: true,
    centerPadding: "0px",
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    draggable: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // Render single item
  const renderItem = (item: { [key: string]: string }, index: number) => {
    const fileUrl = item[src];
    const fileName = item[title];
    const isImage = imageFileExtensions.includes(
      fileUrl.split(".").pop()?.toLowerCase() || ""
    );

    return (
      <div key={index} style={{ margin: "auto", textAlign: "center" }}>
        {isImage ? (
          <img
            src={fileUrl}
            alt={item[title] || "File"}
            style={{
              width: "50%",
              height: "auto",
              maxHeight: "200px",
              objectFit: "contain",
              margin: "auto",
            }}
            onClick={() => handleOpenFile(fileUrl)}
          />
        ) : (
          <div
            style={{ display: "flex" }}
            onClick={() => handleOpenFile(fileUrl)}
          >
            {checkIcon(fileUrl)}
          </div>
        )}

        <Typography.p style={{ textAlign: "center", marginTop: "10px" }}>
          {item[title]}
        </Typography.p>

        {(download || share) && (
          <div
            style={{
              width: "200px",
              margin: "auto",
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            {download && (
              <IoCloudDownloadOutline
                title="Download"
                style={{ fontSize: 24, cursor: "pointer" }}
                // onClick={() => handleDownload(fileUrl, fileName)}
                onClick={() => handleOpenFile(fileUrl)}
              />
            )}
            {share && (
              <RWebShare
                data={{
                  text: fileName,
                  url: fileUrl,
                  title: "Plan Brochure Attachment",
                }}
                //  onClick={() => SuccessToast("shared successfully!")}
              >
                <button
                  style={{
                    border: "none",
                    background: "none",
                  }}
                >
                  <IoShareSocialOutline
                    title="Share"
                    style={{ fontSize: 24, cursor: "pointer" }}
                    //onClick={() => handleCopyShare(fileUrl)}
                  />
                </button>
              </RWebShare>
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="slider-container myslickslider">
      {list.length > 1 ? (
        <Slider {...settings}>
          {list.map((item: any, index: number) => renderItem(item, index))}
        </Slider>
      ) : (
        list.map((item: any, index: number) => renderItem(item, index))
      )}
    </div>
  );
};

export default React.memo(SlickSlider);
