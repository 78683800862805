import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import {
  DELETE_TYPE,
  GETALL_TYPE,
  GETBYGUID_TYPE,
} from "../../../_contstants/common";
import { ILeadStatus } from "../leadStatusModel";

export const getAllLeadStatus = createAsyncThunk(
  "getAllLeadStatus/ManageLeadStatus",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Master/ManageLeadStatus`,
        {
          LeadStatus: "",
          OperationType: GETALL_TYPE,
        }
      );
      if (response.data?.Status === 200) {
        // SuccessToast(response.data?.Details || "Success");
        return response.data?.Data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      console.error("Error Fetching Lead Status:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createLeadStatus = createAsyncThunk(
  "createLeadStatus/ManageLeadStatus",
  async (LeadStatus: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Master/ManageLeadStatus`,
        LeadStatus
      );
      if (response.data.Status === 201) {
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Inserting Lead Status:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateLeadStatus = createAsyncThunk(
  "updateLeadStatus/ManageLeadStatus",
  async (LeadStatus: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Master/ManageLeadStatus`,
        LeadStatus
      );
      if (response.data?.Status === 200) {
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Updating Lead Status:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getLeadStatusById = createAsyncThunk(
  "getLeadStatusById/ManageLeadStatus",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Master/ManageLeadStatus`,
        {
          LeadStatusGUID: ID,
          OperationType: GETBYGUID_TYPE,
        }
      );
      const result = response.data?.Data[0] as any;
      return {
        ID: result?.ID,
        LeadStatusGUID: result?.LeadStatusGUID,
        LeadStatus: result?.LeadStatus,
        IsActive: result?.IsActive === true ? 1 : 2,
      } as ILeadStatus;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Lead Status:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteLeadStatus = createAsyncThunk(
  "deleteLeadStatus/ManageLeadStatus",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Master/ManageLeadStatus`,
        {
          LeadStatusGUID: ID,
          OperationType: DELETE_TYPE,
        }
      );
      if (response.data?.Status === 200) {
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Deleting Lead Status:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const findAllActiveLeadStatus = createAsyncThunk(
  "findAllActiveLeadStatus/ManageLeadStatus",
  async (LeadStatus: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Master/ManageLeadStatus`,
        LeadStatus
      );
      return response.data.Data;
    } catch (error: any) {
      console.error("Error Fetching Lead Status:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const LeadStatusActiveInactive = createAsyncThunk(
  "LeadStatusActiveInactive/ManageLeadStatus",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Master/ManageLeadStatus`,
        formData
      );
      if (response.data?.Status === 200) {
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive Lead Status:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
