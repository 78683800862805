import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
// import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";

export const getAllActivityLogs = createAsyncThunk(
  "ActivityLog/ActivityLogFindAll",
  async (formdata: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ActivityLog/ActivityLogFindAll`,
        formdata
      );
      return response.data?.Data;
    } catch (error: any) {
      console.error("Error Fetching Activity Logs:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
export const getAllClientActivityLogs = createAsyncThunk(
  "ActivityLog/NBLeadActivityLogFindAll",
  async (formdata: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ActivityLog/NBLeadActivityLogFindAll`,
        formdata
      );
      return response.data?.Data;
    } catch (error: any) {
      console.error("Error Fetching Client Activity Logs:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
