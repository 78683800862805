/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  GridLayout,
  GridLayoutItem,
  Card,
  CardTitle,
  CardBody,
  Menu,
} from "@progress/kendo-react-layout";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate } from "react-router-dom";
import { Typography } from "@progress/kendo-react-common";
import { Button } from "@progress/kendo-react-buttons";
import { filterIcon } from "@progress/kendo-svg-icons";
import ActivityTab from "./ActivityTab";
import { closeDialog } from "../../components/dialog/dialogSlice";
import {
  resetLogTab,
  setLogTab,
} from "../clientActivityTab/clientActivityTabSlice";
import { LoadingPanel } from "../../components/layout/Loading";
import { IoMail } from "react-icons/io5";
import { FaPhoneAlt, FaBirthdayCake } from "react-icons/fa";
import moment from "moment";

const ContactSidePan: React.FC = () => {
  const location = useLocation();
  const IsUserActivityLog = location.state?.IsUserActivityLog;
  const clientDetails = useAppSelector((state) => state.client?.ClientDetail);
  const UserDetails = useAppSelector((state) => state.user?.UserDetails);
  const commanUserDetails = IsUserActivityLog ? UserDetails : clientDetails;
  const gridref = useRef<any>(null);
  const loading = useAppSelector((state) => state.client?.formLoading);
  if (loading) return <LoadingPanel gridRef={gridref} />;
  return (
    <>
      {commanUserDetails.ID && (
        <Card style={{ marginBottom: "10px" }}>
          <CardBody style={{ padding: 10 }}>
            <CardTitle
              style={{
                fontWeight: "bold",
                marginBottom: "20px",
                textDecoration: "underline",
              }}
            >
              Personal Info
            </CardTitle>
            <GridLayout cols={[{ width: "100%" }, { width: "100%" }]}>
              <GridLayoutItem col={1} row={1}>
                <GridLayout
                  cols={[{ width: "100%" }, { width: "100%" }]}
                  style={{ padding: 4 }}
                >
                  <GridLayoutItem col={1} row={1}>
                    <div>
                      <Typography.h6
                        style={{
                          display: "inline-block",
                        }}
                        fontWeight={"bold"}
                        marginBottom={2}
                      >
                        {commanUserDetails &&
                          `${commanUserDetails?.FirstName} ${commanUserDetails?.LastName}`}
                      </Typography.h6>
                    </div>
                    {/* MObile No */}
                    {commanUserDetails && commanUserDetails?.MobileNo && (
                      <div>
                        <Typography.h6
                          variant="subtitle1"
                          fontWeight={"normal"}
                          marginBottom={2}
                          className="d-flex align-items-center gap-1"
                        >
                          <FaPhoneAlt />
                          {`${commanUserDetails?.MobileNo}`}
                        </Typography.h6>
                      </div>
                    )}
                    {/* EMAIL ID */}
                    {commanUserDetails && commanUserDetails?.EmailID && (
                      <div>
                        <Typography.h6
                          variant="subtitle1"
                          fontWeight={"bold"}
                          style={{ width: "fit-content" }}
                          marginBottom={2}
                          className="d-flex align-items-center gap-1"
                        >
                          {" "}
                          <IoMail />
                          {`${commanUserDetails?.EmailID}`}
                        </Typography.h6>
                      </div>
                    )}
                    {/* BirthDate*/}
                    {!IsUserActivityLog &&
                      commanUserDetails &&
                      clientDetails?.BirthDate && (
                        <div>
                          <Typography.h6
                            variant="subtitle1"
                            fontWeight={"bold"}
                            marginBottom={2}
                            className="d-flex align-items-center gap-2"
                          >
                            {" "}
                            <FaBirthdayCake />
                            {`${moment(clientDetails?.BirthDate).format(
                              "DD MMM YYYY"
                            )}`}
                          </Typography.h6>
                        </div>
                      )}
                  </GridLayoutItem>
                </GridLayout>
              </GridLayoutItem>
            </GridLayout>
          </CardBody>
        </Card>
      )}
    </>
  );
};

const LogHeader: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleContactsClick = () => {
    navigate(-1);
  };

  const handleFilterActivity = (e: any) => {
    const newValue = e.item.text;
    if (newValue) {
      dispatch(setLogTab(newValue));
    }
  };

  const MainFilterIconButton = () => {
    return (
      <Button
        fillMode={"solid"}
        themeColor={"primary"}
        type="button"
        svgIcon={filterIcon}
      />
    );
  };

  const filterItems: any[] = [
    {
      render: () => <MainFilterIconButton />,
      items: [
        { text: "ACTIVITY" },
        { text: "NOTE" },
        { text: "TASK" },
        { text: "CALL" },
        { text: "MEETING" },
        { text: "ATTACHMENT" },
        { text: "RECORDING" },
      ],
    },
  ];
  return (
    <>
      <div className="d-flex align-items-center justify-content-between px-1">
        <ArrowBackIcon
          onClick={handleContactsClick}
          style={{ cursor: "pointer" }}
        />
        <div className="d-flex align-items-center">
          <Menu
            items={filterItems}
            openOnClick={true}
            onSelect={handleFilterActivity}
            className="justify-content-end mr-5"
          />
        </div>
      </div>
    </>
  );
};

const ClientActivityLogs: React.FC = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    return () => {
      dispatch(resetLogTab());
      dispatch(closeDialog());
    };
  }, []);

  return (
    <>
      <LogHeader />
      <GridLayout
        style={{ padding: 8 }}
        cols={[{ width: "30%" }, { width: "70%" }]}
      >
        <GridLayoutItem
          col={1}
          row={1}
          style={{ height: "100%", padding: "0px 10px 0px 0px" }}
        >
          <ContactSidePan />
        </GridLayoutItem>
        <GridLayoutItem col={2} row={1}>
          <ActivityTab />
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

export default ClientActivityLogs;
