import React from "react";
import { Card } from "@progress/kendo-react-layout";

type orientation = "vertical" | "horizontal";

interface ShadowCardProps {
  style?: React.CSSProperties;
  orientation?: orientation;
  children: React.ReactNode;
}

const ShadowCard: React.FC<ShadowCardProps> = (props) => {
  return (
    <Card
      orientation={props.orientation}
      style={{
        boxShadow: "0 0 20px rgb(128 128 128 / 50%)",
        padding: 10,
        ...props.style,
      }}
    >
      {props.children}
    </Card>
  );
};
export default ShadowCard;
