import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { ICategory } from "../categoryModel";

export const getAllCategory = createAsyncThunk(
  "Category/CategoryFindAll",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Category/CategoryFindAll`,
        {
          Category: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      console.error("Error Fetching Category:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createCategory = createAsyncThunk(
  "Category/CategoryInsert",
  async (Category: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Category/CategoryInsert`,
        Category
      );
      if (response.data?.Status === 201) {
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Category:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateCategory = createAsyncThunk(
  "Category/CategoryUpdate",
  async (Category: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Category/CategoryUpdate`,
        Category
      );
      if (response.data?.Status === 200) {
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Category:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getCategoryById = createAsyncThunk(
  "Category/CategoryFindByID",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Category/CategoryFindByID`,
        {
          CategoryGUID: ID,
        }
      );
      const result = response.data?.Data as any;
      return {
        ID: result.ID,
        CategoryGUID: result.CategoryGUID,
        CategoryName: result.CategoryName,
        IsActive: result.IsActive === true ? 1 : 2,
      } as ICategory;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Category:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteCategory = createAsyncThunk(
  "Category/CategoryDelete",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Category/CategoryDelete`,
        {
          CategoryGUID: ID,
        }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Deleting Category:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const findAllActiveCategory = createAsyncThunk(
  "Category/CategoryFindAllActive",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Category/CategoryFindAllActive`
      );
      // SuccessToast(response.data?.Details || "Success")
      return response.data.Data;
    } catch (error: any) {
      console.error("Error Deleting Category:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const CategoryActiveInactive = createAsyncThunk(
  "Category/CategoryActiveInActive",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Category/CategoryActiveInActive`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive Category:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
