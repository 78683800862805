import React from "react";

interface IAlertProps {
  alert?: string;
  color?: string;
  backgroundColor?: string;
  borderColor?: string;
}

const AlertBox: React.FC<IAlertProps> = ({
  alert = "No data found.",
  color = "#ae3b3e",
  backgroundColor = "#F8D7DA",
  borderColor = "#F5C2C7",
}) => {
  return (
    <div
      style={{
        marginTop: 10,
        padding: 10,
        borderRadius: 5,
        color: `${color}`,
        backgroundColor: `${backgroundColor}`,
        border: `2px solid ${borderColor}`,
        display: "flex",
        alignItems: "center",
        gap: 5,
      }}
    >
      {alert}
    </div>
  );
};

export default AlertBox;
