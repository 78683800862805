import moment from "moment";
import { useEffect, useState } from "react";

export const checkAcessRights = (route: string, rightID: string) => {
  const userResponseString = localStorage.getItem("UserRightsAssign") || "[]";
  let userResponse: {
    MenuID: number;
    MenuKey: string;
    MenuName: string;
    RightsID: string;
  }[];

  try {
    userResponse = JSON.parse(userResponseString);
  } catch (error) {
    userResponse = [];
  }

  return userResponse
    ?.find((e: any) => e.MenuKey === route?.substring(1))
    ?.RightsID?.includes(rightID);
};

export const hasRights = (menuKey: string) => {
  const userResponseString = localStorage.getItem("UserRightsAssign") || "[]";
  let userResponse: {
    MenuID: number;
    MenuKey: string;
    MenuName: string;
    RightsID: string;
  }[];

  try {
    userResponse = JSON.parse(userResponseString);
  } catch (error) {
    userResponse = [];
  }

  const menu = userResponse.find((item) => item.MenuKey === menuKey);
  return menu && menu.RightsID;
};

export const getLocalStorageItem = (Name: string) => {
  return localStorage.getItem(Name);
};

export const getFilterFromToDate = (SelectedDate: number) => {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  const weekStart = new Date(today);
  weekStart.setDate(today.getDate() - today.getDay());
  const last30Days = new Date(today);
  last30Days.setDate(today.getDate() - 30);
  const monthStart = new Date(today.getFullYear(), today.getMonth(), 1);

  const formate = (date: Date) => {
    return moment(date).format("YYYY-MM-DD");
  };

  switch (SelectedDate) {
    case 1:
      return { FromDate: formate(today), ToDate: formate(today) };
    case 2:
      return {
        FromDate: formate(yesterday),
        ToDate: formate(yesterday),
      };
    case 3:
      return {
        FromDate: formate(weekStart),
        ToDate: formate(today),
      };
    case 4:
      return {
        FromDate: formate(last30Days),
        ToDate: formate(today),
      };
    case 5:
      return {
        FromDate: formate(monthStart),
        ToDate: formate(today),
      };
    default:
      return {
        FromDate: "",
        ToDate: "",
      };
  }
};
// Function for Responsive
export const useResponsiveJSX = (breakpoints: number[]): number => {
  const [index, setIndex] = useState<number>(0);

  useEffect(() => {
    const updateIndex = () => {
      const width = window.innerWidth;
      const newIndex = breakpoints.findIndex((bp) => width <= bp);
      setIndex(newIndex === -1 ? breakpoints.length : newIndex);
    };

    updateIndex();
    window.addEventListener("resize", updateIndex);
    return () => window.removeEventListener("resize", updateIndex);
  }, [breakpoints]);

  return index;
};


