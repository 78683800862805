import React, { useEffect } from "react";
import {
  GridLayout,
  GridLayoutItem,
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from "@progress/kendo-react-layout";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  clearClientActivityLogsList,
  // setSearchDetails,
  setSelectedILTab,
} from "../clientActivityTab/clientActivityTabSlice";
import ClientActivityLogs from "../clientForm/ClientActivityLogs";
import { getAllClientActivityLogs } from "../clientActivityTab/services/clientActivityTab.services";
import { Typography } from "@progress/kendo-react-common";

import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import { getAllActiveUsers, getUserByID } from "../user/services/user.services";
// import { Button } from "@progress/kendo-react-buttons";
import { findIndex } from "lodash";
import moment from "moment";
import { clearUserDetails } from "../user/userSlice";
import TabStripTitle from "../../components/common/TabStripTitle";
import { FiList } from "react-icons/fi";
import { MdLeaderboard } from "react-icons/md";
import { AiOutlineDeploymentUnit } from "react-icons/ai";
import { DateRangeFilter } from "../../_contstants/common";
import FormDatePicker from "../../components/formFields/FormDateField";
import { getFilterFromToDate } from "../../_helper/helper";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}
const UserChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const SelectedDate = formRenderProps.valueGetter("SelectedDate");
  const userID = formRenderProps.valueGetter("userID");
  const FromDate = formRenderProps.valueGetter("FromDate");
  const ToDate = formRenderProps.valueGetter("ToDate");
  const UserList = useAppSelector((state) => state.user.UserList);
  const dispatch = useAppDispatch();
  const SelectedILTab = useAppSelector(
    (state) => state.activityLog.SelectedILTab
  );
  const alignment = useAppSelector((state) => state.activityLog.alignment);
  useEffect(() => {
    if (userID) {
      const UserIndex = findIndex(UserList, { ID: userID });
      if (UserIndex > -1) {
        const User = UserList[UserIndex];
        dispatch(getUserByID(User?.ID));
      }
    }
  }, [userID]);
  useEffect(() => {
    if (SelectedDate !== 6 && userID && SelectedDate) {
      formRenderProps.onChange("FromDate", { value: null });
      formRenderProps.onChange("ToDate", { value: null });
      const filter = getFilterFromToDate(SelectedDate);
      const payload = {
        PastActivityFilters: alignment === "PAST" ? 1 : 0,
        CreatedBy: userID ?? null,
        NBActivityType:
          SelectedILTab === 1
            ? "Lead"
            : SelectedILTab === 2
            ? "All"
            : "Inquiry",
        ...filter,
      };
      dispatch(getAllClientActivityLogs(payload));
      // dispatch(setSearchDetails({ userID, SelectedDate, ...filter }));
    }
  }, [userID, SelectedDate, alignment, SelectedILTab]);

  useEffect(() => {
    if (userID && FromDate && ToDate) {
      const payload = {
        PastActivityFilters: alignment === "PAST" ? 1 : 0,
        CreatedBy: userID ?? null,
        FromDate: FromDate ? moment(FromDate).format("YYYY-MM-DD") : "",
        ToDate: ToDate ? moment(ToDate).format("YYYY-MM-DD") : "",
        NBActivityType:
          SelectedILTab === 1
            ? "Lead"
            : SelectedILTab === 2
            ? "All"
            : "Inquiry",
      };
      dispatch(getAllClientActivityLogs(payload));
      // dispatch(setSearchDetails({ userID, dateRange }));
    } else {
      dispatch(clearClientActivityLogsList());
    }
  }, [userID, alignment, SelectedILTab, FromDate, ToDate]);

  return null;
};

const UserActivityLogsTab: React.FC = () => {
  const SelectedILTab = useAppSelector(
    (state) => state.activityLog.SelectedILTab
  );

  const UserList = useAppSelector((state) => state.user.UserList);
  const SearchDetails = useAppSelector(
    (state) => state.activityLog.SearchDetails
  );
  const dispatch = useAppDispatch();
  const handleSelect = (e: TabStripSelectEventArguments) => {
    dispatch(setSelectedILTab(e.selected));
  };

  useEffect(() => {
    dispatch(getAllActiveUsers());
    return () => {
      dispatch(clearUserDetails());
      dispatch(clearClientActivityLogsList());
    };
  }, []);
  return (
    <>
      <GridLayout gap={{ rows: 10, cols: 20 }} cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            User Activity Log
          </Typography.h4>
        </GridLayoutItem>

        <GridLayoutItem>
          <Form
            // key={formKey}
            initialValues={SearchDetails}
            render={(formRenderProps: FormRenderProps) => (
              <FormElement>
                <GridLayout
                  style={{ marginRight: 40 }}
                  gap={{ rows: 20, cols: 20 }}
                  cols={[
                    { width: "33.33%" },
                    { width: "33.33%" },
                    { width: "33.33%" },
                  ]}
                >
                  <GridLayoutItem>
                    <Field
                      name={`userID`}
                      label="Users"
                      wrapperStyle={{ margin: 0 }}
                      placeholder="Select user"
                      component={FormSelectionField}
                      validator={requiredValidator} astrike={true}
                      options={UserList?.map((user: any) => {
                        return {
                          value: user?.ID,
                          label: `${user.FirstName} ${user.LastName}`,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem className="d-flex justify-content-start ">
                    <Field
                      wrapperStyle={{
                        margin: "0 0 0 10px",
                        width: 180,
                        flex: "none",
                      }}
                      name="SelectedDate"
                      label="Filter"
                      component={FormSelectionField}
                      size={"small"}
                      placeholder="Filter"
                      options={DateRangeFilter?.map((date: any) => {
                        return {
                          value: date.value,
                          label: date.label,
                        };
                      })}
                    />
                    {formRenderProps.valueGetter("SelectedDate") === 6 && (
                      <>
                        <Field
                          wrapperStyle={{
                            margin: "0 0 0 10px",
                            width: 150,
                            flex: "none",
                          }}
                          name="FromDate"
                          label="From Date"
                          component={FormDatePicker}
                          // size={"small"}
                          placeholder="From Date"
                        />
                        <Field
                          wrapperStyle={{
                            margin: "0 0 0 10px",
                            width: 150,
                            flex: "none",
                          }}
                          name="ToDate"
                          label="To Date"
                          component={FormDatePicker}
                          // size={"small"}
                          minDate={formRenderProps.valueGetter("FromDate")}
                          placeholder="To Date"
                        />
                      </>
                    )}
                  </GridLayoutItem>

                  <UserChangeWatcher formRenderProps={formRenderProps} />
                </GridLayout>
              </FormElement>
            )}
          />
        </GridLayoutItem>
        <GridLayoutItem style={{ marginTop: 10 }}>
          <TabStrip
            className="nb-inquiry-types-tab"
            selected={SelectedILTab}
            onSelect={handleSelect}
          >
            <TabStripTab
              title={<TabStripTitle title={"Inquiry"} icon={FiList} />}
            >
              <ClientActivityLogs />
            </TabStripTab>
            <TabStripTab
              title={<TabStripTitle title={"Lead"} icon={MdLeaderboard} />}
            >
              <ClientActivityLogs />
            </TabStripTab>
            <TabStripTab
              title={
                <TabStripTitle title={"All"} icon={AiOutlineDeploymentUnit} />
              }
            >
              <ClientActivityLogs />
            </TabStripTab>
          </TabStrip>
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

export default UserActivityLogsTab;
