import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";

export const createBrochureAttachment = createAsyncThunk(
  "BrochureAttachments/InsertBrochureAttachments",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/BrochureAttachments/InsertBrochureAttachments`,
        formData
      );
      if (response.data.Status === 200) {
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating  Brochure Attachment:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateBrochureAttachment = createAsyncThunk(
  "BrochureAttachments/UpdateBrochureAttachments",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/BrochureAttachments/UpdateBrochureAttachments`,
        formData
      );
      if (response.data.Status === 200) {
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating Brochure Attachment:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteBrochureAttachment = createAsyncThunk(
  "BrochureAttachments/BrochureAttachmentsDelete",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/BrochureAttachments/BrochureAttachmentsDelete`,
        { ID: ID }
      );
      if (response.data.Status === 200) {
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting  Brochure Attachment:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getBrochureAttachmentByPlanID = createAsyncThunk(
  "BrochureAttachments/BrochureAttachmentsFilter",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/BrochureAttachments/BrochureAttachmentsFilter`,
        { PlanBrochureID: ID }
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Plan Brochure:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
