import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";

export const createExcel = createAsyncThunk(
  "ExcelExport/ExcelExport",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ExcelExport/ExcelExport`,
        formData
      );
      return response.data;
    } catch (error: any) {
      console.error("Error create attachment:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
