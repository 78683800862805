import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { ICity } from "../cityModel";

export const getAllCities = createAsyncThunk("City/CityFindAll", async () => {
  try {
    const response = await axiosApiInstance.post(
      `${API_URL}/City/CityFindAll`,
      {
        CityName: "",
      }
    );
    return response.data?.Data;
  } catch (error: any) {
    // ErrorToast(error?.response?.data?.Details || "Something went wrong");
    console.error("Error Fetching Cities:", error);
    return Promise.reject({
      message: error?.response?.data?.Details || "Something went wrong",
    });
  }
});

export const createCity = createAsyncThunk(
  "City/CityInsert",
  async (CityData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/City/CityInsert`,
        CityData
      );
      if (response.data.Status === 201) {
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating city:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateCity = createAsyncThunk(
  "/City/CityUpdate",
  async (CityData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/City/CityUpdate`,
        CityData
      );
      if (response.data.Status === 200) {
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating city:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteCity = createAsyncThunk(
  "City/CityDelete",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/City/CityDelete`,
        { CityGUID: ID }
      );
      if (response.data.Status === 200) {
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting city:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getCityByID = createAsyncThunk(
  "City/CityFindByID",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/City/CityFindByID`,
        { CityGUID: ID }
      );
      const result = response.data?.Data as any;
      return {
        ID: result.ID,
        CityGUID: result.CityGUID,
        CountryID: result.CountryID,
        StateID: result.StateID,
        CityName: result.CityName,
        IsActive: result.IsActive === true ? 1 : 2,
      } as ICity;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching city:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveCities = createAsyncThunk(
  "City/CityFindAllActive",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/City/CityFindAllActive`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching cities:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const cityActiveInactive = createAsyncThunk(
  "City/CityActiveInActive",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/City/CityActiveInActive`,
        formData
      );
      if (response.data.Status === 200) {
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive city:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
