import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../../app/config";
import { axiosApiInstance } from "../../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../../components/toast/Toasts";
import moment from "moment";
import { ITasksDetail } from "../taskModel";

export const getAllTasks = createAsyncThunk(
  "Tasks/TasksFindAll",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Tasks/TasksFindAll`,
        formData
      );
      return response.data?.Data?.map((e: any) => ({
        ...e,
        IsReminder: e.IsReminder ? "True" : "False",
      }));
    } catch (error: any) {
      console.error("Error Fetching Tasks:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createTask = createAsyncThunk(
  "Tasks/TasksInsert",
  async (Tasks: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Tasks/TasksInsert`,
        Tasks
      );
      if (response.data?.Status === 201) {
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Tasks:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateTask = createAsyncThunk(
  "Tasks/TasksUpdate",
  async (Tasks: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Tasks/TasksUpdate`,
        Tasks
      );
      if (response.data?.Status === 200) {
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Tasks:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getTaskById = createAsyncThunk(
  "Tasks/TasksFindByID",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Tasks/TasksFindByID`,
        {
          TaskGUID: ID,
        }
      );
      const result = response.data?.Data;
      return {
        ID: result?.ID,
        TaskGUID: result?.TaskGUID,
        LeadID: result?.LeadID,
        NBLeadTypeID: result?.NBLeadTypeID,
        NBInquiryTypeID: result?.NBInquiryTypeID,
        TaskOwnerID: result?.TaskOwnerID,
        Subject: result?.Subject,
        StartDate: result?.StartDate
          ? moment(result?.StartDate, "DD/MM/YYYY").toDate()
          : "",
        DueDate: result?.DueDate
          ? moment(result?.DueDate, "DD/MM/YYYY").toDate()
          : "",
        TaskStatus: result?.TaskStatus,
        Priority: result?.Priority,
        Description: result?.Description,
        IsReminder: result?.IsReminder,
        ReminderDate: result?.ReminderDate
          ? moment(result?.ReminderDate, "DD/MM/YYYY").toDate()
          : "",
        ReminderTime: result?.ReminderTime
          ? moment(result?.ReminderTime, "HH:mm").toDate()
          : "",
        Repeat: result?.Repeat ?? 1,
        NotifyVia: result?.NotifyVia ?? 1,
        TasksAttachmentList: result?.TasksAttachmentList,
        IsActive: result?.IsActive === true ? 1 : 2,
      } as ITasksDetail;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Tasks:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteTask = createAsyncThunk(
  "Tasks/TasksDelete",
  async (ID: string) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Tasks/TasksDelete`,
        {
          TaskGUID: ID,
        }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Deleting Tasks:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
