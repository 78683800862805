import { createSlice } from "@reduxjs/toolkit";
import { IChangePasswordInitialState } from "./changePasswordModel";
import { changePassword } from "./services/changepassword.services";

const initialState: IChangePasswordInitialState = {
  loading: false,
  formLoading: false,
  error: "",
  ChangePasswordDetails: {
    ID: null,
    OldPassword: "",
    Password: "",
  },
};

export const changePasswordSlice = createSlice({
  name: "password",
  initialState,
  reducers: {
    clearChangePasswordDetails: (state) => {
      state.ChangePasswordDetails = initialState.ChangePasswordDetails;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(changePassword.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(changePassword.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(changePassword.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { clearChangePasswordDetails } = changePasswordSlice.actions;
export default changePasswordSlice.reducer;
