import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import {
  DELETE_TYPE,
  // GETALLACTIVEWITHFILTER_TYPE,
  GETALL_TYPE,
  GETBYGUID_TYPE,
} from "../../../_contstants/common";
import { IInquirySubtype } from "../inquirysubtypeModel";

export const getAllInquirySubtype = createAsyncThunk(
  "getAllInquirySubtype/ManageInquirySubtype",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Master/ManageInquirySubType`,
        {
          InquirySubtype: "",
          OperationType: GETALL_TYPE,
        }
      );
      if (response.data.Status === 200) {
        return response.data?.Data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      console.error("Error Fetching InquirySubtype:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createInquirySubtype = createAsyncThunk(
  "createInquirySubtype/ManageInquirySubtype",
  async (InquirySubtypes: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Master/ManageInquirySubType`,
        InquirySubtypes
      );
      if (response.data.Status === 201) {
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching InquirySubtype:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateInquirySubtype = createAsyncThunk(
  "updateInquirySubtype/ManageInquirySubtype",
  async (InquirySubtype: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Master/ManageInquirySubType`,
        InquirySubtype
      );
      if (response.data.Status === 200) {
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching InquirySubtype:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getInquirySubtypeById = createAsyncThunk(
  "getInquirySubtypeById/ManageInquirySubtype",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Master/ManageInquirySubType`,
        {
          InquirySubTypeGUID: ID,
          OperationType: GETBYGUID_TYPE,
        }
      );
      const result = response.data?.Data[0] as any;
      return {
        ID: result?.ID,
        InquirySubTypeGUID: result?.InquirySubTypeGUID,
        InquirySubType: result?.InquirySubType,
        InquiryTypeID: result?.InquiryTypeID,
        InquiryType: result?.InquiryType,
        IsActive: result?.IsActive === true ? 1 : 2,
      } as IInquirySubtype;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching InquirySubtype:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteInquirySubtype = createAsyncThunk(
  "deleteInquirySubtype/ManageInquirySubtype",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Master/ManageInquirySubType`,
        {
          InquirySubTypeGUID: ID,
          OperationType: DELETE_TYPE,
        }
      );
      if (response.data.Status === 200) {
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Deleting InquirySubtype:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const findAllActiveInquirySubtype = createAsyncThunk(
  "Master/ManageInquirySubtype",
  async (InquirySubtype: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Master/ManageInquirySubType`,
        InquirySubtype
      );
      return response.data.Data;
    } catch (error: any) {
      console.error("Error Fetching InquirySubtype:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const InquirySubtypeActiveInactive = createAsyncThunk(
  "InquirySubtypeActiveInactive/ManageInquirySubtype",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Master/ManageInquirySubType`,
        formData
      );
      if (response.data.Status === 200) {
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive InquirySubtype:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
