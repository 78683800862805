// import React from "react";
import { Routes, Route } from "react-router-dom";
import Layout from "../components/layout/Layout";
// import Login from "../features/login/Login";
// import { openDialog } from "../components/dialog/dialogSlice";
// import { useAppDispatch } from "../app/hooks";
import LogIn from "../features/login/Login";
import Country from "../features/country/Country";
import User from "../features/user/User";
import CreateUser from "../features/user/CreateUser";
import UserType from "../features/userType/UserType";
import CreateUserType from "../features/userType/CreateUserType";
import Rights from "../features/rights/Rights";
import CreateRights from "../features/rights/CreateRights";
import Menu from "../features/menu/Menu";
import CreateMenu from "../features/menu/CreateMenu";
import Category from "../features/category/Category";
import InquiryType from "../features/inquiryType/InquiryType";
import InquirySubtype from "../features/inquirySubType/InquirySubType";
import LeadSource from "../features/leadsource/LeadSource";
import Relation from "../features/relation/Relation";
import LeadStatus from "../features/leadstatus/LeadStatus";
import LeadType from "../features/leadtype/LeadType";
import OccupationType from "../features/occupationType/OccupationType";

import ClientForm from "../features/clientForm/ClientForm";
import CreateClientForm from "../features/clientForm/CreateClientForm";
import State from "../features/state/State";
import City from "../features/city/City";
import Company from "../features/company/Company";
import CreateCompany from "../features/company/CreateCompany";
import NBinquiry from "../features/NB Inquiry/NBinquiry";
import CreateNBinquiry from "../features/NB Inquiry/CreateNBinquiry";
import PrivateRoute from "./PrivateRoute";
import CreateBNInquiryTypeStatus from "../features/NB Inquiry/CreateLeadeTypeStatus";
import LeadList from "../features/clientForm/LeadList";
import CreateLead from "../features/clientForm/CreateLead";
import Dashboard from "../features/dashboard/Dashboard";
import LeadActivityLogs from "../features/clientForm/LeadActivityLogs";
import ClientActivityLogsTab from "../features/clientForm/ClientActivityLogsTab";
import CreateNBinquiryActivityLogs from "../features/NB Inquiry/CreateNBinquiryActivityLogs";
import CreateNBinquiryType from "../features/NB Inquiry/CreateNBinquiryType";
import DashboardInnerList from "../features/dashboard/DashboardInnerList";
import DashboardClientWisePropspect from "../features/dashboard/DashboardClientWiseProspect";
import UserActivityLogsTab from "../features/clientActivityTab/UserActivityLog";
import PlanBrochure from "../features/planBrochure/PlanBrochure";
import CreatePlanBrochure from "../features/planBrochure/CreatePlanBrochure";
import NoActivityInquiryLeadTab from "../features/noActivity/NoActivity";
import NBinquiryList from "../features/NB Inquiry/NBInquiryList";
import TasksUpcoming from "../features/dashboard/InnerView/TasksUpcoming";
import CallsUpcoming from "../features/dashboard/InnerView/CallsUpcoming";
import MeetingsUpcoming from "../features/dashboard/InnerView/MeetingsUpcoming";
import OtherInvestor from "../features/OtherInvestor/OtherInvestor";
import CreateOtherInvestor from "../features/OtherInvestor/CreateOtherInvestor";
import DashboardClientWiseInquiryAndLead from "../features/dashboard/DashboardClientWiseInquiryAndLead";
import DashboardInquiryClosingAmount from "../features/dashboard/InnerView/InquiryClosingAmount";
import DashboardLeadClosingAmount from "../features/dashboard/InnerView/LeadClosingAmount";

const MainRouter = () => {
  // const dispatch = useAppDispatch();
  return (
    <Routes>
      <Route path="login" element={<LogIn />} />
      <Route
        path="/"
        element={
          <PrivateRoute>
            <Layout />
          </PrivateRoute>
        }
      >
        <Route index={true} element={<Dashboard />} />
        <Route path="dashboard/:list" element={<DashboardInnerList />} />
        <Route path="dashboard/employeewiseprospectleadlist" element={<DashboardClientWisePropspect />}/>
        <Route path="dashboard/employeewiseinquiryandlead" element={<DashboardClientWiseInquiryAndLead />}/>
        <Route path="dashboard/inquiryclosingamount" element={<DashboardInquiryClosingAmount />}/>
        <Route path="dashboard/leadclosingamount" element={<DashboardLeadClosingAmount />}/>

        <Route path="dashboard/nbinquirylist" element={<NBinquiryList />}/>
        <Route path="dashboard/upcommingtasks" element={<TasksUpcoming />}/>
        <Route path="dashboard/upcommingcalls" element={<CallsUpcoming />}/>
        <Route path="dashboard/upcommingmeetings" element={<MeetingsUpcoming />}/>

        <Route path="country" element={<Country />} />
        <Route path="state" element={<State />} />
        <Route path="city" element={<City />} />
        <Route path="user" element={<User />} />
        <Route path="user/create" element={<CreateUser />} />
        <Route path="user/edit" element={<CreateUser />} />
        <Route path="usertype" element={<UserType />} />
        <Route path="usertype/create" element={<CreateUserType />} />
        <Route path="usertype/edit" element={<CreateUserType />} />
        <Route path="rights" element={<Rights />} />
        <Route path="rights/create" element={<CreateRights />} />
        <Route path="rights/edit" element={<CreateRights />} />
        <Route path="menu" element={<Menu />} />
        <Route path="menu/create" element={<CreateMenu />} />
        <Route path="menu/edit" element={<CreateMenu />} />
        <Route path="category" element={<Category />} />
        <Route path="inquirytype" element={<InquiryType />} />
        <Route path="inquirysubtype" element={<InquirySubtype />} />
        <Route path="leadsource" element={<LeadSource />} />
        <Route path="relation" element={<Relation />} />
        <Route path="leadstatus" element={<LeadStatus />} />
        <Route path="leadtype" element={<LeadType />} />
        <Route path="occupationtype" element={<OccupationType />} />
        <Route path="company" element={<Company />} />
        <Route path="company/create" element={<CreateCompany />} />
        <Route path="company/edit" element={<CreateCompany />} />
        
        <Route path="clientform" element={<ClientForm />} />
        <Route path="clientform/create" element={<CreateClientForm />} />
        <Route path="clientform/edit" element={<CreateClientForm />} />
        <Route path="clientform/Inquirylist" element={<CreateBNInquiryTypeStatus />}/>
        <Route path="clientform/Inquirylist/create" element={<CreateNBinquiry />}/>
        <Route path="clientform/Inquirylist/edit" element={<CreateNBinquiryType />}/>
        <Route path="clientform/leadlist" element={<LeadList />} />
        <Route path="clientform/leadlist/create" element={<CreateLead />}/>
        <Route path="clientform/leadlist/edit" element={<CreateLead />} />
        <Route path="clientform/clientactivitylog" element={<ClientActivityLogsTab />}/>
        <Route path="clientform/clientactivitylogs" element={<CreateNBinquiryActivityLogs />} />
        <Route path="otherinvestors" element={<OtherInvestor />} />
        <Route path="otherinvestors/create" element={<CreateOtherInvestor />} />
        <Route path="otherinvestors/edit" element={<CreateOtherInvestor />} />
     
        <Route path="clientform/leadactivitylogs" element={<LeadActivityLogs />}/>

        <Route path="nbinquiry" element={<NBinquiry />} />
        <Route path="nbinquiry/create" element={<CreateNBinquiry />} />
        <Route path="nbinquiry/edit" element={<CreateNBinquiry />} />
        <Route path="nbinquiry/Inquirylist" element={<CreateBNInquiryTypeStatus />}/>
       
        <Route path="nbinquiry/Inquirylist/edit" element={<CreateNBinquiryType />}/>
        <Route path="nbinquiry/Inquirylist/create" element={<CreateNBinquiryType />}/>
        <Route path="nbinquiry/nbinquiryactivitylogs" element={<CreateNBinquiryActivityLogs />}/>

        <Route path="planbrochure" element={<PlanBrochure />} />
        <Route path="planbrochure/create" element={<CreatePlanBrochure />} />
        <Route path="planbrochure/edit" element={<CreatePlanBrochure />} />
       
        <Route path="useractivitylog" element={<UserActivityLogsTab />} />
        <Route path="noactivity" element={<NoActivityInquiryLeadTab />} />
        <Route path="noactivity/inquiry/edit" element={<CreateNBinquiryType />}/>
        <Route path="noactivity/lead/edit" element={<CreateLead />} />
        {/* <Route path="country/create" element={<CreateCountry />} />
        <Route path="country/edit" element={<CreateCountry />} /> */}
        <Route path="*" element={<h1>404</h1>} />
      </Route>
    </Routes>
  );
};

export default MainRouter;
