import React, { useEffect } from "react";
import {
  GridLayout,
  GridLayoutItem,
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from "@progress/kendo-react-layout";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Typography } from "@progress/kendo-react-common";

import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
// import { requiredValidator } from "../../components/formFields/CommonValidator";
import moment from "moment";
import {
  clearNoActivityLeadInquiryList,
  setSearchDetails,
  setSelectedLeadInquiryLTab,
} from "./noActivitySlice";
import { findAllActiveInquiryType } from "../inquiryType/services/inquirytype.services";
import {
  DateRangeFilter,
  GETALLACTIVEWITHFILTER_TYPE,
} from "../../_contstants/common";
import FormMultiSelectionFiled from "../../components/formFields/FormMultiSelectionFiled";
import { getAllInquiryLeadNoActivity } from "./services/noActivity.services";
import NoActivityGrid from "./NoActivityGrid";
import { FiList } from "react-icons/fi";
import TabStripTitle from "../../components/common/TabStripTitle";
import { MdLeaderboard } from "react-icons/md";
import FormDatePicker from "../../components/formFields/FormDateField";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { getFilterFromToDate } from "../../_helper/helper";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}

const InquiryTypeChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const SelectedDate = formRenderProps.valueGetter("SelectedDate");
  const InquiryTypeID = formRenderProps.valueGetter("InquiryTypeID");
  const FromDate = formRenderProps.valueGetter("FromDate");
  const ToDate = formRenderProps.valueGetter("ToDate");

  const dispatch = useAppDispatch();
  const SelectedLeadInquiryTab = useAppSelector(
    (state) => state.noActivityIL.SelectedLeadInquiryTab
  );
  useEffect(() => {
    if (SelectedDate !== 6 && InquiryTypeID && SelectedDate) {
      formRenderProps.onChange("FromDate", { value: null });
      formRenderProps.onChange("ToDate", { value: null });
      const filter = getFilterFromToDate(SelectedDate);
      const payload = {
        InquiryTypeID: InquiryTypeID.join(","),
        InquirySearch: SelectedLeadInquiryTab === 0 ? 1 : 2,
        ...filter,
      };

      dispatch(getAllInquiryLeadNoActivity(payload));
      dispatch(setSearchDetails({ InquiryTypeID, SelectedDate, ...filter }));
    }
    if (!SelectedDate) {
      formRenderProps.onChange("FromDate", { value: null });
      formRenderProps.onChange("ToDate", { value: null });
      dispatch(clearNoActivityLeadInquiryList());
      dispatch(
        setSearchDetails({ InquiryTypeID, SelectedDate, FromDate, ToDate })
      );
    }
  }, [InquiryTypeID, SelectedDate, SelectedLeadInquiryTab]);
  useEffect(() => {
    if (InquiryTypeID && FromDate && ToDate) {
      const payload = {
        InquiryTypeID: InquiryTypeID.join(","),
        FromDate: FromDate ? moment(FromDate).format("YYYY-MM-DD") : "",
        ToDate: ToDate ? moment(ToDate).format("YYYY-MM-DD") : "",
        InquirySearch: SelectedLeadInquiryTab === 0 ? 1 : 2,
      };
      dispatch(getAllInquiryLeadNoActivity(payload));
      dispatch(
        setSearchDetails({ InquiryTypeID, SelectedDate, FromDate, ToDate })
      );
    }
  }, [InquiryTypeID, SelectedLeadInquiryTab, FromDate, ToDate]);

  return null;
};

const NoActivityInquiryLeadTab: React.FC = () => {
  const SelectedLeadInquiryTab = useAppSelector(
    (state) => state.noActivityIL.SelectedLeadInquiryTab
  );

  const InquiryTypeList = useAppSelector(
    (state) => state.inquiry.InquiryTypeList
  );
  const dispatch = useAppDispatch();
  const handleSelect = (e: TabStripSelectEventArguments) => {
    dispatch(setSelectedLeadInquiryLTab(e.selected));
  };
  const SearchDetails = useAppSelector(
    (state) => state.noActivityIL.SearchDetails
  );

  useEffect(() => {
    dispatch(
      findAllActiveInquiryType({ OperationType: GETALLACTIVEWITHFILTER_TYPE })
    );
    return () => {
      // dispatch(clearNoActivityLeadInquiryList());
    };
  }, []);

  return (
    <>
      <GridLayout gap={{ rows: 10, cols: 20 }} cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            No Activity
          </Typography.h4>
        </GridLayoutItem>

        <GridLayoutItem>
          <Form
            // key={formKey}
            initialValues={SearchDetails}
            render={(formRenderProps: FormRenderProps) => (
              <FormElement>
                <GridLayout
                  style={{ marginRight: 40 }}
                  gap={{ rows: 20, cols: 20 }}
                  cols={[
                    { width: "33.33%" },
                    { width: "33.33%" },
                    { width: "33.33%" },
                  ]}
                >
                  <GridLayoutItem>
                    <Field
                      name={`InquiryTypeID`}
                      label="Inquiry Type"
                      wrapperStyle={{ margin: 0 }}
                      placeholder="Select Inquiry Type"
                      component={FormMultiSelectionFiled}
                      options={InquiryTypeList?.map((inquiryType: any) => {
                        return {
                          value: inquiryType?.ID,
                          label: inquiryType.InquiryType,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem className="d-flex justify-content-start ">
                    <Field
                      wrapperStyle={{
                        margin: "0 0 0 10px",
                        width: 180,
                        flex: "none",
                      }}
                      name="SelectedDate"
                      label="Filter"
                      component={FormSelectionField}
                      size={"small"}
                      placeholder="Filter"
                      options={DateRangeFilter?.map((date: any) => {
                        return {
                          value: date.value,
                          label: date.label,
                        };
                      })}
                    />
                    {formRenderProps.valueGetter("SelectedDate") === 6 && (
                      <>
                        <Field
                          wrapperStyle={{
                            margin: "0 0 0 10px",
                            width: 150,
                            flex: "none",
                          }}
                          name="FromDate"
                          label="From Date"
                          component={FormDatePicker}
                          // size={"small"}
                          placeholder="From Date"
                        />
                        <Field
                          wrapperStyle={{
                            margin: "0 0 0 10px",
                            width: 150,
                            flex: "none",
                          }}
                          name="ToDate"
                          label="To Date"
                          component={FormDatePicker}
                          // size={"small"}
                          minDate={formRenderProps.valueGetter("FromDate")}
                          placeholder="To Date"
                        />
                      </>
                    )}
                  </GridLayoutItem>
                  <InquiryTypeChangeWatcher formRenderProps={formRenderProps} />
                </GridLayout>
              </FormElement>
            )}
          />
        </GridLayoutItem>

        <GridLayoutItem style={{ marginTop: 10 }}>
          <TabStrip
            className="nb-inquiry-types-tab"
            selected={SelectedLeadInquiryTab}
            onSelect={handleSelect}
          >
            <TabStripTab
              title={<TabStripTitle title={"Inquiry"} icon={FiList} />}
            >
              <NoActivityGrid />
            </TabStripTab>
            <TabStripTab
              title={<TabStripTitle title={"Lead"} icon={MdLeaderboard} />}
            >
              <NoActivityGrid />
            </TabStripTab>
          </TabStrip>
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

export default NoActivityInquiryLeadTab;
