import React, { useEffect, useRef } from "react";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { Typography } from "@progress/kendo-react-common";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { closeDialog } from "../../../components/dialog/dialogSlice";
import { RootState } from "../../../app/store";
import AppDialog from "../../../components/dialog/Dialog";
import FormTextField from "../../../components/formFields/FormTextField";
import { requiredValidator } from "../../../components/formFields/CommonValidator";
import FormTextArea from "../../../components/formFields/FormTextArea";
import FormSelectionField from "../../../components/formFields/FormSelectionField";
import { useLocation } from "react-router-dom";
import FormDatePicker from "../../../components/formFields/FormDateField";
import FormTimePicker from "../../../components/formFields/FormTimeField";
import FormCheckbox from "../../../components/formFields/FormCheckbox";
import moment from "moment";
import {
  createCall,
  getAllCalls,
  getCallById,
  updateCall,
} from "./services/calls.services";
import { clearCallsDetails } from "./callsSlice";
import { LoadingPanel } from "../../../components/layout/Loading";

const CloseLogDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const closeLogDialog = () => {
    dispatch(closeDialog());
  };
  return (
    <Button
      themeColor={"primary"}
      fillMode={"outline"}
      onClick={closeLogDialog}
    >
      Close
    </Button>
  );
};

const CreateCallsDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const CallGUID = useAppSelector((state) => state.calls.CallGUID);
  const CallsDetail = useAppSelector((state) => state.calls.CallsDetail);
  const formLoading = useAppSelector((state) => state.calls.formLoading);
  const SelectedTab = useAppSelector((state) => state.calls.SelectedTab);
  const gridRef = useRef<any>(null);
  const ManageCallPurposeList = useAppSelector(
    (state) => state.calls.ManageCallPurposeList
  );
  const ManageCallResultList = useAppSelector(
    (state) => state.calls.ManageCallResultList
  );
  const ManageCallTypeList = useAppSelector(
    (state) => state.calls.ManageCallTypeList
  );
  const dialogName = useAppSelector(
    (state: RootState) => state.dialog.dialogName
  );
  const NBInquiryTypeID = location?.state?.ActivityLog?.NBInquiryTypeID;
  const NBLeadTypeID = location.state?.ActivityLog?.NBLeadTypeID;
  const LeadID = location?.state?.ActivityLog?.LeadID;

  useEffect(() => {
    if (CallGUID) {
      dispatch(getCallById(CallGUID));
    }
  }, [CallGUID]);
  const handleSubmit = async (values: any) => {
    const payload = {
      LeadID: LeadID ?? null,
      NBInquiryTypeID: NBInquiryTypeID ?? null,
      NBLeadTypeID: NBLeadTypeID ?? null,
    };
    const formData = {
      ...payload,
      CallTypeID: values.CallTypeID ? +values.CallTypeID : null,
      CallStatus: values.CallStatus === "Scheduled" ? "Scheduled" : "Completed",
      CallDate: values.CallDate
        ? moment(values.CallDate).format("YYYY-MM-DD")
        : "",
      CallPurposeID: values.CallPurposeID ? +values.CallPurposeID : null,
      CallResultID: values.CallResultID ? +values.CallResultID : null,
      Subject: values.Subject,
      Agenda: values.Agenda,
      Description: values.Description,
      ...(values.IsFollowup && {
        FollowupDate: values.FollowupDate
          ? moment(values.FollowupDate).format("YYYY-MM-DD")
          : null,
        FollowupTime: values.FollowupTime
          ? moment(values.FollowupTime).format("HH:mm")
          : "",
        FollowupNotes: values.FollowupNotes || "",
      }),
      ...(values.CallStatus === "Scheduled" && {
        FollowupDate: null,
        FollowupTime: null,
        FollowupNotes: null,
      }),
      IsFollowup: values.CallStatus === "Scheduled" ? false : values.IsFollowup,
    };
    try {
      if (CallGUID) {
        const editPayload = {
          CallGUID: CallGUID,
          ...formData,
        };
        const response = await dispatch(updateCall(editPayload));

        if (response?.payload?.Status === 200) {
          dispatch(closeDialog());
          dispatch(clearCallsDetails());
          dispatch(
            getAllCalls({
              ...payload,
              CallStatus: SelectedTab === 0 ? "" : "Completed",
            })
          );
        }
      } else {
        const response = await dispatch(createCall(formData));
        if (response?.payload?.Status === 201) {
          dispatch(closeDialog());
          dispatch(clearCallsDetails());
          dispatch(
            getAllCalls({
              ...payload,
              CallStatus: SelectedTab === 0 ? "" : "Completed",
            })
          );
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };
  const ManageCallStatus = [
    { value: "Scheduled", label: "Scheduled" },
    { value: "Completed", label: "Completed" },
  ];
  if (formLoading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      {dialogName === "CALL" && (
        <AppDialog className="dialog-width">
          <Form
            onSubmit={handleSubmit}
            initialValues={CallsDetail}
            render={(formRenderProps: FormRenderProps) => (
              <FormElement>
                <Typography.h5 style={{ margin: 0, textAlign: "center" }}>
                  {CallGUID ? "Update Call" : "Create Call"}
                </Typography.h5>
                <GridLayout
                  gap={{ rows: 0, cols: 5 }}
                  cols={[
                    { width: "33.33%" },
                    { width: "33.33%" },
                    { width: "33.33%" },
                  ]}
                  style={{ marginRight: 10 }}
                >
                  <GridLayoutItem style={{ width: "100%" }}>
                    <Field
                      name="CallTypeID"
                      label="Call type"
                      placeholder="Select Type"
                      component={FormSelectionField}
                      validator={requiredValidator} astrike={true}
                      options={ManageCallTypeList?.map((calltype: any) => {
                        return {
                          value: calltype?.ID,
                          label: calltype?.CallType,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="CallStatus"
                      label="Outgoing Status"
                      component={FormSelectionField}
                      validator={requiredValidator} astrike={true}
                      options={ManageCallStatus?.map((call: any) => {
                        return {
                          value: call?.value,
                          label: call?.label,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="CallDate"
                      label="Call Date"
                      component={FormDatePicker}
                      validator={requiredValidator} astrike={true}
                      maxDate={moment().toDate()}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem style={{ width: "100%" }}>
                    <Field
                      name="CallPurposeID"
                      label="Call Purpose"
                      placeholder="Select Purpose"
                      component={FormSelectionField}
                      validator={requiredValidator} astrike={true}
                      options={ManageCallPurposeList?.map(
                        (callpurpose: any) => {
                          return {
                            value: callpurpose?.ID,
                            label: callpurpose?.CallPurpose,
                          };
                        }
                      )}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem style={{ width: "100%" }}>
                    <Field
                      name="CallResultID"
                      label="Call Result"
                      placeholder="Select Result"
                      component={FormSelectionField}
                      validator={requiredValidator} astrike={true}
                      options={ManageCallResultList?.map((callresult: any) => {
                        return {
                          value: callresult?.ID,
                          label: callresult?.CallResult,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="Subject"
                      label="Subject"
                      placeholder="Subject"
                      component={FormTextField}
                      validator={requiredValidator} astrike={true}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="Agenda"
                      label=" Call Agenda"
                      placeholder="Call Agenda"
                      component={FormTextField}
                      // validator={requiredValidator}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem colSpan={2} rowSpan={2}>
                    <Field
                      name="Description"
                      label="Description"
                      component={FormTextArea}
                      // validator={requiredValidator}
                    />
                  </GridLayoutItem>
                  {formRenderProps.valueGetter("CallStatus") ===
                    "Completed" && (
                    <GridLayoutItem colSpan={3}>
                      <Field
                        name="IsFollowup"
                        label="Is Follow-up"
                        component={FormCheckbox}
                      />
                    </GridLayoutItem>
                  )}

                  {formRenderProps.valueGetter("IsFollowup") &&
                    formRenderProps.valueGetter("CallStatus") ===
                      "Completed" && (
                      <>
                        <GridLayoutItem>
                          <Field
                            name="FollowupDate"
                            label="Follow-up Date"
                            component={FormDatePicker}
                            validator={requiredValidator} astrike={true}
                          />
                          <Field
                            name="FollowupTime"
                            label="Follow-up Time"
                            component={FormTimePicker}
                            validator={requiredValidator} astrike={true}
                          />
                        </GridLayoutItem>
                        <GridLayoutItem colSpan={2} rowSpan={2}>
                          <Field
                            name="FollowupNotes"
                            label="Follow-up Notes"
                            component={FormTextArea}
                            // validator={requiredValidator}
                          />
                        </GridLayoutItem>
                      </>
                    )}
                </GridLayout>
                <GridLayout
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "10px",
                  }}
                >
                  <Button
                    type="submit"
                    themeColor={"primary"}
                    fillMode={"solid"}
                    style={{ marginRight: "10px" }}
                    disabled={!formRenderProps.allowSubmit}
                  >
                    {CallGUID ? "Update" : "Save"}
                  </Button>
                  <CloseLogDialog />
                </GridLayout>
              </FormElement>
            )}
          />
        </AppDialog>
      )}
    </>
  );
};

export default CreateCallsDialog;
