import { createSlice } from "@reduxjs/toolkit";
import { createExcel } from "./services/excelUpload.services";

interface IExcelUploadInitialState {
  loading: boolean;
  formLoading: boolean;
  error: string;
}
const initialState: IExcelUploadInitialState = {
  loading: false,
  formLoading: false,
  error: "",
};

export const excelUploadSlice = createSlice({
  name: "excelUpload",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createExcel.pending, (state) => {
      state.formLoading = false;
      state.error = "";
    });
    builder.addCase(createExcel.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createExcel.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export default excelUploadSlice.reducer;
