import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { CompositeFilterDescriptor } from "@progress/kendo-data-query";
import {
  GridCellProps,
  GridCustomCellProps,
  GridCustomHeaderCellProps,
  Grid as KendoGrid,
  GridColumn as Column,
  GridFilterChangeEvent,
  GridPageChangeEvent,
} from "@progress/kendo-react-grid";
import { LoadingPanel } from "../../components/layout/Loading";
import AlertBox from "../../components/common/AlertBox";
import {
  HeaderThElement,
  PagerTargetEvent,
} from "@progress/kendo-react-data-tools";
import { filterBy } from "@progress/kendo-data-query";
import { FiEdit } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { Button } from "@progress/kendo-react-buttons";
import IconButton from "../../components/common/IconButton";
import { openDialog } from "../../components/dialog/dialogSlice";
import { setNBInquiryTypeID } from "./nbinquirySlice";
import {
  AiOutlineAudio,
  AiOutlineFile,
  AiOutlineFileDone,
  AiOutlinePaperClip,
  AiOutlinePhone,
  AiOutlineTeam,
} from "react-icons/ai";

const NBinquiryList: React.FC = () => {
  // const navigate = useNavigate();

  //   const handleNBinquiryCreate = () => {
  //     navigate("/nbinquiry/create");
  //   };
  return (
    <>
      {/* <DeleteNBinquiryDialog /> */}
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4px 20px 20px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            Inquiry List
          </Typography.h4>
          {/* <Button
              onClick={handleNBinquiryCreate}
              fillMode={"solid"}
              themeColor={"primary"}
            >
              Create NB Inquiry
            </Button> */}
        </GridLayoutItem>
        <GridLayoutItem>
          <NBinquiryListGrid />
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

import { findNBinquiryByID } from "./services/nbinquiry.services";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
interface PageState {
  skip: number;
  take: number;
}
const initialDataState: PageState = { skip: 0, take: 10 };
const NBinquiryListGrid: React.FC = () => {
  //   const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const LeadID = location.state?.InquiryStatus?.LeadID;
  const LeadGUID = location.state?.LeadGUID;
  const formloading = useAppSelector((state) => state.nbinquiry.formLoading);
  const NBinquiryList = useAppSelector(
    (state) => state?.nbinquiry?.NBinquiryList
  );
  const gridRef = useRef<any>(null);

  useEffect(() => {
    dispatch(findNBinquiryByID(LeadGUID));
  }, []);
  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);
  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
    setPage({ skip: 0, take: page.take });
  };
  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();

  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All" ? NBinquiryList.length : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };
  const InquiryActivityLogsCommandCell = (props: GridCellProps) => {
    const handleActivityLogs = ({
      LeadID,
      NBInquiryTypeID,
      NBLeadTypeID,
      ActivityLogType,
    }: any) => {
      navigate("/nbinquiry/nbinquiryactivitylogs", {
        state: {
          ActivityLog: {
            LeadID,
            NBInquiryTypeID,
            NBLeadTypeID,
            ActivityLogType,
          },
        },
      });
    };

    return (
      <td
        className={`action-td ${props.className}`}
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "5px",
          flexWrap: "wrap",
          ...props.style,
        }}
      >
        <Button
          themeColor={"warning"}
          type="button"
          fillMode="outline"
          size="small"
          title="Notes"
          onClick={() =>
            handleActivityLogs({
              LeadID: LeadID,
              NBInquiryTypeID: props.dataItem.ID,
              NBLeadTypeID: null,
              ActivityLogType: 0,
            })
          }
        >
          <AiOutlineFile />
        </Button>
        <Button
          themeColor={"inverse"}
          type="button"
          fillMode="outline"
          size="small"
          title="Tasks"
          onClick={() =>
            handleActivityLogs({
              LeadID: LeadID,
              NBInquiryTypeID: props.dataItem.ID,
              NBLeadTypeID: null,
              ActivityLogType: 1,
            })
          }
        >
          <AiOutlineFileDone />
        </Button>
        <Button
          themeColor={"secondary"}
          type="button"
          fillMode="outline"
          size="small"
          title="Calls"
          onClick={() =>
            handleActivityLogs({
              LeadID: LeadID,
              NBInquiryTypeID: props.dataItem.ID,
              NBLeadTypeID: null,
              ActivityLogType: 2,
            })
          }
        >
          <AiOutlinePhone />
        </Button>
        <Button
          themeColor={"success"}
          type="button"
          fillMode="outline"
          size="small"
          title="Meetings"
          onClick={() =>
            handleActivityLogs({
              LeadID: LeadID,
              NBInquiryTypeID: props.dataItem.ID,
              NBLeadTypeID: null,
              ActivityLogType: 3,
            })
          }
        >
          <AiOutlineTeam />
        </Button>
        <Button
          themeColor={"tertiary"}
          type="button"
          fillMode="outline"
          size="small"
          title="Attachments"
          onClick={() =>
            handleActivityLogs({
              LeadID: LeadID,
              NBInquiryTypeID: props.dataItem.ID,
              NBLeadTypeID: null,
              ActivityLogType: 4,
            })
          }
        >
          <AiOutlinePaperClip />
        </Button>
        <Button
          themeColor={"error"}
          type="button"
          fillMode="outline"
          size="small"
          title="Recording"
          onClick={() =>
            handleActivityLogs({
              LeadID: LeadID,
              NBInquiryTypeID: props.dataItem.ID,
              NBLeadTypeID: null,
              ActivityLogType: 5,
            })
          }
        >
          <AiOutlineAudio />
        </Button>
      </td>
    );
  };

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleEditInquiry = (inquiryType: any) => {
      navigate(`/nbinquiry/Inquirylist/edit`, {
        state: { inquiryType: inquiryType },
      });
    };
    const handleOpenDeleteDialog = (ID: number) => {
      dispatch(openDialog("deleteNBinquiryType"));
      dispatch(setNBInquiryTypeID(ID));
    };
    return (
      <td className={`action-td ${props.className}`} style={{ ...props.style }}>
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Edit"
          onClick={() =>
            handleEditInquiry({
              NBInquiryTypeGUID: props.dataItem.NBInquiryTypeGUID,
              LeadID: LeadID,
              NBInquiryBy: props.dataItem.NBInquiryBy,
              IsInquiryType: true,
            })
          }
        >
          <FiEdit className="absolute-position" style={{ fontSize: "20px" }} />
        </IconButton>
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Delete"
          onClick={() => handleOpenDeleteDialog(props.dataItem.ID)}
        >
          <MdDelete
            className="absolute-position"
            style={{ fontSize: "24px" }}
          />
        </IconButton>
      </td>
    );
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      style={props.thProps?.style}
      {...props.thProps}
      className={`table-header ${
        props.index === 9 ||
        props.index === 10 ||
        props.index === 0 ||
        props.index === 1
          ? "locked-header"
          : ""
      }`}
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td
        style={{ ...props.style }}
        {...props.tdProps}
        colSpan={1}
        className={`table-cell ${props.className}`}
      >
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  if (formloading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <>
      {NBinquiryList && NBinquiryList?.length ? (
        <KendoGrid
          style={{ maxHeight: "calc(100vh - 152px)" }}
          filterable={true}
          filter={filter}
          data={
            filter
              ? filterBy(NBinquiryList, filter).slice(
                  page.skip,
                  page.take + page.skip
                )
              : NBinquiryList.slice(page.skip, page.take + page.skip)
          }
          onFilterChange={handleFilterChange}
          skip={page.skip}
          take={page.take}
          total={NBinquiryList.length}
          pageable={{
            buttonCount: 5,
            pageSizes: [5, 10, 15, "All"],
            pageSizeValue: pageSizeValue,
            type: "input",
          }}
          onPageChange={pageChange}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
        >
          <Column field="InquiryNo" locked title="Inquiry No" width={110} />
          <Column field="InquiryDate" locked title="Inquiry Date" width={120} />
          <Column
            field="NBInquiryByName"
            title="Inquiry By Person"
            width={250}
          />
          <Column field="LeadType" title="Lead Type" width={150} />
          <Column field="InquiryType" title="Inquiry Type" width={150} />
          <Column field="InquirySubType" title="Inquiry Sub Type" width={150} />
          <Column
            field="InquiryAllotmentName"
            title="Alloted To Person"
            width={250}
          />
          <Column field="LeadStatus" title="Lead Status" width={150} />
          <Column field="Frequency" title="Frequency" width={150} />
          <Column
            field="ID"
            title="Activity Logs"
            cell={InquiryActivityLogsCommandCell}
            width={150}
            filterable={false}
            locked
          />
          <Column
            locked
            field="NBInquiryTypeGUID"
            title="Actions"
            cell={MyEditCommandCell}
            width={110}
            filterable={false}
          />
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

export default NBinquiryList;
