import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRelation, IRelationInitialState } from "./relationModel";
import {
  createRelation,
  deleteRelation,
  findAllActiveRelation,
  getAllRelation,
  getRelationById,
  RelationActiveInactive,
  updateRelation,
} from "./services/relation.services";

const initialState: IRelationInitialState = {
  loading: false,
  formLoading: false,
  error: "",
  RelationID: null,
  RelationList: [],
  RelationDetail: {
    RelationName: "",
    IsActive: 1,
  },
};

export const relationSlice = createSlice({
  name: "relation",
  initialState,
  reducers: {
    clearRelationDetails: (state) => {
      state.RelationDetail = initialState.RelationDetail;
    },
    setRelationID: (state, action) => {
      state.RelationID = action.payload;
    },
    clearRelationID: (state) => {
      state.RelationID = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllRelation.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllRelation.fulfilled,
      (state, action: PayloadAction<IRelation[]>) => {
        state.loading = false;
        state.RelationList = action.payload;
      }
    );
    builder.addCase(getAllRelation.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.RelationList = [];
    });
    builder.addCase(findAllActiveRelation.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      findAllActiveRelation.fulfilled,
      (state, action: PayloadAction<IRelation[]>) => {
        state.loading = false;
        state.RelationList = action.payload || [];
      }
    );
    builder.addCase(findAllActiveRelation.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.RelationList = [];
    });
    builder.addCase(createRelation.pending, (state) => {
      state.formLoading = false;
      state.error = "";
    });
    builder.addCase(createRelation.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createRelation.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateRelation.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateRelation.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateRelation.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteRelation.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteRelation.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteRelation.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getRelationById.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getRelationById.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.RelationDetail = action.payload;
    });
    builder.addCase(getRelationById.rejected, (state, action) => {
      state.formLoading = false;
      state.RelationDetail = initialState.RelationDetail;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(RelationActiveInactive.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(RelationActiveInactive.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(RelationActiveInactive.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { clearRelationDetails, setRelationID, clearRelationID } =
  relationSlice.actions;
export default relationSlice.reducer;
