import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import { CompositeFilterDescriptor } from "@progress/kendo-data-query";
import {
  GridCellProps,
  GridCustomCellProps,
  GridCustomHeaderCellProps,
  Grid as KendoGrid,
  GridColumn as Column,
  GridFilterChangeEvent,
  GridPageChangeEvent,
} from "@progress/kendo-react-grid";
import { LoadingPanel } from "../../components/layout/Loading";
import AlertBox from "../../components/common/AlertBox";
// import ButtonEdit from "../../components/drawer/ButtonEdit";
import {
  HeaderThElement,
  PagerTargetEvent,
} from "@progress/kendo-react-data-tools";
import { filterBy } from "@progress/kendo-data-query";
import AppDrawer from "../../components/drawer/Drawer";
// import { setNBInquiryTypeGUID } from "../NB Inquiry/nbinquirySlice";
// import { nbInquiryFindByLeadStatus } from "../NB Inquiry/services/nbinquiry.services";
import { Button } from "@progress/kendo-react-buttons";
import { findNBLeadByLeadGUID } from "../NBLead/services/nblead.services";
import CreateLead from "./CreateLead";
import IconButton from "../../components/common/IconButton";
import { FiEdit } from "react-icons/fi";
import { clearNBLeadDetails } from "../NBLead/nbleadSlice";
import {
  AiOutlineAudio,
  AiOutlineFile,
  AiOutlineFileDone,
  AiOutlinePaperClip,
  AiOutlinePhone,
  AiOutlineTeam,
} from "react-icons/ai";

import {
  // DELETE_ACCESS,
  EDIT_ACCESS,
  INSERT_ACCESS,
} from "../../_contstants/common";
import { checkAcessRights, useResponsiveJSX } from "../../_helper/helper";

const NBLeadList: React.FC = () => {
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const isDrawerOpen = useAppSelector((state) => state.drawer.open);
  const drawerName = useAppSelector((state) => state.drawer.name);
  const isAddAccess = checkAcessRights("/leadlist", INSERT_ACCESS);
  // const loading = useAppSelector((state) => state.client.loading);
  const LeadID = location?.state?.InquiryStatus?.ClientID;
  const LeadGUID = location?.state?.LeadGUID;
  const formLoading = useAppSelector((state) => state.nblead.formLoading);
  useEffect(() => {
    if (LeadGUID) {
      dispatch(findNBLeadByLeadGUID(LeadGUID));
    }
  }, [LeadGUID]);

  const handleCreateLead = () => {
    dispatch(clearNBLeadDetails());
    navigate("/clientform/leadlist/create", {
      state: { ClientID: LeadID },
    });
  };

  if (formLoading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      <GridLayoutItem
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "4px 20px 20px 0",
        }}
      >
        <>
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            Lead List
          </Typography.h4>
          {isAddAccess && (
            <Button
              onClick={handleCreateLead}
              fillMode={"solid"}
              themeColor={"primary"}
            >
              Create Lead
            </Button>
          )}
        </>
      </GridLayoutItem>
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem>
          <StatusTabel />
        </GridLayoutItem>
        {isDrawerOpen && drawerName === "editLeadList" && (
          <AppDrawer>
            <CreateLead />
          </AppDrawer>
        )}
      </GridLayout>
    </>
  );
};

interface PageState {
  skip: number;
  take: number;
}
const initialDataState: PageState = { skip: 0, take: 10 };
const StatusTabel: React.FC = () => {
  const navigate = useNavigate();
  const NBLeadList = useAppSelector((state) => state?.nblead?.NBLeadList);
  const isEditAccess = checkAcessRights("/leadlist", EDIT_ACCESS);
  const breakPoint = useResponsiveJSX([1024, 2170, 2514]);
  const dynamicWidth = [150, 200, 250, 300][breakPoint] ?? 300;
  // const isDeleteAccess = checkAcessRights("/leadlist", DELETE_ACCESS);
  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);
  const location = useLocation();
  const LeadID = location?.state?.InquiryStatus?.ClientID;
  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();

  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All" ? NBLeadList.length : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };
  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
    setPage({ skip: 0, take: page.take });
  };

  const LeadActivityLogsCommandCell = (props: GridCellProps) => {
    const handleActivityLogs = ({
      LeadID,
      NBInquiryTypeID,
      NBLeadTypeID,
      ActivityLogType,
    }: any) => {
      navigate("/clientform/leadactivitylogs", {
        state: {
          ActivityLog: {
            LeadID,
            NBInquiryTypeID,
            NBLeadTypeID,
            ActivityLogType,
          },
        },
      });
    };

    return (
      <td
        className={`action-td ${props.className}`}
        style={{
          ...props.style,
        }}
      >
        <Button
          themeColor={"warning"}
          type="button"
          style={{ margin: "2px" }}
          fillMode="outline"
          size="small"
          title="Notes"
          onClick={() =>
            handleActivityLogs({
              LeadID: LeadID,
              NBInquiryTypeID: null,
              NBLeadTypeID: props.dataItem.ID,
              ActivityLogType: 0,
            })
          }
        >
          <AiOutlineFile />
        </Button>
        <Button
          themeColor={"inverse"}
          style={{ margin: "2px" }}
          type="button"
          fillMode="outline"
          size="small"
          title="Tasks"
          onClick={() =>
            handleActivityLogs({
              LeadID: LeadID,
              NBInquiryTypeID: null,
              NBLeadTypeID: props.dataItem.ID,
              ActivityLogType: 1,
            })
          }
        >
          <AiOutlineFileDone />
        </Button>
        <Button
          themeColor={"secondary"}
          type="button"
          style={{ margin: "2px" }}
          fillMode="outline"
          size="small"
          title="Calls"
          onClick={() =>
            handleActivityLogs({
              LeadID: LeadID,
              NBInquiryTypeID: null,
              NBLeadTypeID: props.dataItem.ID,
              ActivityLogType: 2,
            })
          }
        >
          <AiOutlinePhone />
        </Button>
        <Button
          themeColor={"success"}
          type="button"
          fillMode="outline"
          style={{ margin: "2px" }}
          size="small"
          title="Meetings"
          onClick={() =>
            handleActivityLogs({
              LeadID: LeadID,
              NBInquiryTypeID: null,
              NBLeadTypeID: props.dataItem.ID,
              ActivityLogType: 3,
            })
          }
        >
          <AiOutlineTeam />
        </Button>
        <Button
          themeColor={"tertiary"}
          type="button"
          style={{ margin: "2px" }}
          fillMode="outline"
          size="small"
          title="Attachment"
          onClick={() =>
            handleActivityLogs({
              LeadID: LeadID,
              NBInquiryTypeID: null,
              NBLeadTypeID: props.dataItem.ID,
              ActivityLogType: 4,
            })
          }
        >
          <AiOutlinePaperClip />
        </Button>
        <Button
          themeColor={"error"}
          type="button"
          style={{ margin: "2px" }}
          fillMode="outline"
          size="small"
          title="Recording"
          onClick={() =>
            handleActivityLogs({
              LeadID: LeadID,
              NBInquiryTypeID: null,
              NBLeadTypeID: props.dataItem.ID,
              ActivityLogType: 5,
            })
          }
        >
          <AiOutlineAudio />
        </Button>
      </td>
    );
  };

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleEditItem = (NBLeadGUID: any, NBLeadBy: any) => {
      navigate("/clientform/leadlist/edit", {
        state: { NBLeadGUID: { NBLeadGUID, NBLeadBy } },
      });
    };
    return (
      <td className={`action-td ${props.className}`} style={{ ...props.style }}>
        {isEditAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Edit"
            onClick={() =>
              handleEditItem(
                props.dataItem.NBLeadsGUID,
                props.dataItem.NBLeadBy
              )
            }
          >
            <FiEdit
              className="absolute-position"
              style={{ fontSize: "20px" }}
            />
          </IconButton>
        )}
      </td>
    );
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      style={props.thProps?.style}
      className={`table-header ${
        props.index === 11 ||
        props.index === 12 ||
        props.index === 0 ||
        props.index === 1 ||
        props.index === 2
          ? "locked-header"
          : ""
      }`}
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td
        style={{ ...props.style }}
        {...props.tdProps}
        colSpan={1}
        className={`table-cell ${props.className}`}
      >
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  return (
    <>
      {NBLeadList && NBLeadList?.length ? (
        <KendoGrid
          style={{ maxHeight: "calc(100vh - 152px)" }}
          filterable={true}
          filter={filter}
          data={
            filter
              ? filterBy(NBLeadList, filter).slice(
                  page.skip,
                  page.take + page.skip
                )
              : NBLeadList.slice(page.skip, page.take + page.skip)
          }
          onPageChange={pageChange}
          onFilterChange={handleFilterChange}
          skip={page.skip}
          take={page.take}
          total={NBLeadList.length}
          pageable={{
            buttonCount: 5,
            pageSizes: [5, 10, 15, "All"],
            pageSizeValue: pageSizeValue,
            type: "input",
          }}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
        >
          <Column
            field="LeadNo"
            locked={true}
            width={dynamicWidth}
            title="No"
          />
          <Column
            field="LeadDate"
            locked={true}
            width={dynamicWidth}
            title="Date"
          />
          <Column
            field="NBLeadByName"
            width={dynamicWidth}
            locked={true}
            title="Lead Person"
          />
          <Column
            field="InquiryType"
            title="Inquiry Type"
            width={dynamicWidth}
          />
          <Column
            field="InquirySubType"
            width={dynamicWidth}
            title="Inquiry Sub Type"
          />
          <Column
            field="CoPersonAllotmentName"
            width={dynamicWidth}
            title="Co Person Allotted"
          />
          <Column field="Frequency" width={dynamicWidth} title="Frequency" />
          <Column field="LeadType" width={dynamicWidth} title="Lead Type" />
          <Column field="LeadStatus" title="Lead Status" width={dynamicWidth} />
          <Column
            field="LeadAllotmentName"
            width={dynamicWidth}
            title="Alloted By Person"
          />
          <Column
            field="CreatedByName"
            width={dynamicWidth}
            title="Alloted To Person"
          />

          <Column
            field="ID"
            title="Activity Logs"
            cell={LeadActivityLogsCommandCell}
            width={"150px"}
            locked={true}
            filterable={false}
          />
          {isEditAccess && (
            <Column
              field="NBLeadsGUID"
              width={"100px"}
              locked={true}
              title="Actions"
              cell={MyEditCommandCell}
              filterable={false}
            />
          )}
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

export default NBLeadList;
