import {
  // Field,
  FieldArray,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import React, { useEffect, useRef, useState } from "react";
import ShadowCard from "../../components/common/ShadowCard";
import {
  // GridLayout,
  GridLayoutItem,
} from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Button } from "@progress/kendo-react-buttons";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { closed } from "../../components/drawer/drawerSlice";
import { LoadingPanel } from "../../components/layout/Loading";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import {
  createNBinquiry,
  findClientsByID,
  getNBinquiryTypeByGUID,
  NBinquiryTypeUpdate,
} from "./services/nbinquiry.services";
import { clearNBinquiryDetails } from "./nbinquirySlice";
import InquiryArray from "./InquiryArray";
import { getAllUser } from "../clientForm/services/services.clientform";
import { findAllActiveLeadType } from "../leadtype/services/leadType.services";
import { findAllActiveLeadStatus } from "../leadstatus/services/leadStatus.services";
import { findAllActiveInquiryType } from "../inquiryType/services/inquirytype.services";
import { findAllActiveInquirySubtype } from "../inquirySubType/services/inquireysubtype.services";
import { GETALLACTIVEWITHFILTER_TYPE } from "../../_contstants/common";
//This Function form Create
function NBInquiryDetailEntityArray(
  inputArray: { [key: string]: string }[],
  srno: number
): any[] {
  const result: {
    [key: number]: {
      SrNo: number;
      InquiryTypeID: number;
      ProposedAmount: number | null;
      ClosingAmount: number | null;
    };
  } = {};
  inputArray.forEach((item) => {
    Object.entries(item).forEach(([key, value]) => {
      const [type, id] = key.split("_");
      const inquiryTypeID = Number(id);
      const amount = Number(value);
      // Ensure the result object for this ID exists
      if (!result[inquiryTypeID]) {
        result[inquiryTypeID] = {
          SrNo: srno,
          InquiryTypeID: inquiryTypeID,
          ProposedAmount: null,
          ClosingAmount: null,
        };
      }
      if (type === "P") result[inquiryTypeID].ProposedAmount = amount;
      if (type === "C") result[inquiryTypeID].ClosingAmount = amount;
    });
  });
  return Object.values(result);
}
//This Function form Edit
function NBInquiryDetailEntityArrayReverce(input: {
  InquiryTypeID: number[];
  ProposedAmount: number[];
  ClosingAmount: number[];
}): { [key: string]: string }[] {
  const result: { [key: string]: string } = {};

  input?.InquiryTypeID?.forEach((id, index) => {
    if (index < input?.ProposedAmount?.length) {
      result[`P_${id}`] = input?.ProposedAmount[index].toString();
    }
    if (index < input.ClosingAmount.length) {
      result[`C_${id}`] = input.ClosingAmount[index].toString();
    }
  });
  return [result]; // Wrap the result object in an array
}

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}
const ClientChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const location = useLocation();
  const ClientList = useAppSelector((state) => state.client.ClientList);
  const LeadID = location.state?.inquiryType?.LeadID;
  useEffect(() => {
    if (LeadID) {
      formRenderProps.onChange("LeadID", { value: LeadID });
    }
    if (ClientList.length) {
      const ClientData = ClientList.find((client: any) => client.ID === LeadID);
      formRenderProps.onChange("LeadStageID", {
        value: ClientData?.LeadStage === 1 ? "Existing" : "Prospect",
      });
    }
  }, [LeadID]);

  return null;
};

const CreateNBinquiryType: React.FC = () => {
  const gridRef = useRef<any>(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const NBInquiryTypeDetail = useAppSelector(
    (state) => state.nbinquiry.NBInquiryTypeDetail
  );
  const formLoading = useAppSelector((state) => state.nbinquiry.formLoading);
  const [formKey, setFormKey] = useState(1);
  const NBInquiryTypeGUID = location.state?.inquiryType?.NBInquiryTypeGUID;
  const LeadID = location.state?.inquiryType?.LeadID;
  const NBInquiryBy = location.state?.inquiryType?.NBInquiryBy;

  useEffect(() => {
    dispatch(findClientsByID(NBInquiryBy ?? LeadID));
    dispatch(getAllUser());
    dispatch(findAllActiveLeadType());
    dispatch(
      findAllActiveLeadStatus({ OperationType: GETALLACTIVEWITHFILTER_TYPE })
    );
    dispatch(
      findAllActiveInquiryType({ OperationType: GETALLACTIVEWITHFILTER_TYPE })
    );
    dispatch(
      findAllActiveInquirySubtype({
        OperationType: GETALLACTIVEWITHFILTER_TYPE,
      })
    );
    return () => {
      dispatch(clearNBinquiryDetails());
    };
  }, []);
  useEffect(() => {
    if (NBInquiryTypeGUID) {
      dispatch(getNBinquiryTypeByGUID(NBInquiryTypeGUID));
    }
  }, [NBInquiryTypeGUID]);

  const NewNBInquiryTypeDetails = {
    ...NBInquiryTypeDetail,
    NBInquiryList: NBInquiryTypeDetail?.NBInquiryList?.map((item: any) => ({
      ...item,
      NBInquiryDetailEntity: NBInquiryDetailEntityArrayReverce(
        item.NBInquiryDetailEntity || []
      ),
    })),
  };

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [NBInquiryTypeDetail]);

  const handleSubmit = async (values: any) => {
    const formData = values.NBInquiryList.map((item: any) => ({
      ID: +item?.ID ?? 0,
      SrNo: 1,
      NBInquiryBy: item?.NBInquiryBy ? +item?.NBInquiryBy : null,
      InquiryDate: item?.InquiryDate
        ? moment(item?.InquiryDate).format("YYYY-MM-DD")
        : "",
      InquirySubTypeID: item?.InquirySubTypeID?.join(",") ?? "",
      LeadTypeID: item?.LeadTypeID ? +item?.LeadTypeID : null,
      LeadStatusID: item?.LeadStatusID ? +item?.LeadStatusID : null,
      InquiryAllotmentID: item?.InquiryAllotmentID
        ? +item?.InquiryAllotmentID
        : null,
      CoPersonAllotmentID: item?.CoPersonAllotmentID
        ? +item?.CoPersonAllotmentID
        : null,
      Frequency: item?.Frequency ?? "",
      NBInquiryDetailReq: item?.NBInquiryDetailEntity
        ? NBInquiryDetailEntityArray(item?.NBInquiryDetailEntity, 1)
        : [],
    }))[0];
    if (NBInquiryTypeGUID) {
      try {
        const editPayload = {
          NBInquiryTypeGUID: NBInquiryTypeGUID,
          ...formData,
        };
        const response = await dispatch(NBinquiryTypeUpdate(editPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate(-1);
          dispatch(closed());
          dispatch(clearNBinquiryDetails());
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
      }
    } else {
      try {
        const insertPayload = {
          LeadID: values.LeadID ?? null,
          LeadStageID: values.LeadStageID === "Existing" ? 1 : 2,
          AllotmentID: values.AllotmentID,
          FamilyID: values.LeadID,
          NBInquiryList: values.NBInquiryList?.map(
            (item: any, index: number) =>
              ({
                ID: +item.ID ?? 0,
                SrNo: index + 1,
                NBInquiryBy: item.NBInquiryBy ? +item.NBInquiryBy : null,
                InquiryDate: item.InquiryDate
                  ? moment(item.InquiryDate).format("YYYY-MM-DD")
                  : "",
                InquirySubTypeID: item.InquirySubTypeID?.join(",") ?? "",
                LeadTypeID: item.LeadTypeID ? +item.LeadTypeID : null,
                LeadStatusID: item.LeadStatusID ? +item.LeadStatusID : null,
                InquiryAllotmentID: item.InquiryAllotmentID
                  ? +item.InquiryAllotmentID
                  : null,
                CoPersonAllotmentID: item.CoPersonAllotmentID
                  ? +item.CoPersonAllotmentID
                  : null,
                Frequency: item.Frequency ?? "",
                NBInquiryDetailEntity: item.NBInquiryDetailEntity
                  ? NBInquiryDetailEntityArray(
                      item.NBInquiryDetailEntity,
                      index + 1
                    )
                  : [],
              } ?? [])
          ),
        };
        const response = await dispatch(createNBinquiry(insertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate(-1);
          dispatch(closed());
          dispatch(clearNBinquiryDetails());
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    }
  };
  const handleFormClose = () => {
    dispatch(closed());
    navigate(-1);
  };
  if (formLoading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      <Form
        onSubmit={handleSubmit}
        initialValues={NewNBInquiryTypeDetails}
        key={formKey}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ marginTop: 10 }}>
              <GridLayoutItem colSpan={7}>
                <Typography.h4>
                  {NBInquiryTypeGUID ? "Update Inquiry" : "Create Inquiry"}
                </Typography.h4>
              </GridLayoutItem>
              <FieldArray
                formRenderProps={formRenderProps}
                component={InquiryArray}
                name="NBInquiryList"
              />

              <GridLayoutItem
                colSpan={3}
                style={{
                  textAlign: "end",
                  marginTop: "20px",
                  width: "100%",
                }}
              >
                <Button
                  type="button"
                  fillMode={"outline"}
                  themeColor={"primary"}
                  style={{ marginRight: 4 }}
                  onClick={handleFormClose}
                >
                  Cancel
                </Button>
                <ButtonWithLoading
                  label={NBInquiryTypeGUID ? "Update" : "Save"}
                  type="submit"
                  disabled={!formRenderProps.allowSubmit || formLoading}
                  loading={formLoading}
                />
              </GridLayoutItem>
              <ClientChangeWatcher formRenderProps={formRenderProps} />
            </ShadowCard>
          </FormElement>
        )}
      />
    </>
  );
};

export default CreateNBinquiryType;
