import React from "react";

import { Card } from "@progress/kendo-react-layout";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { tabChange } from "../clientActivityTab/clientActivityTabSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";

const ToggleTab: React.FC = () => {
  const alignment: string = useAppSelector(
    (state) => state.activityLog.alignment
  );
  const dispatch = useAppDispatch();
  const handleTabChange = (newAlignment: string) => {
    if (newAlignment && newAlignment !== alignment) {
      dispatch(tabChange(newAlignment));
    }
  };

  return (
    <Card
      style={{
        width: "fit-content",
        margin: "10px 0px",
        display: "flex",
        padding: "2px",
        border: "1px solid",
        borderRadius: "50px",
        flexWrap: "wrap",
        zIndex: 1,
      }}
    >
      <ButtonGroup>
        <Button
          fillMode={alignment === "UPCOMING" ? "solid" : "flat"}
          style={{ padding: "3px 15px", borderRadius: "15px" }}
          onClick={() => {
            handleTabChange("UPCOMING");
          }}
          togglable={true}
          selected={alignment === "UPCOMING"}
        >
          UPCOMING
        </Button>
        <Button
          fillMode={alignment === "PAST" ? "solid" : "flat"}
          rounded={"full"}
          style={{ padding: "3px 15px", borderRadius: "15px" }}
          onClick={() => {
            handleTabChange("PAST");
          }}
          togglable={true}
          selected={alignment === "PAST"}
        >
          PAST
        </Button>
      </ButtonGroup>
    </Card>
  );
};

export default ToggleTab;
