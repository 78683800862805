import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IOccupationType } from "../occupationtypeModel";

export const getAllOccupationType = createAsyncThunk(
  "Occupation/OccupationFindAll",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Occupation/OccupationFindAll`,
        {
          OccupationType: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      console.error("Error Fetching OccupationType:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createOccupationType = createAsyncThunk(
  "Occupation/OccupationInsert",
  async (OccupationType: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Occupation/OccupationInsert`,
        OccupationType
      );
      if (response.data?.Status === 201) {
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching OccupationType:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateOccupationType = createAsyncThunk(
  "Occupation/OccupationUpdate",
  async (OccupationType: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Occupation/OccupationUpdate`,
        OccupationType
      );
      if (response.data?.Status === 200) {
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching OccupationType:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getOccupationTypeById = createAsyncThunk(
  "Occupation/OccupationFindByID",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Occupation/OccupationFindByID`,
        {
          OccupationGUID: ID,
        }
      );
      const result = response.data?.Data as any;
      return {
        ID: result.ID,
        OccupationGUID: result.OccupationGUID,
        OccupationName: result.OccupationName,
        IsActive: result.IsActive === true ? 1 : 2,
      } as IOccupationType;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching OccupationType:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteOccupationType = createAsyncThunk(
  "Occupation/OccupationDelete",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Occupation/OccupationDelete`,
        {
          OccupationGUID: ID,
        }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Deleting OccupationType:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const findAllActiveOccupationType = createAsyncThunk(
  "Occupation/OccupationFindAllActive",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Occupation/OccupationFindAllActive`
      );
      // SuccessToast(response.data?.Details || "Success")
      return response.data.Data;
    } catch (error: any) {
      console.error("Error Deleting OccupationType:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const OccupationTypeActiveInactive = createAsyncThunk(
  "Occupation/OccupationActiveInActive",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Occupation/OccupationActiveInActive`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive OccupationType:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
