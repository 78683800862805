import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IInquiryInitialState, IInquiryType } from "./inquirytypeModel";
import {
  createInquiryType,
  deleteInquiryType,
  findAllActiveInquiryType,
  getAllInquiryType,
  getInquiryTypeById,
  InquiryTypeActiveInactive,
  updateInquiryType,
} from "./services/inquirytype.services";

const initialState: IInquiryInitialState = {
  loading: false,
  formLoading: false,
  error: "",
  InquiryTypeList: [],
  InquiryTypeID: null,
  InquiryTypeDetail: {
    InquiryTypeGUID: "",
    InquiryType: "",
    IsActive: 1,
  },
};

export const InquirySlice = createSlice({
  name: "inquiry",
  initialState,
  reducers: {
    clearInquiryTypeDetails: (state) => {
      state.InquiryTypeDetail = initialState.InquiryTypeDetail;
    },
    setInquiryTypeID: (state, action) => {
      state.InquiryTypeID = action.payload;
    },
    clearInquiryTypeID: (state) => {
      state.InquiryTypeID = null;
    },
    clearInquiryTypeList: (state) => {
      state.InquiryTypeList = [];
    },
  },
  extraReducers: (builder) => {
    /* For getAll Category */

    builder.addCase(getAllInquiryType.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllInquiryType.fulfilled,
      (state, action: PayloadAction<IInquiryType[]>) => {
        state.loading = false;
        state.InquiryTypeList = action.payload;
      }
    );
    builder.addCase(getAllInquiryType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.InquiryTypeList = [];
    });

    /* For FindAllActiveCategory */

    builder.addCase(findAllActiveInquiryType.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      findAllActiveInquiryType.fulfilled,
      (state, action: PayloadAction<IInquiryType[]>) => {
        state.loading = false;
        state.InquiryTypeList = action.payload || [];
      }
    );
    builder.addCase(findAllActiveInquiryType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.InquiryTypeList = [];
    });

    /* For createCategory */

    builder.addCase(createInquiryType.pending, (state) => {
      state.formLoading = false;
      state.error = "";
    });
    builder.addCase(createInquiryType.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createInquiryType.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    /* For updateCategory */

    builder.addCase(updateInquiryType.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateInquiryType.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateInquiryType.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    /* For DeleteCategory */

    builder.addCase(deleteInquiryType.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteInquiryType.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteInquiryType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    /* For ActiveInactiveCategory */

    builder.addCase(InquiryTypeActiveInactive.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(InquiryTypeActiveInactive.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(InquiryTypeActiveInactive.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    /* For CategoryById */

    builder.addCase(getInquiryTypeById.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getInquiryTypeById.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.InquiryTypeDetail = action.payload;
    });
    builder.addCase(getInquiryTypeById.rejected, (state, action) => {
      state.formLoading = false;
      state.InquiryTypeDetail = initialState.InquiryTypeDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearInquiryTypeDetails,
  setInquiryTypeID,
  clearInquiryTypeID,
  clearInquiryTypeList,
} = InquirySlice.actions;
export default InquirySlice.reducer;
