import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { INotes, INotesInitialState } from "./notesModel";
import {
  createNotes,
  deleteNotes,
  getAllNotes,
  getNotesById,
  InserAttachment,
  updateNotes,
} from "./services/services.notes";

const initialState: INotesInitialState = {
  loading: false,
  formLoading: false,
  error: "",
  NotesList: [],
  NotesID: null,
  NoteGUID: "",
  NotesDetail: {
    LeadID: null,
    NBLeadTypeID: null,
    NBInquiryTypeID: null,
    Title: "",
    Description: "",
    NotesAttachmentList: [],
  },
};

export const notesSlice = createSlice({
  name: "notes",
  initialState,
  reducers: {
    clearNotesDetail: (state) => {
      state.NotesDetail = initialState.NotesDetail;
    },
    setNotesID: (state, action) => {
      state.NotesID = action.payload;
    },
    clearNotesID: (state) => {
      state.NotesID = initialState.NotesID;
    },
    setNoteGUID: (state, action) => {
      state.NoteGUID = action.payload;
    },
    clearNoteGUID: (state) => {
      state.NoteGUID = initialState.NoteGUID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllNotes.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllNotes.fulfilled,
      (state, action: PayloadAction<INotes[]>) => {
        state.loading = false;
        state.NotesList = action.payload;
      }
    );
    builder.addCase(getAllNotes.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.NotesList = [];
    });

    builder.addCase(createNotes.pending, (state) => {
      state.formLoading = false;
      state.error = "";
    });
    builder.addCase(createNotes.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createNotes.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(updateNotes.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateNotes.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateNotes.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(deleteNotes.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteNotes.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteNotes.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getNotesById.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getNotesById.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.NotesDetail = action.payload;
    });
    builder.addCase(getNotesById.rejected, (state, action) => {
      state.formLoading = false;
      state.NotesDetail = initialState.NotesDetail;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(InserAttachment.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(InserAttachment.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(InserAttachment.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearNotesDetail,
  setNotesID,
  clearNotesID,
  setNoteGUID,
  clearNoteGUID,
} = notesSlice.actions;
export default notesSlice.reducer;
