import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { INBLead, INbLeadInitial } from "./nbLeadModel";
import {
  BNLeadActiveInactive,
  createBNLead,
  deleteBNLead,
  findAllActiveBNLead,
  findNBLeadByLeadGUID,
  getAllBNLead,
  getBNLeadById,
  updateNBLead,
} from "./services/nblead.services";

const initialState: INbLeadInitial = {
  loading: false,
  formLoading: false,
  error: "",
  NBLeadList: [],
  NBLeadID: null,
  NBLeadDetail: {
    LeadID: null,
    LeadAllotmentID: null,
    InquiryTypeID: null,
    InquirySubTypeID: null,
    LeadTypeID: null,
    NBLeadBy: null,
    LeadStatusID: null,
    CoPersonAllotmentID: null,
    ProposedAmount: "",
    ClosingAmount: "",
    Frequency: "",
    LeadDate: "",
    NBLeadDetailEntity: [],
  },
};

export const nbleadSlice = createSlice({
  name: "nbleadslice",
  initialState,
  reducers: {
    clearNBLeadDetails: (state) => {
      state.NBLeadDetail = initialState.NBLeadDetail;
    },
    setNBLeadID: (state, action) => {
      state.NBLeadID = action.payload;
    },
    clearNBLeadID: (state) => {
      state.NBLeadID = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllBNLead.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllBNLead.fulfilled,
      (state, action: PayloadAction<INBLead[]>) => {
        state.loading = false;
        state.NBLeadList = action.payload;
      }
    );
    builder.addCase(getAllBNLead.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.NBLeadList = [];
    });

    builder.addCase(findAllActiveBNLead.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      findAllActiveBNLead.fulfilled,
      (state, action: PayloadAction<INBLead[]>) => {
        state.loading = false;
        state.NBLeadList = action.payload || [];
      }
    );
    builder.addCase(findAllActiveBNLead.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.NBLeadList = [];
    });

    builder.addCase(createBNLead.pending, (state) => {
      state.formLoading = false;
      state.error = "";
    });
    builder.addCase(createBNLead.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createBNLead.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(updateNBLead.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateNBLead.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateNBLead.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(deleteBNLead.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteBNLead.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteBNLead.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(BNLeadActiveInactive.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(BNLeadActiveInactive.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(BNLeadActiveInactive.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getBNLeadById.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getBNLeadById.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.NBLeadDetail = action.payload;
    });
    builder.addCase(getBNLeadById.rejected, (state, action) => {
      state.formLoading = false;
      state.NBLeadDetail = initialState.NBLeadDetail;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(findNBLeadByLeadGUID.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(findNBLeadByLeadGUID.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.NBLeadList = action.payload;
    });
    builder.addCase(findNBLeadByLeadGUID.rejected, (state, action) => {
      state.formLoading = false;
      state.NBLeadList = initialState.NBLeadList;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { clearNBLeadDetails, setNBLeadID, clearNBLeadID } =
  nbleadSlice.actions;
export default nbleadSlice.reducer;
