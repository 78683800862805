import { Card, CardHeader } from "@progress/kendo-react-layout";
import React from "react";
import { IconType } from "react-icons";

interface DashBoardCardProps {
  title: string;
  cardColor: string;
  amount: any;
  icon: IconType;
}

const LeadStatusCard: React.FC<DashBoardCardProps> = ({
  icon: Icon,
  amount,
  cardColor,
  title,
}) => {
  return (
    <Card
      style={{
        width: "250px",
        borderRadius: "10px",
        border: "none",
       
      }}
    >
      <CardHeader style={{ border: "none", padding: 0 }}>
        <div style={{ display: "flex", alignItems: "center", gap: 15 }}>
          <div
            style={{
              backgroundColor: cardColor,
              borderRadius: "50%",
              padding: "10px",
              marginRight: "10px",
              color: "#fff",
             
            }}
          >
            <Icon size={40} />
          </div>
          <div>
            <h2 style={{ margin: 0, color: cardColor }}>{amount}</h2>
            <p style={{ margin: "5px 0 0", fontWeight: "bold" }}>{title}</p>
          </div>
        </div>
      </CardHeader>
    </Card>
  );
};

export default LeadStatusCard;
