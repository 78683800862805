import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICategory, ICategoryInitialState } from "./categoryModel";
import { CategoryActiveInactive, createCategory, deleteCategory, findAllActiveCategory, getAllCategory, getCategoryById, updateCategory } from "./services/category.services";


const initialState: ICategoryInitialState = {
  loading: false,
  formLoading: false,
  error: "",
  CategoryList: [],
  CategoryID: null,
  CategoryDetail: {
    CategoryGUID: "",
    CategoryName: "",
    IsActive: 1
  },
  InquiryTypeID: undefined
};

export const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    clearCategoryDetails: (state) => {
      state.CategoryDetail = initialState.CategoryDetail;
    },
    setCategoryID: (state, action) => {
      state.CategoryID = action.payload;
    },
    clearCategoryID: (state) => {
      state.CategoryID = null;
    },
  },
  extraReducers: (builder) => {
    /* For getAll Category */

    builder.addCase(getAllCategory.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllCategory.fulfilled,
      (state, action: PayloadAction<ICategory[]>) => {
        state.loading = false;
        state.CategoryList = action.payload;
      }
    );
    builder.addCase(getAllCategory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.CategoryList = [];
    });

    /* For FindAllActiveCategory */

    builder.addCase(findAllActiveCategory.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      findAllActiveCategory.fulfilled,
      (state, action: PayloadAction<ICategory[]>) => {
        state.loading = false;
        state.CategoryList = action.payload || [];
      }
    );
    builder.addCase(findAllActiveCategory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.CategoryList = [];
    });

    /* For createCategory */

    builder.addCase(createCategory.pending, (state) => {
      state.formLoading = false;
      state.error = "";
    });
    builder.addCase(createCategory.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createCategory.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    /* For updateCategory */

    builder.addCase(updateCategory.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateCategory.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateCategory.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    /* For DeleteCategory */

    builder.addCase(deleteCategory.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteCategory.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteCategory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    /* For ActiveInactiveCategory */

    builder.addCase(CategoryActiveInactive.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(CategoryActiveInactive.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(CategoryActiveInactive.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    /* For CategoryById */

    builder.addCase(getCategoryById.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getCategoryById.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.CategoryDetail = action.payload;
    });
    builder.addCase(getCategoryById.rejected, (state, action) => {
      state.formLoading = false;
      state.CategoryDetail = initialState.CategoryDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearCategoryDetails,
  setCategoryID,
  clearCategoryID,
} = categorySlice.actions;
export default categorySlice.reducer;
