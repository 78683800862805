/* eslint-disable react-hooks/exhaustive-deps */
import { Alert } from "@mui/material";
import React, { useRef } from "react";
import { useAppSelector } from "../../app/hooks";
import {
  Card,
  CardBody,
  CardTitle,
  GridLayout,
  GridLayoutItem,
} from "@progress/kendo-react-layout";

import { LoadingPanel } from "../../components/layout/Loading";
import ToggleTab from "./ToggleTab";
import {
  FaRegStickyNote,
  FaTasks,
  FaMicrophoneAlt,
  FaPhoneAlt,
  FaPaperclip,
  FaUsers,
} from "react-icons/fa";
import ActivityLogCard from "../../components/common/ActivityLogCard";
const ActivityTab: React.FC = () => {
  const alignment = useAppSelector((state) => state.activityLog.alignment);
  const gridRef = useRef<any>(null);
  const loading = useAppSelector((state) => state.activityLog.loading);
  const ClientActivityLogsList = useAppSelector(
    (state) => state.activityLog.ClientActivityLogsList
  );
  const currentTab = useAppSelector((state) => state.activityLog.currentTab);
  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <GridLayout style={{ width: "100%" }}>
      <GridLayout
        style={{
          marginTop: 8,
          marginBottom: 8,
        }}
      >
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <ToggleTab />
        </GridLayoutItem>
      </GridLayout>
      <Card>
        <CardBody>
          <CardTitle style={{ fontWeight: "bold", marginBottom: "25px" }}>
            {alignment !== "UPCOMING" ? "Past" : "Upcoming"}
          </CardTitle>
          {ClientActivityLogsList && ClientActivityLogsList.length > 0 ? (
            ClientActivityLogsList.map((Activity: any) => (
              <>
                {Activity.ActivityLog === "CallLog" &&
                  (currentTab === "CALL" || currentTab === "ACTIVITY") && (
                    <ActivityLogCard
                      activity={Activity}
                      icon={FaPhoneAlt}
                      activityType="Call"
                      color="#124e78"
                    />
                  )}
                {Activity.ActivityLog === "TaskLog" &&
                  (currentTab === "TASK" || currentTab === "ACTIVITY") && (
                    <ActivityLogCard
                      activity={Activity}
                      icon={FaTasks}
                      activityType="Task"
                      color="#ff7f11"
                    />
                  )}
                {Activity.ActivityLog === "MeetingLog" &&
                  (currentTab === "MEETING" || currentTab === "ACTIVITY") && (
                    <ActivityLogCard
                      activity={Activity}
                      icon={FaUsers}
                      activityType="Meeting"
                      color="#f2bb05"
                    />
                  )}
                {Activity.ActivityLog === "NoteLog" &&
                  (currentTab === "NOTE" || currentTab === "ACTIVITY") && (
                    <ActivityLogCard
                      activity={Activity}
                      icon={FaRegStickyNote}
                      activityType="Note"
                      color="#d74e09"
                    />
                  )}
                {Activity.ActivityLog === "AttachmentLog" &&
                  (currentTab === "ATTACHMENT" ||
                    currentTab === "ACTIVITY") && (
                    <ActivityLogCard
                      activity={Activity}
                      icon={FaPaperclip}
                      activityType="Attachment"
                      color="#826aed"
                    />
                  )}
                {Activity.ActivityLog === "RecodingLog" &&
                  (currentTab === "RECORDING" || currentTab === "ACTIVITY") && (
                    <ActivityLogCard
                      activity={Activity}
                      icon={FaMicrophoneAlt}
                      activityType="Recording"
                      color="#1e555c"
                    />
                  )}
              </>
            ))
          ) : (
            <Alert severity="error">No Data Found</Alert>
          )}
        </CardBody>
      </Card>
    </GridLayout>
  );
};

export default ActivityTab;
