import React, { useEffect, useRef } from "react";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { Typography } from "@progress/kendo-react-common";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  closeDialog,
  openDialog,
} from "../../../components/dialog/dialogSlice";
import { RootState } from "../../../app/store";
import AppDialog from "../../../components/dialog/Dialog";
import FormTextField from "../../../components/formFields/FormTextField";
import { requiredValidator } from "../../../components/formFields/CommonValidator";
import FormTextArea from "../../../components/formFields/FormTextArea";
import FormFileUpload from "../../../components/formFields/FormFileUpload";
import {
  InserAttachment,
  createNotes,
  getAllNotes,
  getNotesById,
  updateNotes,
} from "./services/services.notes";
import { clearNotesDetail } from "./notesSlice";
import { useLocation } from "react-router-dom";
import { LoadingPanel } from "../../../components/layout/Loading";
import SlickSlider from "../../../components/common/SlickSlider";
const CloseLogDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const closeLogDialog = () => {
    dispatch(closeDialog());
    dispatch(clearNotesDetail());
    dispatch(openDialog("Notes"));
  };
  return (
    <Button
      themeColor={"primary"}
      fillMode={"outline"}
      onClick={closeLogDialog}
    >
      Close
    </Button>
  );
};

const CreateNotesDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const NoteGUID = useAppSelector((state) => state.notes.NoteGUID);
  const NotesDetail = useAppSelector((state) => state.notes.NotesDetail);
  const formLoading = useAppSelector((state) => state.notes.formLoading);
  const gridRef = useRef<any>(null);
  const LeadID = location?.state?.ActivityLog?.LeadID;
  const NBInquiryTypeID = location?.state?.ActivityLog?.NBInquiryTypeID;
  const NBLeadTypeID = location.state?.ActivityLog?.NBLeadTypeID;
  const dialogName = useAppSelector(
    (state: RootState) => state.dialog.dialogName
  );

  useEffect(() => {
    if (NoteGUID) {
      dispatch(getNotesById(NoteGUID));
    }
  }, [NoteGUID]);

  const handleSubmit = async (values: any) => {
    const payload = {
      LeadID: LeadID ? LeadID : null,
      NBInquiryTypeID: NBInquiryTypeID ? NBInquiryTypeID : null,
      NBLeadTypeID: NBLeadTypeID ? NBLeadTypeID : null,
    };
    const formData = {
      ...payload,
      Title: values.Title,
      Description: values.Description,
    };
    try {
      if (!NoteGUID) {
        const response = await dispatch(createNotes(formData));
        if (response?.payload?.Status === 201) {
          if (values.attachments) {
            const files = new FormData();
            files.append("AttachmentType", "Note");
            let AttachmentName: string[] = [];
            values?.attachments?.forEach((doc: any) => {
              const binaryDoc = doc.getRawFile();
              AttachmentName.push(binaryDoc.name);
              files.append("AttachmentURL", binaryDoc);
            });
            files.append("AttachmentName", AttachmentName.join(","));
            files.append("LeadID", LeadID || "");
            files.append(
              "ReferenceGUID",
              response?.payload?.Data?.ReferenceGUID || ""
            );
            files.append(
              "NBInquiryTypeID",
              NBInquiryTypeID ? NBInquiryTypeID : ""
            );
            files.append("NBLeadTypeID", NBLeadTypeID ?? "");
            dispatch(InserAttachment(files));
          }
          dispatch(closeDialog());
          dispatch(clearNotesDetail());
          dispatch(getAllNotes(payload));
        }
      } else {
        const editPayload = {
          NoteGUID: NoteGUID,
          ...formData,
        };
        const response = await dispatch(updateNotes(editPayload));
        if (response?.payload?.Status === 200) {
          dispatch(closeDialog());
          dispatch(clearNotesDetail());
          dispatch(getAllNotes(payload));
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  if (formLoading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <>
      {dialogName === "CreateNotes" && (
        <AppDialog className="dialog-width">
          <Form
            onSubmit={handleSubmit}
            initialValues={NotesDetail}
            render={(formRenderProps: FormRenderProps) => (
              <FormElement>
                <Typography.h5 style={{ margin: 0, textAlign: "center" }}>
                  {NoteGUID ? "Update Notes" : "Create Notes"}
                </Typography.h5>
                <GridLayout
                  gap={{ rows: 0, cols: 5 }}
                  cols={[
                    { width: "33.33%" },
                    { width: "33.33%" },
                    { width: "33.33%" },
                  ]}
                  style={{ marginRight: 10 }}
                >
                  <GridLayoutItem colSpan={3}>
                    <Field
                      name="Title"
                      label="Title"
                      placeholder="Title"
                      component={FormTextField}
                      validator={requiredValidator} astrike={true}
                    />
                  </GridLayoutItem>

                  <GridLayoutItem colSpan={3}>
                    <Field
                      name="Description"
                      label="Description"
                      rows={4}
                      component={FormTextArea}
                      validator={requiredValidator} astrike={true}
                    />
                  </GridLayoutItem>
                  {!NoteGUID && (
                    <GridLayoutItem colSpan={2}>
                      <Field
                        name="attachments"
                        type="file"
                        filetype="file"
                        label="Attachments"
                        multiple
                        // accept=".xlsx,.pdf ,.text"
                        component={FormFileUpload}
                      />
                    </GridLayoutItem>
                  )}
                  {NoteGUID && NotesDetail.NotesAttachmentList.length > 0 && (
                    <GridLayoutItem
                      colSpan={2}
                      style={{ marginTop: "20px", padding: "0 25px" }}
                    >
                      <Typography.p>Attachments</Typography.p>
                      <SlickSlider
                        list={NotesDetail.NotesAttachmentList}
                        src="AttachmentURL"
                        title="AttachmentName"
                      />
                    </GridLayoutItem>
                  )}
                </GridLayout>
                <GridLayout
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "10px",
                  }}
                >
                  <Button
                    type="submit"
                    themeColor={"primary"}
                    fillMode={"solid"}
                    style={{ marginRight: "10px" }}
                    disabled={!formRenderProps.allowSubmit}
                  >
                    {NoteGUID ? "Update" : "Save"}
                  </Button>
                  <CloseLogDialog />
                </GridLayout>
              </FormElement>
            )}
          />
        </AppDialog>
      )}
    </>
  );
};

export default CreateNotesDialog;
