import { IconType } from "react-icons";
import { useAppSelector } from "../../app/hooks";
import {
  Avatar,
  GridLayout,
  GridLayoutItem,
} from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import moment from "moment";
import { Link } from "react-router-dom";
import { Button } from "@progress/kendo-react-buttons";

interface ActivityLogCardProps {
  activity: any;
  icon: IconType;
  activityType: string;
  color: string;
}
const ActivityLogCard = ({
  activity,
  icon: Icon,
  activityType,
  color,
}: ActivityLogCardProps) => {
  const alignment = useAppSelector((state) => state.activityLog.alignment);

  return (
    <>
      <GridLayout
        style={{ margin: "25px 10px" }}
        cols={[
          { width: 80 },
          { width: 50 },
          { width: "250px" },
          { width: "12px" },
          { width: "calc(100% - 414px)" },
        ]}
        gap={{ rows: 5, cols: 5 }}
      >
        <GridLayoutItem
          col={1}
          row={1}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "10px",
          }}
        >
          {alignment !== "PAST" ? (
            activity.ActivityLog === "TaskLog" ? (
              <>
                <Typography.p style={{ margin: 0, fontWeight: "bold" }}>
                  Remider {activity.FollowupDate}
                </Typography.p>
                <Typography.p style={{ margin: 0, fontWeight: "bold" }}>
                  {moment(activity.FollowupTime, "HH:mm:ss").format("hh:mm A")}
                </Typography.p>
              </>
            ) : (
              <>
                <Typography.p style={{ margin: 0, fontWeight: "bold" }}>
                  Follow Up {activity.FollowupDate}
                </Typography.p>
                <Typography.p style={{ margin: 0, fontWeight: "bold" }}>
                  {moment(activity.FollowupTime, "HH:mm:ss").format("hh:mm A")}
                </Typography.p>
              </>
            )
          ) : (
            <Typography.p style={{ margin: 0, fontWeight: "bold" }}>
              {activity.ActivityDate}
            </Typography.p>
          )}
        </GridLayoutItem>
        <GridLayoutItem
          col={2}
          row={1}
          style={{
            marginTop: "8px",
            zIndex: 1,
            position: "relative",
          }}
        >
          <div
            style={{
              borderRight: "1.5px solid #cccccc",
              position: "absolute",
              top: "-33px",
              bottom: "-26px",
              left: "-32px",
            }}
          />
          <Avatar
            rounded="full"
            type="image"
            size="small"
            style={{
              position: "relative",
              height: 35,
              width: 35,
              backgroundColor: `${color}`,
            }}
          >
            <Icon
              style={{
                fontSize: 20,
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
              }}
            />
          </Avatar>
        </GridLayoutItem>
        <GridLayoutItem
          col={3}
          row={1}
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Typography.h6
            style={{
              display: "inline",
              color: `${color}`,
              margin: 0,
            }}
          >
            Logged {activityType}
          </Typography.h6>
          <div>
            {/* OWNER */}
            <Avatar
              rounded="full"
              type="image"
              size="small"
              style={{
                position: "relative",
                height: 18,
                width: 18,
                marginRight: 4,
                backgroundColor: "grey",
              }}
            >
              <AccountCircleIcon
                style={{
                  fontSize: 12,
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)",
                }}
              />
            </Avatar>
            <Typography.p
              style={{
                display: "inline",
                fontSize: "small",
                color: "gray",
              }}
            >
              {activity.ActivityOwnName}
            </Typography.p>
          </div>
          {activity.ActivityPurpose ? (
            <Typography.h6 style={{ margin: "0" }}>
              Purpose : {activity.ActivityPurpose}
            </Typography.h6>
          ) : (
            <Typography.h6 style={{ margin: "0" }}>
              Title : {activity.ActivityTitle}
            </Typography.h6>
          )}

          {activity.ActivityType && (
            <Typography.h6 style={{ margin: "0" }}>
              Type : {activity.ActivityType}
            </Typography.h6>
          )}
          {activityType === "Recording" && (
            <>
              <audio src={activity.ActivityDescription} controls></audio>
            </>
          )}

          {activityType !== "Note" &&
            activityType !== "Attachment" &&
            activityType !== "Recording" && (
              <>
                {activity.Activitypriority ? (
                  <Typography.h6 style={{ margin: "0" }}>
                    Priority : {activity.Activitypriority}
                  </Typography.h6>
                ) : activity.ActivityResult ? (
                  <Typography.h6 style={{ margin: "0" }}>
                    Result : {activity.ActivityResult}
                  </Typography.h6>
                ) : (
                  <Typography.h6 style={{ margin: "0" }}>
                    Outcome : {activity.ActivityOutCome}
                  </Typography.h6>
                )}
                <Typography.h6 style={{ margin: "0" }}>
                  Status : {activity.ActivityStutas}
                </Typography.h6>
              </>
            )}
        </GridLayoutItem>
        {activity.ActivityDescription && activityType !== "Recording" && (
          <GridLayoutItem col={4} row={1} style={{ whiteSpace: "pre-line" }}>
            <Typography.h6
              style={{
                display: "inline-block",
                textAlign: "left",
                margin: "0px",
              }}
            >
              {"-"}
            </Typography.h6>
          </GridLayoutItem>
        )}

        {activityType === "Attachment" ? (
          <>
            <Link to={activity.ActivityDescription} target="_blank">
              <Button style={{ backgroundColor: `${color}`, color: "#fff" }}>
                View
              </Button>
            </Link>
          </>
        ) : (
          <>
            <GridLayoutItem col={5} row={1} style={{ textAlign: "justify" }}>
              <Typography.p style={{ display: "inline" }}>
                {activity.ActivityDescription &&
                  activityType !== "Recording" &&
                  activity.ActivityDescription}{" "}
                <br />
                {alignment !== "PAST" && activity?.FollowupNote && activityType !== "Recording" && (
                  <div
                    style={{
                      backgroundColor: "#ffcbcb",
                      borderRadius: "5px",
                      padding: "5px",
                      color: "red",
                      marginTop: 10,
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>
                      {" "}
                      <span style={{ fontWeight: "bolder" }}>
                        Follow Up Note :-
                      </span>{" "}
                      {activity?.FollowupNote}
                    </span>
                  </div>
                )}
              </Typography.p>
            </GridLayoutItem>
          </>
        )}
      </GridLayout>
      <div
        style={{
          borderBottom: "1.5px solid #cccccc",
          width: "100%",
          marginLeft: "154px",
        }}
      />
    </>
  );
};
export default ActivityLogCard;
