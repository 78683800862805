import { createSlice } from "@reduxjs/toolkit";
import { IActivityLogsInitialState } from "./clientActivityTabModel";
import {
  getAllActivityLogs,
  getAllClientActivityLogs,
} from "./services/clientActivityTab.services";

const initialState: IActivityLogsInitialState = {
  loading: false,
  formLoading: false,
  error: "",
  SelectedILTab: 0,
  ActivityLogsList: [],
  ClientActivityLogsList: [],
  alignment: "UPCOMING",
  currentTab: "ACTIVITY",
  SearchDetails: {
    userID: null,
    FormDate: null,
    ToDate: null,
    SelectedDate: null,
  },
};

export const activityLogSlice = createSlice({
  name: "activityLog",
  initialState,
  reducers: {
    tabChange: (state, action) => {
      state.alignment = action.payload;
    },
    setLogTab: (state, action) => {
      state.currentTab = action.payload;
    },
    clearClientActivityLogsList: (state) => {
      state.ClientActivityLogsList = initialState.ClientActivityLogsList;
    },
    resetLogTab: (state) => {
      state.currentTab = initialState.currentTab;
    },
    setSelectedILTab: (state, action) => {
      state.SelectedILTab = action.payload;
    },
    setSearchDetails: (state, action) => {
      state.SearchDetails = action.payload;
    },
  },
  extraReducers: (builder) => {
    // ActivityLogFindAll
    builder.addCase(getAllActivityLogs.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getAllActivityLogs.fulfilled, (state, action) => {
      state.loading = false;
      state.ActivityLogsList = action.payload;
    });
    builder.addCase(getAllActivityLogs.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.ActivityLogsList = [];
    });

    // NBLeadActivityLogFindAll
    builder.addCase(getAllClientActivityLogs.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getAllClientActivityLogs.fulfilled, (state, action) => {
      state.loading = false;
      state.ClientActivityLogsList = action.payload;
    });
    builder.addCase(getAllClientActivityLogs.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.ClientActivityLogsList = [];
    });
  },
});

export const {
  tabChange,
  resetLogTab,
  setLogTab,
  setSelectedILTab,
  clearClientActivityLogsList,
  setSearchDetails,
} = activityLogSlice.actions;
export default activityLogSlice.reducer;
