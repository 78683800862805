import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getAllInquiryLeadNoActivity } from "./services/noActivity.services";
import { INoActivityInquiryLeadInitial } from "./noActivityModel";

const initialState: INoActivityInquiryLeadInitial = {
  loading: false,
  formLoading: false,
  error: "",
  NoActivityLeadInquiryList: [],
  SelectedLeadInquiryTab: 0,
  SearchDetails: {
    InquiryTypeID: [],
    SelectedDate: null,
    FromDate: null,
    ToDate: null,
  },
};

export const noActivityILSlice = createSlice({
  name: "noActivityIL",
  initialState,
  reducers: {
    setSelectedLeadInquiryLTab: (state, action) => {
      state.SelectedLeadInquiryTab = action.payload;
    },
    clearNoActivityLeadInquiryList: (state) => {
      state.NoActivityLeadInquiryList = initialState.NoActivityLeadInquiryList;
    },
    setSearchDetails: (state, action) => {
      state.SearchDetails = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllInquiryLeadNoActivity.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllInquiryLeadNoActivity.fulfilled,
      (state, action: PayloadAction<[]>) => {
        state.loading = false;
        state.NoActivityLeadInquiryList = action.payload;
      }
    );
    builder.addCase(getAllInquiryLeadNoActivity.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.NoActivityLeadInquiryList = [];
    });
  },
});

export const {
  setSelectedLeadInquiryLTab,
  clearNoActivityLeadInquiryList,
  setSearchDetails,
} = noActivityILSlice.actions;
export default noActivityILSlice.reducer;
