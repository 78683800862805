import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAttachment, IAttachmentInitialState } from "./attachmentModel";
import {
  getAllAttachments,
  createAttachment,
  updateAttachment,
  deleteAttachment,
} from "./services/attachment.services";

const initialState: IAttachmentInitialState = {
  loading: false,
  formLoading: false,
  error: "",
  AttachmentList: [],
  AttachmentID: null,
  ReferenceGUID: "",
  AttachmentDetail: {
    ID: null,
    AttachmentURL: "",
    AttachmentName: "",
    LeadID: null,
    NBInquiryTypeID: null,
    ReferenceGUID: "",
    AttachmentType: "",
    NBLeadTypeID: null,
    IsActive: 1,
  },
};

export const attachmentlice = createSlice({
  name: "attachment",
  initialState,
  reducers: {
    clearAttachmentDetail: (state) => {
      state.AttachmentDetail = initialState.AttachmentDetail;
    },
    setAttachmentID: (state, action) => {
      state.AttachmentID = action.payload;
    },
    setReferenceGUID: (state, action) => {
      state.ReferenceGUID = action.payload;
    },
    clearReferenceGUID: (state) => {
      state.ReferenceGUID = initialState.ReferenceGUID;
    },
    clearAttachmentID: (state) => {
      state.AttachmentID = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllAttachments.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllAttachments.fulfilled,
      (state, action: PayloadAction<IAttachment[]>) => {
        state.loading = false;
        state.AttachmentList = action.payload;
      }
    );
    builder.addCase(getAllAttachments.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.AttachmentList = initialState.AttachmentList;
    });

    builder.addCase(createAttachment.pending, (state) => {
      state.formLoading = false;
      state.error = "";
    });
    builder.addCase(createAttachment.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createAttachment.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(updateAttachment.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateAttachment.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateAttachment.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(deleteAttachment.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteAttachment.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteAttachment.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearAttachmentDetail,
  clearAttachmentID,
  clearReferenceGUID,
  setAttachmentID,
  setReferenceGUID,
} = attachmentlice.actions;
export default attachmentlice.reducer;
