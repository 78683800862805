import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { ICountry } from "../countryModel";

export const getAllCountries = createAsyncThunk(
  "/Country/CountryFindAll",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Country/CountryFindAll`,
        {
          CountryName: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching countries:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveCountries = createAsyncThunk(
  "Country/CountryFindAllActive",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Country/CountryFindAllActive`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching countries:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createCountry = createAsyncThunk(
  "Country/CountryInsert",
  async (CountryData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Country/CountryInsert`,
        CountryData
      );
      if (response.data?.Status === 201) {
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating country:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateCountry = createAsyncThunk(
  "Country/CountryUpdate",
  async (CountryData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Country/CountryUpdate`,
        CountryData
      );
      if (response.data?.Status === 200) {
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating country:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getCountryByID = createAsyncThunk(
  "Country/CountryFindByID",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Country/CountryFindByID`,
        {CountryGUID: ID }
      );
      const result = response.data?.Data as any;

      return {
        ID: result.ID,
        CountryGUID: result.CountryGUID,
        CountryName: result.CountryName,
        IsActive: result.IsActive === true ? 1 : 2,
      } as ICountry;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching country:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const countryActiveInactive = createAsyncThunk(
  "Country/CountryActiveInActive",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Country/CountryActiveInActive`,
        formData
      );
      if (response.data?.Status === 200) {
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive country:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteCountry = createAsyncThunk(
  "Country/CountryDelete",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Country/CountryDelete`,
        { CountryGUID:ID }
      );
      if (response.data?.Status === 200) {
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting country:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
