import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IBrochureAttach,
  IBrochureAttachInitialState,
} from "./planBrochureAttachmentModel";
import {
  createBrochureAttachment,
  deleteBrochureAttachment,
  getBrochureAttachmentByPlanID,
  updateBrochureAttachment,
} from "./services/planBrochureAttachment.services";

const initialState: IBrochureAttachInitialState = {
  loading: false,
  formLoading: false,
  error: "",
  BrochureAttachementList: [],
  BrochureAttachmentID: null,
  BrochureAttachDetail: {
    ID: null,
    AttachmentName: "",
    AttachmentURL: "",
  },
};

export const BrochureAttachSlice = createSlice({
  name: "brochureAttachment",
  initialState,
  reducers: {
    clearBrochureAttachDetail: (state) => {
      state.BrochureAttachDetail = initialState.BrochureAttachDetail;
    },
    setBrochureAttachID: (state, action) => {
      state.BrochureAttachmentID = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBrochureAttachmentByPlanID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getBrochureAttachmentByPlanID.fulfilled,
      (state, action: PayloadAction<IBrochureAttach[]>) => {
        state.loading = false;
        state.BrochureAttachementList = action.payload;
      }
    );
    builder.addCase(getBrochureAttachmentByPlanID.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.BrochureAttachementList = [];
    });

    builder.addCase(createBrochureAttachment.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(createBrochureAttachment.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createBrochureAttachment.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(updateBrochureAttachment.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateBrochureAttachment.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateBrochureAttachment.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(deleteBrochureAttachment.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteBrochureAttachment.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteBrochureAttachment.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { setBrochureAttachID, clearBrochureAttachDetail } =
  BrochureAttachSlice.actions;
export default BrochureAttachSlice.reducer;
