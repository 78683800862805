import React from "react";
import { PieChartOutlined, UserOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { GrUserManager } from "react-icons/gr";
import { GrTransaction } from "react-icons/gr";
import { Layout, Menu } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../app/hooks";
import { openDialog } from "../dialog/dialogSlice";
import { setSelectedILTab } from "../../features/clientActivityTab/clientActivityTabSlice";

const { Sider } = Layout;

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}
const items: MenuItem[] = [
  getItem("Dashboard", "", <PieChartOutlined />),
  getItem("User Management", "usermanagement", <GrUserManager />, [
    getItem("User", "user"),
    getItem("User Type", "usertype"),
    getItem("Menu", "menu"),
    getItem("Rights", "rights"),
  ]),
  getItem("Masters", "masters", <UserOutlined />, [
    getItem("Country", "country"),
    getItem("State", "state"),
    getItem("City", "city"),
    getItem("Category", "category"),
    getItem("Inquiry Type", "inquirytype"),
    getItem("Inquiry Sub Type", "inquirysubtype"),
    getItem("Lead Source", "leadsource"),
    getItem("Relation", "relation"),
    getItem("Lead Status", "leadstatus"),
    getItem("Lead Type", "leadtype"),
    getItem("Occupation", "occupationtype"),
    getItem("Company", "company"),
  ]),
  getItem("Transaction", "transaction", <GrTransaction />, [
    getItem("Client", "clientform"),
    getItem("NB Inquiry", "nbinquiry"),
    getItem("Activity Logs", "useractivitylog"),
    getItem("No Activity", "noactivity"),
    getItem("Plan Brochure", "planbrochure"),
    getItem("Upload Excel", `upload-excel`),
  ]),
];

interface HeaderProps {
  collapsed: boolean;
  setCollapsed: (value: boolean) => void;
}

const Header: React.FC<HeaderProps> = ({ collapsed, setCollapsed }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const onClick: MenuProps["onClick"] = (e) => {
    if (e.key === "upload-excel") {
      dispatch(openDialog("CreateExcel"));
    } else if (e.key === "useractivitylog") {
      navigate(e.key, {
        state: {
          IsUserActivityLog: true,
        },
      });
      dispatch(setSelectedILTab(0));
    } else {
      navigate(e.key);
    }
  };

  /****************************ADD BY JYOTINDRA ******************************/
  const [openKeys, setOpenKeys] = React.useState<string[]>([]);
  const onOpenChange: MenuProps["onOpenChange"] = (keys) => {
    // Ensure only one menu item is open at a time
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (items.map((item) => item?.key).includes(latestOpenKey)) {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    } else {
      setOpenKeys(keys);
    }
  };
  /*************************************************************************/

  const userResponseString = localStorage.getItem("UserRightsAssign") || "[]";
  let userResponse: {
    MenuID: number;
    MenuKey: string;
    MenuName: string;
    RightsID: string;
  }[];

  try {
    userResponse = JSON.parse(userResponseString);
  } catch (error) {
    userResponse = [];
  }

  const allowedKeys = new Set(userResponse.map((item) => item.MenuKey));

  function filterMenuItems(menuItems: MenuItem[]): MenuItem[] {
    return menuItems.reduce((filteredItems, item: any) => {
      if (allowedKeys.has(item.key)) {
        if (item.children) {
          const filteredChildren = filterMenuItems(item.children);
          filteredItems.push({ ...item, children: filteredChildren });
        } else {
          filteredItems.push(item);
        }
      } else if (item.children) {
        const filteredChildren = filterMenuItems(item.children);
        if (filteredChildren.length > 0) {
          filteredItems.push({ ...item, children: filteredChildren });
        }
      }
      return filteredItems;
    }, [] as MenuItem[]);
  }

  let filteredItems = filterMenuItems(items);

  const hasDashboardAccess = userResponse.some(
    (item) => item.MenuKey === "dashboard"
  );
  if (hasDashboardAccess) {
    filteredItems = [
      getItem("Dashboard", "", <PieChartOutlined />),
      ...filteredItems,
    ];
  }

  function mapToGetItemFormat(menuItems: MenuItem[]): MenuItem[] {
    return menuItems.map((item: any) =>
      getItem(
        item.label,
        item.key,
        item.icon,
        item.children ? mapToGetItemFormat(item.children) : undefined
      )
    );
  }

  const formattedItems = mapToGetItemFormat(filteredItems);

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
    >
      <div className="drawer-logo">
        <h3 style={{ textAlign: "center", color: "white" }}>MT</h3>
      </div>
      <Menu
        className="sider-scroll"
        style={{
          overflow: "auto",
          height: "calc(100vh - 115px)",
        }}
        onClick={onClick}
        theme="dark"
        onOpenChange={onOpenChange} // Add jyotindra
        openKeys={openKeys} // Add jyotindra
        defaultSelectedKeys={[""]}
        selectedKeys={[location.pathname?.substring(1)?.split("/")[0]]}
        mode="inline"
        // items={items}
        items={formattedItems}
      />
    </Sider>
  );
};

export default Header;
