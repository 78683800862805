import React, { useEffect, useRef, useState } from "react";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import { Button } from "@progress/kendo-react-buttons";
import {
  CompositeFilterDescriptor,
  filterBy,
} from "@progress/kendo-data-query";
import {
  GridCellProps,
  GridCustomCellProps,
  GridCustomHeaderCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  Grid as KendoGrid,
  GridColumn as Column,
} from "@progress/kendo-react-grid";
import {
  HeaderThElement,
  PagerTargetEvent,
} from "@progress/kendo-react-data-tools";
import { MdCancel, MdDelete } from "react-icons/md";
import { Switch } from "@progress/kendo-react-inputs";
import {
  planBrochureActiveInactive,
  deletePlanBrochure,
  getAllPlanBrochure,
} from "./services/planBrochure.services";
import {
  clearPlanBrochureDetail,
  setPlanBrochureID,
} from "./planBrochureSlice";
import { useNavigate } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { LoadingPanel } from "../../components/layout/Loading";
import IconButton from "../../components/common/IconButton";
import AlertBox from "../../components/common/AlertBox";
import { FaPaperclip } from "react-icons/fa";
import { getBrochureAttachmentByPlanID } from "../PlanBrochureAttachment/services/planBrochureAttachment.services";
import SlickSlider from "../../components/common/SlickSlider";
import {
  DELETE_ACCESS,
  EDIT_ACCESS,
  INSERT_ACCESS,
} from "../../_contstants/common";
import { checkAcessRights } from "../../_helper/helper";

const PlanBrochure: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isAddAccess = checkAcessRights(location.pathname, INSERT_ACCESS);

  useEffect(() => {
    const payload = {
      CompanyID: 0,
      PlanID: 0,
    };
    dispatch(getAllPlanBrochure(payload));
    return () => {
      dispatch(closeDialog());
    };
  }, []);

  const handlePlanBrochureCreate = () => {
    navigate("/planbrochure/create");
  };
  return (
    <>
      <DeletePlanBrochureDialog />
      <ViewBrochureAttachmentsDialog />
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4px 20px 20px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            Plan Brochure List
          </Typography.h4>
          {isAddAccess && (
            <Button
              onClick={handlePlanBrochureCreate}
              fillMode={"solid"}
              themeColor={"primary"}
            >
              Create Plan Brochure
            </Button>
          )}
        </GridLayoutItem>
        <GridLayoutItem>
          <PlanBrochureGridComponent />
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

const DeletePlanBrochureDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const PlanBrochureID = useAppSelector(
    (state) => state.planBrochure?.PlanBrochureID
  );
  const dialogName = useAppSelector((state) => state.dialog.dialogName);

  const handleDeleteAction = async (ID: number | null) => {
    const payload = {
      CompanyID: 0,
      PlanID: 0,
    };
    if (ID) {
      const response = await dispatch(deletePlanBrochure(ID));
      if (response.payload?.Data.Status === 200) {
        dispatch(getAllPlanBrochure(payload));
        dispatch(closeDialog());
        dispatch(clearPlanBrochureDetail());
      } else {
        dispatch(getAllPlanBrochure(payload));
        dispatch(closeDialog());
        dispatch(clearPlanBrochureDetail());
      }
    }
  };

  return (
    <>
      {dialogName === "deletePlanBrochure" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Plan Brochure?"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this Plan Brochure?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(PlanBrochureID)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};
const ViewBrochureAttachmentsDialog: React.FC = () => {
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const dispatch = useAppDispatch();
  const BrochureAttachementList = useAppSelector(
    (state) => state.brochureAttachment.BrochureAttachementList
  );
  const loading = useAppSelector((state) => state.brochureAttachment.loading);

  const gridRef = useRef<any>(null);
  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      {dialogName === "viewBrochureAttachments" && (
        <AppDialog className="dialog-width">
          <>
            <GridLayout
              gap={{ rows: 0, cols: 5 }}
              cols={[
                { width: "33.33%" },
                { width: "33.33%" },
                { width: "33.33%" },
              ]}
              style={{ marginRight: 10 }}
            >
              <GridLayoutItem
                colSpan={3}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography.h5>{"Attachments"}</Typography.h5>
                <MdCancel
                  style={{ color: "red", fontSize: 24 }}
                  onClick={() => dispatch(closeDialog())}
                />
              </GridLayoutItem>
              <GridLayoutItem colSpan={3} style={{ padding: 20 }}>
                {BrochureAttachementList?.length > 0 ? (
                  <SlickSlider
                    list={BrochureAttachementList}
                    src="AttachmentURL"
                    title="AttachmentName"
                    download
                    share
                  />
                ) : (
                  <AlertBox alert="No Attachments!" />
                )}
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = { skip: 0, take: 10 };

const PlanBrochureGridComponent: React.FC = () => {
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const PlanBrochureList = useAppSelector(
    (state) => state.planBrochure.PlanBrochureList
  );
  const loading = useAppSelector((state) => state.planBrochure.loading);
  const isEditAccess = checkAcessRights(location.pathname, EDIT_ACCESS);
  const isDeleteAccess = checkAcessRights(location.pathname, DELETE_ACCESS);

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);
  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();

  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All" ? PlanBrochureList.length : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;

  const ViewAttachmentCommandCell = (props: GridCellProps) => {
    const handleViewAttachmentsDialog = (ID: number) => {
      dispatch(openDialog("viewBrochureAttachments"));
      dispatch(getBrochureAttachmentByPlanID(ID));
    };
    return (
      <td
        className="action-td"
        style={{
          ...props.style,
        }}
      >
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Attachments"
          onClick={() => handleViewAttachmentsDialog(props.dataItem.ID)}
        >
          <FaPaperclip
            className="absolute-position"
            style={{ fontSize: "24px" }}
          />
        </IconButton>
      </td>
    );
  };

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleEditItem = (ID: any) => {
      navigate("/planbrochure/edit", {
        state: { ID },
      });
    };
    const handleOpenDeleteDialog = (ID: number) => {
      dispatch(openDialog("deletePlanBrochure"));
      dispatch(setPlanBrochureID(ID));
    };
    return (
      <td
        className="action-td"
        style={{
          ...props.style,
        }}
      >
        {isEditAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Edit"
            onClick={() => handleEditItem(props.dataItem.ID)}
          >
            <FiEdit
              className="absolute-position"
              style={{ fontSize: "20px" }}
            />
          </IconButton>
        )}
        {isDeleteAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Delete"
            onClick={() => handleOpenDeleteDialog(props.dataItem.ID)}
          >
            <MdDelete
              className="absolute-position"
              style={{ fontSize: "24px" }}
            />
          </IconButton>
        )}
      </td>
    );
  };

  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
    setPage({ skip: 0, take: page.take });
  };
  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className="table-header"
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  const MyActiveInActtiveCommandCell = (props: GridCellProps) => {
    const [IsActive, setIsActive] = useState<boolean>(props.dataItem.IsActive);

    const handleActiveInActive = () => {
      setIsActive(!IsActive);
      const data = {
        ID: props.dataItem.ID,
        IsActive: !IsActive,
      };
      const payload = {
        CompanyID: 0,
        PlanID: 0,
      };
      dispatch(planBrochureActiveInactive(data)).then((response: any) => {
        if (response.payload?.data?.Status === 200) {
          dispatch(getAllPlanBrochure(payload));
        } else {
          dispatch(getAllPlanBrochure(payload));
        }
      });
    };

    return (
      <td style={{ textAlign: "center" }}>
        <Switch
          name="IsActive"
          checked={IsActive}
          disabled={isEditAccess ? false : true}
          onChange={handleActiveInActive}
          size="small"
        />
      </td>
    );
  };

  return (
    <>
      {PlanBrochureList && PlanBrochureList.length ? (
        <KendoGrid
          style={{ maxHeight: "calc(100vh - 152px)" }}
          filterable={true}
          filter={filter}
          data={
            filter
              ? filterBy(PlanBrochureList, filter).slice(
                  page.skip,
                  page.take + page.skip
                )
              : PlanBrochureList.slice(page.skip, page.take + page.skip)
          }
          onFilterChange={handleFilterChange}
          skip={page.skip}
          take={page.take}
          total={PlanBrochureList.length}
          pageable={{
            buttonCount: 5,
            pageSizes: [5, 10, 15, "All"],
            pageSizeValue: pageSizeValue,
            type: "input",
          }}
          onPageChange={pageChange}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
        >
          <Column
            field="CompanyName"
            title="Company Name"
            cell={(props: any) => (
              <td>
                {isEditAccess ? (
                  <div
                    className="hoverableName"
                    onClick={() =>
                      navigate("/planbrochure/edit", {
                        state: { ID: props.dataItem?.ID },
                      })
                    }
                  >
                    {props.dataItem?.CompanyName}
                  </div>
                ) : (
                  props.dataItem?.CompanyName
                )}
              </td>
            )}
          />
          <Column field="CreatedOn" title="Date" />
          <Column field="PlanName" title="Plan Name" />
          {/* <Column field="PlanName" title="Attachments" /> */}
          <Column
            field="ID"
            title="Attachments"
            cell={ViewAttachmentCommandCell}
            width={"130px"}
            filterable={false}
            locked={true}
          />
          <Column
            field="ID"
            title="Status"
            cell={MyActiveInActtiveCommandCell}
            width={"90px"}
            filterable={false}
            locked={true}
          />
          {(isDeleteAccess || isEditAccess) && (
            <Column
              field="ID"
              title="Actions"
              cell={MyEditCommandCell}
              width={"110px"}
              filterable={false}
            />
          )}
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

export default PlanBrochure;
