import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../../app/config";
import { axiosApiInstance } from "../../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../../components/toast/Toasts";
import moment from "moment";

export const getAllMeetings = createAsyncThunk(
  "Meetings/MeetingsFindAll",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Meetings/MeetingsFindAll`,
        formData
      );
      return response.data?.Data?.map((e: any) => ({
        ...e,
        IsFollowup: e.IsFollowup ? "True" : "False",
      }));
    } catch (error: any) {
      console.error("Error Fetching Meetings:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createMeetings = createAsyncThunk(
  "Meetings/MeetingsInsert",
  async (Meetings: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Meetings/MeetingsInsert`,
        Meetings
      );
      if (response.data?.Status === 201) {
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Meetings:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateMeetings = createAsyncThunk(
  "Meetings/MeetingsUpdate",
  async (Meetings: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Meetings/MeetingsUpdate`,
        Meetings
      );
      if (response.data?.Status === 200) {
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Meetings:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getMeetingsById = createAsyncThunk(
  "Meetings/MeetingsFindByID",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Meetings/MeetingsFindByID`,
        { MeetingGUID: ID }
      );
      const result = response.data?.Data as any;
      return {
        MeetingGUID: result.MeetingGUID,
        LeadID: result.LeadID,
        NBLeadTypeID: result.NBLeadTypeID,
        NBInquiryTypeID: result.NBInquiryTypeID,
        MeetingTypeID: result.MeetingTypeID,
        MeetingDate: result.MeetingDate
          ? moment(result?.MeetingDate, "DD/MM/YYYY").toDate()
          : "",
        StartTime: result.StartTime
          ? moment(result?.StartTime, "HH:mm").toDate()
          : "",
        EndTime: result.EndTime
          ? moment(result?.EndTime, "HH:mm").toDate()
          : "",
        Location: result.Location,
        Purpose: result.Purpose,
        Description: result.Description,
        MeetingStatusID: result.MeetingStatusID,
        MeetingOutcomeID: result.MeetingOutcomeID,
        Attendee: result.Attendee,
        FollowupDate: result.FollowupDate
          ? moment(result.FollowupDate, "DD/MM/YYYY").toDate()
          : "",
        FollowupTime: result.FollowupTime
          ? moment(result.FollowupTime, "HH:mm").toDate()
          : "",
        FollowupNotes: result.FollowupNotes,
        IsFollowup: result.IsFollowup,
        MeetingsAttachmentList: result.MeetingsAttachmentList ?? [],
        IsActive: result.IsActive === 1 ? true : false,
      };
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Meetings:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteMeetings = createAsyncThunk(
  "Meetings/MeetingsDelete",
  async (ID: string) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Meetings/MeetingsDelete`,
        {
          MeetingGUID: ID,
        }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Deleting Meetings:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const findAllMeetingType = createAsyncThunk(
  "Master/ManageMeetingType",
  async (meetingType: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Master/ManageMeetingType`,
        meetingType
      );
      return response.data.Data;
    } catch (error: any) {
      console.error("Error Deleting meetingType:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const findAllMeetingStatus = createAsyncThunk(
  "Master/ManageMeetingStatus",
  async (meetingStatus: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Master/ManageMeetingStatus`,
        meetingStatus
      );
      return response.data.Data;
    } catch (error: any) {
      console.error("Error Deleting meetingStatus:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
export const MangeMeetingsOutComes = createAsyncThunk(
  "Master/ManageMeetingOutcome",
  async (MeetingsoutCome: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Master/ManageMeetingOutcome`,
        MeetingsoutCome
      );
      return response.data.Data;
    } catch (error: any) {
      console.error("Error Deleting Manage MeetingsoutCome:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
