import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast } from "../../../components/toast/Toasts";
// import { IDashboardDetails } from "../dashboardModel";

export const getDashboard = createAsyncThunk(
  "MasterSub/DashBoardCountView",
  async (filter: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/MasterSub/DashBoardCountView`,
        filter
      );
      if (response.data.Status === 200) {
        return response.data?.Data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      console.error("Error Fetching InquirySubtype:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
export const getDashboardInner = createAsyncThunk(
  "MasterSub/DashboardInnerView",
  async (filter: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/MasterSub/DashboardInnerView`,
        filter
      );
      if (response.data.Status === 200) {
        return response.data?.Data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      console.error("Error Fetching DashboardInner:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getClientsProspectLeadList = createAsyncThunk(
  "MasterSub/ClientsProspectInnerView",
  async (filter: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/MasterSub/ClientsProspectInnerView`,
        filter
      );
      if (response.data.Status === 200) {
        return response.data?.Data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      console.error("Error Fetching Clients Prospect Inner View:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

// ACTIVITY API

export const getUpcomingTasksList = createAsyncThunk(
  "Tasks/FindAllUpComingTask",
  async (UpcomingName: string) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Tasks/FindAllUpComingTask`,
        {
          UpcomingName,
        }
      );
      if (response.data.Status === 200) {
        return response.data?.Data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      console.error("Error Fetching Upcoming Task:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getUpcomingMeetingsList = createAsyncThunk(
  "Meetings/FindAllUpcomingMeeting",
  async (UpcomingName: string) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Meetings/FindAllUpcomingMeeting`,
        {
          UpcomingName,
        }
      );
      if (response.data.Status === 200) {
        return response.data?.Data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      console.error("Error Fetching Upcoming Meetings:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getUpcomingCallsList = createAsyncThunk(
  "Calls/FindAllUpComingCall",
  async (UpcomingName: string) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Calls/FindAllUpComingCall`,
        {
          UpcomingName,
        }
      );

      if (response.data.Status === 200) {
        return response.data?.Data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      console.error("Error Fetching Upcoming Calls:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getInquiryClosingAmount = createAsyncThunk(
  "MasterSub/FindCloseInquiryList",
  async (UserID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/MasterSub/FindCloseInquiryList`,
        {
          UserID,
        }
      );

      if (response.data.Status === 200) {
        return response.data?.Data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      console.error("Error Fetching Upcoming Calls:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getLeadClosingAmount = createAsyncThunk(
  "MasterSub/FindCloseLeadList",
  async (UserID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/MasterSub/FindCloseLeadList`,
        {
          UserID,
        }
      );

      if (response.data.Status === 200) {
        return response.data?.Data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      console.error("Error Fetching Upcoming Calls:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
