import React, { useRef } from "react";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { Typography } from "@progress/kendo-react-common";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { closeDialog } from "../../../components/dialog/dialogSlice";
import { RootState } from "../../../app/store";
import AppDialog from "../../../components/dialog/Dialog";
import FormFileUpload from "../../../components/formFields/FormFileUpload";
import { useLocation } from "react-router-dom";
import {
  createRecoding,
  getAllRecoding,
  updateRecoding,
} from "./services/recoding.services";
import { LoadingPanel } from "../../../components/layout/Loading";
import FormTextField from "../../../components/formFields/FormTextField";
import { clearRecodingID, clearRecodingTitle } from "./recodingSlice";

const CloseLogDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const closeLogDialog = () => {
    dispatch(closeDialog());
  };
  return (
    <Button
      themeColor={"primary"}
      fillMode={"outline"}
      onClick={closeLogDialog}
    >
      Close
    </Button>
  );
};

const CreateRecodingDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const RecodingDetail = useAppSelector(
    (state) => state.recoding.RecodingDetail
  );
  const RecodingID = useAppSelector((state) => state.recoding.RecodingID);
  const formLoading = useAppSelector((state) => state.recoding.formLoading);
  const LeadID = location?.state?.ActivityLog?.LeadID;
  const NBInquiryTypeID = location?.state?.ActivityLog?.NBInquiryTypeID;
  const NBLeadTypeID = location.state?.ActivityLog?.NBLeadTypeID;
  const gridRef = useRef<any>(null);
  const dialogName = useAppSelector(
    (state: RootState) => state.dialog.dialogName
  );
  const payload = {
    LeadID: LeadID ?? null,
    NBLeadTypeID: NBLeadTypeID ?? null,
    NBInquiryTypeID: NBInquiryTypeID ?? null,
  };

  const handleSubmit = async (values: any) => {
    const Title = values.Title;
    try {
      const createFormData = (title: string, binaryData: any) => {
        const formData = new FormData();
        formData.append("LeadID", LeadID || "");
        formData.append(
          "NBInquiryTypeID",
          NBInquiryTypeID ? NBInquiryTypeID : ""
        );
        formData.append("NBLeadTypeID", NBLeadTypeID ? NBLeadTypeID : "");
        if (RecodingID) {
          formData.append("ID", `${RecodingID}` ?? "");
        }
        formData.append("Title", title || "");
        formData.append("RecodingFiles", binaryData);
        return formData;
      };
      // Function to handle processing and dispatching of files
      const processFiles = async (files: any[], title: string) => {
        for (const file of files) {
          const binaryData = await file.getRawFile();
          const formData = createFormData(title, binaryData);
          if (RecodingID) {
            const response = await dispatch(updateRecoding(formData));
            if (response.payload?.Status === 200) {
              dispatch(closeDialog());
              dispatch(clearRecodingID());
              dispatch(clearRecodingTitle());
              dispatch(getAllRecoding(payload));
            }
          } else {
            const response = await dispatch(createRecoding(formData));
            if (response.payload?.Status === 200) {
              dispatch(closeDialog());
              dispatch(clearRecodingID());
              dispatch(clearRecodingTitle());
              dispatch(getAllRecoding(payload));
            }
          }
        }
      };
      await processFiles(values.RecodingFiles, Title);
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  if (formLoading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      {dialogName === "CreateRecoding" && (
        <AppDialog className="dialog-width">
          <Form
            onSubmit={handleSubmit}
            initialValues={RecodingDetail}
            render={(formRenderProps: FormRenderProps) => (
              <FormElement>
                <Typography.h5 style={{ margin: 0, textAlign: "center" }}>
                  {RecodingID ? "Update Recording" : "Create Recording"}
                </Typography.h5>
                <GridLayout
                  gap={{ rows: 0, cols: 5 }}
                  cols={[{ width: "100%" }]}
                  style={{ marginRight: 10 }}
                >
                  <GridLayoutItem>
                    <Field
                      name="Title"
                      type="text"
                      placeholder={"Title"}
                      label={"Title"}
                      component={FormTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="RecodingFiles"
                      type="file"
                      filetype="file"
                      label="Recording File"
                      accept=".wav,.mp3,.m4a ,.aac ,.flac ,.ogg"
                      component={FormFileUpload}
                    />
                  </GridLayoutItem>
                </GridLayout>
                <GridLayout
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "10px",
                  }}
                >
                  <Button
                    type="submit"
                    themeColor={"primary"}
                    fillMode={"solid"}
                    style={{ marginRight: "10px" }}
                    disabled={!formRenderProps.allowSubmit}
                  >
                    {RecodingID ? "Update" : "Save"}
                  </Button>
                  <CloseLogDialog />
                </GridLayout>
              </FormElement>
            )}
          />
        </AppDialog>
      )}
    </>
  );
};

export default CreateRecodingDialog;
