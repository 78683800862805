import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../../app/config";
import { axiosApiInstance } from "../../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../../components/toast/Toasts";

export const getAllNotes = createAsyncThunk("Note/NoteFindAll", async (formData:any) => {
  try {
    const response = await axiosApiInstance.post(
      `${API_URL}/Note/NoteFindAll`,formData
      // {
      //   Notes: "",
      // }
    );
    return response.data?.Data;
  } catch (error: any) {
    console.error("Error Fetching Notes:", error);
    return Promise.reject({
      message: error?.response?.data?.Details || "Something went wrong",
    });
  }
});

export const createNotes = createAsyncThunk(
  "Note/NoteInsert",
  async (Notes: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Note/NoteInsert`,
        Notes
      );
      if (response.data?.Status === 201) {
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Notes:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateNotes = createAsyncThunk(
  "Note/NoteUpdate",
  async (Notes: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Note/NoteUpdate`,
        Notes
      );
      if (response.data?.Status === 200) {
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Notes:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getNotesById = createAsyncThunk(
  "Note/NoteFindByID",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Note/NoteFindByID`,
        {
          NoteGUID: ID,
        }
      );
      response.data?.Data as any;
      return response.data?.Data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Notes:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteNotes = createAsyncThunk(
  "Note/NoteDelete",
  async (ID: string) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Note/NoteDelete`,
        {
          NoteGUID: ID,
        }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Deleting Notes:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const InserAttachment = createAsyncThunk(
  "MasterSub/InsertAttachment",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/MasterSub/InsertAttachment`,
        formData
      );
      if (response.data.Status === 200) {
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error uploading karigar Image:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateAttachment = createAsyncThunk(
  "MasterSub/UpdateAttachment",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/MasterSub/UpdateAttachment`,
        formData
      );
      if (response.data.Status === 200) {
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error uploading karigar Image:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const attachmentFilter = createAsyncThunk(
  "MasterSub/AttachmentFilter",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/MasterSub/AttachmentFilter`,
        formData
      );
      if (response.data.Status === 200) {
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error uploading karigar Image:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
