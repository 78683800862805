import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IDashboardDetails, IDashboardInitialState } from "./dashboardModel";
import {
  getClientsProspectLeadList,
  getDashboard,
  getDashboardInner,
  getInquiryClosingAmount,
  getLeadClosingAmount,
  getUpcomingCallsList,
  getUpcomingMeetingsList,
  getUpcomingTasksList,
} from "./services/dashboard.services";

const initialState: IDashboardInitialState = {
  loading: false,
  formLoading: false,
  error: "",
  dateFilter: {
    SelectedDate: null,
    FromDate: null,
    ToDate: null,
  },
  DashboardDetails: {
    TotalInquiry: null,
    OpenNBInquiry: null,
    OwnInquiry: null,
    GroupCode: "",
    ProspectCount: "",
    UpComingCalls: null,
    UpComingMeetings: null,
    UpComingTasks: null,
    InquiryTypeWiseCounts: [],
    LeadStatusWiseCount: [],
    EmployeeWiseCounts: [],
    EmployeeWiseProspectsCounts: [],
    EmployeeWiseInquiryCloseAmount: [],
    EmployeeWiseLeadCloseAmount: [],
  },
  DashboardInnerList: [],
  ClientsProspectLeadList: [],
  UpcomingTasksList: [],
  UpcomingMeetingsList: [],
  UpcomingCallsList: [],
  UpComingCallsTab: 0,
  UpComingMeetingsTab: 0,
  UpComingTasksTab: 0,
  InquiryClosingAmountList: [],
  LeadClosingAmountList: [],
  ClosingAmountTab: 0,
};

export const DashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    clearDashboardDetails: (state) => {
      state.DashboardDetails = initialState.DashboardDetails;
    },
    clearDateFilter: (state) => {
      state.dateFilter = initialState.dateFilter;
    },
    setDateFilter: (state, action) => {
      state.dateFilter = action.payload;
    },
    setUpComingCallsTab: (state, action) => {
      state.UpComingCallsTab = action.payload;
    },
    setUpComingMeetingsTab: (state, action) => {
      state.UpComingMeetingsTab = action.payload;
    },
    setUpComingTasksTab: (state, action) => {
      state.UpComingTasksTab = action.payload;
    },
    setClosingAmountTab: (state, action) => {
      state.ClosingAmountTab = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDashboard.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getDashboard.fulfilled,
      (state, action: PayloadAction<IDashboardDetails>) => {
        state.loading = false;
        state.DashboardDetails = action.payload;
      }
    );
    builder.addCase(getDashboard.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.DashboardDetails = initialState.DashboardDetails;
    });

    builder.addCase(getDashboardInner.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getDashboardInner.fulfilled,
      (state, action: PayloadAction<[]>) => {
        state.loading = false;
        state.DashboardInnerList = action.payload;
      }
    );
    builder.addCase(getDashboardInner.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.DashboardInnerList = initialState.DashboardInnerList;
    });

    builder.addCase(getClientsProspectLeadList.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getClientsProspectLeadList.fulfilled,
      (state, action: PayloadAction<[]>) => {
        state.loading = false;
        state.ClientsProspectLeadList = action.payload;
      }
    );
    builder.addCase(getClientsProspectLeadList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.ClientsProspectLeadList = initialState.ClientsProspectLeadList;
    });

    // Activity API
    builder.addCase(getUpcomingTasksList.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getUpcomingTasksList.fulfilled,
      (state, action: PayloadAction<[]>) => {
        state.loading = false;
        state.UpcomingTasksList = action.payload;
      }
    );
    builder.addCase(getUpcomingTasksList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.UpcomingTasksList = initialState.UpcomingTasksList;
    });

    builder.addCase(getUpcomingCallsList.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getUpcomingCallsList.fulfilled,
      (state, action: PayloadAction<[]>) => {
        state.loading = false;
        state.UpcomingCallsList = action.payload;
      }
    );
    builder.addCase(getUpcomingCallsList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.UpcomingCallsList = initialState.UpcomingCallsList;
    });

    builder.addCase(getUpcomingMeetingsList.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getUpcomingMeetingsList.fulfilled,
      (state, action: PayloadAction<[]>) => {
        state.loading = false;
        state.UpcomingMeetingsList = action.payload;
      }
    );
    builder.addCase(getUpcomingMeetingsList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.UpcomingMeetingsList = initialState.UpcomingMeetingsList;
    });

    builder.addCase(getInquiryClosingAmount.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getInquiryClosingAmount.fulfilled,
      (state, action: PayloadAction<[]>) => {
        state.loading = false;
        state.InquiryClosingAmountList = action.payload;
      }
    );
    builder.addCase(getInquiryClosingAmount.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.InquiryClosingAmountList = initialState.InquiryClosingAmountList;
    });
    builder.addCase(getLeadClosingAmount.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getLeadClosingAmount.fulfilled,
      (state, action: PayloadAction<[]>) => {
        state.loading = false;
        state.LeadClosingAmountList = action.payload;
      }
    );
    builder.addCase(getLeadClosingAmount.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.LeadClosingAmountList = initialState.LeadClosingAmountList;
    });
  },
});

export const {
  clearDashboardDetails,
  clearDateFilter,
  setDateFilter,
  setUpComingCallsTab,
  setUpComingMeetingsTab,
  setUpComingTasksTab,
  setClosingAmountTab,
} = DashboardSlice.actions;
export default DashboardSlice.reducer;
