import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRecoding, IRecodingInitialState } from "./recodingModel";
import {
  getAllRecoding,
  createRecoding,
  updateRecoding,
  deleteRecoding,
} from "./services/recoding.services";

const initialState: IRecodingInitialState = {
  loading: false,
  formLoading: false,
  error: "",
  RecodingList: [],
  RecodingID: null,
  ReferenceGUID: "",
  RecodingDetail: {
    ID: null,
    NBInquiryTypeID: null,
    RecodingFiles: "",
    Title: "",
    NBLeadTypeID: null,
  },
};

export const recodinglice = createSlice({
  name: "recoding",
  initialState,
  reducers: {
    clearRecodingDetail: (state) => {
      state.RecodingDetail = initialState.RecodingDetail;
    },
    setRecodingID: (state, action) => {
      state.RecodingID = action.payload;
    },
    clearRecodingID: (state) => {
      state.RecodingID = null;
    },
    setRecodingTitle: (state, action) => {
      state.RecodingDetail.Title = action.payload;
    },
    clearRecodingTitle: (state) => {
      state.RecodingDetail.Title = initialState.RecodingDetail.Title;
    },
    setReferenceGUID: (state, action) => {
      state.ReferenceGUID = action.payload;
    },
    clearReferenceGUID: (state) => {
      state.ReferenceGUID = initialState.ReferenceGUID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllRecoding.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllRecoding.fulfilled,
      (state, action: PayloadAction<IRecoding[]>) => {
        state.loading = false;
        state.RecodingList = action.payload;
      }
    );
    builder.addCase(getAllRecoding.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.RecodingList = initialState.RecodingList;
    });

    builder.addCase(createRecoding.pending, (state) => {
      state.formLoading = false;
      state.error = "";
    });
    builder.addCase(createRecoding.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createRecoding.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(updateRecoding.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateRecoding.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateRecoding.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(deleteRecoding.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteRecoding.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteRecoding.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearRecodingDetail,
  clearRecodingID,
  clearReferenceGUID,
  setRecodingID,
  setReferenceGUID,
  setRecodingTitle,
  clearRecodingTitle,
} = recodinglice.actions;
export default recodinglice.reducer;
