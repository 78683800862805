import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { INBinquiryDetail } from "../nbinquiryModel";
import moment from "moment";

export const getAllNBinquiry = createAsyncThunk(
  "NBInquiry/NBInquiryFindAll",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/NBInquiry/NBInquiryFindAll`,
        {
          NBinquiry: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      console.error("Error Fetching NBinquiry:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createNBinquiry = createAsyncThunk(
  "NBInquiry/NBInquiryInsert",
  async (NBinquiry: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/NBInquiry/NBInquiryInsert`,
        NBinquiry
      );
      if (response.data?.Status === 201) {
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching NBinquiry:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
export const getNBinquiryByGUID = createAsyncThunk(
  "NBInquiry/NBInquiryByGUID",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/NBInquiry/NBInquiryByGUID`,
        {
          NBInquiryGUID: ID,
        }
      );
      const result = response.data?.Data as any;
      return {
        ID: result.ID,
        LeadID: result.LeadID,
        LeadStageID: result.LeadStageID === 1 ? "Existing" : "Prospect",
        AllotmentID: result.AllotmentID,
        FamilyID: result.FamilyID,
        NBInquiryList: result.NBInquiryList
          ? result.NBInquiryList?.map((inquiry: any) => ({
              ID: inquiry.ID,
              NBInquiryBy: inquiry.NBInquiryBy,
              InquirySubTypeID: inquiry.InquirySubTypeID
                ? inquiry.InquirySubTypeID.split(",").map(Number)
                : [],
              InquiryDate: inquiry.InquiryDate
                ? moment(inquiry.InquiryDate, "DD/MM/YYYY").toDate()
                : "",
              Frequency: inquiry.Frequency,
              CoPersonAllotmentID: inquiry.CoPersonAllotmentID,
              InquiryAllotmentID: inquiry.InquiryAllotmentID,
              LeadStatusID: inquiry.LeadStatusID,
              LeadTypeID: inquiry.LeadTypeID,
              InquiryNo: inquiry.InquiryNo,
              NBInquiryTypeGUID: inquiry.NBInquiryTypeGUID,
              NBInquiryID: inquiry.NBInquiryID,
              NBInquiryByName: inquiry.NBInquiryByName,
              InquirySubType: inquiry.InquirySubType,
              CoPersonAllotmentName: inquiry.CoPersonAllotmentName,
              InquiryTypeID: inquiry.InquiryTypeID
                ? inquiry.InquiryTypeID.split(",").map(Number)
                : [],
              NBInquiryDetailEntity: {
                // SrNo: inquiry.SrNo,
                InquiryTypeID: inquiry.InquiryTypeID
                  ? inquiry.InquiryTypeID.split(",").map(Number)
                  : [],
                ProposedAmount: inquiry.ProposedAmount
                  ? inquiry.ProposedAmount.split(",").map(Number)
                  : [],
                ClosingAmount: inquiry.ClosingAmount
                  ? inquiry.ClosingAmount.split(",").map(Number)
                  : [],
              },
            }))
          : [],
      } as INBinquiryDetail;
    } catch (error: any) {
      ErrorToast(
        error?.response?.data?.Details || "Something wentdfsadfsd wrong"
      );
      console.error("Error Fetching NBinquiry:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateNBinquiry = createAsyncThunk(
  "NBInquiry/NBInquiryUpdate",
  async (NBinquiry: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/NBInquiry/NBInquiryUpdate`,
        NBinquiry
      );
      if (response.data?.Status === 200) {
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching NBinquiry:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteNBinquiry = createAsyncThunk(
  "NBInquiry/NBInquiryDelete",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/NBInquiry/NBInquiryDelete`,
        {
          NBInquiryGUID: ID,
        }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Deleting NBinquiry:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const findAllActiveNBinquiry = createAsyncThunk(
  "NBInquiry/NBInquiryFindAllActive",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/NBInquiry/NBInquiryFindAllActive`,
        formData
      );
      // SuccessToast(response.data?.Details || "Success")
      return response.data.Data;
    } catch (error: any) {
      console.error("Error Deleting NBinquiry:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

// NBInquiryType API
export const getNBinquiryTypeByGUID = createAsyncThunk(
  "NBInquiry/NBInquiryTypeByGUID",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/NBInquiry/NBInquiryTypeByGUID`,
        {
          NBInquiryTypeGUID: ID,
        }
      );
      const result = response.data?.Data as any;
      return {
        LeadID: result.LeadID,
        LeadStageID: result.LeadStageID === 1 ? "Existing" : "Prospect",
        // AllotmentID: result.AllotmentID,
        NBInquiryList: [
          {
            ID: result.ID,
            NBInquiryTypeGUID: result.NBInquiryTypeGUID,
            NBInquiryID: result.NBInquiryID,
            NBInquiryBy: result.NBInquiryBy,
            NBInquiryByName: result.NBInquiryByName,
            InquirySubTypeID: result.InquirySubTypeID
              ? result.InquirySubTypeID.split(",").map(Number)
              : [],
            LeadTypeID: result.LeadTypeID,
            InquiryAllotmentID: result.InquiryAllotmentID,
            LeadStatusID: result.LeadStatusID,
            CoPersonAllotmentID: result.CoPersonAllotmentID,
            CoPersonAllotmentName: result.CoPersonAllotmentName,
            InquiryTypeID: result.InquiryTypeID
              ? result.InquiryTypeID.split(",").map(Number)
              : [],
            Frequency: result.Frequency,
            InquiryDate: result.InquiryDate
              ? moment(result.InquiryDate, "DD/MM/YYYY").toDate()
              : "",
            InquirySubType: result.InquirySubType,
            NBInquiryDetailEntity: {
              InquiryTypeID: result.InquiryTypeID
                ? result.InquiryTypeID.split(",").map(Number)
                : [],
              ProposedAmount: result.ProposedAmount
                ? result.ProposedAmount.split(",").map(Number)
                : [],
              ClosingAmount: result.ClosingAmount
                ? result.ClosingAmount.split(",").map(Number)
                : [],
            },
          },
        ] as any[],
      } as INBinquiryDetail;
    } catch (error: any) {
      ErrorToast(
        error?.response?.data?.Details || "Something wentdfsadfsd wrong"
      );
      console.error("Error Fetching NBinquiry:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteInquiryArrayDetails = createAsyncThunk(
  "NBInquiry/NBInquiryTypeIDDelete",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/NBInquiry/NBInquiryTypeIDDelete`,
        { ID }
      );
      // SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error LeadFamily Details:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const NBinquiryTypeUpdate = createAsyncThunk(
  "NBInquiry/NBInquiryTypeUpdate",
  async (NBinquiry: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/NBInquiry/NBInquiryTypeUpdate`,
        NBinquiry
      );
      if (response.data?.Status === 200) {
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching NBinquiry:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const InquiryDelete = createAsyncThunk(
  "NBInquiry/NBInquiryTypeIDDelete",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/NBInquiry/NBInquiryTypeIDDelete`,
        { ID }
      );
      // SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error LeadFamily Details:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const findClientsByID = createAsyncThunk(
  "Leads/ClientListById",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Leads/ClientListById`,
        { ID: ID }
      );
      response.data?.Data as any;
      return response.data?.Data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching NBinquiry:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const nbInquiryFindByLeadStatus = createAsyncThunk(
  "NBInquiry/NBInquiryFindLeadStatus",
  async (formdata: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/NBInquiry/NBInquiryFindLeadStatus`,
        formdata
      );
      response.data?.Data as any;
      // return response.data?.Data;
      return response.data?.Data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching NBinquiry:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const findNBinquiryByID = createAsyncThunk(
  "NBInquiry/NBInquiryFindByID",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/NBInquiry/NBInquiryFindByID`,
        { LeadGUID: ID }
      );
      return response.data?.Data?.NBInquiryList as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching NBinquiry:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

// export const createNbLead = createAsyncThunk(
//   "NBLead/NBLeadInsert",
//   async (NbLead: any) => {
//     try {
//       const response = await axiosApiInstance.post(
//         `${API_URL}/NBLead/NBLeadInsert`,
//         NbLead
//       );
//       if (response.data?.Status === 201) {
//         SuccessToast(response.data?.Details || "Success");
//         return response.data;
//       } else {
//         ErrorToast(response?.data?.Details || "Something went wrong");
//         console.error(response?.data?.Details);
//       }
//     } catch (error: any) {
//       ErrorToast(error?.response?.data?.Details || "Something went wrong");
//       console.error("Error Fetching NbLead:", error);
//       return Promise.reject({
//         message: error?.response?.data?.Details || "Something went wrong",
//       });
//     }
//   }
// );

// export const findNBLeadByLeadGUID = createAsyncThunk(
//   "NBLead/NBLeadByLeadGUID",
//   async (ID: any) => {
//     try {
//       const response = await axiosApiInstance.post(
//         `${API_URL}/NBLead/NBLeadByLeadGUID`,
//         { LeadGUID: ID }
//       );
//       response.data?.Data as any;
//       return response.data?.Data;
//     } catch (error: any) {
//       ErrorToast(error?.response?.data?.Details || "Something went wrong");
//       console.error("Error Fetching NBinquiry:", error);
//       return Promise.reject({
//         message: error?.response?.data?.Details || "Something went wrong",
//       });
//     }
//   }
// );
