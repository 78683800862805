import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosApiInstance } from "../../../app/axios";
import { API_URL } from "../../../app/config";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { ICompanyType } from "../companyModel";


export const getAllCompany = createAsyncThunk(
  "Company/CompanyFindAll",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Company/CompanyFindAll`,
        {
          Company: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      console.error("Error Fetching Company:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createCompany = createAsyncThunk(
  "Company/CompanyInsert",
  async (Company: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Company/CompanyInsert`,
        Company
      );
      if (response.data?.Status === 201) {
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Company:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateCompany = createAsyncThunk(
  "Company/CompanyUpdate",
  async (Company: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Company/CompanyUpdate`,
        Company
      );
      if (response.data?.Status === 200) {
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Company:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getCompanyById = createAsyncThunk(
  "Company/CompanyFindByID",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Company/CompanyFindByID`,
        {ID}
      );
      const result = response.data?.Data as any;
      return {
        ID: result.ID,
        CompanyName: result.CompanyName,
        IsActive: result.IsActive === true ? 1 : 2,
        Plan: result.PlanList
          ? result.PlanList.map((results: any) => {
              return {
                ID:results.ID,
                CompanyID:results.CompanyID,
                PlanName: results.PlanName,
                CompanyName:results.CompanyName
              };
            })
          : [],
      } as ICompanyType;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Company:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteCompany = createAsyncThunk(
  "Company/CompanyDelete",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Company/CompanyDelete`,
        {ID}
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Deleting Company:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const findAllActiveCompany = createAsyncThunk(
  "Company/CompanyFindAllActive",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Company/CompanyFindAllActive`
      );
      // SuccessToast(response.data?.Details || "Success")
      return response.data.Data;
    } catch (error: any) {
      console.error("Error Deleting Company:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const CompanyActiveInactive = createAsyncThunk(
  "Company/CompanyActiveInActive",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Company/CompanyActiveInActive`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive Company:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

