import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { ILeadType } from "../leadTypeModel";

export const getAllLeadType = createAsyncThunk(
  "LeadType/LeadTypeFindAll",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/LeadType/LeadTypeFindAll`,
        {
          LeadType: "",
        }
      );
      //   return response.data?.Data;
      if (response?.data?.Status === 200) {
        // SuccessToast(response.data?.Details || "Success");
        return response.data?.Data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      console.error("Error Fetching Lead Type:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createLeadType = createAsyncThunk(
  "LeadType/LeadTypeInsert",
  async (LeadType: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/LeadType/LeadTypeInsert`,
        LeadType
      );
      if (response.data?.Status === 201) {
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Inserting Lead Type:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateLeadType = createAsyncThunk(
  "LeadType/LeadTypeUpdate",
  async (LeadType: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/LeadType/LeadTypeUpdate`,
        LeadType
      );
      if (response.data?.Status === 200) {
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Updating Lead Type:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getLeadTypeById = createAsyncThunk(
  "LeadType/LeadTypeFindByID",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/LeadType/LeadTypeFindByID`,
        {
          LeadTypeGUID: ID,
        }
      );
      const result = response.data?.Data as any;
      return {
        ID: result.ID,
        LeadTypeGUID: result.LeadTypeGUID,
        LeadType: result.LeadType,
        IsActive: result.IsActive === true ? 1 : 2,
      } as ILeadType;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Lead Type:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteLeadType = createAsyncThunk(
  "LeadType/LeadTypeDelete",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/LeadType/LeadTypeDelete`,
        {
          LeadTypeGUID: ID,
        }
      );
      if (response.data?.Status === 200) {
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Deleting Lead Type:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const findAllActiveLeadType = createAsyncThunk(
  "LeadType/LeadTypeFindAllActive",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/LeadType/LeadTypeFindAllActive`
      );
      // SuccessToast(response.data?.Details || "Success")
      return response.data.Data;
    } catch (error: any) {
      console.error("Error Deleting Lead Type:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const LeadTypeActiveInactive = createAsyncThunk(
  "LeadType/LeadTypeActiveInActive",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/LeadType/LeadTypeActiveInActive`,
        formData
      );
      if (response.data?.Status === 200) {
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive Lead Type:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
