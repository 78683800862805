import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import moment from "moment";
import { IOtherInvestor } from "../otherInvestorModel";

export const getAllOtherInvestor = createAsyncThunk(
  "OtherInvestment/OtherInvestmentFindAll",
  async (LeadID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/OtherInvestment/OtherInvestmentFindAll`,
        {
          LeadID,
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      console.error("Error Fetching Other Investor:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createOtherInvestor = createAsyncThunk(
  "OtherInvestment/OtherInvestmentInsert",
  async (formdata: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/OtherInvestment/OtherInvestmentInsert`,
        formdata
      );
      if (response.data?.Status === 201) {
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating other investor:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateOtherInvestor = createAsyncThunk(
  "OtherInvestment/OtherInvestmentUpdate",
  async (formdata: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/OtherInvestment/OtherInvestmentUpdate`,
        formdata
      );
      if (response.data?.Status === 200) {
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error update other investor:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const findOtherInvestorByID = createAsyncThunk(
  "OtherInvestment/OtherInvestmentFindByID",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/OtherInvestment/OtherInvestmentFindByID`,
        { InvestmentGUID: ID }
      );
      const result = response.data?.Data as any;
      return {
        ID: result?.ID,
        InvestmentGUID: result?.InvestmentGUID,
        CategoryID: result?.CategoryID,
        InvestmentDetails: result?.InvestmentDetails,
        AllottedDate: result?.AllottedDate
          ? moment(result?.AllottedDate, "YYYY-MM-DD").toDate()
          : "",
        FurtherAction: result?.FurtherAction,
        UserID: result?.UserID,
        LeadID: result?.LeadID,
      } as IOtherInvestor;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Other Investor:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteOtherInvestor = createAsyncThunk(
  "OtherInvestment/OtherInvestmentDelete",
  async (ID: string) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/OtherInvestment/OtherInvestmentDelete`,
        {
          InvestmentGUID: ID,
        }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Deleting other Investor:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
