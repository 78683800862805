import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  GridLayout,
  GridLayoutItem,
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from "@progress/kendo-react-layout";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import MeetingsDialog from "./MeetingsDialog";
import {
  findAllMeetingStatus,
  findAllMeetingType,
  getAllMeetings,
  MangeMeetingsOutComes,
} from "./services/services.meetings";
import { setSelectedTab } from "./meetingsSlice";

const MeetingsActivityTab: React.FC = () => {
  const location = useLocation();

  const dispatch = useAppDispatch();
  const SelectedTab = useAppSelector((state) => state.meetings.SelectedTab);
  const handleSelect = (e: TabStripSelectEventArguments) => {
    dispatch(setSelectedTab(e.selected));
  };
  const LeadID = location?.state?.ActivityLog?.LeadID;
  const NBInquiryTypeID = location?.state?.ActivityLog?.NBInquiryTypeID;
  const NBLeadTypeID = location.state?.ActivityLog?.NBLeadTypeID;

  useEffect(() => {
    dispatch(findAllMeetingType({ OperationType: 5 }));
    dispatch(findAllMeetingStatus({ OperationType: 5 }));
    dispatch(MangeMeetingsOutComes({ OperationType: 5 }));
  }, []);

  useEffect(() => {
    const payload = {
      LeadID: LeadID ? LeadID : null,
      NBInquiryTypeID: NBInquiryTypeID ? NBInquiryTypeID : null,
      NBLeadTypeID: NBLeadTypeID ? NBLeadTypeID : null,
      MeetingStatusID: SelectedTab === 0 ? 0 : 4,
    };
    dispatch(getAllMeetings(payload));
  }, [SelectedTab]);

  return (
    <>
      <GridLayoutItem
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          //   padding: "4px 20px 20px 0",
        }}
      >
        {/* <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
          Lead Activity Logs
        </Typography.h4> */}
      </GridLayoutItem>
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem>
          <TabStrip
            className="nb-inquiry-types-tab"
            selected={SelectedTab}
            onSelect={handleSelect}
          >
            <TabStripTab title={"Meetings"}>
              <MeetingsDialog />
            </TabStripTab>
            <TabStripTab title={"Closed Meetings"}>
              <MeetingsDialog />
            </TabStripTab>
          </TabStrip>
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

export default MeetingsActivityTab;
