import React, { useState } from "react";
import { FieldRenderProps, FieldWrapper } from "@progress/kendo-react-form";
import {
  InputSuffix,
  TextBox,
  TextBoxProps,
} from "@progress/kendo-react-inputs";
import { FloatingLabel, Hint, Error } from "@progress/kendo-react-labels";
import { eyeIcon, eyeSlashIcon } from "@progress/kendo-svg-icons";
import { Button } from "@progress/kendo-react-buttons";
import { CgDanger } from "react-icons/cg";

const FormPasswordField = (props: TextBoxProps & FieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    optional,
    placeholder,
    ...other
  } = props;

  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : "";
  const errorId = showValidationMessage ? `${id}_error` : "";

  const [inputType, setType] = useState("password");

  return (
    <FieldWrapper>
      <FloatingLabel
        label={label}
        editorId={id}
        editorValue={props.value}
        editorValid={valid}
        editorDisabled={disabled}
        optional={optional}
        className="field-label"
      >
        <TextBox
          {...other}
          valid={valid}
          type={inputType}
          placeholder={placeholder}
          suffix={() => (
            <InputSuffix>
              {inputType === "password" ? (
                <Button
                  themeColor={showValidationMessage ? "error" : "success"}
                  fillMode={"flat"}
                  svgIcon={eyeSlashIcon}
                  onClick={() => setType("text")}
                />
              ) : (
                <Button
                  themeColor={showValidationMessage ? "error" : "success"}
                  fillMode={"flat"}
                  svgIcon={eyeIcon}
                  onClick={() => setType("password")}
                />
              )}
            </InputSuffix>
          )}
          id={id}
          disabled={disabled}
          aria-describedby={`${hintId} ${errorId}`}
        />
      </FloatingLabel>
      {showHint && <Hint id={hintId}>{hint}</Hint>}
      {showValidationMessage && (
        <Error className="d-flex justify-content-end" id={errorId}>
          <span
            className="d-flex align-items-center"
            style={{
              background: "#DF4957",
              color: "white",
              padding: "1px 8px 1px 5px",
              borderRadius: 10,
              gap: 5,
            }}
          >
            <CgDanger /> {validationMessage}
          </span>
        </Error>
      )}
    </FieldWrapper>
  );
};

export default FormPasswordField;
