import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ILeadSorce, ILeadSourceInitialState } from "./leadsourceModel";
import { createLeadSource, deleteLeadSource, findAllActiveLeadSource, getAllLeadSource, getLeadSourceById, LeadSourceActiveInactive, updateLeadSource } from "./services/leadsource.services";


const initialState: ILeadSourceInitialState = {
  loading: false,
  formLoading: false,
  error: "",
  LeadSorceList: [],
  LeadSorceID: null,
  LeadSorceDetail: {
      LeadSourceGUID: "",
      LeadSource: "",
      IsActive: 1
  },
};

export const leadsourceSlice = createSlice({
  name: "leadsource",
  initialState,
  reducers: {
    clearLeadsourceDetails: (state) => {
      state.LeadSorceDetail = initialState.LeadSorceDetail;
    },
    setLeadsourceID: (state, action) => {
      state.LeadSorceID = action.payload;
    },
    clearLeadsourceID: (state) => {
      state.LeadSorceID = null;
    },
  },
  extraReducers: (builder) => {

    builder.addCase(getAllLeadSource.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllLeadSource.fulfilled,
      (state, action: PayloadAction<ILeadSorce[]>) => {
        state.loading = false;
        state.LeadSorceList = action.payload;
      }
    );
    builder.addCase(getAllLeadSource.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.LeadSorceList = [];
    });


    builder.addCase(findAllActiveLeadSource.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      findAllActiveLeadSource.fulfilled,
      (state, action: PayloadAction<ILeadSorce[]>) => {
        state.loading = false;
        state.LeadSorceList = action.payload || [];
      }
    );
    builder.addCase(findAllActiveLeadSource.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.LeadSorceList = [];
    });


    builder.addCase(createLeadSource.pending, (state) => {
      state.formLoading = false;
      state.error = "";
    });
    builder.addCase(createLeadSource.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createLeadSource.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });


    builder.addCase(updateLeadSource.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateLeadSource.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateLeadSource.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });


    builder.addCase(deleteLeadSource.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteLeadSource.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteLeadSource.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });


    builder.addCase(LeadSourceActiveInactive.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(LeadSourceActiveInactive.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(LeadSourceActiveInactive.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });


    builder.addCase(getLeadSourceById.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getLeadSourceById.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.LeadSorceDetail = action.payload;
    });
    builder.addCase(getLeadSourceById.rejected, (state, action) => {
      state.formLoading = false;
      state.LeadSorceDetail = initialState.LeadSorceDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { clearLeadsourceDetails, setLeadsourceID, clearLeadsourceID } =
  leadsourceSlice.actions;
export default leadsourceSlice.reducer;
