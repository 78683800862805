import {
  Field,
  FieldArray,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import React, { useEffect, useRef } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Typography } from "@progress/kendo-react-common";
import { clearCompanyTypeDetails, clearCompanyTypeID } from "./companySlice";
import {
  createCompany,
  getAllCompany,
  getCompanyById,
  updateCompany,
} from "./services/services.company";
import PlanArray from "./PlanArray";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closed } from "../../components/drawer/drawerSlice";
import { LoadingPanel } from "../../components/layout/Loading";
import ShadowCard from "../../components/common/ShadowCard";
import FormTextField from "../../components/formFields/FormTextField";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { STATUSARRAY } from "../../_contstants/common";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";

const CreateCompany: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const gridRef = useRef<any>(null);
  const CompanyTypeDetail = useAppSelector(
    (state) => state.company.CompanyTypeDetail
  );
  const ID = location?.state?.ID;
  const formLoading = useAppSelector((state) => state.company.formLoading);

  useEffect(() => {
    return () => {
      dispatch(clearCompanyTypeDetails());
      dispatch(clearCompanyTypeID());
      dispatch(closed());
    };
  }, []);

  useEffect(() => {
    if (ID) {
      dispatch(getCompanyById(ID));
    }
  }, [ID]);

  const handleSubmit = async (values: any) => {
    try {
      if (!ID) {
        const insertPayload = {
          CompanyName: values.CompanyName,
          Plan: values.Plan
            ? values.Plan.map((plan: any) => {
                return {
                  ID: plan.ID,
                  PlanName: plan.PlanName,
                };
              })
            : [],
          IsActive: values.IsActive === 1,
        };
        const response = await dispatch(createCompany(insertPayload));
        if (response?.payload?.Status === 201) {
          dispatch(closed());
          dispatch(clearCompanyTypeDetails());
          dispatch(getAllCompany());
          navigate(-1);
        }
      } else {
        const editPayload = {
          ID: ID,
          CompanyName: values.CompanyName,
          Plan: values.Plan
            ? values.Plan.map((plan: any) => {
                return {
                  PlanName: plan.PlanName,
                };
              })
            : [],
          IsActive: values.IsActive === 1,
        };
        const response = await dispatch(updateCompany(editPayload));
        if (response?.payload?.Status === 200) {
          dispatch(closed());
          dispatch(clearCompanyTypeDetails());
          dispatch(getAllCompany());
          navigate(-1);
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  const handleFormClose = () => {
    dispatch(closed());
    navigate("/company");
  };

  if (formLoading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={CompanyTypeDetail}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement>
          <ShadowCard style={{ padding: 12 }}>
            <GridLayout
              style={{ marginRight: 20 }}
              gap={{ rows: 0, cols: 10 }}
              cols={[
                { width: "25%" },
                { width: "25%" },
                { width: "25%" },
                { width: "25%" },
              ]}
            >
              <GridLayoutItem colSpan={4}>
                <Typography.h4 className="m-0">
                  {ID ? "Update Company" : "Create Company"}
                </Typography.h4>
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }} colSpan={2}>
                <Field
                  name="CompanyName"
                  label="Company Name"
                  placeholder="Company Name"
                  component={FormTextField}
                  validator={requiredValidator} astrike={true}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  id={"IsActive"}
                  name={"IsActive"}
                  component={FormSelectionField}
                  label={"Status"}
                  options={STATUSARRAY?.map((status: any) => {
                    return {
                      value: status.value,
                      label: status.label,
                    };
                  })}
                />
              </GridLayoutItem>
            </GridLayout>
          </ShadowCard>
          <ShadowCard style={{ marginTop: 10 }}>
            <GridLayoutItem colSpan={6}>
              <Typography.h4>{"Add Plan"}</Typography.h4>
            </GridLayoutItem>
            <FieldArray
              formRenderProps={formRenderProps}
              component={PlanArray}
              name="Plan"
            />
            <GridLayoutItem
              colSpan={3}
              style={{
                textAlign: "end",
                marginTop: "10px",
                width: "100%",
              }}
            >
              <Button
                type="button"
                fillMode={"outline"}
                themeColor={"primary"}
                style={{ marginRight: 5 }}
                onClick={handleFormClose}
              >
                Cancel
              </Button>
              <ButtonWithLoading
                label={ID ? "Update" : "Create"}
                type="submit"
                disabled={!formRenderProps.allowSubmit || formLoading}
                loading={formLoading}
              />
            </GridLayoutItem>
          </ShadowCard>
        </FormElement>
      )}
    />
  );
};

export default CreateCompany;
