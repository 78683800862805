import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import {
  IPlanBrochure,
  IPlanBrochureAttachemntList,
} from "../planBrochureModel";

export const getAllPlanBrochure = createAsyncThunk(
  "PlanBrochure/PlanBrochureFindAll",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/PlanBrochure/PlanBrochureFindAll`,
        formData
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Plan Brochure:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createPlanBrochure = createAsyncThunk(
  "PlanBrochure/PlanBrochureInsert",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/PlanBrochure/PlanBrochureInsert`,
        formData
      );
      if (response.data.Status === 201) {
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating Plan Brochure:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updatePlanBrochure = createAsyncThunk(
  "PlanBrochure/PlanBrochureUpdate",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/PlanBrochure/PlanBrochureUpdate`,
        formData
      );
      if (response.data.Status === 200) {
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating Plan Brochure Update:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deletePlanBrochure = createAsyncThunk(
  "PlanBrochure/PlanBrochureDelete",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/PlanBrochure/PlanBrochureDelete`,
        { ID }
      );
      if (response.data.Status === 200) {
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting Plan Brochure:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getPlanBrochureByID = createAsyncThunk(
  "PlanBrochure/PlanBrochureFindByID",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/PlanBrochure/PlanBrochureFindByID`,
        {
          ID,
        }
      );
      const result = response.data?.Data as any;
      return {
        ID: result.ID,
        CompanyID: result.CompanyID,
        CompanyName: result.CompanyName,
        PlanID: result.PlanID,
        PlanName: result.PlanName,
        CreatedOn: result.CreatedOn,
        CreatedBy: result.CreatedBy,
        CreatedByName: result.CreatedByName,
        UpdatedBy: result.UpdatedBy,
        UpdatedByName: result.UpdatedByName,
        UpdatedOn: result.UpdatedOn,
        PlanBrochureAttachmentList: result.PlanBrochureAttachmentList.map(
          (plan: any) =>
            ({
              ID: plan.ID,
              PlanBrochureID: plan.PlanBrochureID,
              AttachmentName: plan.AttachmentName,
              AttachmentURL: plan.AttachmentURL,
            } as IPlanBrochureAttachemntList)
        ),
        IsActive: result.IsActive === true ? 1 : 2,
      } as IPlanBrochure;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Plan Brochure By ID:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActivePlanBrochure = createAsyncThunk(
  "PlanBrochure/PlanBrochureFindAllActive",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/PlanBrochure/PlanBrochureFindAllActive`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Active Plan Brochure:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const planBrochureActiveInactive = createAsyncThunk(
  "PlanBrochure/PlanBrochureActiveInActive",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/PlanBrochure/PlanBrochureActiveInActive`,
        formData
      );
      if (response.data.Status === 200) {
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive Plan Brochure:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const FindAllCompanyIdByPlan = createAsyncThunk(
  "PlanBrochure/FindAllCompanyIdByPlan",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/PlanBrochure/FindAllCompanyIdByPlan`,
        { CompanyID: ID }
      );
      return response.data?.Data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error  CompanyId By Plan:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
