//  eslint-disable react-hooks/exhaustive-deps
import React, { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  filterBy,
  CompositeFilterDescriptor,
} from "@progress/kendo-data-query";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
} from "@progress/kendo-react-grid";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { PagerTargetEvent } from "@progress/kendo-react-data-tools";
import { HeaderThElement } from "@progress/kendo-react-data-tools";
import { Typography } from "@progress/kendo-react-common";
import AlertBox from "../../components/common/AlertBox";
import { LoadingPanel } from "../../components/layout/Loading";
import IconButton from "../../components/common/IconButton";
import { getDashboardInner } from "./services/dashboard.services";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FiList } from "react-icons/fi";
const DashboardInnerList: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { list = "" } = useParams<{ list: string }>();

  const Title = (titlestring: { list: string }) => {
    const titleMap: Record<string, string> = {
      totalleadlist: "Total Lead List",
      totalopenleadlist: "Total Open Lead List",
      ownleadlist: "Own Lead List",
      leadstatuswiselist: "Lead Status Wise List",
      inquirytypewiselist: "Inquiry Type Wise List",
      employeewiselist: "Employee Wise List",
    };
    return titleMap[titlestring.list] || null;
  };
  const UserID = location?.state?.UserID;
  const LeadStatusID = location?.state?.LeadStatusID;
  const InquiryTypeID = location?.state?.InquiryTypeID;
  const OperationType = location?.state?.OperationType;
  const FromDate = location?.state?.FromDate;
  const ToDate = location?.state?.ToDate;
  useEffect(() => {
    const payload = {
      FromDate: FromDate ?? "",
      ToDate: ToDate ?? "",
      UserID: UserID ?? null,
      LeadStatusID: LeadStatusID ?? null,
      InquiryTypeID: InquiryTypeID ?? null,
      OperationType: OperationType ?? null,
    };

    dispatch(getDashboardInner(payload));
  }, []);

  return (
    <>
      {/* <DeleteCountryDialog /> */}
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4px 20px 20px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            {Title({ list })}
          </Typography.h4>
          {/* <ButtonCreate label="Create Country" drawerName="countryForm" /> */}
        </GridLayoutItem>
        <GridLayoutItem>
          <DashboardInnerListGrid />
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = { skip: 0, take: 10 };

const DashboardInnerListGrid: React.FC = () => {
  const gridRef = useRef<any>(null);
  // const dispatch = useAppDispatch();
  const DashboardInnerList = useAppSelector(
    (state) => state.dashboard?.DashboardInnerList
  );
  const loading = useAppSelector((state) => state.dashboard?.loading);
  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);
  const navigate = useNavigate();
  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();

  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All" ? DashboardInnerList.length : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleInquiryList = (LeadGUID: any, InquiryStatus: any) => {
      navigate("/dashboard/nbinquirylist", {
        state: { LeadGUID, InquiryStatus },
      });
    };
    return (
      <td className={`action-td ${props.className}`} style={{ ...props.style }}>
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          themeColor="info"
          title="Inquiry List"
          style={{ marginRight: 5 }}
          onClick={() =>
            handleInquiryList(props.dataItem.LeadGUID, {
              InquiryStatus: "ALL",
              status: 2,
              LeadID: props.dataItem.LeadID,
              FullName: `${props.dataItem.FirstName} ${props.dataItem.LastName}`,
              LeadStage: props.dataItem.LeadStage,
            })
          }
        >
          <FiList className="absolute-position" style={{ fontSize: "20px" }} />
          {/* <FiEdit className="absolute-position" style={{ fontSize: "20px" }} /> */}
        </IconButton>
        {/* <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Delete"
          
        >
          <MdDelete
            className="absolute-position"
            style={{ fontSize: "24px" }}
          />
        </IconButton> */}
      </td>
    );
  };

  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
    setPage({ skip: 0, take: page.take });
  };
  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      style={props.thProps?.style}
      {...props.thProps}
      className={`table-header ${
        props.index === 10 ||
        props.index === 0 ||
        props.index === 1 ||
        props.index === 2
          ? "locked-header"
          : ""
      }`}
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td
        style={{ ...props.style }}
        {...props.tdProps}
        colSpan={1}
        className={`table-cell ${props.className}`}
      >
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  // const MyActiveInActtiveCommandCell = (props: GridCellProps) => {
  //   const [IsActive, setIsActive] = useState<boolean>(props.dataItem.IsActive);

  //   const handleActiveInActive = () => {
  //     setIsActive(!IsActive);
  //     const data = {
  //       CountryGUID: props.dataItem.CountryGUID,
  //       IsActive: !IsActive,
  //     };
  //       dispatch(countryActiveInactive(data)).then((response: any) => {
  //         if (response.payload?.data?.Status === 200) {
  //           dispatch(getAllCountries());
  //         } else {
  //           dispatch(getAllCountries());
  //         }
  //       });
  //   };

  //   return (
  //     <td style={{ textAlign: "center" }}>
  //       <Switch
  //         name="IsActive"
  //         checked={IsActive}
  //         onChange={handleActiveInActive}
  //         size="small"
  //       />
  //     </td>
  //   );
  // };

  return (
    <>
      {DashboardInnerList && DashboardInnerList.length ? (
        <KendoGrid
          style={{ maxHeight: "calc(100vh - 152px)" }}
          filterable={true}
          filter={filter}
          data={
            filter
              ? filterBy(DashboardInnerList, filter).slice(
                  page.skip,
                  page.take + page.skip
                )
              : DashboardInnerList.slice(page.skip, page.take + page.skip)
          }
          onFilterChange={handleFilterChange}
          skip={page.skip}
          take={page.take}
          total={DashboardInnerList.length}
          pageable={{
            buttonCount: 5,
            pageSizes: [5, 10, 15, "All"],
            pageSizeValue: pageSizeValue,
            type: "input",
          }}
          onPageChange={pageChange}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
        >
          <Column field="InquiryNo" title="Inquiry No" width={110} locked />
          <Column field="InquiryDate" title="Inquiry Date" width={120} locked />
          <Column field="LeadName" title="Client Name" width={250} locked />
          <Column field="InquiryType" title="Inquiry Type" width={120} />
          <Column field="InquirySubType" title="Sub Type" width={120} />
          {/* <Column field="ProposedAmount" title="Proposed Amount" width={200} />
          <Column field="ClosingAmount" title="Closing Amount" width={200} /> */}
          <Column field="Frequency" title="Frequency" width={100} />
          <Column field="LeadType" title="Lead Type" width={110} />
          <Column field="LeadStatus" title="Lead Status" width={100} />
          <Column
            field="CoPersonAllotmentName"
            title="Co-Alloted Person"
            width={250}
          />
          <Column
            field="InquiryAllotmentName"
            title="Alloted To Person"
            width={250}
          />
          {/* <Column field="CountryCode" title="Country Code" /> */}
          {/* <Column
            field="CountryGUID"
            title="Status"
            cell={MyActiveInActtiveCommandCell}
            width={"90px"}
            filterable={false}
            locked={true}
          /> */}
          <Column
            field="ID"
            title="Actions"
            cell={MyEditCommandCell}
            width={110}
            locked
            filterable={false}
          />
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

// const DeleteCountryDialog: React.FC = () => {
//   const dispatch = useAppDispatch();
//   const CountryID = useAppSelector((state) => state.country.CountryID);
//   const dialogName = useAppSelector((state) => state.dialog.dialogName);

//   const handleDeleteAction = async (ID: number | null) => {
//     if (ID) {
//       const response = await dispatch(deleteCountry(ID));
//       if (response.payload?.Data.Status === 200) {
//         dispatch(getAllCountries());
//         dispatch(closeDialog());
//         dispatch(clearCountryID());
//       } else {
//         dispatch(getAllCountries());
//         dispatch(closeDialog());
//         dispatch(clearCountryID());
//       }
//     }
//   };

//   return (
//     <>
//       {dialogName === "DeleteCountry" && (
//         <AppDialog>
//           <>
//             <Typography.h5>{"Delete Country?"}</Typography.h5>
//             <GridLayout>
//               <GridLayoutItem>
//                 <Typography.p>
//                   Are you sure you want to delete this Country?
//                 </Typography.p>
//               </GridLayoutItem>
//               <GridLayoutItem
//                 style={{
//                   display: "flex",
//                   justifyContent: "end",
//                 }}
//               >
//                 <DialogCloseButton themeColor="error" />
//                 <Button
//                   fillMode={"solid"}
//                   themeColor={"error"}
//                   onClick={() => handleDeleteAction(CountryID)}
//                   type="button"
//                 >
//                   Delete
//                 </Button>
//               </GridLayoutItem>
//             </GridLayout>
//           </>
//         </AppDialog>
//       )}
//     </>
//   );
// };

export default DashboardInnerList;
